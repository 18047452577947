import { HttpMethod } from "../../enum/httpMethod";
import { DocumentUtility } from "../../utility/documentUtility";
import { apiConfig, sessionStorageKey } from "../../configuration/config.json"
// import { Redirect } from "react-router-dom";
export class HttpService {
  basseUrl = apiConfig.apiEndPoint;
  protected fetch = (method: HttpMethod | string, urlRoute?: string, headers?: {}, body?: any) => {
    return new Promise<any>((resolve, reject) => {
      let url = this.basseUrl + (urlRoute !== undefined ? urlRoute : "");
      fetch(url, {
        method: method,
        headers: {...headers, 'Content-Type': 'application/json',},
        body: JSON.stringify(body),
        mode: 'cors',
        cache: "no-cache"
      })
        .then((res) => {
          if(res!=undefined || res != null){
            console.log(res)
          res.json().then(x => {
            res.ok ? resolve(x) : reject(this.getResponse(res, x))
          })
          .catch((err) => {
            console.log(err)
          })
          .finally();
        }
      }
        )
        .catch((err) => {
          console.log(err);
          //window.location.href="/under-repair";
        })
        .finally();
      
    });
  };
  private getResponse = (response: Response, data: any) => {
    const location = window.location;
    if (response.status === 401 && location.pathname!=='/') {
      window.localStorage.removeItem(sessionStorageKey.sessionKey);
      window.location.href = "/"
    }
    return { status: response.status, statusText: response.statusText, data: data };
  }
  protected getQuery = (urlRoute?: string, headers?: Headers | string[][] | Record<string, string>) => {
    return this.fetch(HttpMethod.Get, urlRoute, headers);
  }
  protected postQuery = (urlRoute?: string, headers?: Headers | string[][] | Record<string, string>, body?: any) => {

    return this.fetch(HttpMethod.Post, urlRoute, headers, body);
  }
  protected putQuery = (urlRoute?: string, headers?: Headers | string[][] | Record<string, string>, body?: any) => {
    return this.fetch(HttpMethod.Put, urlRoute, headers, body);
  }
  protected patchQuery = (urlRoute?: string, headers?: Headers | string[][] | Record<string, string>, body?: any) => {
    return this.fetch(HttpMethod.PATCH, urlRoute, headers, body);
  }
  protected deleteQuery = (urlRoute?: string, headers?: Headers | string[][] | Record<string, string>, body?: any) => {
    return this.fetch(HttpMethod.DELETE, urlRoute, headers, body);
  }
  protected setUrlParam(param: any, url: string) {
    if (param !== undefined) {
      return `${url}/${param}`
    }
  }
}
