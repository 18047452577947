export function formatNumber(number:number) {
    if (typeof number !== 'number') {
      return number; // Return as is if not a number
    }
  
    const options = {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    };
  
    return number.toLocaleString('en-IN', options);
  }