import React from 'react';
import { NavigationList } from "./NavigationList";
import { Card,Grid } from "@mui/material";

export const Sidebar:React.FC=()=> {

  
  
  return ( 
    <Grid container display="flex" alignItems="center" justifyContent="center">
      <Grid item md={12} sx={{ mb: 1 }}>
        <Card className="sidebar-container">
          {/* <NavigationList role={context.authentication?.session?.role.join(",")} /> */}
          <NavigationList />
        </Card>
      </Grid>
    </Grid>
   
  );
};




