// import React from 'react'

// const Pagination = ({page,setPage}:any) => {
//   return (
// <>
// <button onClick={()=>setPage(page-1)}>-</button>
//     <button disabled>{page}</button>
//     <button onClick={()=>setPage(page+1)}>+</button>
// </>
//   )
// }

// export default Pagination



import * as React from 'react';

import Stack from '@mui/material/Stack';
import { Pagination, Typography } from '@mui/material';

export default function CommonPagination({page,setPage,totalPages}:any) {
//   const [page, setPage] = React.useState(1);
  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    // setPage(value);
    setPage(value - 1);
  };

  const total = Math.ceil(totalPages / 2);

  return (  <Pagination  count={total} page={page+1} onChange={handleChange} />);
}

{/* <Stack rowGap={1}>
<Typography variant='body1' component='p'>Page: {page+1}</Typography>
<Pagination  count={total} page={page+1} onChange={handleChange} />
</Stack> */}