import React, { useEffect, useState } from "react";

import {
  Box,
  Button,
  FormControl,
  Grid,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import userEditIcon from "../../assets/icons/user-edit.svg";
import userSettingIcon from "../../assets/icons/user-setting.svg";
import userDeactivateIcon from "../../assets/icons/deactivate-user.svg";
import { serviceProvider } from "../../provider/serviceProvider";
import { toast } from "react-toastify";
import CommonModal from "../modal/CommonModal";
import { IRole } from "../../interface/IRole";
import SelectFieldComponent from "../common/SelectFieldComponent";
import { ValidationUtiltiy } from "../../utility/ValidationUtility";
import { ValidationType } from "../../enum/validationType";
import { IValidationResponse } from "../../interface/IValidation";
import { useNavigate } from "react-router-dom";
import ButtonComponent from "../common/ButtonComponent";
import InputFieldComponent from "../common/InputFieldComponent";
import { sessionUtility } from "../../utility/sessionUtility";
const initialUserInput = {
  roleId: "",
};
const EditUserModalBody = ({
  handleCloseEditUserModal,
  userId,
  roleId,
  pageState,
  getUsersList,
  isActive,
}: any) => {
  const [userInput, setUserInput] = useState(initialUserInput);
  const [loader, setLoader] = useState<boolean>(false);
  const [allRoles, setAllRoles] = useState([]);
  const navigate = useNavigate();

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setUserInput({ ...userInput, [name]: value });
    validateFieldsOnInput(name);
  };

  const validateFieldsOnInput = (key: string) => {
    switch (key) {
      case "roleId":
        validateRole();
        break;
      default:
        break;
    }
  };

  const logout = () => {
    window.localStorage.clear();
    navigate("/login")
  };

  const handleEditUser = (userId: any) => {
    const userDetails = sessionUtility.getAuthentication().session;
    const param = `${userId}/roles/${userInput.roleId}`;
    setLoader(true);
    isValidRequest()
      .then(() => {
        serviceProvider.userService
          .put(param, {})
          .then((res) => {
            console.log(res);
            if (res.responseCode === 200) {
              toast.success(res.message);
              handleCloseEditUserModal();

              if(userDetails?.uId === userId)  logout()
              if (isActive) {
                const query = `search?uId=NA&email=all&fullName=all&isActive=${true}&offset=${
                  pageState.page
                }&limit=${
                  pageState.pageSize
                }&order=recordUpdatedts.desc&profile=BASIC`;
                getUsersList(query);
              } else {
                const query = `search?uId=NA&email=all&fullName=all&isActive=${false}&offset=${
                  pageState.page
                }&limit=${
                  pageState.pageSize
                }&order=recordUpdatedts.desc&profile=BASIC`;
                getUsersList(query);
              }
              // setTimeout(()=>{
              //   // window.location.reload();
              //   window.location.href =`/users-management?index=${indexNumber}`
              // },100)
            } else {
              toast.error(res?.message || "Something Went Wrong!!");
            }
            setLoader(false);
          })
          .catch((err) => {
            console.log(err);
            toast.error(err?.data?.message || "Something Went Wrong!!");
            handleCloseEditUserModal();
            setLoader(false);
          });
      })
      .catch(() => {
        setLoader(false);
        toast.error("Please fill all the Required Fields!");
      });
  };

  const getAllRoles = () => {
    // setLoader(true);
    serviceProvider.roleService
      .getAllRoles()
      .then((res) => {
        if (res.responseCode === 200) {
          setAllRoles(res.data);
        }
        // setLoader(false);
        console.log({ res });
      })
      .catch((err) => {
        console.log({ err });
        // setLoader(false);
      });
  };

  useEffect(() => {
    getAllRoles();
  }, []);

  useEffect(() => {
    setUserInput({ ...userInput, roleId: roleId });
  }, [roleId]);
  return (
    <Grid container spacing={1}>
      <Grid item xs={12} className="modal-table-container">
        <Grid container className="heading">
          <Grid item xs={12}>
            <Typography variant="h6" component="h6">
              Edit User
            </Typography>
          </Grid>
        </Grid>

        <Grid container rowSpacing={2}>
          {/* <Grid item xs={12}>
            <Box
              pl="10px"
              pr="10px"
              border="1px solid gray"
              borderRadius="20px"
            >
              <InputFieldComponent
                id="user-fullname"
                type="text"
                name="name"
                placeholder="Enter Fullname"
                // value={userInput.name}
                onChange={handleInputChange}
              />
            </Box>
          </Grid> */}
          <Grid item xs={12}>
            <Box
              pl="10px"
              pr="10px"
              border="1px solid gray"
              borderRadius="20px"
            >
              <SelectFieldComponent
                id="user-roleId"
                name="roleId"
                value={userInput.roleId}
                options={allRoles}
                labelKey="roleDisplayName"
                optionKey="roleId"
                onChange={handleInputChange}
              />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <span className="modalError" id="errorDiv-user-roleId"></span>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid
          container
          columnSpacing={2}
          display="flex"
          justifyContent="center"
          alignItems="center"
          mt="20px"
        >
          <Grid item xs={2}>
            <ButtonComponent
              fullWidth={false}
              loader={loader}
              disabled={loader}
              label="Save"
              onClick={() => handleEditUser(userId)}
            />
            {/* <Button
              variant="contained"
              className="rounded-btn-fill"
              onClick={() => handleEditUser(userId)}
            >
              Save
            </Button> */}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default EditUserModalBody;

const isValidRequest = (): Promise<boolean> => {
  let responseArray: IValidationResponse[] = [];
  responseArray.push(validateRole());
  // responseArray.push(ValidationUtiltiy.validateElement("signup-confirmPassword", ValidationType.Text, true, "errorDiv-cpwd", "Confirm Password"));
  let haveErrors =
    responseArray.filter((x) => {
      return !x.isValid;
    }).length <= 0;
  return new Promise((resolve, reject) => {
    if (haveErrors) {
      resolve(true);
    } else {
      reject(false);
    }
  });
};

const validateRole = () => {
  return ValidationUtiltiy.validateElement(
    "user-roleId",
    ValidationType.MUISelect,
    true,
    "errorDiv-user-roleId",
    "Role"
  );
};
