// import { Divider, Grid, Typography } from '@mui/material'
// import { Box } from '@mui/system'
// import React, { useState } from 'react';
// import img from "../../assets/images/cessena.svg";
// import ButtonComponent from '../common/ButtonComponent';
// import CommonModal from '../modal/CommonModal';
// import EditAvailability from './EditAvailability';
// import { useNavigate } from 'react-router-dom';
// import { getFormattedDate, getFormattedTime } from '../../utility/formateDateAndTime';

// const AircraftAvailabiltyCard = ({aircraft}:any) => {

//     const [open,setOpen]=useState(false);
// const navigate= useNavigate();

//     const handleOpenModal=()=>setOpen(true)
//     const handleCloseModal=()=>setOpen(false);
// console.log({aircraft})
//     const images = aircraft && aircraft?.aircraftDetails && aircraft?.aircraftDetails?.imageUrl !== "" && JSON.parse(aircraft?.aircraftDetails?.imageUrl);


//     const handleNavigateToAircraftDetails=()=>{
//         const encodedAircraftDetails = encodeURIComponent(JSON.stringify(aircraft));
//         const queryParams = new URLSearchParams({
//           aircraftDetails: encodedAircraftDetails,
//         });
//         // navigate(`/search-results/${itineraryId}?${queryParams}`);

//         navigate(`/aircraft-details?${queryParams}`)
//     }
//     return (
//       <>
//         <CommonModal open={open} handleCloseModal={handleCloseModal} modalBody={<EditAvailability operatorUid={aircraft?.operatorUid} aircraftId={aircraft?.aircraftId}/>} />
//         <Grid container spacing={1.5} className="aircraft-card">
//             <Grid item xs={2}>
//                 <Box className="card-img">
//                     <img src={images && images[0]} alt={aircraft?.aircraftDetails?.name} height="100%" width="100%" />
//                 </Box>
//             </Grid>
//             <Grid item xs={10}>
//                 <Grid container>
//                     <Grid item xs={6}>
//                         <Grid container>
//                             <Grid item xs={4}>
//                                 <Typography variant='h6' component='h6'>Aircraft Name</Typography>
//                             </Grid>
//                             <Grid item xs={8}>
//                                 <Typography variant='h6' component='h6'>: {aircraft?.aircraftDetails?.name || "--"}</Typography>
//                             </Grid>
//                         </Grid>
//                         <Grid container>
//                             <Grid item xs={4}>
//                                 <Typography variant="body1" component="p">
//                                     Call Sign
//                                 </Typography>
//                             </Grid>
//                             <Grid item xs={8}>
//                                 <Typography variant="body1" component="p">
//                                     : {aircraft?.aircraftDetails?.callSign || "--"}
//                                 </Typography>
//                             </Grid>
//                         </Grid>
//                         <Grid container>
//                             <Grid item xs={4}>
//                                 <Typography variant="body1" component="p">
//                                     Fleet name
//                                 </Typography>
//                             </Grid>
//                             <Grid item xs={8}>
//                                 <Typography variant="body1" component="p">
//                                     : {aircraft?.fleetDetails?.name || "--"}
//                                 </Typography>
//                             </Grid>
//                         </Grid>
//                         <Grid container>
//                             <Grid item xs={4}>
//                                 <Typography variant="body1" component="p">
//                                 Passenger capacity
//                                 </Typography>
//                             </Grid>
//                             <Grid item xs={8}>
//                                 <Typography variant="body1" component="p">
//                                     : {aircraft?.aircraftDetails?.passengerCapacity || "--"}
//                                 </Typography>
//                             </Grid>
//                         </Grid>

//                     </Grid>
//                     <Grid item xs={0.1} sx={{display:"flex",justifyContent:"left"}}>
//                             <Divider orientation='vertical'/>
//                             </Grid>
//                     <Grid item xs={3}>

//                         <Grid container>

//                             <Grid item xs={4}>
//                                 <Typography variant='h6' component='h6'>Availability</Typography>
//                             </Grid>
//                             {/* <Grid item xs={8}>
//                                 <Typography variant='h6' component='h6'>: Beachcraft Super King Air</Typography>
//                             </Grid> */}
//                         </Grid>
//                         <Grid container>
//                             <Grid item xs={4}>
//                                 <Typography variant="body1" component="p">
//                                     From
//                                 </Typography>
//                             </Grid>
//                             <Grid item xs={8}>
//                                 <Typography variant="body1" component="p">
//                                     : {`${getFormattedDate(aircraft?.availabilityStartDt)} ${getFormattedTime(aircraft?.availabilityStartDt)}`}
//                                 </Typography>
//                             </Grid>
//                         </Grid>
//                         <Grid container>
//                             <Grid item xs={4}>
//                                 <Typography variant="body1" component="p">
//                                     To
//                                 </Typography>
//                             </Grid>
//                             <Grid item xs={8}>
//                                 <Typography variant="body1" component="p">
//                                 : {`${getFormattedDate(aircraft?.availabilityEndDt)} ${getFormattedTime(aircraft?.availabilityEndDt)}`}
//                                 </Typography>
//                             </Grid>
//                         </Grid>
//                         <Grid container>
//                             <Grid item xs={4}>
//                                 <Typography variant="body1" component="p">
//                                 Airport
//                                 </Typography>
//                             </Grid>
//                             <Grid item xs={8}>
//                                 <Typography variant="body1" component="p">
//                                     : {aircraft?.airportDetails?.name || "--"}
//                                 </Typography>
//                             </Grid>
//                         </Grid>

//                     </Grid>
//                     <Grid item xs={2.9} >
//                     <Typography variant='h6' component='h6'>Current status : {aircraft?.active ?"Active":"Inactive"}</Typography>
//                     <Box className="btn-container">
//                         <ButtonComponent className="rounded-btn-outlined" fullWidth={false} label="Details" onClick={handleNavigateToAircraftDetails}/>
//                         <ButtonComponent fullWidth={false} label="Edit availability" onClick={handleOpenModal}/>
//                     </Box>
//                     </Grid>
//                 </Grid>
//             </Grid>
//         </Grid>
//       </>
//     )
// }

// export default AircraftAvailabiltyCard;




import { Divider, Grid, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React, { useContext, useRef, useState } from 'react';
import img from "../../assets/images/cessena.svg";
import ButtonComponent from '../common/ButtonComponent';
import CommonModal from '../modal/CommonModal';
import EditAvailability from './EditAvailability';
import { useNavigate } from 'react-router-dom';
import { getFormattedDate, getFormattedTime } from '../../utility/formateDateAndTime';
import S3 from "react-aws-s3-typescript";
import { awsConfig } from "../../configuration/config.json";
import { toast } from 'react-toastify';
import { ValidationUtiltiy } from '../../utility/ValidationUtility';
import UserContext from '../../context/UserContext';
import { serviceProvider } from '../../provider/serviceProvider';


const initialPayload = {

    aircraftId: "",
    approvedByUid: "",
    imageUrl: "",
    operatorAdminRequestId: "",
    operatorUid: "",
    reasonForCancellation: "",
    status: "PENDING"

}
const AircraftAvailabiltyCard = ({ aircraft, isOperator = false }: any) => {

    const [open, setOpen] = useState(false);
    const [confirmationModal, setConfirmationModal] = useState(false);
    const [loader, setLoader] = useState(false);
    const [requestImageModal, setRequestImageModal] = useState(false);
    const navigate = useNavigate();
    const [imageURLS, setImageURLs] = useState<any>([]);
    const [userInput, setUserInput] = useState<any>(initialPayload);
    // const handleOpenModal = () => setRequestImageModal(true)
    // const handleCloseModal = () => setRequestImageModal(false);
    const context = useContext(UserContext)
    const operatorId = context?.authentication?.session?.uId;
    const handleOpenModal = () => setOpen(true)
    const handleCloseModal = () => setOpen(false);

    const handleOpenRequestImageModal = () => setRequestImageModal(true)
    const handleCloseRequestImageModal = () => setRequestImageModal(false);

    const handleOpenConfirmationModal = () => setConfirmationModal(true)
    const handleCloseConfirmationModal = () => setConfirmationModal(false);
    console.log({ aircraft })
    const images = aircraft && aircraft?.aircraftDetails && aircraft?.aircraftDetails?.imageUrl !== "" && JSON.parse(aircraft?.aircraftDetails?.imageUrl);
    // const images = [1,2,3,4]


    const chooseFileRef: React.MutableRefObject<HTMLInputElement | null> = useRef(null);


    const handleChooseFile = () => {
        // 👇️ open file input box on click of another element
        chooseFileRef.current?.click();
    };
    console.log({ imageURLS });

    // new added
    const handleFileChange = (event: any) => {
        const fileObjs: [] = event.target.files;

        if (!fileObjs) {
            return;
        }

        const validFiles = Array.from(fileObjs).filter(fileObj => ValidationUtiltiy.isValidImg(fileObj["name"]));

        if (validFiles.length === 0) {
            toast.error("No valid files selected.");
            return;
        }

        const uploadPromises = validFiles.map((file: any, index) => {
            const newFileName = "123" + "_" + file.name.replace(/\..+$/, "");
            return uploadImage(file, newFileName);
        });

        Promise.all(uploadPromises)
            .then((uploadedURLs) => {
                setImageURLs((prevImageURLs: any) => [...prevImageURLs, ...uploadedURLs]);
                updateUserInput(JSON.stringify(uploadedURLs));
                toast.success("Aircrafts Pictures Uploaded");
            })
            .catch((error) => {
                console.error(error);
                toast.error("Error Occurred");
            });
    };


    // new added

    const uploadImage = (file: any, newFile: any) => {

        return new Promise((resolve, reject) => {
            const ReactS3Client = new S3(awsConfig);
            ReactS3Client.uploadFile(
                file,
                awsConfig.aircraftsSubFolder + "/" + newFile
            ).then((data) => {
                if (data.status === 204) {
                    resolve(data.location);
                } else {
                    reject("File Not Uploaded");
                }
            }).catch((err: any) => {
                reject("Something Went Wrong.");
            });
        });
    };
    //   console.log({ files })

    const updateUserInput = (imageUrl: string) => {
        // alert("dfdd")
        // console.log({ imageUrl });

        if (imageUrl !== "") setUserInput({ ...userInput, imageUrl: imageUrl })
    }



    const handleRequestImagesToApprove = (aircraftId: any, operatorId: any,) => {
        const payload = { ...userInput, aircraftId, operatorUid: operatorId }

        setLoader(true);
        serviceProvider.operatorRequestService.post(payload).then((res) => {
            setLoader(false)
            console.log(res)
            if (res.responseCode === 200) {
                toast.success(res.message);

                setTimeout(() => {
                      window.location.reload()
                }, 100)
            } else {
                toast.error("Something Went Wrong!!")
            }

        }).catch((err) => {
            console.log({ err });
            setLoader(false)
            toast.error(err?.data?.message || "Something Went Wrong!!")

        })
    }
    const handleDeleteAircraftAvailibility = (availabilityId: string) => {
        

        setLoader(true);
        serviceProvider.aircraftAvailabilityService.delete(availabilityId).then((res) => {
            setLoader(false)
            console.log(res)
            if (res.responseCode === 200) {
                toast.success(res?.message);
                handleCloseConfirmationModal()
                setTimeout(() => {
                      window.location.reload()
                }, 100)
            } else {
                toast.error("Something Went Wrong!!")
            }

        }).catch((err) => {
            console.log({ err });
            setLoader(false)
            toast.error(err?.data?.message || "Something Went Wrong!!")

        })
    }


    const requestImageModalBody = (
        <Grid container rowSpacing={3}>
            <Grid item xs={12}>
                <Typography variant='h6' component='h6'>Request for Images to approve</Typography>
            </Grid>
            <Grid item xs={12} display="flex" justifyContent="center">
                <input type="file" multiple accept="image/*" ref={chooseFileRef} style={{ display: "none" }} onChange={handleFileChange} />
                <ButtonComponent className="rounded-btn-outlined" fullWidth={false} label="Upload Images" onClick={handleChooseFile} />
            </Grid>
            <Grid item xs={12} display="flex" justifyContent="center" alignItems="center">
                <ButtonComponent fullWidth={false} label="Submit" onClick={() => handleRequestImagesToApprove(aircraft?.aircraftDetails?.aircraftId, operatorId)} />
            </Grid>
        </Grid>
    )
    const removeAvailabilityConfirmatonModalBody = (
        <Grid container rowSpacing={5}>
            <Grid item xs={12}>
                <Typography variant='h6' component='h6' textAlign="center">Are you sure you want to remove aircraft Availability ?</Typography>
            </Grid>
           
            <Grid item xs={12} >
                <Box display="flex" justifyContent="center" alignItems="center" gap="20px">

                <ButtonComponent className="rounded-btn-outlined" fullWidth={false} label="Cancel" onClick={handleCloseConfirmationModal} />
                <ButtonComponent fullWidth={false} label="Yes" disabled={loader} onClick={()=>handleDeleteAircraftAvailibility(aircraft?.availabilityId)} />
                </Box>
            </Grid>
        </Grid>
    )

    const handleNavigateToAircraftDetails = (aircraftId:any) => {
        // const encodedAircraftDetails = encodeURIComponent(JSON.stringify(aircraft));
        // const queryParams = new URLSearchParams({
        //     aircraftDetails: encodedAircraftDetails,
        // });
        // navigate(`/search-results/${itineraryId}?${queryParams}`);

        navigate(`/aircraft-details/${aircraftId}`)
    }
    return (
        <>
            <CommonModal open={open} handleCloseModal={handleCloseModal} modalBody={<EditAvailability operatorUid={aircraft?.operatorUid} aircraftId={aircraft?.aircraftId} availabilityId={aircraft?.availabilityId} aircraft={aircraft} isOperator={isOperator} handleCloseModal={handleCloseModal} />} />
            <CommonModal open={requestImageModal} handleCloseModal={handleCloseRequestImageModal} modalBody={requestImageModalBody} />
            <CommonModal open={confirmationModal} handleCloseModal={handleCloseConfirmationModal} modalBody={removeAvailabilityConfirmatonModalBody} />
            <Grid container className="aircraft-card">
                <Grid item xs={12} sm={4} md={3} lg={2}>
                    <Box className="card-img">
                        <img src={images && images[0]} alt={aircraft?.aircraftDetails?.name} height="100%" width="100%" />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={7} md={7} lg={7} pl="12px">
                    <Grid container  >
                        <Grid item xs={4} lg={3}>
                            <Typography variant='h6' component='h6'>Aircraft Name</Typography>
                        </Grid>
                        <Grid item xs={8} lg={9}>
                            <Typography variant='h6' component='h6'>: {aircraft?.aircraftDetails?.name || "--"}</Typography>
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={4} lg={3}>
                            <Typography variant="body1" component="p">
                                Call Sign
                            </Typography>
                        </Grid>
                        <Grid item xs={8} lg={9}>
                            <Typography variant="body1" component="p">
                                : {aircraft?.aircraftDetails?.callSign || "--"}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={4} lg={3}>
                            <Typography variant="body1" component="p">
                                Fleet name
                            </Typography>
                        </Grid>
                        <Grid item xs={8} lg={9}>
                            <Typography variant="body1" component="p">
                                : {aircraft?.fleetDetails?.name || "--"}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={4} lg={3}>
                            <Typography variant="body1" component="p">
                                Guest capacity
                            </Typography>
                        </Grid>
                        <Grid item xs={8} lg={9}>
                            <Typography variant="body1" component="p">
                                : {aircraft?.aircraftDetails?.passengerCapacity || "--"}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} lg={3}>
                    <Typography variant='h6' component='h6' textAlign={{ xs: "left", lg: "right" }}>Current status : {aircraft?.active ? "Active" : "Inactive"}</Typography>
                </Grid>

                <Grid item xs={12} pt="12px">

                    <Grid container>

                        <Grid item xs={12} lg={8}>
                            <Typography variant='h6' component='h6'>Availability</Typography>
                        </Grid>

                        {/* <Grid item xs={8}>
                                <Typography variant='h6' component='h6'>: Beachcraft Super King Air</Typography>
                            </Grid> */}
                    </Grid>
                    
                    <Grid container alignItems="center">
                        <Grid item xs={12} lg={2.5}>
                            <Typography variant="body1" component="p">
                                From  : {`${getFormattedDate(aircraft?.availabilityStartDt)} ${getFormattedTime(aircraft?.availabilityStartDt)}`}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} lg={2.5}>
                            <Typography variant="body1" component="p">
                                To  : {`${getFormattedDate(aircraft?.availabilityEndDt)} ${getFormattedTime(aircraft?.availabilityEndDt)}`}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} lg={3}>
                            <Typography variant="body1" component="p">
                                Airport   : {aircraft?.airportDetails?.name || "--"}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} lg={4}>
                            <Box className="btn-container">
                                {/* {
                                    isOperator && <ButtonComponent fullWidth={false} label="Request Images" onClick={handleOpenRequestImageModal} />
                                } */}
                                <ButtonComponent className="rounded-btn-outlined" fullWidth={false} label="Delete availability" onClick={handleOpenConfirmationModal} />
                                <ButtonComponent fullWidth={false} label="Edit availability" onClick={handleOpenModal} />
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

export default AircraftAvailabiltyCard