
import React,{useState} from "react"

import { Button, FormControl, Grid, MenuItem, Select, TextField, Tooltip, Typography } from '@mui/material';
import userEditIcon from "../../assets/icons/user-edit.svg"
import userSettingIcon from "../../assets/icons/user-setting.svg"
import userDeactivateIcon from "../../assets/icons/deactivate-user.svg";
import userActivateIcon from "../../assets/icons/activate-user.svg";
import CommonModal from "../modal/CommonModal";
import DeactivateUserModalBody from "./DeactivateUser";
import EditUserModalBody from "./EditUser";
import { useNavigate } from "react-router-dom";
import ActivateUserModalBody from "./ActivateUser";

export const ManageUser = ({ userId, roleId ,isActive,pageState,getUsersList}:any) => {
    const [openDeactivateUser, setOpenDeactivateUser] = useState(false);
    const [openActivateUser, setOpenActivateUser] = useState(false);
    const handleOpenDeactivateUserModal = () => setOpenDeactivateUser(true)
    const handleCloseDeactivateUserModal = () => setOpenDeactivateUser(false);
    const [openEditUser, setOpenEditUser] = useState(false);
    const navigate= useNavigate()
   
    const handleOpenEditUserModal = () => setOpenEditUser(true)
    const handleCloseEditUserModal = () => setOpenEditUser(false);


    const handleOpenActivateUserModal=()=> setOpenActivateUser(true)
    const handleCloseActivateUserModal=()=> setOpenActivateUser(false)
    return (
       <>
        <CommonModal open={openDeactivateUser} handleCloseModal={handleCloseDeactivateUserModal} modalBody={<DeactivateUserModalBody handleCloseDeactivateUserModal={handleCloseDeactivateUserModal} userId={userId} pageState={pageState} getUsersList={getUsersList}/>} />
        <CommonModal open={openActivateUser} handleCloseModal={handleCloseActivateUserModal} modalBody={<ActivateUserModalBody handleCloseActivateUserModal={handleCloseActivateUserModal} userId={userId} pageState={pageState} getUsersList={getUsersList}/>} />
        <CommonModal open={openEditUser} handleCloseModal={handleCloseEditUserModal} modalBody={<EditUserModalBody handleCloseEditUserModal={handleCloseEditUserModal} userId={userId} roleId={roleId} pageState={pageState} getUsersList={getUsersList} isActive={isActive}/>} />
      <Grid container columnSpacing={2}>
        <Grid item xs={4} spacing={2}>
          <Tooltip title="Update User">
            <Button onClick={handleOpenEditUserModal}>
              <img src={userEditIcon} alt="edit icon" height="20px" width="20px" />
            </Button>
          </Tooltip>
        </Grid>
        <Grid item xs={4}>
          <Tooltip title={`${isActive ? "Deactivate User":"Activate User"}`}>
            <Button onClick={isActive?handleOpenDeactivateUserModal:handleOpenActivateUserModal}>
              <img src={isActive ? userDeactivateIcon : userActivateIcon} alt="icon" height="20px" width="20px" />
            </Button>
          </Tooltip>
        </Grid>
        {/* <Grid item xs={4}>
          <Tooltip title="User Settings">
            <Button onClick={()=>navigate("/")}>
              <img src={userSettingIcon} alt="setting icon" height="20px" width="20px" />
            </Button>
          </Tooltip>
        </Grid> */}
      </Grid>
       </>
    );
  };
  