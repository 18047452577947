import { Skeleton } from '@mui/material';
import React from 'react';

interface LoaderProps{
    height:string,
    width:string
}

const Loader:React.FC<LoaderProps> = ({height,width}) => {
  return  <Skeleton variant="rounded" width={width} height={height} />
}

export default Loader