// export function foramteCurrency(number: any) {
//     if (typeof number !== 'number') {
//       return number; // Return as is if not a number
//     }
  
//     const formattedNumber = number.toLocaleString('en-IN', {
//       maximumFractionDigits: 2,
//       style: 'currency',
//       currency: 'INR',
//       currencyDisplay: 'code',  //commented this line if you ruppee symbol instead of INR
//     });
  
//     return formattedNumber;
//   }


// export function formatNumberToIndianFormat(number: any) {
//   return number?.toLocaleString('en-IN');
// }


export function formateCurrency(number: any) {
  if (typeof number !== 'number') {
    return number; // Return as is if not a number
  }

  const formattedNumber = number.toLocaleString('en-IN', {
    maximumFractionDigits: 2,
    style: 'currency',
    currency: 'INR',
    currencyDisplay: 'code',  //commented this line if you ruppee symbol instead of INR
  });

  return formattedNumber;
}

// // Example usage
// const formatted = formatNumberToIndianFormat(500000.00);
// console.log(formatted); // Outputs: ₹5,00,000.00

