// import React, { useContext, useState,useEffect } from 'react'
// import CommonModal from '../modal/CommonModal';
// import { Box, Checkbox, FormControlLabel, Grid, Typography } from '@mui/material';
// import ButtonComponent from '../common/ButtonComponent';
// import SearchBar from '../searchBar/SearchBar';
// import AircraftAvailabiltyCard from '../operatorManagement/AircraftAvailabiltyCard';
// import NoResult from '../noResult/NoResult';
// import CommonPagination from '../common/CommonPagination';
// import AutoCompleteComponent from '../common/AutoCompleteComponent';
// import DateTimePickerComponent from '../common/DateTimePickerComponent';
// import dayjs from 'dayjs';
// import { serviceProvider } from '../../provider/serviceProvider';
// import UserContext from '../../context/UserContext';
// import { toast } from 'react-toastify';
// import Loader from '../loader/Loader';


// const initialPayload = {
//     active: true,
//     aircraftId: "",
//     availabilityEndDt: "",
//     availabilityId: "",
//     availabilityStartDt: "",
//     operatorUid: "",
//     startAirportId: "",
//   };

// const OperatorAircraftAvailability = () => {

//     const [open, setOpen] = useState(false);
//   const [loader, setLoader] = React.useState<boolean>(false);
//   const [operatorAircrafts, setOperatorAircrafts] = useState([]);
//   const [page, setPage] = useState(0);
//   const [totalPages, setTotalPages] = useState(0);
//   const [checked, setChecked] = useState<boolean>(false);
//   const [searchQuery, setSearchQuery] = useState<string>("");
//   const [userInput, setUserInput] = useState<any>(initialPayload);
//   const [startDate, setStartDate] = useState(null);
//   const [endDate, setEndDate] = useState(null);
//   const [airports, setAirports] = useState<any>([]);
//   const [assginedAircrafts, setAssginedAircrafts] = useState<any>([]);
//   const context = useContext(UserContext);
//   const operatorId = context?.authentication?.session?.uId;

//   const handleStartDateChange = (newDate: any) => {
//     setStartDate(newDate);
//   };
//   const handleEndDateChange = (newDate: any) => {
//     setEndDate(newDate);
//   };

//   const handleInputChange = (property: any, value: any) => {
//     setUserInput({ ...userInput, [property]: value });
//     // if (property === "aircraftId") {
//     //     setUserInput({ ...userInput, aircraftId: newValue?.aircraftId })

//     // } else if (property === "airportId") {
//     //     setUserInput({ ...userInput, startAirportId: newValue?.airportId })
//     // }
//     // validateFieldsOnInput(property)
//   };

//   const handleOpenModal = (operator: any) => {
//     setOpen(true);
//     setUserInput({ ...userInput, operatorId: operator?.uId });
//   };
//   const handleCloseModal = () => setOpen(false);

//   // const handleSearch=(e:any)=>{

//   //     const searchQuery = e.target.value || "all";

//   //     const param = `search?operatorUid=${operatorId}&fullName=all&aircraftName=${searchQuery}&isActive=NA&airportId=all&from=all&to=all&offset=${page}&limit=2&order=updatedTs.desc&profile=BASIC`
//   //     getOperatorAircrafts(param)
//   // }

//   const getOperatorAircraftAvailabilityList = (param: any) => {
//     setLoader(true);
//     serviceProvider.aircraftAvailabilityService
//       .get(param)
//       .then((res: any) => {
//         if (res.responseCode === 200) {
//           setOperatorAircrafts([]);
//           setOperatorAircrafts(res.data.data);
//           setTotalPages(res.data.totalRecordCount);
//         } else {
//           toast.error(res?.message || "Something Went Wrong!!");
//         }
//         setLoader(false);
//       })
//       .catch((err: any) => {
//         setLoader(false);
//       });
//   };
// //   const getAssignedAircrafts = (operatorId: any) => {
// //     setLoader(true);
// //     serviceProvider.aircraftService
// //       .getAllAssingedAircrafts(operatorId, true)
// //       .then((res: any) => {
// //         if (res.responseCode === 200) {
// //           setAssginedAircrafts(res.data);
// //         } else {
// //           toast.error(res?.message || "Something Went Wrong!!");
// //         }
// //         setLoader(false);
// //       })
// //       .catch((err: any) => {
// //         setLoader(false);
// //       });
// //   };

//   const handleSearch = (e: any) => {
//     setSearchQuery(e.target.value);
//   };

//   const handleCheckbox = (e: any) => {
//     setChecked(e.target.checked);
//   };

//   const handleAddAvailibility = (operatorUid: any) => {
//     const formattedStartDate = startDate
//       ? dayjs(new Date(startDate).toISOString()).format("YYYY-MM-DDTHH:mm:ss")
//       : "";
//     const formattedEndDate = endDate
//       ? dayjs(new Date(endDate).toISOString()).format("YYYY-MM-DDTHH:mm:ss")
//       : "";
//     // const formattedStartDate = startDate != undefined ? new Date(startDate).toISOString() : new Date();
//     // const formattedEndDate = endDate != undefined ? new Date(endDate).toISOString() : new Date();

//     const payload = {
//       ...userInput,
//       availabilityStartDt: formattedStartDate,
//       availabilityEndDt: formattedEndDate,
//       operatorUid,
//     };

//     setLoader(true);
//     serviceProvider.aircraftAvailabilityService
//       .post(payload)
//       .then((res) => {
//         setLoader(false);
//         console.log(res);
//         if (res.responseCode === 200) {
//           toast.success(res.message);
//           handleCloseModal();

//           setTimeout(() => {
//             window.location.reload();
//           }, 100);
//         } else {
//           toast.error(res?.message || "Something Went Wrong!!");
//         }
//       })
//       .catch((err) => {
//         console.log({ err });
//         setLoader(false);
//         toast.error(err?.data?.message || "Something Went Wrong!!");
//       });
//   };

//   //   const handleIsAircraftAvailable = (e: any) => {
//   // console.log(e.target.checked);

//   //     // if(e.target.checked){
//   //     //   setChecked(true)
//   //     // }else{
//   //     //   setChecked(false)
//   //     // }

//   //     // handleSearch(e,e.target.checked)
//   //   }

//   //   const handleSearch = (e:any) => {
//   //     const searchQuery = e.target.value === "" ? "all":e.target.value;
//   //     const param = `search?operatorUid=all&fullName=all&aircraftName=${searchQuery}&isActive=NA&airportId=all&from=all&to=all&offset=${page}&limit=2&order=updatedTs.desc&profile=BASIC`

//   //   getAircraftAvailabilityList(param)

//   // //     const isActiveAircraft = e.target.checked ? true : "NA";

//   //     // const param = `search?operatorUid=all&fullName=all&aircraftName=${searchQuery}&isActive=${isActiveAircraft}&airportId=all&from=all&to=all&offset=${page}&limit=2&order=updatedTs.desc&profile=BASIC`
//   //     // setLoader(true);
//   //     // serviceProvider.aircraftAvailabilityService.get(param).then((res: any) => {
//   //     //   // console.log(res);
//   //     //   if (res.responseCode === 200) {

//   //     //     setAircraftAvailabilityLists(res.data.data)
//   //     //     setTotalPages(res.data.totalRecordCount)

//   //     //   } else {
//   //     //     toast.error("Something Went Wrong!!")
//   //     //   }
//   //     //   setLoader(false)

//   //     // }).catch((err: any) => {
//   //     //   setLoader(false)
//   //     // })
//   //   }

//   //   const handleCheckBox = (e:any) => {
//   // //     const searchQuery = e.target.value === "" ? "all":e.target.value;
//   // // console.log(searchQuery);

//   //     const isActiveAircraft = e.target.checked ? true : "NA";

//   //     const param = `search?operatorUid=all&fullName=all&aircraftName=all&isActive=${isActiveAircraft}&airportId=all&from=all&to=all&offset=${page}&limit=2&order=updatedTs.desc&profile=BASIC`
//   //     getAircraftAvailabilityList(param)
//   //   }

//   const modalBody = (
//     <Grid container rowSpacing={3} className="edit-availability-modal">
//       <Grid item xs={12}>
//         <Typography variant="h6" component="h6">
//           Add availability
//         </Typography>
//       </Grid>
//       <Grid item xs={12}>
//         <AutoCompleteComponent
//           id="select-aircraftId"
//           options={assginedAircrafts}
//           className="select-box"
//           // defaultValue={searchResults?.startAirport}
//           getOptionLabel={(option: any) => `${option.name}`}
//           // getOptionLabel={(option: IAirport) => `${option.name} (${option.icaoCode})`}
//           // renderOption={(props: any, option: any) => (
//           //     <Box sx={{ width: "100%", borderBottom: "1px solid rgba(0, 0, 0, 0.12)" }} {...props}>
//           //         <Typography textAlign="left">{option.city}</Typography>
//           //         <Typography textAlign="left">{option.name} ({option.icaoCode})</Typography>
//           //     </Box>
//           // )}
//           onChange={(e: any, newValue: any) =>
//             handleInputChange("aircraftId", newValue?.aircraftId)
//           }
//           placeholder="Select Aircraft"
//         />
//         <span className="modalError" id="errorDiv-select-aircraftId"></span>
//       </Grid>
//       <Grid item xs={12}>
//         <DateTimePickerComponent
//           className="date-input"
//           placeholder="Start Date and Time"
//           disablePast={true}
//           value={startDate}
//           onChange={handleStartDateChange}
//         />
//       </Grid>
//       <Grid item xs={12}>
//         <DateTimePickerComponent
//           className="date-input"
//           placeholder="End Date and Time"
//           disablePast={true}
//           value={endDate}
//           onChange={handleEndDateChange}
//         />
//       </Grid>
//       <Grid item xs={12}>
//         <Grid item xs={12}>
//           <AutoCompleteComponent
//             id="select-aiportId"
//             options={airports}
//             className="select-box"
//             getOptionLabel={(option: any) => `${option.name}`}
//             onChange={(e: any, newValue: any) =>
//               handleInputChange("startAirportId", newValue?.airportId)
//             }
//             placeholder="Select Airport"
//           />
//           <span className="modalError" id="errorDiv-select-aiportId"></span>
//         </Grid>
//         <Typography variant="body1" component="p">
//           Airport is not listed? Please <span>contact us</span>
//         </Typography>
//       </Grid>

//       <Grid item xs={12} className="btn-container">
//         <ButtonComponent
//           label="Save"
//           fullWidth={false}
//           onClick={() => handleAddAvailibility(operatorId)}
//         />
//       </Grid>
//     </Grid>
//   );

//   const getAllAirports = () => {
//     setLoader(true);
//     serviceProvider.airportService
//       .getAllAirports()
//       .then((res) => {
//         if (res.responseCode === 200) {
//           setAirports(res.data);
//         } else {
//           toast.error(res?.message || "Something Went Wrong!!");
//         }
//         setLoader(false);
//       })
//       .catch((err) => {
//         console.log({ err });
//         setLoader(false);
//       });
//   };

 

//   useEffect(() => {
//     const isActive = checked ? checked : "NA";
//     const query = searchQuery ? searchQuery : "all";
//     const param = `search?operatorUid=${operatorId}&fullName=all&aircraftName=${query}&isActive=${isActive}&airportId=all&from=all&to=all&offset=${page}&limit=2&order=updatedTs.desc&profile=BASIC`;
//     getOperatorAircraftAvailabilityList(param);
//   }, [page, checked, searchQuery]);


// //   useEffect(() => {
// //     getAssignedAircrafts(operatorId);
// //   }, [operatorId]);


//   useEffect(() => {
//     getAllAirports();
//   }, []);
//   return (
//     <>
//     <CommonModal
//       open={open}
//       handleCloseModal={handleCloseModal}
//       modalBody={modalBody}
//     />
//     <Grid container rowSpacing={3}>
//       <Grid item xs={12}>
//         <Grid container>
//         <Grid item xs={5}>
//           <SearchBar 
//           onChange={handleSearch} 
//           placeholder={`Search by aircraft name`} />
//         </Grid>
//         <Grid item xs={7}>
//           <Box display="flex" alignItems="center" justifyContent="flex-end">
//             <FormControlLabel
//               value={checked}
//               control={<Checkbox size="small"  
//               onChange={handleCheckbox} 
//               />}
//               label="Available Aircraft"
//               labelPlacement="end"
//             />

//           </Box>
//         </Grid>

//         <Grid item xs={12} display="flex" justifyContent="flex-end" >
//         <ButtonComponent fullWidth={false} label="Add Availability" onClick={handleOpenModal} />
//             </Grid>
//       </Grid>
//         </Grid>
//         <Grid item xs={12}>
//         <Grid rowSpacing={3} container>

//         {loader ? 
//         new Array(3).fill(0).map((_, id) => {
//           return (
//             <Grid item xs={12} key={id}>
//               <Loader height="250px" width="100%" />
//             </Grid>
//           );
//         }
//       ) : operatorAircrafts.length >= 1 && loader === false ? 
//         operatorAircrafts.map((aircraft: any) => {
//           return <Grid key={aircraft?.aircraftId} item xs={12}>
//               <AircraftAvailabiltyCard
//                 aircraft={aircraft}
//                 isOperator={true}
//               />
//             </Grid>
          
//         }) : 
//         <Grid item xs={12}>
//           <NoResult title={"Aircraft Availability"} />
//         </Grid>
//       }



// </Grid>
// <Box className="pagination-box">
// <CommonPagination page={page} setPage={setPage} totalPages={totalPages} />
// </Box>
//         </Grid>
//     </Grid>
   
//   </>
//   )
// }

// export default OperatorAircraftAvailability;

import { Box, Checkbox, FormControlLabel, Grid, Typography } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import Loader from '../loader/Loader'
import NoResult from '../noResult/NoResult'
import CommonPagination from '../common/CommonPagination'
import { toast } from 'react-toastify'
import { serviceProvider } from '../../provider/serviceProvider'
import SearchBar from '../searchBar/SearchBar'
import CommonModal from '../modal/CommonModal'
import UserContext from '../../context/UserContext'
import DateTimePickerComponent from '../common/DateTimePickerComponent'
import AutoCompleteComponent from '../common/AutoCompleteComponent'
import ButtonComponent from '../common/ButtonComponent'
import dayjs from 'dayjs'
import AircraftAvailabiltyCard from '../operatorManagement/AircraftAvailabiltyCard'
import InputFieldComponent from '../common/InputFieldComponent'


const initialPayload = {
  active: true,
  aircraftId: "",
  availabilityEndDt: "",
  availabilityId: "",
  availabilityStartDt: "",
  operatorUid: "",
  startAirportId: ""
}

const OperatorAircraftAvailability = () => {
  const [loader, setLoader] = React.useState<boolean>(false);
  const [aircraftAvailabilityLists, setAircraftAvailabilityLists] = useState([]);
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [checked, setChecked] = useState<boolean>(false);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [userInput, setUserInput] = useState<any>(initialPayload);
  const [open, setOpen] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [airports, setAirports] = useState<any>([]);
  const [assginedAircrafts, setAssginedAircrafts] = useState<any>([]);

  
  const context = useContext(UserContext);
  const operatorId = context?.authentication?.session?.uId;
  const operatorName = context?.authentication?.session?.fullName;







  const handleOpenModal = (operator: any) => {
    setOpen(true);
    setUserInput({ ...userInput, operatorId: operator?.uId })
}
const handleCloseModal = () => setOpen(false);


const handleStartDateChange = (newDate: any) => {
  setStartDate(newDate);
}
const handleEndDateChange = (newDate: any) => {
  setEndDate(newDate);
}

const handleInputChange = (property: any, value: any) => {
  setUserInput({ ...userInput, [property]: value })
  
};

  
  const getAircraftAvailabilityList = (param:any) => {
    // const param = `search?operatorUid=all&fullName=all&isActive=NA&offset=${page}&limit=2&order=updatedTs.desc&profile=BASIC`
    // const param = `search?operatorUid=all&fullName=all&aircraftName=all&isActive=NA&airportId=all&from=all&to=all&offset=${page}&limit=2&order=updatedTs.desc&profile=BASIC`
    setLoader(true);
    serviceProvider.aircraftAvailabilityService.get(param).then((res: any) => {
      // console.log(res);
      if (res.responseCode === 200) {
        setAircraftAvailabilityLists([])
        setAircraftAvailabilityLists(res.data.data)
        setTotalPages(res.data.totalRecordCount)
      } else {
        toast.error( res?.message || "Something Went Wrong!!")
      }
      setLoader(false)

    }).catch((err: any) => {
      setLoader(false)
    })
  }





  const handleSearch = (e:any) => {
    // const searchQuery = e.target.value || "all";

    setSearchQuery(e.target.value)
    
  }
  const handleCheckbox = (e:any) => {
    setChecked(e.target.checked)
  }
  



  const getAssignedAircrafts = (operatorId: any) => {
    setLoader(true);
    serviceProvider.aircraftService.getAllAssingedAircrafts(operatorId,true,"NA").then((res: any) => {
        if (res.responseCode === 200) {
            setAssginedAircrafts(res.data)
        } else {
            toast.error(res?.message || "Something Went Wrong!!")
        }
        setLoader(false)
    }).catch((err: any) => {
        setLoader(false)
    })
}


  const getAllAirports = () => {
    setLoader(true);
    serviceProvider.airportService.getAllAirports().then((res) => {
        if (res.responseCode === 200) {
            setAirports(res.data)
        } else {
            toast.error(res?.message ||"Something Went Wrong!!")
        }
        setLoader(false)
    }).catch((err) => {
        console.log({ err });
        setLoader(false)

    })
}





  const handleAddAvailibility = (operatorUid: any) => {

    const formattedStartDate = startDate ? dayjs(new Date(startDate).toISOString()).format('YYYY-MM-DDTHH:mm:ss') : '';
    const formattedEndDate = endDate ? dayjs(new Date(endDate).toISOString()).format('YYYY-MM-DDTHH:mm:ss') : '';

    const payload = { ...userInput, availabilityStartDt: formattedStartDate, availabilityEndDt: formattedEndDate, operatorUid };

    setLoader(true);
    serviceProvider.aircraftAvailabilityService.post(payload).then((res) => {
        setLoader(false)
        console.log(res)
        if (res.responseCode === 200) {
            toast.success(res.message);
handleCloseModal()
            
              // window.location.href=`/operator-my-aircrafts?index=${1}`
              window.location.href=`/?index=${1}`
        
        } else {
            toast.error(res?.message ||"Something Went Wrong!!")
        }

    }).catch((err) => {
        console.log({ err });
        setLoader(false)
        toast.error(err?.data?.message || "Something Went Wrong!!")

    })
}

useEffect(() => {
  const isActive= checked ? checked :"NA";
  const query = searchQuery ? searchQuery : "all"
  const param = `search?operatorUid=${operatorId}&fullName=all&aircraftName=${query}&isActive=${isActive}&airportId=all&from=all&to=all&offset=${page}&limit=2&order=updatedTs.desc&profile=BASIC`
  getAircraftAvailabilityList(param)
  // else if (value === 2) getOperatorRequestsList()
}, [page,checked,searchQuery,operatorId])

useEffect(() => {
  getAllAirports();
  
}, [])

useEffect(()=>{
  if(operatorId) getAssignedAircrafts(operatorId)
},[operatorId])
  

  const modalBody = (
    <Grid container rowSpacing={3} className='edit-availability-modal'>
        <Grid item xs={12}>
            <Typography variant='h6' component='h6'>Add availability</Typography>
        </Grid>
        <Grid item xs={12}>
             <InputFieldComponent
             fullWidth={true}
                id="edit-flight-duration"
                className='select-box'
                value={operatorName}
                disabled={true}
                
              />
            <span className="modalError" id='errorDiv-select-aircraftId'></span>

        </Grid>
        <Grid item xs={12}>
            <AutoCompleteComponent
                id="select-aircraftId"
                options={assginedAircrafts}
                className='select-box'
                
                // defaultValue={searchResults?.startAirport}
                getOptionLabel={(option: any) => `${option.name}`}
                // getOptionLabel={(option: IAirport) => `${option.name} (${option.icaoCode})`}
                // renderOption={(props: any, option: any) => (
                //     <Box sx={{ width: "100%", borderBottom: "1px solid rgba(0, 0, 0, 0.12)" }} {...props}>
                //         <Typography textAlign="left">{option.city}</Typography>
                //         <Typography textAlign="left">{option.name} ({option.icaoCode})</Typography>
                //     </Box>
                // )}
                onChange={(e: any, newValue: any) => handleInputChange("aircraftId", newValue?.aircraftId)}
                placeholder="Select Aircraft"
            />
            <span className="modalError" id='errorDiv-select-aircraftId'></span>

        </Grid>
        <Grid item xs={12} >
            <DateTimePickerComponent className="date-input" placeholder="Start Date and Time" disablePast={true} value={startDate} onChange={handleStartDateChange} />
        </Grid>
        <Grid item xs={12} >
            <DateTimePickerComponent className="date-input" placeholder="End Date and Time" disablePast={true} value={endDate} onChange={handleEndDateChange} />
        </Grid>
        <Grid item xs={12}>
            <Grid item xs={12}>
                <AutoCompleteComponent
                    id="select-aiportId"
                    options={airports}
                    className='select-box'
                    getOptionLabel={(option: any) => `${option.name}`}

                    onChange={(e: any, newValue: any) => handleInputChange("startAirportId", newValue?.airportId)}
                    placeholder="Select Airport"
                />
                <span className="modalError" id='errorDiv-select-aiportId'></span>
            </Grid>
            <Typography variant='body1' component="p">Airport is not listed? Please <span>contact us</span></Typography>
        </Grid>

        <Grid item xs={12} className='btn-container' >
            <ButtonComponent label="Save" loader={loader} disabled={loader} fullWidth={false} onClick={() => handleAddAvailibility(operatorId)} />
        </Grid>

    </Grid>
)

 
  return (
    <>
     <CommonModal open={open} handleCloseModal={handleCloseModal} modalBody={modalBody} />
      <Grid container rowSpacing={3}>
        <Grid item xs={12}>
        <Grid container>
        <Grid item xs={5}>
          <SearchBar 
          onChange={handleSearch} 
          placeholder={`Search by aircraft name`} />
        </Grid>
        <Grid item xs={7}>
          <Box display="flex" alignItems="center" justifyContent="flex-end">
            <FormControlLabel
              value={checked}
              control={<Checkbox size="small"  
              onChange={handleCheckbox} 
              />}
              label="Available Aircraft"
              labelPlacement="end"
            />

          </Box>
        </Grid>

        <Grid item xs={12} display="flex" justifyContent="flex-end" >
        <ButtonComponent fullWidth={false} label="Add Availability" onClick={handleOpenModal} />
            </Grid>
      </Grid>
        </Grid>
        <Grid item xs={12}>
        <Grid rowSpacing={3} container>

{/* {
aircraftAvailabilityLists?.length>=1 && aircraftAvailabilityLists?.map((aircraft:any)=>{
return   <Grid item xs={12} key={aircraft?.availabilityId}>
<AircraftAvailabiltyCard aircraft={aircraft}/>
</Grid>
})
}  */}


{
  loader ?
    new Array(3).fill(0).map((_, id) => {
      return <Grid item xs={12} key={id}>
        <Loader height='250px' width='100%' />
      </Grid>
    }) : aircraftAvailabilityLists.length >= 1 && loader === false 
    ? aircraftAvailabilityLists.map((aircraft: any) => {
       return <Grid item xs={12}>
        <AircraftAvailabiltyCard aircraft={aircraft} isOperator={true} />
      </Grid>
    }) :
      <Grid item xs={12}>
        <NoResult title={"Aircrafts Availability"} />
      </Grid>
}
</Grid>

{
  aircraftAvailabilityLists?.length >=1 && <Box className="pagination-box">
  <CommonPagination page={page} setPage={setPage} totalPages={totalPages} />
  </Box>
}

        </Grid>
      </Grid>

      
    </>
  )
}

export default OperatorAircraftAvailability