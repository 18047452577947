import { Box, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import AutoCompleteComponent from "../common/AutoCompleteComponent";
import { IAircraft } from "../../interface/IAircraft";
import { serviceProvider } from "../../provider/serviceProvider";
import ItineraryDetails from "./ItineraryDetails";
import ButtonComponent from "../common/ButtonComponent";
import { toast } from "react-toastify";
import { sessionUtility } from "../../utility/sessionUtility";
import VerifyOtp from "../login/VerifyOtp";
import { useNavigate } from "react-router-dom";

const GenerateQuotation = ({
  queryDetails,
  handleCloseGenerateQuotationModal,
  indexNumber,
}: any) => {
  const [loader, setLoader] = useState({
    verifyUser:false,
    generateQuotation:false,
    operatorsList:false,
    assignedAircrafts:false,
  });
  const [aircrafts, setAircrafts] = useState([]);
  const [aircraftId, setAircraftId] = useState<string>("");
  const [userId, setUserId] = useState<string>("");
  const [verifyOtpModal, setVerifyOtpModal] = React.useState<boolean>(false);
  const [mobileNumber, setMobileNumber] = useState<string>("");
  const [assginedAircrafts, setAssginedAircrafts] = useState<any>([]);
  const [operatorList, setOperatorList] = useState<any>([]);
  const [operatorId, setOperatorId] = useState<any>("");


  const navigate= useNavigate()
  // let uid = sessionUtility.getAuthentication().session?.uId;

  let parsedOrderOfCities;

  parsedOrderOfCities =
    queryDetails &&
    queryDetails?.itineraryDetails &&
    queryDetails?.itineraryDetails?.orderOfCities &&
    JSON.parse(queryDetails?.itineraryDetails?.orderOfCities);

  let orderOfCitiesArr: any = [];

  if (parsedOrderOfCities) {
    orderOfCitiesArr = Object.values(parsedOrderOfCities);
  }

  console.log({ queryDetails });

  // show user details  check user with mobile number


  const handleOpenVerifyOtpModal = () => setVerifyOtpModal(true);
  const handleCloseVerifyOtpModal = () => setVerifyOtpModal(false);



  const getOperatorsList = () => {
    // const searchQuery = e.target.value
    // setSearchQuery(e.target.value)
    // const active = value === 0 ? true : false;

    const operatorRoleId = "eaf1aa60-fee7-4e32-9afc-e04fc8f61c48";

    const query = `search?roleId=${operatorRoleId}&uId=NA&email=all&fullName=all&isActive=NA&offset=0&limit=100000&order=recordUpdatedts.desc&profile=BASIC`;
    setLoader({...loader,operatorsList:true});
    serviceProvider.userService
      .get(query)
      .then((res) => {
        // console.log({ res })
        if (res.responseCode === 200) {
          setOperatorList(res.data.details);
        } else {
          toast.error(res?.message || "Something Went Wrong !!");
        }
        // if (res.responseCode === 200 && active)   setPageState(prev=>({...prev,loader:false,data:res.data.details,totalPages:res.data.totalRecordCount}))
        // else if (res.responseCode === 200 && !active)   setPageState(prev=>({...prev,loader:false,data:res.data.details,totalPages:res.data.totalRecordCount}))
        setLoader({...loader,operatorsList:false});
      })
      .catch((err) => {
        console.log({ err });
        setLoader({...loader,operatorsList:false});
      });
  };


  const getAssignedAircrafts = (operatorId: any) => {
    setLoader({...loader,assignedAircrafts:true});
    serviceProvider.aircraftService
      .getAllAssingedAircrafts(operatorId, true,"NA")
      .then((res: any) => {
        if (res.responseCode === 200) {
          setAssginedAircrafts(res.data);
        } else {
          toast.error(res?.message || "Something Went Wrong!!");
        }
        setLoader({...loader,assignedAircrafts:false});
      })
      .catch((err: any) => {
        setLoader({...loader,assignedAircrafts:false});
      });
  };

  const handleRegisterUser = (name:any,email:any,mobileNumber:any) => {
    // setLoader(true);
    setLoader({...loader,verifyUser:true})

    const payload = {
      agree_promotions: true,
      agree_terms: true,
      emailId: email,
      fullName: name,
      mobileNumber: mobileNumber,
      roleId: "",
    };

    // isValidRequest()
      // .then(() => {
        serviceProvider.userService
          .post(payload)
          .then((res: any) => {
            // setLoader(false);
            setLoader({...loader,verifyUser:false})
            if (res.responseCode === 200) {
              toast.success(res.message);
              setUserId(res?.data?.uid)
              // setOpen(false);

              // setTimeout(() => {
              //     handleOpenVerifyOtpModal();
              // }, 1000);
            } else {
              toast.error(res?.message ||"Something Went Wrong.");
            }

            // setUserInput(initialUserInput);
          })
          .catch((err: any) => {
            toast.error(err?.data?.message || "Something Went Wrong!");
            // setLoader(false);
            setLoader({...loader,verifyUser:false})
          });
      // })
      // .catch(() => {
      //   toast.error("Please fill all the Required Fields!");
      //   setLoader(false);
      // });
  };



  const handleVerifyUser = (name:any,email:any,mobileNumber: any) => {
    const param = `verify/${mobileNumber}`;
    // setLoader(true);
    setLoader({...loader,verifyUser:true})
    serviceProvider.userService
      .get(param)
      .then((res: any) => {
        if(res.responseCode ===200){
          setUserId(res?.data?.uid);

          toast.success("User Verified.")
          // toast.success(toast.success)
        }else if(res.responseCode === 404){
          toast.error(res?.message)
          handleRegisterUser(name,email,mobileNumber)
        }else{
          toast.error(res?.message);
        }
        // setLoader(false);
        setLoader({...loader,verifyUser:false})
      })
      .catch((err: any) => {
        console.log({ err });
        // setLoader(false);
        setLoader({...loader,verifyUser:false})
      });
  };


  const handleGenerateQuotation = (
    enquiryId:any,
    uid: any,
    itineraryId: any,
    aircraftId: any

  ) => {
    const payload = {
      aircraftId: aircraftId,
      itineraryId: itineraryId,
      uid: uid,
    };

    const param = `quoteenquiries/${enquiryId}`
    // setLoader(true);
    setLoader({...loader,generateQuotation:true})
    serviceProvider.quotationService
      .saveEnquiryQuotation(param, payload)
      .then((res: any) => {
        if (res.responseCode === 200) {
          toast.success(res.message);
          handleCloseGenerateQuotationModal();
          setTimeout(() => {
            // window.location.reload();
            window.location.href=`/query-management?index=${indexNumber}`
          }, 100);
        } else {
          toast.error(res?.message || "Something Went Wrong!!");
        }
        // setLoader(false);
        setLoader({...loader,generateQuotation:false})
      })
      .catch((err: any) => {
        setLoader({...loader,generateQuotation:false})
        // setLoader(false);
        toast.error(err?.data?.message || "Something Went Wrong!!");
      });
  };

  useEffect(() => {
    // getAllAircraftsList();
    getOperatorsList();
  }, []);

  useEffect(() => {
    if (operatorId) getAssignedAircrafts(operatorId);
  }, [operatorId]);

  //   console.log({start});

  return (
   <>
   
    <Grid container rowSpacing={2} className="generate-quotation-modal">
      <Grid item xs={12}>
        <Typography variant="h6" component="h6" gutterBottom>
          Generate Quotation
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Grid container rowSpacing={3}>
          <Grid item xs={12}>
            <Grid container rowSpacing={1}>
            <Grid item xs={12}>
            <Typography variant="body1" component="p">
              Username : {queryDetails?.name || "--"}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1" component="p">
              Email : {queryDetails?.email || "--"}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1" component="p">
              Mobile Number : {queryDetails?.mobileNumber || "--"}
            </Typography>
          </Grid>
            </Grid>
          </Grid>
         {
          !userId &&  <Grid item xs={12} display="flex" justifyContent="center" alignItems="center">
          <ButtonComponent
            fullWidth={false}
            label="Verify User"
            loader={loader.verifyUser}
            disabled={loader.verifyUser}
            onClick={() => handleVerifyUser(queryDetails?.name,queryDetails?.email,queryDetails?.mobileNumber)}
          />
        </Grid>
         }
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Grid container className="itinerary-details-card">
          <Grid item xs={12}>
            {queryDetails?.itineraryDetails?.itineraryType !== "MULTICITY" && (
              <ItineraryDetails
                itinerary={queryDetails?.itineraryDetails}
                startAirport={queryDetails?.itineraryDetails?.startAirport}
                endAirport={queryDetails?.itineraryDetails?.endAirport}
              />
            )}
            {queryDetails?.itineraryDetails?.itineraryType === "MULTICITY" &&
              orderOfCitiesArr?.length >= 1 &&
              orderOfCitiesArr?.map((itinerary: any) => {
                const startAirport = JSON.parse(itinerary?.startAirportDetails);
                const endAirport = JSON.parse(itinerary?.endAirportDetails);
                return (
                  <ItineraryDetails
                    itinerary={itinerary}
                    startAirport={startAirport}
                    endAirport={endAirport}
                  />
                );
              })}
          </Grid>
        </Grid>
      </Grid>

      {/* <Grid item xs={12}>
        <AutoCompleteComponent
          options={aircrafts}
          position="end"
          className="select-box"
          // defaultValue={emptyLegDetails?.aircraftDetails}

          getOptionLabel={(option: IAircraft) => `${option.name}`}
          renderOption={(props: any, option: any) => (
            <Box
              sx={{
                width: "100%",
                borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
              }}
              {...props}
            >
              <Typography textAlign="left">{option.name}</Typography>
            </Box>
          )}
          onChange={(e: any, newValue: any) =>
            setAircraftId(newValue?.aircraftId)
          }
          placeholder="Select Aircraft"
        >
        
        </AutoCompleteComponent>
      </Grid> */}

<Grid item xs={12}>
        <AutoCompleteComponent
          id="select-operatorId"
          options={operatorList}
          className="select-box"
          // defaultValue={searchResults?.startAirport}
          getOptionLabel={(option: any) => `${option.fullName}`}
          // position="end"
          // getOptionLabel={(option: IAirport) => `${option.name} (${option.icaoCode})`}
          // renderOption={(props: any, option: any) => (
          //     <Box sx={{ width: "100%", borderBottom: "1px solid rgba(0, 0, 0, 0.12)" }} {...props}>
          //         <Typography textAlign="left">{option.city}</Typography>
          //         <Typography textAlign="left">{option.name} ({option.icaoCode})</Typography>
          //     </Box>
          // )}
          onChange={(e: any, newValue: any) =>setOperatorId(newValue?.uid) }
          placeholder="Select Operator"
        />
        <span className="modalError" id="errorDiv-select-aircraftId"></span>
      </Grid>
      <Grid item xs={12}>
        <AutoCompleteComponent
          id="select-aircraftId"
          options={assginedAircrafts}
          className="select-box"
          // defaultValue={searchResults?.startAirport}
          getOptionLabel={(option: any) => `${option.name} (${option?.callSign})`}
          // getOptionLabel={(option: IAirport) => `${option.name} (${option.icaoCode})`}
          // renderOption={(props: any, option: any) => (
          //     <Box sx={{ width: "100%", borderBottom: "1px solid rgba(0, 0, 0, 0.12)" }} {...props}>
          //         <Typography textAlign="left">{option.city}</Typography>
          //         <Typography textAlign="left">{option.name} ({option.icaoCode})</Typography>
          //     </Box>
          // )}
          onChange={(e: any, newValue: any) => setAircraftId(newValue?.aircraftId)}
          placeholder="Select Aircraft"
        />
        <span className="modalError" id="errorDiv-select-aircraftId"></span>
      </Grid>

      <Grid item xs={12} className="btn-container">
        <ButtonComponent
          className="rounded-btn-outlined"
          fullWidth={false}
          label="Cancel"
          onClick={handleCloseGenerateQuotationModal}
        />
        <ButtonComponent
          fullWidth={false}
          disabled={loader.generateQuotation || !userId}
          loader={loader.generateQuotation}
          label="Generate Quotation"
          onClick={() =>
            handleGenerateQuotation(
              queryDetails?.quoteEnquiryId,
              userId,
              queryDetails?.itineraryDetails?.itineraryId,
              aircraftId
            )
          }
        />
      </Grid>

      {/* <Grid item xs={12}>
            <Typography variant="body1" component="p" textAlign="center">
              Are you sure you want to resolve this query?
            </Typography>
          </Grid>
          <Grid item xs={12} className="btn-container">
            <ButtonComponent
              className="rounded-btn-outlined"
              fullWidth={false}
              label="Cancel"
              onClick={handleCloseConfirmModal}
            />
            <ButtonComponent
              fullWidth={false}
              label="Resolve"
              onClick={() => handleUpdateQueryStatus(queryId)}
            />
          </Grid> */}
    </Grid>
   </>
  );
};

export default GenerateQuotation;
