import React, { useState, useEffect } from 'react';
import { Grid, Button, Typography } from "@mui/material";
import { serviceProvider } from '../../provider/serviceProvider';
import { toast } from "react-toastify";
import SearchBar from '../searchBar/SearchBar';
import CommonModal from '../modal/CommonModal';
import QueryTabs from './QueryTabs';
import { useLocation, useNavigate } from 'react-router-dom';
// import AddUser from './AddUser';





const QueryManagement = () => {
  const [open, setOpen] = useState(false);
  const [loader, setLoader] = useState(false);
  const [allUsers, setAllUsers] = useState([]);
  // const [activeUsers, setActiveUsers] = useState([])
  // const [inActiveUsers, setInActiveUsers] = useState([]);
  const [value, setValue] = React.useState(0);

   const [status,setStatus]=useState("PENDING");
   const location = useLocation();
   const searchParams = new URLSearchParams(location.search);
   const index = searchParams.get('index');
 
   // Convert the 'index' value to a number if needed
   const indexNumber = parseInt(index + "", 10);
   const navigate = useNavigate();

  const handleSelectChange =(e:any)=>{
    setStatus(e.target.value)
    
  }
  const [pageState, setPageState] = React.useState({
    loader:false,
    data:[],
    totalPages:0,
    page:0,
    pageSize:5,

  });

  const handleChange = (event: React.SyntheticEvent, newValue: number,) => {
    console.log("new", newValue)
    setValue(newValue);
    navigate(`/query-management?index=${newValue}`)
    // if(newValue==0){
    //   getActiveUsers();
    // }
    // else{
    //   getInActiveUsers();
    // }
  };


  const handleOpenModal = () => setOpen(true)
  const handleCloseModal = () => setOpen(false)



  const handleAddUser = (userInput:any) => {
    setLoader(true);
    serviceProvider.userService.post(userInput).then((res) => {
      console.log({ res });
      if (res.responseCode === 200) {
        toast.success(res.message);
        handleCloseModal();
        setTimeout(()=>{
          window.location.reload();
        },300)
      }else{
        toast.error(res?.message ||"Something Went Wrong!!")
      }
      setLoader(false);
    }).catch((err) => {
      toast.error(err?.data?.message || "Something Went Wrong!!");
      console.log({ err });
      setLoader(false);
      // handleCloseModal()
    });
  };
  // const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   const searchQuery = e.target.value
  //   // setSearchQuery(e.target.value)
  //   const active = value === 0 ? true : false
  //   // const query = `uId=NA&email=all&fullName=${searchQuery}&isActive=${active}&offset=${pageState.page}&limit=${pageState.pageSize}&order=recordUpdatedts.desc&profile=BASIC`
  //   setLoader(true)
  //   serviceProvider.userService.getSearchResults(query).then((res) => {
  //     console.log({ res })

  //     if (res.responseCode === 200 && active)   setPageState(prev=>({...prev,loader:false,data:res.data.details,totalPages:res.data.totalRecordCount}))
  //     else if (res.responseCode === 200 && !active)   setPageState(prev=>({...prev,loader:false,data:res.data.details,totalPages:res.data.totalRecordCount}))
  //     setLoader(false)
  //   }).catch((err) => {
  //     console.log({ err })
  //     setLoader(false)
  //   })
  // }
 

  
  const getAllQueries = (param:any) => {
    // const query = `search?uId=NA&email=all&fullName=all&isActive=${false}&offset=${pageState.page}&limit=${pageState.pageSize}&order=recordUpdatedts.desc&profile=BASIC`
    // setLoader(true);
    setPageState(prev=>({...prev,loader:true}))

    serviceProvider.userService.get(param).then((res) => {
      setLoader(false)
      if (res.responseCode === 200) {
        // setInActiveUsers(res.data.details)
        setPageState(prev=>({...prev,loader:false,data:res.data.data,totalPages:res.data.totalRecordCount}))
      }
      setPageState(prev=>({...prev,loader:false}))
    }).catch((err) => {
      // setLoader(false)
      setPageState(prev=>({...prev,loader:false}))
      console.log({ err })
    })
  }

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const searchQuery = e.target.value
   const param = `allqueries?status=PENDING&queryType=GENERAL&from=all&to=all&offset=${pageState.page}&limit=${pageState.pageSize}&order=updatedTs.desc&profile=BASIC`
    getAllQueries(param)
  }





  useEffect(()=>{
    if(indexNumber){
      setValue(indexNumber)
    }else{
      setValue(0)
    }
  },[indexNumber])


//   useEffect(()=>{
// // getActiveUsers()
// let param;
// if(value==0){
//    param = `allqueries?status=PENDING&queryType=GENERAL&from=all&to=all&offset=${pageState.page}&limit=${pageState.pageSize}&order=updatedTs.desc&profile=BASIC`
//   getAllQueries(param)
//   // getActiveUsers();
  
// }else if(value===1){
//   param = `allqueries?status=PENDING&queryType=QUOTATION&from=all&to=all&offset=${pageState.page}&limit=${pageState.pageSize}&order=updatedTs.desc&profile=BASIC`
//   getAllQueries(param)
  
// }
// else{
//   param = `allqueries?status=PENDING&queryType=BMJEXCLUSIVE&from=all&to=all&offset=${pageState.page}&limit=${pageState.pageSize}&order=updatedTs.desc&profile=BASIC`
//   getAllQueries(param)
// }
//   },[value,pageState.page,pageState.pageSize])


  return (
    <>
      {/* <CommonModal open={open} handleCloseModal={handleCloseModal} modalBody={<AddUser onAddUser={handleAddUser} handleCloseModal={handleCloseModal} />} /> */}

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={5} md={5} >
              <Typography variant='h6' component='h6'>Queries</Typography>
            </Grid>
            {/* <Grid item xs={7}>
              <SearchBar onChange={handleSearch} placeholder="Search UserName" />
            </Grid> */}
          </Grid>
        </Grid>

        {/* <Grid item xs={12}>
          <Button variant="contained" className="rounded-btn-fill" onClick={handleOpenModal}>Add User</Button>
        </Grid> */}
        <Grid item xs={12}>
          <QueryTabs pageState={pageState} setPageState={setPageState} value={value} handleChange={handleChange} />
        </Grid>
      </Grid>
    </>
  );
};

export default QueryManagement;
