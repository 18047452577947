import React from "react";
import { Card, Grid, Typography } from "@mui/material";
import {
  getFormattedDate,
  getFormattedTime,
} from "../../utility/formateDateAndTime";
import { formatHours } from "../../utility/formatHours";

const ItineraryQuotationDetails = ({ itineraryDetails }: any) => {
  // console.log({itineraryDetails})
  
  return (
    <Card className="user-quotation-details-card">
      <Grid container className="user-detail-item">
        <Grid item xs={4}>
          <Typography variant="body1" component="p">
            Start Date Time
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography variant="body1" component="p">
            {`${getFormattedDate(itineraryDetails?.startDt)} ${getFormattedTime(
              itineraryDetails?.startDt
            )}`}
          </Typography>
        </Grid>
      </Grid>
      
        <Grid container className="user-detail-item">
          <Grid item xs={4}>
            <Typography variant="body1" component="p">
              End Date Time
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography variant="body1" component="p">
              {`${getFormattedDate(itineraryDetails?.endDt)} ${getFormattedTime(
                itineraryDetails?.endDt
              )}`}
            </Typography>
          </Grid>
        </Grid>
      
      <Grid container className="user-detail-item">
        <Grid item xs={4}>
          <Typography variant="body1" component="p">
            Start Airport
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography variant="body1" component="p">
            {itineraryDetails?.startAirportDetails?.name || "--"}
          </Typography>
        </Grid>
      </Grid>
      <Grid container className="user-detail-item">
        <Grid item xs={4}>
          <Typography variant="body1" component="p">
            End Airport
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography variant="body1" component="p">
            {itineraryDetails?.endAirportDetails?.name || "--"}
          </Typography>
        </Grid>
      </Grid>
      <Grid container className="user-detail-item">
        <Grid item xs={4}>
          <Typography variant="body1" component="p">
            Flight Duration
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography variant="body1" component="p">
            {formatHours(itineraryDetails?.duration || 0)}
          </Typography>
        </Grid>
      </Grid>
      <Grid container className="user-detail-item">
        <Grid item xs={4}>
          <Typography variant="body1" component="p">
            Passenger Capacity
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography variant="body1" component="p">
            {itineraryDetails?.numberOfPassengers || "--"}
          </Typography>
        </Grid>
      </Grid>
    </Card>
  );
};

export default ItineraryQuotationDetails;
