import { Button, Card, Grid, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { IQuotation } from '../../interface/IQuotation'
import editIcon from "../../assets/icons/edit-icon.svg";
import InputFieldComponent from '../common/InputFieldComponent';
import ButtonComponent from '../common/ButtonComponent';
import CommonModal from '../modal/CommonModal';
import { serviceProvider } from '../../provider/serviceProvider';
import { toast } from 'react-toastify';
import AutoCompleteComponent from '../common/AutoCompleteComponent';
import { IAircraft } from '../../interface/IAircraft';
import { Box } from '@mui/system';
const UserQuotationDetails = ({ quotationDetails }: any) => {
  const [open, setOpen] = useState(false);
  const [loader, setLoader] = useState(false);
  const [userInput, setUserInput] = useState<any>({});
  const [aircraftsList, setAircraftsList] = useState<any[]>([]);
  const handleOpenModal = () => setOpen(true)
  const handleCloseModal = () => setOpen(false);



  const handleInputChange = (property: any, newValue: any) => {
    // if (property === "startAirportId") setSearchInput({ ...searchInput, [property]: newValue.airportId })
    // else if (property === "endAirportId") setSearchInput({ ...searchInput, [property]: newValue.airportId })
    // else setSearchInput({ ...searchInput, [property]: newValue })


    setUserInput({ ...userInput, [property]: newValue });

  };


  const handleEditAircraft = (aircraftId:any) => {


    // alert("hello")

    setLoader(true)
    serviceProvider.aircraftService.editAircraft(aircraftId,userInput).then((res) => {
     
      setLoader(false)
      if(res.responseCode===200){
        toast.success(res.message)
        // navigate("/fleets-management")
        // window.len
        // setCabinSpecs()
      }else{
        toast.error(res?.message || "Something Went Wrong !!")
      }
      
    }).catch((err) => {
      toast.error(err?.data?.message || "Something Went Wrong !!")
      setLoader(false)
    //   emptyInputFields()
      console.log({ err })
    })
  }


  const getAircraftList = (numberOfPassengers: any) => {

    const query = `aircrafts?aircraftId=NA&fleetId=NA&minNumberOfPassengers=${numberOfPassengers}`

    setLoader(true);

    serviceProvider.aircraftService.get(query).then((res: any) => {
      if (res.responseCode === 200) {
        setAircraftsList(res.data)
        // setTotalPages(res.data.totalRecordCount);


      } else {
        toast.error(res?.message ||"Something Went Wrong!")
      }
      setLoader(false)
      // setQuotations(res.data)
      // setTotalPages(res.totalRecordCount)
    }).catch((err) => {
      setLoader(false)
      console.log({ err })
    })
  }



  console.log({aircraftsList})

  useEffect(() => {
    if(quotationDetails?.itineraryDetails?.numberOfPassengers){
      getAircraftList(quotationDetails?.itineraryDetails?.numberOfPassengers);
    }
    setUserInput(quotationDetails)
  }, [quotationDetails]);

console.log({userInput})


  const modalBody = (
    <Grid container spacing={1}>
      <Grid item xs={12} className="modal-table-container">
        <Grid container className="edit-detail-item">

          <Grid item xs={6} className='edit-item dark-bg'>
            <Typography variant='body1' component='p'>
              Aircraft Name
            </Typography>
          </Grid>
          <Grid item xs={6} className='edit-item'>
          <AutoCompleteComponent
              options={aircraftsList}
              // className='search-input'
              defaultValue={userInput?.aircraftDetails}
              // defaultValue="hello"
              // readOnly={index > 0 ? true : false}
              getOptionLabel={(option: IAircraft) => `${option.name}`}
              renderOption={(props: any, option: any) => (
                <Box sx={{ width: "100%", borderBottom: "1px solid rgba(0, 0, 0, 0.12)" }} {...props}>
                  <Typography textAlign="left">{option.name}</Typography>
                  {/* <Typography textAlign="left">{option.name} ({option.icaoCode})</Typography> */}
                </Box>
              )}
              onChange={(e: any, newValue: any) => handleInputChange("aircraftId", newValue?.aircraftId)}
              // onChange={(e: any, newValue: any) => handleInputChange(index, "startAirportId", newValue?.airportId,newValue)}
              placeholder="From"
            />

          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} >
        <Grid container columnSpacing={2} display="flex" justifyContent="center" alignItems="center" mt="20px">
          <Grid item xs={2}>
            <ButtonComponent variant="contained" className="rounded-btn-fill" label="Save" onClick={()=>handleEditAircraft(userInput?.aircraftDetails?.aircraftId)} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
  return (
    <>
      {/* <CommonModal open={open} handleCloseModal={handleCloseModal} modalBody={modalBody} /> */}
      <Card className='user-quotation-details-card'>
        {/* <Grid container className='user-detail-item'>
    <Grid item xs={4}>
      <Typography variant="body1" component="p">Quotation ID</Typography>
    </Grid>
    <Grid item xs={8}>
    <Typography variant="body1" component="p">{quotationDetails?.quotationId || "--"}</Typography>
    </Grid>
</Grid> */}
        <Grid container className='user-detail-item'>
          <Grid item xs={4}>
            <Typography variant="body1" component="p">User Name</Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography variant="body1" component="p">{quotationDetails?.userDetails?.fullName || "--"}</Typography>
          </Grid>
        </Grid>
        <Grid container className='user-detail-item'>
          <Grid item xs={4}>
            <Typography variant="body1" component="p">Email</Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography variant="body1" component="p">{quotationDetails?.userDetails?.email || "--"}</Typography>
          </Grid>
        </Grid>
        <Grid container className='user-detail-item'>
          <Grid item xs={4}>
            <Typography variant="body1" component="p">Mobile No.</Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography variant="body1" component="p">{quotationDetails?.userDetails?.mobileNumber || "--"}</Typography>
          </Grid>
        </Grid>
        <Grid container className='user-detail-item'>
          <Grid item xs={4}>
            <Typography variant="body1" component="p">Aircraft Name</Typography>
          </Grid>
          <Grid item xs={8} display='flex' alignItems='center' justifyContent='space-between'>
            <Typography variant="body1" component="p">{quotationDetails?.aircraftDetails?.name || "--"}</Typography>
            {/* <Button sx={{ minWidth: "0", padding: 0 }}
              onClick={handleOpenModal}
            >
              <img src={editIcon} alt="edit icon" height="20px" width="20px" />
            </Button> */}
          </Grid>
        </Grid>


      </Card>
    </>
  )
}

export default UserQuotationDetails