import React from "react";
import {
  Box,
  Button,
  Card,
  Chip,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import dividerPlane from "../../assets/icons/divider-plane.svg";
import { useLocation, useNavigate } from "react-router-dom";
import { serviceProvider } from "../../provider/serviceProvider";
import { useEffect, useState } from "react";
import {
  getFormattedDate,
  getFormattedTime,
} from "../../utility/formateDateAndTime";
import { IQuotation } from "../../interface/IQuotation";
import { IItinerary } from "../../interface/IItinerary";
import { formateCurrency } from "../../utility/formateCurreny";
import ItineraryDetail from "./ItineraryDetail";
import { toast } from "react-toastify";
import ButtonComponent from "../common/ButtonComponent";
import CommonModal from "../modal/CommonModal";
// import ItineraryDetails from '../queryManagement/ItineraryDetails';
interface QuotationCardProps {
  index: number;
  quotation: IQuotation;
}

const QuotationCard: React.FC<QuotationCardProps> = ({
  index,
  quotation,
}: any) => {
  const [loader, setLoader] = useState<boolean>(false);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const navigate = useNavigate();
  // const location = useLocation();
  // const searchParams = new URLSearchParams(location.search);
  // const indexValue = searchParams.get('index');

  // // Convert the 'index' value to a number if needed
  // const indexNumber = parseInt(indexValue + "", 10);

  let parsedOrderOfCities;

  parsedOrderOfCities =
    quotation &&
    quotation?.itineraryDetails &&
    quotation?.itineraryDetails?.orderOfCities &&
    JSON.parse(quotation?.itineraryDetails?.orderOfCities);

  // console.log({ parsedOrderOfCities });

  const orderOfCitiesArr: any =
    parsedOrderOfCities !== undefined &&
    parsedOrderOfCities !== null &&
    Object.values(parsedOrderOfCities);

  // console.log({ orderOfCitiesArr });

  const handleOpenConfirmModal = () => setOpenConfirmModal(true);
  const handleCloseConfirmModal = () => setOpenConfirmModal(false);

  const handleFinalizeQuotation = (quotationId: string, uId: string) => {
    const queryParams = new URLSearchParams({
      quotationId,
      uId,
    });
    navigate(`/finalize-quotation-amount?${queryParams}&index=${index}`);
  };

  const handleCancelQuotation = (quotationId: any) => {
    const param = `${quotationId}/CANCELLED`;
    setLoader(true);
    serviceProvider.quotationService
      .put(param, "")
      .then((res) => {
        // alert("true");
        if (res.responseCode === 200) {
          // handleCloseQuoteModal();
          handleCloseConfirmModal();
          toast.success(res.message);
          window.location.reload();
          // navigate("/quotation-management")
        } else {
          toast.error(res?.message || "Something Went Wrong!!");
        }

        setLoader(false);
      })
      .catch((err) => {
        console.log({ err });
        toast.error(err?.data?.message || "Something Went Wrong!!");
        setLoader(false);
      });
  };

  const confirmModalBody = (
    <Grid container rowSpacing={4} className="confirm-request-modal">
      <Grid item xs={12}>
        <Typography variant="h6" component="h6">
          Cancel Quotation
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body1" component="p" textAlign="center">
          Are you sure you want to cancel this quotation?
        </Typography>
      </Grid>
      <Grid item xs={12} className="btn-container">
        <ButtonComponent
          className="rounded-btn-outlined"
          fullWidth={false}
          label="Cancel"
          onClick={handleCloseConfirmModal}
        />
        <ButtonComponent
          fullWidth={false}
          loader={loader}
          disabled={loader}
          label="Confirm"
          onClick={() => handleCancelQuotation(quotation?.quotationId)}
        />
      </Grid>
    </Grid>
  );

  // alert(index)

  return (
    <>
      <CommonModal
        open={openConfirmModal}
        handleCloseModal={handleCloseConfirmModal}
        modalBody={confirmModalBody}
      />
      <Card className="quotation-card">
        <Grid container className="user-details">
        
          <Grid item xs={12}>
            <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
              <p className="custom-chip">
                {quotation?.itineraryDetails?.itineraryType || "--"}
              </p>
              <p className="custom-chip">{quotation?.flightType || "--"}</p>
            </Box>
            {/* <Chip label="primary" color="primary"  className="custom-chip" /> */}
          </Grid>
          <Grid item xs={12} className="item">
            <Typography variant="body1" component="span">
              User name :
            </Typography>
            <Typography variant="body1" component="span">
              {quotation?.userDetails?.fullName || "--"}
            </Typography>
          </Grid>
          <Grid item xs={12} className="item">
            <Typography variant="body1" component="span">
              Email :
            </Typography>
            <Typography variant="body1" component="span">
              {quotation?.userDetails?.email || "--"}
            </Typography>
          </Grid>
          <Grid item xs={12} className="item">
            <Typography variant="body1" component="span">
              Mobile No :
            </Typography>
            <Typography variant="body1" component="span">
              {quotation?.userDetails?.mobileNumber || "--"}
            </Typography>
          </Grid>
        </Grid>
        <Grid container className="quotation-details">
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={8} className="itinerary-details">
              
                {quotation?.pathDetails?.itineraryPathDetails?.length >= 1 &&
                  quotation?.pathDetails?.itineraryPathDetails
                    .filter(
                      (itineraryPath: any) =>
                        itineraryPath?.flightType === "SEARCHED"
                    )
                    .map((filteredItineraryPath: any) => (
                      <React.Fragment key={filteredItineraryPath?.itineraryId}>
                        <ItineraryDetail
                          itineraryDetails={filteredItineraryPath}
                        />
                        <Divider sx={{ mt: "5px", mb: "10px" }} />
                      </React.Fragment>
                    ))}

          
              </Grid>
              <Grid item xs={0.5} display="flex" justifyContent="center">
                <Divider orientation="vertical" />
              </Grid>
              <Grid item xs={3.5} className="total-paid">
                <Typography variant="h6" component="h6">
   
                  {/* {index === 0 ? "Estimated Price" : !quotation?.finalPrice ? "Estimated Price" : "Final Price"} */}
                  {quotation?.status === "PENDING" ? "Estimated Price" : !quotation?.finalPrice ? "Estimated Price" : "Final Price (including GST)"}
                </Typography>
                {/* <Typography variant='h6' component='h6'>{
            index === (1 || 2) ? "Final Price":"Estimated Price"
}</Typography> */}
                {/* <Typography variant='h5' component="h5">{ index === 1 ?formateCurrency(quotation?.finalPrice) || "--" : formateCurrency(quotation?.estimatedPrice) || "--"}</Typography> */}
                <Typography variant="h5" component="h5">
                  {quotation?.status === "PENDING"
                    ? formateCurrency(quotation?.estimatedPrice) || "--"
                    : !quotation?.finalPrice ? formateCurrency(quotation?.estimatedPrice) :formateCurrency(quotation?.priceAfterGst) || "--" }
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          {/* {index === 0 && (
            <Grid item xs={12} className="finalize-btn">
              <Button
                variant="contained"
                className="rounded-btn-fill"
                onClick={() =>
                  handleFinalizeQuotation(
                    quotation?.quotationId,
                    quotation?.uid
                  )
                }
              >
                Finalize Quotation Amount
              </Button>
            </Grid>
          )} */}
          {/* {index === 1 && ( */}
            <Grid item xs={12}>
              <Grid container spacing={2}>
                {/* <Grid item xs={12} className="finalize-btn"> */}
                  {
                    quotation?.status === "PENDING" && (
                      <Grid item xs={6} className="finalize-btn">
                      <Button
                      variant="contained"
                      className="rounded-btn-fill"
                      fullWidth={true}
                      onClick={() =>
                        handleFinalizeQuotation(
                          quotation?.quotationId,
                          quotation?.uid
                        )
                      }
                    >
                      Finalize Quotation Amount
                    </Button>
                    </Grid>
                    )
                  }
                  {
                   quotation?.status === "SENT" && (
                      <Grid item xs={6} className="finalize-btn">
                      <Button
                    variant="contained"
                    className="rounded-btn-fill"
                    fullWidth={true}
                    onClick={() =>
                      handleFinalizeQuotation(
                        quotation?.quotationId,
                        quotation?.uid
                      )
                    }
                  >
                    Update Quotation Amount
                  </Button>
                  </Grid>
                    )
                  }

                  {
                    (quotation?.status === "PENDING" || quotation?.status === "SENT") &&(
                      <Grid item xs={6} className="finalize-btn">
                      <ButtonComponent
                        className="rounded-btn-outlined"
                        // fullWidth={false}
                        label={"Cancel Quotation"}
                        onClick={handleOpenConfirmModal}
                        // onClick={() => handleFailedBooking(bookingDetails?.bookingId)}
                      />
                     </Grid>
                    )
                  }
                {/* </Grid> */}
              </Grid>
            </Grid>
          {/* )} */}
          {/* {index === 0 || index === 1 && (
            <Grid item xs={12} className="finalize-btn">
              <ButtonComponent
                className="rounded-btn-outlined"
                fullWidth={false}
                label={"Cancel Quotation"}
                onClick={handleOpenConfirmModal}
                // onClick={() => handleFailedBooking(bookingDetails?.bookingId)}
              />
            </Grid>
          )} */}
          {/* {index === 1 && <Grid item xs={12} className="finalize-btn"></Grid>} */}
        </Grid>
      </Card>
    </>
  );
};

export default QuotationCard;
