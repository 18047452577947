import React, { useEffect, useState } from "react";
import {
  Typography,
  Grid,
  Button,
  TextField,
  InputAdornment,
  Box,
} from "@mui/material";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import CommonModal from "../modal/CommonModal";
import { serviceProvider } from "../../provider/serviceProvider";
import { useLocation, useNavigate } from "react-router-dom";
import { IFleet } from "../../interface/IFleet";
import { IAircraft } from "../../interface/IAircraft";
import FleetTabs from "./FleetsTabs";
import SearchBar from "../searchBar/SearchBar";
import ButtonComponent from "../common/ButtonComponent";
import AddFleet from "./AddFleet";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { toast } from "react-toastify";



const grid = 8;

const getItemStyle = (isDragging:boolean, draggableStyle:any) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  translate:"none",
  // border:isDragging ? "4px solid red":"none",
  // padding: grid * 2,
  // margin: `0 0 ${grid}px 0`,

  // change background colour if dragging
  // background: isDragging ? "lightgreen" : "grey",

  // styles we need to apply on draggables
  ...draggableStyle
});

const getListStyle = (isDraggingOver:any) => ({
  // background: isDraggingOver ? "lightblue" : "lightgrey",
  // padding: grid,
  // width: 250
});



const FleetManagement: React.FC = () => {
  const [value, setValue] = React.useState<number>(0);
  const [open, setOpen] = useState<boolean>(false);
  const [openFleetReorder, setOpenFleetReorder] = useState<boolean>(false);
  const [openAircraftReorder, setOpenAircraftReorder] =
    useState<boolean>(false);

  const handleOpenModal = () => setOpen(true);
  const handleCloseModal = () => setOpen(false);

  const handleOpenAircraftReorderModal = () => setOpenAircraftReorder(true);
  const handleCloseAircraftReorderModal = () => setOpenAircraftReorder(false);

  const handleOpenFleetReorderModal = () => setOpenFleetReorder(true);
  const handleCloseFleetReorderModal = () => setOpenFleetReorder(false);

  const [loader, setLoader] = useState<boolean>(false);
  const [allFleets, setAllFleets] = useState<IFleet[]>([]);
  const [filteredFleets, setFilteredFleets] = useState<IFleet[]>([]);
  const [fleetAircrafts, setFleetAircrafts] = useState<IAircraft[]>([]);
  const [filteredFleetAircrafts, setFilteredFleetAircrafts] = useState<
    IAircraft[]
  >([]);
  const navigate = useNavigate();
  const [searchActive, setSearchActive] = useState<any>({
    allFleets: false,
    fleetAircrafts: false,
  });
  const [searchQuery, setSearchQuery] = useState<string>("");

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const fleetId = searchParams.get("fleetId");

  const index = searchParams.get("index");

  // Convert the 'index' value to a number if needed
  const indexNumber = parseInt(index + "", 10);

  const navigateToTab = (fleetId: string, index: any) => {
    navigate(`?fleetId=${fleetId}&index=${index}`);
    setFilteredFleets(allFleets);
    setFilteredFleetAircrafts(fleetAircrafts);
  };

  const getAllFleets = () => {
    setLoader(true);
    serviceProvider.fleetService
      .getAllFleets()
      .then((res) => {
        setLoader(false);
        if (res.responseCode === 200) {
          setAllFleets(res.data);
        }
        console.log({ res });
      })
      .catch((err) => {
        setLoader(false);
        toast.error(err?.data?.error?.message || "Something went wrong!")
        console.log({ err });
      });
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    setSearchQuery("");
  };

  // const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   const newSearchQuery = e.target.value.toLowerCase();
  // console.log({newSearchQuery})
  //   if (newSearchQuery === "") {
  //     // setSearchActive(false);
  //     setSearchActive({allFleets:false,fleetAircrafts:false});
  //   } else {

  //     if (value === 0) {
  //       // Filter fleets
  //       setSearchActive({allFleets:true,fleetAircrafts:false});
  //       const filteredFleet = allFleets.filter(
  //         (fleet) => fleet.name.toLowerCase().includes(newSearchQuery)
  //       );
  //       setFilteredFleets(filteredFleet);
  //     } else {
  //       setSearchActive({allFleets:false,fleetAircrafts:true});
  //       // Filter aircrafts
  //       const filteredAircrafts = fleetAircrafts.filter(
  //         (aircraft) => aircraft.name.toLowerCase().includes(newSearchQuery)
  //       );
  //       setFilteredFleetAircrafts(filteredAircrafts);
  //     }
  //   }
  // };

  const getAllFleetAircrafts = (fleetId: string) => {
    setLoader(true);
    serviceProvider.aircraftService
      .getAllAircrafts(fleetId)
      .then((res) => {
        setLoader(false);
        if (res.responseCode === 200) {
          setFleetAircrafts(res.data);
        }
        console.log({ res });
      })
      .catch((err) => {
        setLoader(false);
        toast.error(err?.data?.error?.message || "Something went wrong!")
        console.log({ err });
      });
  };

  const handleReorderAircrafts = () => {
    setLoader(true);
    serviceProvider.aircraftService
      .put("reorder",fleetAircrafts)
      .then((res) => {
        setLoader(false);
        if (res.responseCode === 200) {
          toast.success("Aircrafts Reordered.");
        handleCloseAircraftReorderModal()
          setFleetAircrafts(res?.data);
        }
        // console.log({ res });
      })
      .catch((err) => {
        setLoader(false);
        toast.error(err?.data?.error?.message || "Something went wrong!")
        console.log({ err });
      });
  };
  const handleReorderFleets = () => {
    setLoader(true);
    serviceProvider.fleetService
      .put("reorder",allFleets)
      .then((res) => {
        setLoader(false);
        if (res.responseCode === 200) {
          toast.success("Fleets Reordered.");
          handleCloseFleetReorderModal();
          setAllFleets(res?.data);
        }
        // console.log({ res });
      })
      .catch((err) => {
        setLoader(false);
        toast.error(err?.data?.error?.message || "Something went wrong!")
        console.log({ err });
      });
  };

  const reorder = (list: any, startIndex: any, endIndex: any) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  function onDragEnd(result: any) {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    const tArr: any[] = reorder(
      value === 0 ? allFleets : fleetAircrafts,
      result.source.index,
      result.destination.index
    );
    // console.log(tArr);

    tArr.forEach((ele: any, idx) => {
      ele.positionOrder = idx;
    });

    if (tArr) {
      value === 0 ? setAllFleets(tArr) : setFleetAircrafts(tArr);
    }
    // if (tArr) setSectionData(tArr);
    // serviceProvider.drillSectionService
    //   .putSectionData(title.includes("Event") ? "EVENTS" : "COLLABORATORS", tArr)
    //   .then((res) => {
    //     // setAlert("success", "Reordered");
    //     enqueueSnackbar("Reordered", {variant:"success"})
    //   });
  }

  useEffect(() => {
    getAllFleets();
    if (fleetId) getAllFleetAircrafts(fleetId);
  }, [fleetId]);

  useEffect(() => {
    if (indexNumber) {
      setValue(indexNumber);
    } else {
      setValue(0);
    }
  }, [indexNumber]);

  // useEffect(()=>{
  //   // const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {

  //     const newSearchQuery = searchQuery.toLowerCase();
  //   console.log({newSearchQuery})
  //     if (newSearchQuery === "") {
  //       // setSearchActive(false);
  //       setSearchActive({allFleets:false,fleetAircrafts:false});
  //     } else {

  //       if (value === 0) {
  //         // Filter fleets
  //         setSearchActive({allFleets:true,fleetAircrafts:false});
  //         const filteredFleet = allFleets.filter(
  //           (fleet) => fleet.name.toLowerCase().includes(newSearchQuery)
  //         );
  //         setFilteredFleets(filteredFleet);
  //       } else {
  //         setSearchActive({allFleets:false,fleetAircrafts:true});
  //         // Filter aircrafts
  //         const filteredAircrafts = fleetAircrafts.filter(
  //           (aircraft) => aircraft.name.toLowerCase().includes(newSearchQuery)
  //         );
  //         setFilteredFleetAircrafts(filteredAircrafts);
  //       }

  //     }

  // },[searchQuery,allFleets,fleetAircrafts,value])

  useEffect(() => {
    // const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {

    const newSearchQuery = searchQuery.toLowerCase();

    if (newSearchQuery === "") {
      // setSearchActive(false);
      setSearchActive({ allFleets: false, fleetAircrafts: false });
    } else {
      setSearchActive({ allFleets: false, fleetAircrafts: true });
      // Filter aircrafts
      const filteredAircrafts = fleetAircrafts.filter((aircraft) =>
        aircraft.name.toLowerCase().includes(newSearchQuery)
      );
      setFilteredFleetAircrafts(filteredAircrafts);
    }
  }, [searchQuery, allFleets, fleetAircrafts, value]);

  const modalBody = (
    <AddFleet
      handleCloseModal={handleCloseModal}
      setAllFleets={setAllFleets}
      getAllFleets={getAllFleets}
    />
  );

  const aircraftReorderModalBody = (
    <Grid container rowSpacing={4} className="add-fleet-container">
      <Grid item xs={12}>
        <Typography variant="h6" component="h6">
          Reorder Aicrafts
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable" direction="vertical">
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                style={getListStyle(snapshot.isDraggingOver)}
                {...provided.droppableProps}
              >
                {fleetAircrafts?.map((aircraft: IAircraft, idx: number) => (
                  <Draggable
                    key={aircraft?.aircraftId}
                    draggableId={aircraft?.aircraftId as string}
                    index={idx}
                    
                  >
                    {(provided, snapshot) => (
                      <div
                        key={aircraft?.aircraftId}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={{
                          padding: "15px 10px",
                          border: "1px solid #4A4A4A",
                          borderRadius: "10px",
                          marginBottom: "10px",
                          ...getItemStyle(snapshot.isDragging, provided.draggableProps.style),
                        }}
                      >
                        <Typography variant="body1" component="p">
                          {aircraft?.name}
                        </Typography>
                      </div>
                    )}
                  </Draggable>
                ))}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        {/* {fleetAircrafts?.map((aircraft: IAircraft, index) => (
             <Typography variant='body1' component="p" sx={{padding:"15px 10px",border:"1px solid #4A4A4A",borderRadius:"10px",mb:"10px"}}>{aircraft?.name}</Typography>
          ))} */}
      </Grid>
      <Grid item xs={12} className="btn-container">
        <ButtonComponent
          fullWidth={false}
          loader={loader}
          disabled={loader}
          label="Save"
          onClick={handleReorderAircrafts}
        />
        {/* <Button variant="contained" className="rounded-btn-fill" onClick={handleAddFleet}>Save</Button> */}
      </Grid>
    </Grid>
  );
  const fleetReorderModalBody = (
    <Grid container rowSpacing={4} className="add-fleet-container">
      <Grid item xs={12}>
        <Typography variant="h6" component="h6">
          Reorder Fleets
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable" direction="vertical">
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                style={getListStyle(snapshot.isDraggingOver)}
                {...provided.droppableProps}
              >
                {allFleets?.map((fleet: IFleet, idx: number) => (
                  <Draggable
                    key={fleet?.fleetId}
                    draggableId={fleet?.fleetId as string}
                    index={idx}
                    
                  >
                    {(provided, snapshot) => (
                      <div
                        key={fleet?.fleetId}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                       
                        style={{
                          padding: "15px 10px",
                          border: "1px solid #4A4A4A",
                          borderRadius: "10px",
                          marginBottom: "10px",
                          ...getItemStyle(snapshot.isDragging, provided.draggableProps.style),
                        }}
                      >
                        <Typography variant="body1" component="p">
                          {fleet?.name}
                        </Typography>
                      </div>
                    )}
                  </Draggable>
                ))}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        {/* {fleetAircrafts?.map((aircraft: IAircraft, index) => (
             <Typography variant='body1' component="p" sx={{padding:"15px 10px",border:"1px solid #4A4A4A",borderRadius:"10px",mb:"10px"}}>{aircraft?.name}</Typography>
          ))} */}
      </Grid>
      <Grid item xs={12} className="btn-container">
        <ButtonComponent
          fullWidth={false}
          loader={loader}
          disabled={loader}
          label="Save"
          onClick={handleReorderFleets}
        />
        {/* <Button variant="contained" className="rounded-btn-fill" onClick={handleAddFleet}>Save</Button> */}
      </Grid>
    </Grid>
  );

  return (
    <>
      <CommonModal
        open={open}
        handleCloseModal={handleCloseModal}
        modalBody={modalBody}
      />
      <CommonModal
        open={openFleetReorder}
        handleCloseModal={handleCloseFleetReorderModal}
        modalBody={fleetReorderModalBody}
      />
      <CommonModal
        open={openAircraftReorder}
        handleCloseModal={handleCloseAircraftReorderModal}
        modalBody={aircraftReorderModalBody}
      />
      <Grid container spacing={2} className="fleets-container">
        {/* ... (other components) */}
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={5} md={5} className="fleet-heading">
              <Typography variant="h6" component="h6">
                {value === 0 ? "Fleet" : "Aircraft"}
              </Typography>
            </Grid>
            <Grid item xs={7} md={7}>
              {value !== 0 && (
                <SearchBar
                  value={searchQuery}
                  onChange={(e: any) => setSearchQuery(e.target.value)}
                  //  onChange={handleSearch}
                  placeholder="Search Aircraft"
                />
              )}
              {/* <SearchBar 
              value={searchQuery}
              onChange={(e:any)=>setSearchQuery(e.target.value)}
              //  onChange={handleSearch} 
              placeholder={`Search ${value === 0 ? "Fleet" : "Aircraft"}`} /> */}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} display="flex" justifyContent="space-between" alignItems="center">
          {value === 0 ? (
            <ButtonComponent
              fullWidth={false}
              variant="contained"
              className="rounded-btn-fill"
              label="Add a fleet"
              onClick={handleOpenModal}
            />
          ) : (
            <ButtonComponent
              variant="contained"
              fullWidth={false}
              className="rounded-btn-fill"
              label="Add Aircraft"
              onClick={() =>
                navigate(`/fleets/aircraft/add/${fleetId}?index=${value}&fleetName=${allFleets[value-1]?.name}`)
              }
            />
          )}
          {value === 0 ? (
            <ButtonComponent
              fullWidth={false}
              variant="contained"
              className="rounded-btn-outlined"
              label="Reorder Fleet"
              onClick={handleOpenFleetReorderModal}
            />
          ) : (
            <ButtonComponent
              variant="contained"
              fullWidth={false}
              className="rounded-btn-outlined"
              label="Reorder Aircraft"
              onClick={handleOpenAircraftReorderModal}
            />
          )}
        </Grid>
        <Grid item xs={12} className="tabs-container">
          <FleetTabs
            loader={loader}
            value={value}
            // allFleets={searchActive.allFleets ? filteredFleets : allFleets}
            allFleets={allFleets}
            // setAllFleets={setAllFleets}
            handleChange={handleChange}
            // navigateToTab={navigateToTab}
            navigateToTab={navigateToTab}
            fleetAircrafts={
              searchActive.fleetAircrafts
                ? filteredFleetAircrafts
                : fleetAircrafts
            }
            // setFleetAircrafts={setFleetAircrafts}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default FleetManagement;
