import React, { useState, useEffect } from "react";
import { Grid, Button, Typography } from "@mui/material";
import { serviceProvider } from "../../provider/serviceProvider";
import SearchBar from "../searchBar/SearchBar";
import CommonModal from "../modal/CommonModal";
import UserTabs from "./UserTabs";
import AddUser from "./AddUser";
import { useLocation, useNavigate } from "react-router-dom";

const UserManagement = () => {
  const [open, setOpen] = useState(false);
  const [loader, setLoader] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [value, setValue] = React.useState(0);
  const [pageState, setPageState] = React.useState({
    loader: false,
    data: [],
    totalPages: 0,
    page: 0,
    pageSize: 5,
  });

  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const index = searchParams.get("index");

  // Convert the 'index' value to a number if needed
  const indexNumber = parseInt(index + "", 10);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    // navigate(`/users-management?index=${newValue}`)
  };

  const handleOpenModal = () => setOpen(true);
  const handleCloseModal = () => setOpen(false);

  // const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   const searchQuery = e.target.value;
  //   // setSearchQuery(e.target.value)
  //   const active = value === 0 ? true : false;
  //   const query = `search?uId=NA&email=all&fullName=${searchQuery}&isActive=${active}&offset=${pageState.page}&limit=${pageState.pageSize}&order=recordUpdatedts.desc&profile=BASIC`;

  //   getUsersList(query);
  // };

  const getUsersList = (param: any) => {
    // setLoader(true);
    setPageState((prev) => ({ ...prev, loader: true }));

    serviceProvider.userService
      .get(param)
      .then((res) => {
        // setLoader(false)
        if (res.responseCode === 200) {
          // setActiveUsers(res.data.details)
          // alert(res.data.totalRecordCount)
          setPageState((prev) => ({
            ...prev,
            loader: false,
            data: res.data.details,
            totalPages: res.data.totalRecordCount,
          }));
        }
        setPageState((prev) => ({ ...prev, loader: false }));
      })
      .catch((err) => {
        // setLoader(false)
        setPageState((prev) => ({ ...prev, loader: false }));
        console.log({ err });
      });
  };

  useEffect(() => {
    if (indexNumber) {
      setValue(indexNumber);
    } else {
      setValue(0);
    }
  }, [indexNumber]);

  
  useEffect(() => {
    // if (value === 0) {
    //   // const query = `search?uId=NA&email=all&fullName=all&isActive=${true}&offset=${
    //   //   pageState.page
    //   // }&limit=${pageState.pageSize}&order=recordUpdatedts.desc&profile=BASIC`;
    //   // getUsersList(query);
     
    // } else {
    //   const query = `search?uId=NA&email=all&fullName=all&isActive=${false}&offset=${
    //     pageState.page
    //   }&limit=${pageState.pageSize}&order=recordUpdatedts.desc&profile=BASIC`;
    //   getUsersList(query);
    // }
    const active = value === 0 ? true : false;

    const query = `search?uId=NA&email=all&fullName=${searchQuery || "all"}&isActive=${active}&offset=${pageState.page}&limit=${pageState.pageSize}&order=recordUpdatedts.desc&profile=BASIC`;

  getUsersList(query);
  }, [value, pageState.page, pageState.pageSize,searchQuery]);

  return (
    <>
      <CommonModal
        open={open}
        handleCloseModal={handleCloseModal}
        modalBody={
          <AddUser
            handleCloseModal={handleCloseModal}
            getUsersList={getUsersList}
            pageState={pageState}
          />
        }
      />

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={5} md={5}>
              <Typography variant="h6" component="h6">
                Users
              </Typography>
            </Grid>
            <Grid item xs={7}>
              <SearchBar
              value={searchQuery}
                onChange={(e:any)=>setSearchQuery(e.target.value)}
                placeholder="Search UserName"
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Button
            variant="contained"
            className="rounded-btn-fill"
            onClick={handleOpenModal}
          >
            Add User
          </Button>
        </Grid>
        <Grid item xs={12}>
          {/* <UserTabs users={allUsers} loader={loader} /> */}
          <UserTabs
            pageState={pageState}
            setPageState={setPageState}
            value={value}
            handleChange={handleChange}
            getUsersList={getUsersList}
          />
          {/* <UserTabs activeUsers={activeUsers}  loader={loader} value={value} handleChange={handleChange} /> */}
        </Grid>
      </Grid>
    </>
  );
};

export default UserManagement;
