// import {
//     Box,
//     Grid,
//     Tab,
//     TextField,
//     Typography,
//     Button,
//     FormControl,
//     InputLabel,
//     Select,
//     MenuItem,
//     Card,
//     Divider,
//     Stack,
//     // Checkbox,
//     // FormControlLabel,
//     // Radio,
//     IconButton,
//     Paper,
//     Modal,
//     // Fab,
//     // Autocomplete,
//     // Snackbar,
//     // Input
// } from "@mui/material";
//   import Tabs, { tabsClasses } from "@mui/material/Tabs";
//   import React, { useEffect, useRef, useState, useContext } from "react";
//   import { serviceProvider } from "../../provider/serviceProvider";
//   import AddIcon from "@mui/icons-material/Add";
//   import SwipeDownIcon from '@mui/icons-material/SwipeDown';
//   import SpinLoader from "../../components/loader/SpinLoader";
//   import S3 from "react-aws-s3-typescript";
//   import { IValidationResponse } from "../../interface/IValidation";
//   import { default as awsConf } from "../../configuration/config.json";
// import Editor from "ckeditor5-custom-build/build/ckeditor";
// import UserContext, { DrillIdContext } from "../../context/UserContext";
// import statsCampaign from "../../configuration/config.json";
// import {default as apiConfig} from "../../configuration/config.json"
//   // import {
//   //   bulkMail
//   // } from "../../configuration/config.json";
// import EditorNew from "../../components/common/EditorNew";
// import CloseIcon from "@mui/icons-material/Close";
// import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
// import MuiAlert, { AlertProps } from "@mui/material/Alert";
// import CheckBoxIcon from "@mui/icons-material/CheckBox";
// // import { AlertBar } from "../commonComponents/alert";
// import inputFileIcon from "../../assets/icons/add-image.svg";
// import { error } from "console";
// // import { enqueueSnackbar } from "notistack";
// import { ValidationUtiltiy } from "../../utility/ValidationUtility";
// import { ValidationType } from '../../enum/validationType';
// import { toast } from "react-toastify";




// const awsConfig = awsConf.awsConfig;

  
// interface TabPanelProps {
//     children?: React.ReactNode;
//     index: number;
//     value: number;
//   }
  
// interface Stats {
//     name: string;
//     value: number;
//   }
  
// function TabPanel(props: TabPanelProps) {
//     const { children, value, index, ...other } = props;
//     return (
//       <div
//         role="tabpanel"
//         hidden={value !== index}
//         id={`simple-tabpanel-${index}`}
//         aria-labelledby={`simple-tab-${index}`}
//         {...other}
//       >
//         {value === index && (
//           <Box sx={{ pt: 3 }}>
//             <Typography>{children}</Typography>
//           </Box>
//         )}
//       </div>
//     );
//   }
  
//   function a11yProps(index: number) {
//     return {
//       id: `simple-tab-${index}`,
//       "aria-controls": `simple-tabpanel-${index}`,
//     };
//   }
  
//   function sendMail() {}
//   export const Mails: React.FC<any> = () => {
//     const [value, setValue] = React.useState(0);
//     const [editorLoaded, setEditorLoaded] = useState(false);
//     const [data, setData] = useState("");
//     const [allEmails, setEmails] = useState("");
//     const [mailTemplateType, setMailTemplateType] = useState<any>([]);
//     const [listOfReceiver, setListOfReceiver] = useState<any>([]);
//     const [templateId, setTemplateId] = useState("NA");
//     const [userTypes, setUserTypes] = useState<any>();
//     const [mailTemplate, setMailTemplate] = useState("");
//     const [userTypeEmailList, setUserTypeEmailList] = useState("");
//     const [participantType, setParticipantType] = useState("");
//     const [inviteGuest, setInviteGuest] = useState(true);
//     const [mailApiResponse, setMailApiResponse] = useState("");
//     const [emailList, setEmailList] = useState("");
//     const [templateName, setTemplateName] = useState("");
//     const [participantState, setParticipantState] = useState("Registered");
//     const [loader, setLoader] = useState<boolean>(false);
//     const [registered, setRegistered] = useState<boolean>(false);
//     const [open, setOpen] = useState(false);
//     const [msgPlatform, setMsgPlatform] = useState("All");
//     const [msgData, setMsgData] = useState("");
//     const [successOpen, setSuccessOpen] = React.useState(false);
//     const [failureOpen, setFailureOpen] = React.useState(false);
//     const [successMessage, setSuccessMessage] = useState("");
//     const [failureMessage, setFailureMessage] = useState("");
//     const [selectedCampaign, setSelectedCampaign] = useState<Campaign | null>(
//       null
//     );
//     const [statsData, setStatsData] = useState<any>([]);
//     const [receiverType, setReceiverType] = useState("NA");
//     const [role, setRole] = useState("");
//     const [customers, setCustomers] = useState<any[]>([]);
//     const [fileValue, setFileValue] = useState<any>(null)

//     const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
//     const checkedicon = <CheckBoxIcon fontSize="small" />;
//     const trackApplications = require("../../configuration/config.json").trackApplications;

//     // const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
//     //   props,
//     //   ref
//     // ) {
//     //   return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
//     // });

//     const initObj = {
//       bcc: "",
//       to: "",
//       cc: "",
//       message: "",
//       subject: "",
//       templateName: "GenericMailForAllUsers",
//       userType: "",
//       headerIdName: "string",
//       headerIdValue: "string",
//       heading: "string",
//       link: "string",
//       mobile: "string",
//       otp: "string",
//       unsubscribeLink: "string",
//       userName: "Dear User",
//     };

//     interface Campaign {
//       sentMailId: number;
//       recipientListTo: string;
//       recipientListCc: string;
//       recipientListBcc: string;
//       sentDt: string;
//       mailCategoryId: number | null;
//       eventId: number | null;
//       campaignId: string;
//       subject: string;
//       mailContent: string;
//       partnerId: number | null;
//       createdby: number | null;
//       createdts: string;
//       updatedby: number | null;
//       updatedts: string;
//       uid: string;
//     }
//     const [submissionObj, setSubmissionObj] = useState(initObj);
//     const [campaigns, setCampaigns] = useState([]);
//     const { drillId } = useContext(DrillIdContext);
//     const [customerId, setCustomerId] = useState("");
//     const [extractedData, setExtractedData] = useState<any>()

//     const handleChange = (event: React.SyntheticEvent, newValue: number) => {
//       setValue(newValue);
//     };
  
//     const setAllEmailIds = (event: any) => {
//       var ids = [];
//       var allids = "";
//       ids = event.target.value.split(",");
//       ids.map((e: string) => {
//         allids = '"' + e + '",' + allids;
//       });
//       allids = allids.substring(0, allids.length - 1);
//       setEmailList(event.target.value);
//       var emailIds = JSON.parse("[" + allids + "]");
//       submissionObj.to = emailIds.toString();
//     };
  
//     const [partnerId, setPartnerId] = useState("");
  
//     const handleSelectCustomer = (e: any) => {
//       setPartnerId(e);
//     };

//     // Commented
  
//     // const PostMail = () => {
//     //   setLoader(true);
//     //   const obj: any = { ...submissionObj };
//     //   obj.message = data;
//     //   if (obj.templateName === "") {
//     //     obj.templateName = null;
//     //   }
//     //   if (obj.from == "") {
//     //     obj.from = "no-reply@wuelev8.tech";
//     //   }
//     //   console.log(obj);
//     //   isValidRequest().then(()=>{serviceProvider.notificationService
//     //     .sendMail(obj, customerId)
//     //     .then((data: any) => {
//     //       //fetchData(partnerId);
//     //       if (data != true) {
//     //         toast.error("Error!! Mail not sent")
//     //         // enqueueSnackbar("Error!! Mail not sent", {variant:"error"})
//     //         // setFailureMessage("Error!! Mail not sent");
//     //         // setFailureOpen(true);
//     //         setLoader(false);
//     //       }
//     //       else{
//     //         // setSuccessMessage("Mail Sent Successfully!!");
//     //         toast.success("Mail Sent Successfully!!")
//     //         // enqueueSnackbar("Mail Sent Successfully!!", {variant:"success"})
//     //         // setSuccessOpen(true);
//     //         // setTimeout(() => window.location.reload(), 1000);
//     //         setLoader(false);
//     //       }
//     //     })
//     //     .catch((e: any) => {
//     //        // setFailureMessage("Error!! Mail not sent");
//     //       toast.error("Error!! Mail not sent")
//     //       // setFailureOpen(true);
//     //       setLoader(false);
//     //     })}).catch((err)=>{
//     //       toast.error("Error!! Mail not sent");

//     //       setLoader(false)
//     //     })
//     // };
  
//     const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
//       setValue(newValue);
//     };
  
//     const fetchData = async (custId:any) => {
//       try {
//         const response = await fetch(trackApplications+"&partnerId="+custId);
//         const data = await response.json();
//         setCampaigns(data.data.reverse());
//       } catch (error) {
//         console.error("Error fetching campaigns:", error);
//       }
//     };
  
//     const fetchStats = async (campaign: Campaign) => {
//       try {
//         const response = await fetch(statsCampaign + campaign?.campaignId);
//         const data = await response.json();
//         setStatsData(data.currentStatus);
//       } catch (error) {
//         console.error("Error fetching campaigns:", error);
//       }
//     };
  
//     const handleOpen = async (campaign: Campaign) => {
//       // try {
//       //   const response = await fetch(statsCampaign+campaign.campaignId)
//       //   const data = await response.json();
//       //   setCampaigns(data.data.reverse());
//       // } catch (error) {
//       //   console.error('Error fetching campaigns:', error);
//       // }
//       setSelectedCampaign(campaign);
//       setOpen(true);
//     };
  
//     const handleModalOpen = ()=>{
//       setOpen(true)
//     }

//     const handleClose = () => {
//       setOpen(false);
//     };

//     const style = {
//       position: 'absolute' as 'absolute',
//       top: '50%',
//       left: '50%',
//       transform: 'translate(-50%, -50%)',
//       bgcolor: 'background.paper',
//       boxShadow: 24,
//       borderRadius:"20px",
//       p: 4,
//     };
    
  
//     const formattedDate = (date: string) => {
//       return new Intl.DateTimeFormat("en-US", {
//         day: "numeric",
//         month: "long",
//         year: "numeric",
//         hour: "numeric",
//         minute: "numeric",
//         hour12: true,
//       }).format(new Date(date));
//     };
  
//     const convertHtmlToText = (html: string) => {
//       const parser = new DOMParser();
//       const parsedHtml = parser.parseFromString(html, "text/html");
//       return parsedHtml.body.textContent || "";
//     };
  
//     const getRoles = () => {
//       setLoader(true)
//       const data = serviceProvider.authenticationService.getRole();
//       if (data != undefined) {
//         setLoader(false)
//         // console.log(data[0]);
//         setRole(data[0]);
//       }
//     };

//     // const handleSnackClose = (
//     //   event?: React.SyntheticEvent | Event,
//     //   reason?: string
//     // ) => {
//     //   if (reason === "clickaway") {
//     //     return;
//     //   }
//     //   setSuccessOpen(false);
//     //   setFailureOpen(false);
//     // };

// const handleFileChange = (e) => {
//       const file = e.target.files[0];
//       setFileValue(file);
//     };


// const handleExtractMails = async()=>{
// if(fileValue){
//     const formData = new FormData();
//  formData.append('file', fileValue);
// setLoader(true)// Use the provided API endpoint to send the file

//    await fetch(`${apiConfig?.mailsite}/wuelev8/api/v1/mails/readFromFile`, {
//       method: 'POST',
//       body: formData
//     })
//       .then(response => response.json())
//       .then(data => {
//         // Assuming the server response contains the extracted emails
//        // setExtractedmails(data);
//        console.log(data, "MailsData")

// const mails = JSON.stringify(data['EMAILS']).slice(1,-1)
//         setEmailList(mails.replaceAll('"',''))
//         if(data){
//           setOpen(false)
//           setLoader(false)
//         }
//         // console.log(data, "extractedData");
//         // You can handle the extractedEmails state as needed
//       })
//       .catch(error => {
//         setLoader(false)
//         console.error('Error uploading file:', error);
//         // Handle errors
//       });
// }
// }



// const validateText= (eligibility) => {
//   return ValidationUtiltiy.validateEditor(eligibility, ValidationType.Editor, true, "errorDiv-subject", "Subject");
// }


// const validateDescription = (data)=>{
//   return ValidationUtiltiy.validateEditor(data, ValidationType.Editor, true, "errorDiv-description", "Description");
// }


// const isValidRequest = (): Promise<boolean> => {
//   let responseArray: IValidationResponse[] = [];
//   responseArray.push(validateDescription(data)); 
//   responseArray.push(validateText(submissionObj.subject));

//   let haveErrors = responseArray.filter((x) => {
//       return !x.isValid;
//   }).length <= 0;
//   return new Promise((resolve, reject) => {
//       if (haveErrors) {
//           resolve(true);
//       } else {
//           reject(false);
//       }
//   });
// }



//     useEffect(() => {
//       setEditorLoaded(true);
//       getRoles();
// // Commented
//       // serviceProvider.getDrill.getDrillInfo(drillId).then((res)=>{
//       //   setCustomerId(res[0].drillPartnerId);
//       //   //fetchData(res[0].drillPartnerId);
//       // }).catch((e)=>{
//       // })
//     }, []);
  
//     useEffect(() => {
//       if (selectedCampaign != null) {
//         fetchStats(selectedCampaign);
//       }
//     }, [selectedCampaign]);

//     if(loader){
//     return <SpinLoader />
//     }
//     return (
//       <div>
//         <Grid>
//             <Tabs value={value} onChange={handleTabChange} aria-label="Mail Tabs">
//               <Tab
//                 label="Mail Campaign"
//                 {...a11yProps(0)}
                
//                 sx={{pl:1}}
//               />
//               {/* <Tab
//                 label="Track Mail Campaign"
//                 {...a11yProps(1)}
                
//                 sx={{pl:1}}
//               /> */}
//               {/* <Tab
//                 label="Send Message"
//                 {...a11yProps(2)}
//                 className="color-logo-blue"
//               /> */}
//             </Tabs>
           
//           <TabPanel value={value} index={0}>
//             <Card 
//               sx={{
//                 borderRadius:"20px",
//                 p: 2,
//                 boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)"
//               }}
//             >
//               <Grid container>
                
//                 <Grid
//                   item
//                   xs={12}
//                   display={{ xs: "block", md: "none" }}
//                   sx={{ mt: 2 }}
//                 ></Grid>
  
//                 <Grid item xs={12} md={12}>
//                   <Grid container>
//                     <Grid item xs={12} md={10}>
//                     <InputLabel shrink sx={{fontWeight:600,color:"#4A4A4A"}}>*You can insert maximum of 5000 emails in one campaign</InputLabel>
//                       {/* <InputLabel className="labelColor mb-0-25" sx={{fontSize:"small"}}> */}
//                       <TextField
//                         fullWidth
//                         size="small"
//                         rows={4}
//                         variant="standard"
//                         className='search-input'
//                         InputProps={{
//                           disableUnderline: true,
//                           sx:{m:"10px"}
//                         }}
//                         multiline={true}
//                         onChange={setAllEmailIds}
//                         value={emailList}
//                         disabled={receiverType != "NA"}
//                         placeholder="Put all emails with comma inbetween like firstuser@wuelev8.tech, 
//                                       seconduser@wuelev8.tech"
//                       />
//                     </Grid>

// <Grid item xs={12} md={2} textAlign={{xs:"end", md:"center"}} sx={{margin:'auto',}} >
// {/* <Button onClick={handleModalOpen}  ><AddIcon/></Button> */}
// {/* <Modal
//   open={open}
//   onClose={handleClose}
//   aria-labelledby="modal-modal-title"
//   aria-describedby="modal-modal-description"
// >
//   <Box sx={style}>
//   <input type="file" style={{margin:'4px 15px'}} onChange={handleFileChange} />
//   </Box>
// </Modal> */}

// <Modal      open={open}
//             onClose={handleClose}
//             aria-labelledby="modal-modal-title"
//             aria-describedby="modal-modal-description"
//           >
//             <Box
//               sx={style}
// width={{xs:"90%", md:400}}
//             >
//               <InputLabel className="labelColor" sx={{ color: "#1b1d1c" }}>
//                 <div
//                   style={{
//                     float: "right",
//                   }}
//                 >
//                   <CloseIcon
//                     sx={{ cursor: "pointer" }}
//                     onClick={handleClose}
//                   />
//                 </div>
//                 <span>
//                   <Divider>Upload Excel File</Divider>
//                 </span>
//               </InputLabel>

//               <Grid
//                 container
//                 sx={{ my: 4, display: "flex", justifyContent: "center", gap:"15px" }}
//               >
// <Grid item xs={12} md={10} >
// <input id="upload-excel-file"
//                                style={{ marginTop: "25px", marginBottom: "25px", display:"none" }}
//                                 name="upload-excel-file"
//                                type="file"
//                                onChange={handleFileChange}
//                            />
// <Grid container textAlign={"center"} className="inputFile">
//     <label style={{cursor:"pointer"}} htmlFor="upload-excel-file">
//     <img src={inputFileIcon} alt="File Icon" />
//     <Typography component={"p"}>
//     <span className="inputFileText">Choose file</span>
//     <br/> <span>{fileValue ? fileValue.name : 'No file chosen'}</span>
//     </Typography>
//     </label>
// </Grid >
// </Grid>
// </Grid>
// <Grid             item
//                   xs={12}
//                   md={6}
//                   justifyContent="center"
//                   alignItems="center"
//                 >
//                   <Grid container>
//                     <Grid
//                       item
//                       xs={12}
//                       display={{ xs: "block", md: "none" }}
//                     ></Grid>

//             <Grid item xs={12} sx={{ textAlign: "start" }}>
//                       <ul>
//                         <li>
//                           <InputLabel className="labelColor" sx={{ fontSize: "small" }}>
//                             Only accepts .xlsx or .csv file
//                           </InputLabel>
//                         </li>
//                       </ul>
//                     </Grid>
// </Grid>
// </Grid>

// <Grid item xs={12} textAlign={"center"} className="mt-1">
//                   <Button
//                     variant="outlined"
//               className="uploadButton"
//                     onClick={handleExtractMails}
//                   >
// Click to extract emails
//                   </Button>
// </Grid>
//             </Box>
//           </Modal>

// <Button variant="contained" style={{marginTop:10,   borderRadius: "20px",color:"#CA3657", backgroundColor:"white", border:"2px dotted #CA3657",boxShadow:"none"}} onClick={handleModalOpen} ><SwipeDownIcon sx={{marginRight:1}}/>  Extract emails </Button>  
// </Grid>
//                     <Grid item xs={12} sx={{ mt: 2 }}>
//                     <InputLabel shrink sx={{fontWeight:600,color:"#4A4A4A"}}>Subject of the email</InputLabel>
//                       <TextField
//                         fullWidth
//                         size="small"
//                         variant="standard"
//                         className='search-input'
//                         InputProps={{
//                           disableUnderline: true,
//                           // sx:{m:"10px"}
//                         }}
//                         value={submissionObj.subject}
//                         onChange={(e) => {
//                           var obj = { ...submissionObj };
//                           obj.subject = e.target.value;
//                           setSubmissionObj(obj);
//                         }}
//                         placeholder="Subject of the email"
//                       />
//                       <span className="modalError" id='errorDiv-subject'></span>
//                     </Grid>
  
//                     <Grid xs={12} sx={{ mt: 2 }}>
//                       {/* <InputLabel className="labelColor mb-0-25">Description</InputLabel> */}
//                       <InputLabel shrink sx={{fontWeight:600,color:"#4A4A4A"}}>Description</InputLabel>
//                       <EditorNew
// placeholder="Write the description"
//                         onChange={(data: string) => {
//                           setData(data);
//                         }}
//                         editorLoaded={editorLoaded}
//                         value={data}
//                       />
//                       <span className="modalError" id="errorDiv-description"></span>
//                     </Grid>
//                     <Grid md={12} textAlign={"end"} my={2}>
//                       <Button
//                        variant="contained"
//                        className="rounded-btn-fill"
//                         // Commented
//                         // onClick={PostMail}
//                       >
//                         Send
//                       </Button>
//                     </Grid>
//                   </Grid>
//                 </Grid>
//               </Grid>
//             </Card>
//           </TabPanel>
//           {/* <TabPanel value={value} index={1}>
//             <Grid
//               container
//               className="color-logo-blue"
//               sx={{ overflow: "scroll", maxHeight: "100vh" }}
//             >
//               {campaigns?.map((campaign: Campaign) => (
//                 <Grid item xs={12} className="color-logo-blue">
//                   <Card
//                     sx={{
//                       //ml: { xs: 0, md: 5 },
//                       mx: 3,
//                       mb: 3,
//                       p: 2,
//                       borderRadius:"20px",
//                       boxShadow: 5,
//                     }}
//                   >
//                     <Grid container spacing={2}>
//                       <Grid item xs={12}>
//                         <Typography
//                           className="color-logo-blue"
//                           variant="h5"
//                           sx={{ fontWeight: "700" }}
//                         >
//                           {campaign?.subject}
//                         </Typography>
//                       </Grid>
//                       <Grid item xs={12} md={12}>
//                         <Grid container>
//                           <Grid
//                             item
//                             xs={12}
//                             md={4}
//                             display="flex"
//                             flexDirection="column"
//                           >
//                             <Typography>Date</Typography>
//                             <Typography variant="subtitle2">
//                               {formattedDate(campaign?.sentDt)}
//                             </Typography>
//                           </Grid>
//                           <Grid
//                             item
//                             md={1}
//                             xs={12}
//                             display="flex"
//                             justifyContent="flex-end"
//                             alignItems="center"
//                           >
//                             <Button
//                               className="btn-common bg-logo-blue btnLayout mb-1"
//                               sx={{ px: 2 }}
//                               onClick={() => handleOpen(campaign)}
//                             >
//                               View
//                             </Button>
//                           </Grid>
//                         </Grid>
//                       </Grid>
//                     </Grid>
//                   </Card>
//                 </Grid>
//               ))}
//             </Grid>
//             <Modal open={open} onClose={handleClose}>
//               <Box
//                 sx={{
//                   position: "absolute",
//                   top: "50%",
//                   left: "50%",
//                   transform: "translate(-50%, -50%)",
//                   borderRadius: "20px",
//                   width: "90%",
//                   maxWidth: 800,
//                   bgcolor: "background.paper",
//                   boxShadow: 24,
//                   p: 4,
//                   overflowY: "scroll"
//                 }}
//               >
//                   <IconButton
//                     sx={{ position: "absolute", top: 8, right: 8 }}
//                     onClick={handleClose}
//                   >
//                     <CloseIcon />
//                   </IconButton>
//                   <Typography variant="h4" sx={{ fontWeight: "700" }}>
//                     Campaign Details
//                   </Typography>
//                   <Grid container display="flex" gap={7} sx={{ mt: 1 }}>
//                     {statsData ? (
//                       statsData.map((status: any) => (
//                         <Typography variant="h6" key={status.name}>
//                           <strong>
//                             {status.name} = {status.value}
//                           </strong>
//                         </Typography>
//                       ))
//                     ) : (
//                       <></>
//                     )}
//                   </Grid>
//                   {selectedCampaign && (
//                     <>
//                       <Typography variant="h6" sx={{ mt: 1 }}>
//                         <strong>Date:</strong>{" "}
//                         {formattedDate(selectedCampaign.sentDt)}
//                       </Typography>
//                       <Typography variant="h6" sx={{ mt: 0.5 }}>
//                         <strong>Subject:</strong> {selectedCampaign.subject}
//                       </Typography>
//                       <Typography variant="h6" sx={{ mt: 0.5 }}>
//                         <strong>Message:</strong>
//                       </Typography>
//                       <Typography
//                         sx={{
//                           display: "display: inline-block",
//                           flexWrap: "nowrap",
//                           wordBreak: "break-word",
//                         }}
//                         variant="body1"
//                         dangerouslySetInnerHTML={{
//                           __html: selectedCampaign.mailContent,
//                         }}
//                       />
//                     </>
//                   )}
//               </Box>
//             </Modal>
//           </TabPanel> */}
//           {/* <TabPanel value={value} index={2}>
//             <Card
//               sx={{
//                 //ml: { xs: 0, md: 5 },
//                 mx: 3,
//                 p: 2,
//                 borderRadius:"20px",
//                 boxShadow: 5,
//               }}
//             >
              
//               <Grid container>
//                 <Grid item xs={12} md={3}>
//                   <Grid container>
//                     <Grid item md={12} sx={{ pr: 3 }}>
//                       <Select
//                         fullWidth
//                         sx={{ width: "100%" }}
//                         size="small"
//                         labelId="demo-simple-select-label"
//                         id="demo-simple-select"
//                         value={msgPlatform}
//                         onChange={(e: any) => setMsgPlatform(e.target.value)}
//                         placeholder="Select Type"
//                       >
//                         <MenuItem value={"All"}>All</MenuItem>
//                         <MenuItem value={"Telegram"}>Telegram</MenuItem>
//                         <MenuItem value={"Discord"}>Discord</MenuItem>
//                       </Select>
//                     </Grid>
//                   </Grid>
//                 </Grid>
  
//                 <Grid
//                   item
//                   xs={12}
//                   display={{ xs: "block", md: "none" }}
//                   sx={{ mt: 2 }}
//                 ></Grid>
//                 <Grid item xs={12} md={9}>
//                   <Grid container>
//                     <Grid xs={12}>
//                       <EditorNew
//                       placeholder="Write the description"
//                         onChange={(data: string) => {
//                           setMsgData(data);
//                         }}
//                         editorLoaded={editorLoaded}
//                         value={msgData}
//                       />
//                       <span className="modalError" id='errorDiv-description'></span>
//                     </Grid>
//                     <Grid md={12}>
//                       <Button
//                         style={{
//                           marginTop: "1rem",
//                           backgroundColor: "#BAD1C2",
//                           fontSize: "1rem !important",
//                           paddingLeft: "3rem",
//                           paddingRight: "3rem",
//                           borderRadius: "20px",
//                           color: "black",
//                           float: "right",
//                         }}
//                         disabled
//                       >
//                         Send
//                       </Button>
//                     </Grid>
//                   </Grid>
//                 </Grid>
//               </Grid>
//             </Card>
//           </TabPanel> */}
//         </Grid>
//         {/* <Snackbar
//           open={successOpen ? successOpen : failureOpen}
//           autoHideDuration={6000}
//           onClose={handleSnackClose}
//           anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
//         >
//           <Alert
//             onClose={handleSnackClose}
//             severity="success"
//             sx={{ width: "100%" }}
//           >
//             {successMessage}
//           </Alert>
//         </Snackbar>
//         <Snackbar
//           open={failureOpen}
//           autoHideDuration={6000}
//           onClose={handleSnackClose}
//           anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
//         >
//           <Alert
//             onClose={handleSnackClose}
//             severity="error"
//             sx={{ width: "100%" }}
//           >
//             {failureMessage}
//           </Alert>
//         </Snackbar> */}
//       </div>
//     );
//   };
  
//   const UpdateMail = (props: any) => {
//     const [open, setOpen] = React.useState(false);
//     const handleOpen = () => setOpen(true);
//     const handleClose = () => setOpen(false);
//     //     const [alertMsg, setAlertMsg] = useState("Successfull Completed !!");
//     //   const [alertColor, setAlertColor] = useState("success" as AlertColor);
//     //   const [isAlertOpen, setIsAlertOpen] = useState(false);
//     //   const setAlert = (msg: string, isOpen: boolean, color: AlertColor) => {
//     //     setAlertMsg(msg)
//     //     setAlertColor(color);
//     //     setIsAlertOpen(isOpen);
  
//     //   }
//     return (
//       <>
//         <Stack direction="row">
//           <Button
//             className="bg-blue hover-red"
//             variant="outlined"
//             sx={{
//               borderRadius: "20px",
//               paddingLeft: "1rem",
//               paddingRight: "1rem",
//               marginRight: "1rem",
//             }}
//             onClick={() => handleOpen}
//           >
//             <IconButton sx={{ padding: "4px" }} aria-label="edit" size="medium">
//               <Typography color="white">Update Status</Typography>
//             </IconButton>
//           </Button>
  
//           {/* <Wuelev8Modal id='editJobForm' isVisible={open} titleSection={{ title: "Update Application Status", className: "" }} 
//               handleClose={handleClose} content={
//               <JobStatusUpdate {...props} />} size={false} /> */}
//         </Stack>
//       </>
//     );
//   };
  



import {
  Box,
  Grid,
  Tab,
  TextField,
  Typography,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Card,
  Divider,
  Stack,
  // Checkbox,
  // FormControlLabel,
  // Radio,
  IconButton,
  Paper,
  Modal,
  // Fab,
  // Autocomplete,
  // Snackbar,
  // Input
} from "@mui/material";
import Tabs, { tabsClasses } from "@mui/material/Tabs";
import React, { useEffect, useRef, useState, useContext } from "react";
import { serviceProvider } from "../../provider/serviceProvider";
import SwipeDownIcon from '@mui/icons-material/SwipeDown';
import SpinLoader from "../../components/loader/SpinLoader";
import S3 from "react-aws-s3-typescript";
import { IValidationResponse } from "../../interface/IValidation";
import { default as awsConf } from "../../configuration/config.json";
import Editor from "ckeditor5-custom-build/build/ckeditor";
import UserContext, { DrillIdContext } from "../../context/UserContext";
import statsCampaign from "../../configuration/config.json";
import {default as apiConfig} from "../../configuration/config.json"
// import {
//   bulkMail
// } from "../../configuration/config.json";
import EditorNew from "../../components/common/EditorNew";
import CloseIcon from "@mui/icons-material/Close";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
// import { AlertBar } from "../commonComponents/alert";
import inputFileIcon from "../../assets/icons/file-upload-icon.svg";
import { error } from "console";
// import { enqueueSnackbar } from "notistack";
import { ValidationUtiltiy } from "../../utility/ValidationUtility";
import { ValidationType } from '../../enum/validationType';
import { toast } from "react-toastify";
import { sessionUtility } from "../../utility/sessionUtility";
import AWS from 'aws-sdk';
import config from '../../configuration/config.json';
import ConfirmationModal from "../../components/common/ConfirmationModal";



const awsConfig = awsConf.awsConfig;
const uId = sessionUtility.getAuthentication().session?.uId as string;
console.log("uId",uId);

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

interface Stats {
  name: string;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ pt: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
interface MailsProps {
  pdfUrl: string;
}
function sendMail() {}
export const Mails: React.FC<any> = ({ pdfUrl }) => {
  const [value, setValue] = React.useState(0);
  const [editorLoaded, setEditorLoaded] = useState(false);
  const [data, setData] = useState("");
  const [allEmails, setEmails] = useState("");
  const [mailTemplateType, setMailTemplateType] = useState<any>([]);
  const [listOfReceiver, setListOfReceiver] = useState<any>([]);
  const [templateId, setTemplateId] = useState("NA");
  const [userTypes, setUserTypes] = useState<any>();
  const [mailTemplate, setMailTemplate] = useState("");
  const [userTypeEmailList, setUserTypeEmailList] = useState("");
  const [participantType, setParticipantType] = useState("");
  const [inviteGuest, setInviteGuest] = useState(true);
  const [mailApiResponse, setMailApiResponse] = useState("");
  const [emailList, setEmailList] = useState("");
  const [templateName, setTemplateName] = useState("");
  const [participantState, setParticipantState] = useState("Registered");
  const [loader, setLoader] = useState<boolean>(false);
  const [registered, setRegistered] = useState<boolean>(false);
  const [open, setOpen] = useState(false);
  const [msgPlatform, setMsgPlatform] = useState("All");
  const [msgData, setMsgData] = useState("");
  const [successOpen, setSuccessOpen] = React.useState(false);
  const [failureOpen, setFailureOpen] = React.useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [failureMessage, setFailureMessage] = useState("");
  const [selectedCampaign, setSelectedCampaign] = useState<Campaign | null>(
    null
  );
  const [statsData, setStatsData] = useState<any>([]);
  const [receiverType, setReceiverType] = useState("NA");
  const [role, setRole] = useState("");
  const [customers, setCustomers] = useState<any[]>([]);
  const [fileValue, setFileValue] = useState<any>(null)
  const [fileName, setFileName] = useState('No file chosen');
  const [useTemplate, setUseTemplate] = useState(true); // Default to true (Template)

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedicon = <CheckBoxIcon fontSize="small" />;
  const trackApplications = require("../../configuration/config.json").trackApplications;
  const userDetails = sessionUtility.getAuthentication();
  const [attachmentPaths, setAttachmentPaths] = useState<string[]>([]);
  





  const s3 = new AWS.S3({
    accessKeyId: config.awsConfig.accessKeyId,
    secretAccessKey: config.awsConfig.secretAccessKey,
    region: config.awsConfig.region,
  });
  const handleAttachFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      try {
        const fileUrl = await uploadFileToS3(file); // Function to upload the file to S3
        setAttachmentPaths([fileUrl]); // Set the file URL in the attachment paths
        setFileName(file.name); // Show the selected file name
        toast.success("File uploaded successfully!");
      } catch (error) {
        console.error("File upload failed:", error);
        toast.error("File upload failed!");
      }
    }
  };
  
  const uploadFileToS3 = async (file) => {
    const folderPath = config.awsConfig.resumeSubFolder; // Folder where the file will be uploaded
  
    // Construct the S3 upload parameters
    const params = {
      Bucket: config.awsConfig.bucketName, // The S3 bucket name
      Key: `${folderPath}/${file.name}`, // The path and filename
      Body: file,
      ContentType: file.type, // File MIME type
      ACL: 'public-read', // Make the file publicly accessible
    };
  
    try {
      // Upload the file to S3
      const data = await s3.upload(params).promise();
      console.log('File uploaded successfully at', data.Location);
      
      // Return the file URL from S3
      return data.Location;
    } catch (error) {
      console.error('Error uploading file to S3:', error);
      throw error;
    }
  };
  
  // const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  //   props,
  //   ref
  // ) {
  //   return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  // });

  const initObj = {
    bcc: "",
    to: "",
    cc: "",
    message: "",
    subject: "",
    templateName: "GeneralMail",
    userType: "",
    headerIdName: "string",
    headerIdValue: "string",
    heading: "string",
    link: "string",
    mobile: "string",
    otp: "string",
    unsubscribeLink: "string",
    userName: "Dear User",
  };

  interface Campaign {
    sentMailId: number;
    recipientListTo: string;
    recipientListCc: string;
    recipientListBcc: string;
    sentDt: string;
    mailCategoryId: number | null;
    eventId: number | null;
    campaignId: string;
    subject: string;
    mailContent: string;
    partnerId: number | null;
    createdby: number | null;
    createdts: string;
    updatedby: number | null;
    updatedts: string;
    uid: string;
  }
  const [submissionObj, setSubmissionObj] = useState(initObj);
  const [campaigns, setCampaigns] = useState([]);
  const { drillId } = useContext(DrillIdContext);
  const [customerId, setCustomerId] = useState("");
  const [Extractedmails, setExtractedmails] = useState("");

  const [extractedData, setExtractedData] = useState<any>()
  const [editorType, setEditorType] = useState<'template' | 'html'>('template'); // Track selected editor type
  const [openModal, setOpenModal] = useState(false);
  const [pendingAction, setPendingAction] = useState<(() => void) | null>(null);
  const [errors, setErrors] = useState({
    email: "",
    subject: "",
    message: ""
  });
  const [activeButton, setActiveButton] = useState('template'); // To track the active button


  const openConfirmation = (action: () => void) => {
    setPendingAction(() => action);  // Store the action to perform
    setOpenModal(true);              // Open the confirmation modal
  };
  

  const handleConfirm = () => {
    if (pendingAction) {
      pendingAction();
      setData("")   // Perform the stored action
    }
    setOpenModal(false);  // Close the modal
  };
  const handleConfirmClose = () => {
    setOpenModal(false);
  };



  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const setAllEmailIds = (event: any) => {
    var ids = [];
    var allids = "";
    ids = event.target.value.split(",");
    ids.map((e: string) => {
      allids = '"' + e + '",' + allids;
    });
    allids = allids.substring(0, allids.length - 1);
    setEmailList(event.target.value);
    var emailIds = JSON.parse("[" + allids + "]");
    submissionObj.to = emailIds.toString();
  };

  useEffect(() => {
    // Automatically set pdfUrl as the initial file name and attachment if available
    if (pdfUrl) {
      setFileName("quotation.pdf");
      setAttachmentPaths([pdfUrl]);
    }
  }, [pdfUrl]);
  // Commented

  const PostMail = async () => {
    setLoader(true);
  
    // Prepare the request payload
    const obj: any = { ...submissionObj };
    obj.message = data;

    // if (!obj.to) {
    //   toast.error("Recipient email (to) is required.");
    //   setLoader(false);
    //   return;
    // }
    // if (!obj.subject) {
    //   toast.error("Subject is required.");
    //   setLoader(false);
    //   return;
    // }
    // if (!obj.message) {
    //   toast.error("Message body is required.");
    //   setLoader(false);
    //   return;
    // }

    const newErrors = { email: "", subject: "", message: "" };
  
    if (!obj.to) {
      newErrors.email = "Email is required.";
    } else if (!validateEmails(obj.to)) {
      // Validate email format
      newErrors.email = "Email is invalid.";
    }
  if (!obj.subject) {
    newErrors.subject = "Subject is required.";
  }
  if (!obj.message) {
    newErrors.message = "Description is required.";
  }

  // Update the errors state
  setErrors(newErrors);

  // If there are any errors, stop the submission
  if (newErrors.email || newErrors.subject || newErrors.message) {
    setLoader(false);
    return;
  }

    // Ensure templateName is null if empty
    if (obj.templateName === "") {
      obj.templateName = null;
    }
  
    // Set default 'from' email if empty
    if (obj.from === "") {
      obj.from = "care@wuelev8.tech";
    }
  
    // Construct the payload as per the new requirement
    const payload = {
      emailContent: {
        bcc: obj.bcc || "",
        to: obj.to || "",
        cc: obj.cc || "",
        message: obj.message || "",
        subject: obj.subject || "",
        templateName: obj.templateName || "GeneralMail",
        userType: obj.userType || "",
        headerIdName: "",
        headerIdValue: "",
        heading: "",
        link: "",
        mobile: "",
        otp: "",
        unsubscribeLink: "",
        userName: obj.userName || "Dear User",
        
      },
      scheduledMailDateTime: "", // Example date and time
      scheduledMails: false,
      template: useTemplate, // Change this if scheduling mails
      platform: "BMJ", // Assuming this is a static value
      attachmentPaths: attachmentPaths // Modify as per your actual attachments
    };
  
    try {
      // Validate the request first
      await isValidRequest();
  
      // Make the API call using fetch
      const response = await fetch(`${apiConfig?.newMailsite}bmj/api/v1/emails/send-bulk?uId=${uId}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': userDetails?.token ,// If required
          'AUTH_UID': uId as string
          
        },
        mode: 'cors',
          cache: "no-cache",
        body: JSON.stringify(payload), // Use the updated payload
      });
      setEmailList("")
      setData("")
      setAttachmentPaths(["string"])
      setFileName("No File Chosen")
      submissionObj.subject = ""
      // Check if the response is successful (status code 2xx)
      if (response.ok) {
        const result = await response.json();
        console.log(result);
  
        if (result?.httpStatus === 200) {
          toast.success("Mail Sent Successfully!!");
        } else {
          toast.error("Error!! Mail not sent");
        }
      } else {
        toast.error("Error!! Mail not sent");
      }
    } catch (error) {
      // Log the error and show a toast notification
      console.error("Error while sending mail:", error);
      toast.error("Error!! Mail not sent");
    } finally {
      // Stop loader regardless of success or failure
      setLoader(false);
      
    }
   
  };
  

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const fetchData = async (custId:any) => {
    try {
      const response = await fetch(trackApplications+"&partnerId="+custId);
      const data = await response.json();
      setCampaigns(data.data.reverse());
    } catch (error) {
      console.error("Error fetching campaigns:", error);
    }
  };

  const fetchStats = async (campaign: Campaign) => {
    try {
      const response = await fetch(statsCampaign + campaign?.campaignId);
      const data = await response.json();
      setStatsData(data.currentStatus);
    } catch (error) {
      console.error("Error fetching campaigns:", error);
    }
  };

  const handleOpen = async (campaign: Campaign) => {
    // try {
    //   const response = await fetch(statsCampaign+campaign.campaignId)
    //   const data = await response.json();
    //   setCampaigns(data.data.reverse());
    // } catch (error) {
    //   console.error('Error fetching campaigns:', error);
    // }
    setSelectedCampaign(campaign);
    setOpen(true);
  };

  const handleModalOpen = ()=>{
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false);
  };

  const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius:"20px",
    p: 4,
  };
  

  const formattedDate = (date: string) => {
    return new Intl.DateTimeFormat("en-US", {
      day: "numeric",
      month: "long",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    }).format(new Date(date));
  };

  const convertHtmlToText = (html: string) => {
    const parser = new DOMParser();
    const parsedHtml = parser.parseFromString(html, "text/html");
    return parsedHtml.body.textContent || "";
  };

  const getRoles = () => {
    setLoader(true)
    const data = serviceProvider.authenticationService.getRole();
    if (data != undefined) {
      setLoader(false)
      // console.log(data[0]);
      setRole(data[0]);
    }
  };

  // const handleSnackClose = (
  //   event?: React.SyntheticEvent | Event,
  //   reason?: string
  // ) => {
  //   if (reason === "clickaway") {
  //     return;
  //   }
  //   setSuccessOpen(false);
  //   setFailureOpen(false);
  // };

const handleFileChange = (e) => {
    const file = e.target.files[0];
    setFileValue(file);
  };

const handleExtractMails = async () => {
  if (fileValue) {
    const formData = new FormData();
    formData.append('file', fileValue);
    setLoader(true); 

    await fetch(`${apiConfig?.newMailsite}bmj/api/v1/mails/readFromFile`, {
      method: 'POST',
      headers: {
        'Authorization': userDetails?.token, 
        'AUTH_UID': uId as string, 
      },
      body: formData, 
    })
      .then(response => response.json())
      .then(data => {
        if (data?.result?.EMAILS) {
          const extractedEmails = data.result.EMAILS;
          setExtractedmails(extractedEmails); 
          
          const mails = extractedEmails.join(',');
          setEmailList(mails); 
          
          setOpen(false);
          setLoader(false);
        } else {
          throw new Error("No emails found in response.");
        }
      })
      .catch(error => {
        setLoader(false);
        console.error('Error uploading file:', error);
      });
  }
};
const validateEmails = (emailString: string) => {
  // Regex pattern for validating a single email
  const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))(\s*,\s*(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,})))*$/;

  // Split the email string by commas and validate each email
  const emails = emailString.split(",");
  for (let email of emails) {
    email = email.trim(); // Remove extra spaces
    if (!emailRegex.test(email)) {
      return false; // Return false if any email is invalid
    }
  }
  return true; // All emails are valid
};



const validateText= (eligibility) => {
return ValidationUtiltiy.validateEditor(eligibility, ValidationType.Editor, true, "errorDiv-subject", "Subject");
}


const validateDescription = (data)=>{
return ValidationUtiltiy.validateEditor(data, ValidationType.Editor, true, "errorDiv-description", "Description");
}

function validateEmail() {
  return ValidationUtiltiy.validateElement(
    "email-allEmail",
    ValidationType.Mail,
    true,"errorDiv-email", "Email");
}

const isValidRequest = (): Promise<boolean> => {
let responseArray: IValidationResponse[] = [];
responseArray.push(validateDescription(data)); 
responseArray.push(validateText(submissionObj.subject));
responseArray.push(validateEmail());

let haveErrors = responseArray.filter((x) => {
    return !x.isValid;
}).length <= 0;
return new Promise((resolve, reject) => {
    if (haveErrors) {
        resolve(true);
    } else {
        reject(false);
    }
});
}



  useEffect(() => {
    setEditorLoaded(true);
    getRoles();
// Commented
    // serviceProvider.getDrill.getDrillInfo(drillId).then((res)=>{
    //   setCustomerId(res[0].drillPartnerId);
    //   //fetchData(res[0].drillPartnerId);
    // }).catch((e)=>{
    // })
  }, []);

  useEffect(() => {
    if (selectedCampaign != null) {
      fetchStats(selectedCampaign);
    }
  }, [selectedCampaign]);

  const handleUseTemplate = () => {
    setUseTemplate(true);
    setEditorType('template');  // Set 'template' to true
  };
  
  const handleUseHtml = () => {
    setUseTemplate(false);
    setEditorType('html'); // Set 'template' to false
  };

  const handleTemplateClick = () => {
    if (editorType === 'html') {     // Only open modal if editorType is 'html'
      openConfirmation(() => {
        setUseTemplate(true);
        setEditorType('template');
        setActiveButton('template');
      });
    }
  };

  const handleHtmlClick = () => {
    if (editorType === 'template') { // Only open modal if editorType is 'template'
      openConfirmation(() => {
        setUseTemplate(false);
        setEditorType('html');
        setActiveButton('html');
      });
    }
  };


  if(loader){
  return <SpinLoader />
  }
  return (
    <div>
      <Grid>
          <Tabs value={value} onChange={handleTabChange} aria-label="Mail Tabs">
            <Tab
              label="Mail Campaign"
              {...a11yProps(0)}
              
              sx={{pl:1}}
            />
            {/* <Tab
              label="Track Mail Campaign"
              {...a11yProps(1)}
              
              sx={{pl:1}}
            /> */}
            {/* <Tab
              label="Send Message"
              {...a11yProps(2)}
              className="color-logo-blue"
            /> */}
          </Tabs>
         
        <TabPanel value={value} index={0}>
          <Card 
            sx={{
              borderRadius:"20px",
              p: 2,
              boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)"
            }}
          >
            <Grid container>
              
              <Grid
                item
                xs={12}
                display={{ xs: "block", md: "none" }}
                sx={{ mt: 2 }}
              ></Grid>

              <Grid item xs={12} md={12}>
                <Grid container>
                  <Grid item xs={12} md={10}>
                  <InputLabel shrink sx={{fontWeight:600,color:"#4A4A4A"}}>*You can insert maximum of 5000 emails in one campaign</InputLabel>
                    {/* <InputLabel className="labelColor mb-0-25" sx={{fontSize:"small"}}> */}
                    <TextField
                      fullWidth
                      size="small"
                      rows={4}
                      variant="standard"
                      className='search-input'
                      InputProps={{
                        disableUnderline: true,
                        sx:{m:"10px"}
                      }}
                      multiline={true}
                      onChange={setAllEmailIds}
                      value={emailList}
                      disabled={receiverType != "NA"}
                      placeholder="Put all emails with comma inbetween like firstuser@wuelev8.tech, 
                                    seconduser@wuelev8.tech"
                      id="email-allEmail"
                    />
                    {errors.email && (
    <span className="modalError" id='errorDiv-email'>{errors.email}</span>
  )}
                  </Grid>

<Grid item xs={12} md={2} textAlign={{xs:"end", md:"center"}} sx={{margin:'auto',}} >


<Modal      open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={style}
width={{xs:"90%", md:400}}
          >
            <InputLabel className="labelColor" sx={{ color: "#1b1d1c" }}>
              <div
                style={{
                  float: "right",
                }}
              >
                <CloseIcon
                  sx={{ cursor: "pointer" }}
                  onClick={handleClose}
                />
              </div>
              <span>
                <Divider>Upload Excel File</Divider>
              </span>
            </InputLabel>

            <Grid
              container
              sx={{ my: 4, display: "flex", justifyContent: "center", gap:"15px" }}
            >
<Grid item xs={12} md={10} >
<input id="upload-excel-file"
                             style={{ marginTop: "25px", marginBottom: "25px", display:"none" }}
                              name="upload-excel-file"
                             type="file"
                             onChange={handleFileChange}
                         />
<Grid container textAlign={"center"} className="inputFile">
  <label style={{cursor:"pointer"}} htmlFor="upload-excel-file">
  <img src={inputFileIcon} alt="File Icon" height="40px" width="40px" />
  <Typography component={"p"}>
  <span className="inputFileText">Choose file</span>
  <br/> <span>{fileValue ? fileValue.name : 'No file chosen'}</span>
  </Typography>
  </label>
</Grid >
</Grid>
</Grid>
<Grid             item
                xs={12}
                md={6}
                justifyContent="center"
                alignItems="center"
              >
                <Grid container>
                  <Grid
                    item
                    xs={12}
                    display={{ xs: "block", md: "none" }}
                  ></Grid>

          <Grid item xs={12} sx={{ textAlign: "start" }}>
                    <ul>
                      <li>
                        <InputLabel className="labelColor" sx={{ fontSize: "small" }}>
                          Only accepts .xlsx or .csv file
                        </InputLabel>
                      </li>
                    </ul>
                  </Grid>
</Grid>
</Grid>

<Grid item xs={12} textAlign={"center"} className="mt-1">
                <Button
                  variant="outlined"
            className="uploadButton"
                  onClick={handleExtractMails}
                >
Click to extract emails
                </Button>
</Grid>
          </Box>
        </Modal>

<Button variant="contained" style={{marginTop:10,   borderRadius: "20px",color:"#CA3657", backgroundColor:"white", border:"2px dotted #CA3657",boxShadow:"none"}} onClick={handleModalOpen} ><SwipeDownIcon sx={{marginRight:1}}/>  Extract emails </Button>  
</Grid>
                  <Grid item xs={12} sx={{ mt: 2 }}>
                  <InputLabel shrink sx={{fontWeight:600,color:"#4A4A4A"}}>Subject of the email</InputLabel>
                    <TextField
                      fullWidth
                      size="small"
                      variant="standard"
                      className='search-input'
                      InputProps={{
                        disableUnderline: true,
                        // sx:{m:"10px"}
                      }}
                      value={submissionObj.subject}
                      onChange={(e) => {
                        var obj = { ...submissionObj };
                        obj.subject = e.target.value;
                        setSubmissionObj(obj);
                      }}
                      placeholder="Subject of the email"
                    />
{errors.subject && (
    <span className="modalError" id='errorDiv-subject'>{errors.subject}</span>
  )}                  </Grid>

                  <Grid xs={12} sx={{ mt: 2 }}>
                    {/* <InputLabel className="labelColor mb-0-25">Description</InputLabel> */}
                    <div style={{display:'flex',flexDirection:'row'}}>
                    <InputLabel shrink sx={{fontWeight:600,color:"#4A4A4A" ,marginTop:'15px'}}>Description</InputLabel>
                    <Button
                     variant="contained"
                     className="rounded-btn-outlined"
                      // Commented
                      // color="#CA3657 !important"
                      sx={{
                        marginRight:'30px',
                        marginBottom:'20px',
                        backgroundColor: activeButton === 'template' ? '#CA3657 !important' : '#FFF !important',
                        color: activeButton === 'template' ? '#FFF !important' : '#CA3657 !important',

                      }}
                      onClick={handleTemplateClick}
                      >
                      Template
                    </Button>
                    <Button
                     variant="contained"
                     className="rounded-btn-outlined"
                     sx={{
                      marginBottom:'20px',
                      backgroundColor: activeButton === 'html' ? '#CA3657 !important' : '#FFF !important',
                        color: activeButton === 'html' ? '#FFF !important' : '#CA3657 !important',
                    }}
                     onClick={handleHtmlClick} 
                     >
                      HTML
                    </Button>
                    </div>
                    {/* <EditorNew
                          placeholder="Write the description"
                      onChange={(data: string) => {
                        setData(data);
                      }}
                      editorLoaded={editorLoaded}
                      value={data}
                    /> */}
                    {editorType === 'template' ? (
          <EditorNew
            placeholder="Write the description"
            onChange={(data: string) => {
              setData(data); // Set editor data
            }}
            editorLoaded={editorLoaded} // Assuming editorLoaded is defined somewhere
            value={data}
          />
        ) : (
          <TextField
            fullWidth
            multiline
            rows={6}
            variant="outlined"
            placeholder="Enter HTML here"
            onChange={(e) => {
              setData(e.target.value); // Set HTML data
            }}
            value={data}
          />
        )}
{errors.message && (
    <span className="modalError" id='errorDiv-description'>{errors.message}</span>
  )}                  </Grid>
                  <Grid md={12} textAlign={{ xs: 'center',sm: 'right', md: 'end' }}  my={2}>
                  <Box sx={{ border: '2px dashed #4A4A4A', 
                    p: 2, 
                    borderRadius: '8px', 
                    width: { xs: '100%', sm: 'auto' },
                    maxWidth: '389px',
                    marginBottom:'30px'
                    }}>
      <Grid container alignItems="center" spacing={2}>
      <Grid item>
          <img
            src={inputFileIcon}
            alt="Upload Icon"
            style={{ width: '40px', height: '40px' }} // Adjust size as needed
          />
        </Grid>
        <Grid item>
          <label htmlFor="file-upload">
            <input
              id="file-upload"
              type="file"
              style={{ display: 'none' }}
              onChange={handleAttachFileChange}
              disabled={!!pdfUrl}
            />
          {!pdfUrl && ( <Button variant="outlined" component="span">
              Choose file
            </Button>)} 
          </label>
        </Grid>
        <Grid item>{fileName}</Grid>
      </Grid>
    </Box>
                    <Button
                     variant="contained"
                     className="rounded-btn-fill"
                      // Commented
                      onClick={PostMail}
                    >
                      Send
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Card>
        </TabPanel>
 
      </Grid>
      <ConfirmationModal open={openModal} onClose={handleConfirmClose} onConfirm={handleConfirm} >
        If you switch to another tab, you will lose the content of this mail.
        Are you sure you want to proceed?
      </ConfirmationModal>
      
    </div>
  );
};

