import { HttpResponse } from "../../enum/httpResponse";
// import { sessionStorageKey } from "../../configuration/config.json";
import config from "../../configuration/config.json";
import { IAuthenticationService } from "../../interface/IAuthenticationService";
import { IAuthentication } from "../../interface/IAuthentication";
import { ILoginRequest, ILoginResponse, IAuthority } from "../../interface/ILogin";
import CryptoUtility from "../../utility/cryptoUtility";
import { LocalStorageUtility } from "../../utility/localStorageUtility";
import { HttpService } from "../commonService/httpService";
import { sessionUtility } from "../../utility/sessionUtility";
export class AuthenticationService extends HttpService implements IAuthenticationService {
    private authentication: IAuthentication = {
        authenticated: false,
        session: null,
        token: "",
    };
    private header: Headers | string[][] | Record<string, string>;
    public sessionStorageKey = config.sessionStorageKey.sessionKey;
    constructor() {
        super();
        this.header = this.initHeader();
    }
    getPermissions(): string[] {
        const permissions = this.getAuthentication().session?.role;
        return permissions !== undefined ? permissions : [];
    }
    
    login(req: ILoginRequest) {
        return new Promise<IAuthentication>((resolve, reject) => {
            this.postQuery("users/login", this.header, req).then(res => {
                const response = res as ILoginResponse;
                if (response.responseCode === HttpResponse.Ok) {
                    this.setSuccessAuthentication(response, req.mobileNumber);
                    resolve(this.authentication);
                } else {
                    this.setFailAuthentication(res.error);
                    reject(this.authentication);
                }
            }).catch((err) => {
                console.log(err)
                this.setFailAuthentication(err);
                reject(this.authentication);
            });
        })
    }

     googlelogin(req: any) {
        return new Promise<IAuthentication>((resolve, reject) => {
            this.postQuery("users/login/google", this.header, req).then(res => {
                const response = res as ILoginResponse;
                if (response.responseCode === HttpResponse.Ok) {
                    this.setSuccessAuthentication(response, response.userDetails.mobileNumber);
                    resolve(this.authentication);
                } else {
                    this.setFailAuthentication(res.error);
                    reject(this.authentication);
                }
            }).catch(err => {
                this.setFailAuthentication(err);
                reject(this.authentication);
            });
        })
    }
    // login(req: ILoginRequest) {
    //     return new Promise<IAuthentication>((resolve, reject) => {
    //         this.postQuery("users/login", this.header, req).then(res => {
    //             const response = res as ILoginResponse;
    //             if (response.responseCode === HttpResponse.Ok) {
    //                 this.setSuccessAuthentication(response, req.username);
    //                 resolve(this.authentication);
    //             } else {
    //                 this.setFailAuthentication(res.error);
    //                 reject(this.authentication);
    //             }
    //         }).catch(err => {
    //             this.setFailAuthentication(err);
    //             reject(this.authentication);
    //         });
    //     })
    // }
    updatePwd(req: any, isUpdate: boolean) {
        if (!isUpdate) {
            return new Promise<IAuthentication>((resolve, reject) => {
                this.postQuery("users/updatepassword", this.header, req).then(res => {
                    const response = res as any;
                    resolve(response);
                }).catch(err => {
                    reject(err);
                })
            });
        } else {
            let userDetails = sessionUtility.getAuthentication();
            return new Promise<IAuthentication>((resolve, reject) => {
                this.putQuery(`users/${userDetails?.session?.uId}/updatepassword`, this.header, req).then(res => {
                    const response = res as any;
                    resolve(response);
                }).catch(err => {
                    reject(err);
                })
            });
        }
    }
    forgotPwd(req: any) {
        return new Promise<IAuthentication>((resolve, reject) => {
            this.postQuery("users/forgotpassword?emailid=" + req.username, this.header, {}).then(res => {
                const response = res as any;
                resolve(response);
            }).catch(err => {
                reject(err);
            })
        });
    }
    resendVerification(req: any): Promise<any> {
        return new Promise<IAuthentication>((resolve, reject) => {
            this.postQuery("users/resendverification?emailid=" + req.username, this.header, {}).then(res => {
                const response = res as any;
                resolve(response);
            }).catch(err => {
                reject(err);
            })
        });
    }
    verify(req: any) {
        return new Promise<IAuthentication>((resolve, reject) => {
            this.postQuery("users/verify/" + req.encodedstring, this.header, {}).then(res => {
                const response = res as ILoginResponse;
                if (response.responseCode === HttpResponse.Ok) {
                    this.setSuccessAuthentication(response, response.userDetails.mobileNumber);
                    resolve(this.authentication);
                } else {
                    this.setFailAuthentication(res.error);
                    reject(this.authentication);
                }
            }).catch(err => {
                this.setFailAuthentication(err);
                reject(this.authentication);
            });
        })
    }
    pageView(req: any): Promise<any> {
        return new Promise<IAuthentication>((resolve, reject) => {
            this.postQuery("common/pages", this.header, req).then(res => {
                const response = res as any;
                resolve(response);
            }).catch(err => {
                reject(err);
            })
        });
    }
    private setSuccessAuthentication(response: ILoginResponse, mobileNumber: string) {
        const authentication: IAuthentication = {
            session: {
                role: this.getRoleFromResponse(response.authorities),
                uId: response.uid,
                fullName: response.userDetails.fullName,
                mobileNumber: mobileNumber
            },
            authenticated: true,
            token: response.token
        };
        this.authentication = authentication;
        LocalStorageUtility.setStorageObj(this.sessionStorageKey, this.authentication);
    }
    private setFailAuthentication(err: any) {
        const authentication: IAuthentication = {
            session: null,
            authenticated: false,
            token: "",
            error: err
        }
        this.authentication = authentication;
        LocalStorageUtility.removeStorage(this.sessionStorageKey);
    }

    private getRoleFromResponse(authorities: string[]): string[] {
        let userRole: string[] = [];
        authorities?.forEach(item => {
            const role = item;
            userRole.push(role)
        });
        return userRole;
    }
    private getAuthentication() {
        return LocalStorageUtility.getStorageObj(config.sessionStorageKey.sessionKey) as IAuthentication;
    }
    logOut() {
        return new Promise((resolve, reject) => {
            this.initAuthentication();
            localStorage.removeItem(config.sessionStorageKey.sessionKey);
            resolve(true);
        });
    }
    initAuthentication() {
        this.authentication.authenticated = false;
        this.authentication.session = null;
        this.authentication.token = "";
    }
    isAuthenticated() {
        return this.authentication.authenticated;
    }
    getToken() {
        return this.authentication.token;
    }
    getRole() {
        return this.getAuthentication().session?.role;
    }
    initHeader() {
        return { "Content-Type": "application/json; charset=UTF-8" }
    }
}

export default AuthenticationService;