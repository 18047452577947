import DataGridTable from "../DataGridTable/DataGridTable";
import { getFormattedDate, getFormattedTime } from "../../utility/formateDateAndTime";
import {  Tooltip } from "@mui/material";
import { IFeed } from "../../interface/IFeed";
import EditFeed from "./EditFeed";


const FeedTable = ({ pageState, setPageState,getAllFeeds }: any) => {



  const columns = [
    {
      field: "feedTitle",
      headerName: "Title",
      width: 400,
      editable: false,
      renderCell: (params: any) => {
        return (
          <Tooltip title={params.row?.feedTitle}>
            <span>{params.row?.feedTitle}</span>
          </Tooltip>
        );
      },
    },
    {
      field: "datetimeToDeactivate",
      headerName: "DateTime to deactivate",
      width: 400,
      editable: false,
    },
    {
      field: "redirect_link",
      headerName: "Redirect Link",
      width: 400,
      editable: false,
    },
    {
      field: "active",
      headerName: "Status",
      width: 400,
      editable: false,
    },{
      field: "edit",
      type: "actions",
      headerName: "Edit",
      width: 200,
      getActions: (params: any) => [
         <EditFeed feedDetails={params.row} getAllFeeds={getAllFeeds} />
      ],
    },
   
  ];
  const rows = pageState?.data?.map((feed: IFeed) => ({
    ...feed,
    id: feed?.feedId, // You can use airport.airportId here if it's unique
    datetimeToDeactivate: `${getFormattedDate(feed?.datetimeToDeactivate)} ${getFormattedTime(feed?.datetimeToDeactivate)}`,
    // isActive: feed?.isActive ? "Active" : "Inactive"
  }));


 
  return (
    <DataGridTable
      pageState={pageState}
      setPageState={setPageState}
      rows={rows}
      columns={columns}
    />
  );
};

export default FeedTable;
