import { IMockStorage } from "../interface/IMockStorage";

class FleetStorage implements IMockStorage {
    data: any[] = [];
    constructor() {
        this.data = dataArray;
    }
    insert(item: any): void {
        throw new Error("Method not implemented.");
    }
    update(item: any): void {
        throw new Error("Method not implemented.");
    }
    delete(srno: number): void {

        this.data.filter(x => x.srno !== srno);
    }
    get() {
        return this.data;
    }
    public getByScenario(scenarioId: string) {
        return this.data.filter(x => x.applicationId === scenarioId)
    }
}

const dataArray: any[] = [
   {
    fleetId: "91a9eb2c-ebe5-48f9-adb4-78a149b8df9f",
    name: "fleet1",
    imageUrl: "http://127.0.0.1:5173/src/assets/images/turboprops.svg",
    recordCreatedBy: "",
    recordCreatedTs: "2023-08-03T10:19:42.195",
    recordUpdatedBy: "",
    recordUpdatedTs: "2023-08-03T10:19:42.195"
  },
   {
    fleetId: "91a9eb2c-be23-48f9-adb4-78a149b8df9f",
    name: "fleet2",
    imageUrl: "https://www.book-myjet.com/img/turboprops.jpg",
    recordCreatedBy: "",
    recordCreatedTs: "2023-08-03T10:19:42.195",
    recordUpdatedBy: "",
    recordUpdatedTs: "2023-08-03T10:19:42.195"
  },
   {
    fleetId: "91a9eb2c-jh43-48f9-adb4-78a149b8df9f",
    name: "fleet3",
    imageUrl: "https://www.book-myjet.com/img/helicopter.jpg",
    recordCreatedBy: "",
    recordCreatedTs: "2023-08-03T10:19:42.195",
    recordUpdatedBy: "",
    recordUpdatedTs: "2023-08-03T10:19:42.195"
  }
];

export default FleetStorage;