// // import React from 'react';
// // import { Autocomplete, TextField } from '@mui/material';
// // import { styled } from '@mui/system';
// // import { IAirport } from '../../interface/IAirport';

// // const StyledAutocomplete = styled(Autocomplete)`
// //   .MuiInputBase-root {
// //     border: none;
// //     background-color: transparent;
// //     outline: none;
// //     padding-left:20px;
// //     padding-right:20px;
// //   }

// //   .MuiAutocomplete-inputRoot {
// //     &:before {
// //       border-bottom: none;
// //     }
// //   }
// //   .MuiAutocomplete-endAdornment {
// //     display: none; /* Hide the arrow icon */
// //   }
// //   .css-x88uz9-MuiInputBase-root-MuiInput-root:after{
// //     border-bottom:none;
// //   }

// //   &:hover .MuiAutocomplete-inputRoot:before,
// //   &.Mui-focused .MuiAutocomplete-inputRoot:before,
// //   &.Mui-focused .MuiAutocomplete-inputRoot:hover:before,
// //   &.Mui-focused .MuiAutocomplete-inputRoot.Mui-focused:before {
// //     border-bottom: none;
// //   }

// // `;
// // // (option: any) => `${option.name} (${option.icaoCode})`
// // const AutoCompleteComponent: React.FC<any> = ({ options, onChange, getOptionLabel, placeholder }) => {
// //   return <StyledAutocomplete
// //     id="outlined-basic"
// //     options={options}
// //     fullWidth
// //     // getOptionLabel={(option:any) => option.name}
// //     getOptionLabel={getOptionLabel}
// //     onChange={onChange}
// //     // value={value}
// //     renderInput={(params) => (
// //       <TextField
// //         {...params}
// //         placeholder={placeholder}

// //         // className='search-input'
// //         InputProps={{ ...params.InputProps, disableUnderline: true }}
// //         id="outlined-basic" size='small' fullWidth variant="standard"
// //       />
// //     )}
// //   />
// // }

// // export default AutoCompleteComponent

// import React from 'react';
// import { Autocomplete, InputAdornment, TextField } from '@mui/material';
// import { styled } from '@mui/system';
// // import { AutoCompleteComponentProps } from '../../interface/IComponentProps';
// import { IAirport } from '../../interface/IAirport';

// const StyledAutocomplete = styled(Autocomplete)`
//   .MuiInputBase-root {
//     border: none;
//     background-color: transparent;
//     outline: none;
//     padding-left:0;
//     padding-right:0;
//   }

//   .MuiAutocomplete-inputRoot {
//     &:before {
//       border-bottom: none;
//     }
//   }
//   .MuiAutocomplete-endAdornment {
//     display: none; /* Hide the arrow icon */
//   }
//   .css-x88uz9-MuiInputBase-root-MuiInput-root:after{
//     border-bottom:none;
//   }

//   &:hover .MuiAutocomplete-inputRoot:before,
//   &.Mui-focused .MuiAutocomplete-inputRoot:before,
//   &.Mui-focused .MuiAutocomplete-inputRoot:hover:before,
//   &.Mui-focused .MuiAutocomplete-inputRoot.Mui-focused:before {
//     border-bottom: none;
//   }

// `;
// // (option: any) => `${option.name} (${option.icaoCode})`
// const AutoCompleteComponent = ({multiline=false, minRows=1 ,multiple=false, id="",options,className,defaultValue,readOnly=false,value="", onChange, getOptionLabel,renderOption, placeholder="" }:any) => {
//   return <StyledAutocomplete
//     id={id}
//     multiple={multiple}

//     options={options}
//     fullWidth
//     readOnly={readOnly}
//     className={className}
//     getOptionLabel={getOptionLabel}
//     onChange={onChange}
//     renderOption={renderOption}
//     defaultValue={defaultValue}

//     // value={value}
//     renderInput={(params) => (
//       <TextField
//         {...params}
//         placeholder={placeholder}
//         InputProps={{
//           ...params.InputProps,
//           multiline:multiline,
//           minRows: minRows,
//           disableUnderline:true
//         }}
//         id="outlined-basic" size='small' fullWidth variant="standard"
//       />
//     )}
//   />
// }

// export default AutoCompleteComponent;

import React from "react";
import { Autocomplete, InputAdornment, TextField } from "@mui/material";
import { styled } from "@mui/system";
// import { AutoCompleteComponentProps } from '../../interface/IComponentProps';
import { IAirport } from "../../interface/IAirport";

const StyledAutocomplete = styled(Autocomplete)`
  .MuiInputBase-root {
    border: none;
    background-color: transparent;
    outline: none;
  }

  .MuiAutocomplete-inputRoot {
    &:before {
      border-bottom: none;
    }
  }
  .MuiAutocomplete-endAdornment {
    display: none; /* Hide the arrow icon */
  }
  .css-x88uz9-MuiInputBase-root-MuiInput-root:after {
    border-bottom: none;
  }

  &:hover .MuiAutocomplete-inputRoot:before,
  &.Mui-focused .MuiAutocomplete-inputRoot:before,
  &.Mui-focused .MuiAutocomplete-inputRoot:hover:before,
  &.Mui-focused .MuiAutocomplete-inputRoot.Mui-focused:before {
    border-bottom: none;
  }
`;

const AutoCompleteComponent = ({
  multiline = false,
  minRows = 1,
  multiple = false,
  id = "",
  options,
  className,
  defaultValue,
  readOnly = false,
  value = "",
  position = "start",
  onChange,
  getOptionLabel,
  renderOption,
  placeholder,
  children,
}: any) => {
  return (
    <StyledAutocomplete
      id={id}
      multiple={multiple}
      options={options}
      fullWidth
      // value={value}
      // defaultValue={defaultValue}
      // defaultValue={d}
      readOnly={readOnly}
      className={className}
      getOptionLabel={getOptionLabel}
      onChange={onChange}
      renderOption={renderOption}
      defaultValue={defaultValue}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder={placeholder}
          InputProps={{
            ...params.InputProps,
            [position === "end" ? "endAdornment" : "startAdornment"]: (
              <InputAdornment position={position}>{children}</InputAdornment>
            ),
            disableUnderline: true,
            multiline: multiline,
            minRows: minRows,
          }}
          id="outlined-basic"
          size="small"
          fullWidth
          variant="standard"
        />
      )}
    />
  );
};

export default AutoCompleteComponent;
