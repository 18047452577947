// import React, { useEffect, useRef, useState } from "react";
// import {
//   Grid,
//   Typography,
//   Card,
//   Box,
//   Button,
//   Tooltip,
//   IconButton,
// } from "@mui/material";
// import editIcon from "../../assets/icons/edit-icon.svg";
// import { toast } from "react-toastify";
// import { serviceProvider } from "../../provider/serviceProvider";
// import dayjs, { Dayjs } from "dayjs";
// import DateTimePickerComponent from "../common/DateTimePickerComponent";
// import InputFieldComponent from "../common/InputFieldComponent";
// import SelectFieldComponent from "../common/SelectFieldComponent";
// import ImageUploadComponent from "../common/ImageUploadComponent";
// import ButtonComponent from "../common/ButtonComponent";
// import S3 from "react-aws-s3-typescript";
// import { awsConfig } from "../../configuration/config.json";
// import { ValidationUtiltiy } from "../../utility/ValidationUtility";
// import { useParams, useNavigate } from "react-router-dom";
// import backArrow from "../../assets/icons/back-arrow.svg";
// import AutoCompleteComponent from "../common/AutoCompleteComponent";
// import { IAircraft } from "../../interface/IAircraft";
// import { IAirport } from "../../interface/IAirport";
// import Loader from "../loader/Loader";
// import SpinLoader from "../loader/SpinLoader";

// const initialFlightInput = {
//   active: true,
//   aircraftId: "",
//   description: "",
//   exclusiveCategoryId: "",
//   endAirportId: "",
//   endDt: "",
//   endMonth: "",
//   flightId: "",
//   flightType: "EMPTYLEG",
//   headline: "",
//   imageUrlList: "",
//   offerList: "",
//   oldPrice: 0,
//   passengerCapacity: 0,
//   price: 0,
//   startAirportId: "",
//   startDt: "",
//   startMonth: "",
// };

// const EditEmpty = () => {
//   const [userInput, setUserInput] = useState<any>(initialFlightInput);
//   const [images, setImages] = useState([] as any);
//   const [imageURLS, setImageURLs] = useState<any>([]);
//   const [loader, setLoader] = useState(false);
//   const [startDateTime, setStartDateTime] = useState<any>(null);
//   const [arrivalDateTime, setArrivalDateTime] = useState<any>();
//   const chooseFileRef: React.MutableRefObject<HTMLInputElement | null> =
//     useRef(null);
//   const [airports, setAirports] = useState([]);
//   const [aircrafts, setAircrafts] = useState([]);
//   const [files, setFiles] = useState([]);
//   const [emptyLegDetails, setEmptyLegDetails] = useState<any>({});

//   const { flightId } = useParams();
//   const navigate = useNavigate();

//   const handleStartDateTime = (newDate: Dayjs | null) => {
//     setStartDateTime(newDate);
//   };
//   const handleArrivalDateTime = (newDate: Dayjs | null) => {
//     setArrivalDateTime(newDate);
//   };

//   // const emptyInputFields = () => {
//   //     setUserInput(initialFlightInput);
//   //     setImageURLs([]);
//   //     setImages([])
//   // }

//   console.log({userInput});
//   const handleInputChange = (property: any, newValue: any) => {
//     console.log({property,newValue});
//     // alert(newValue)

//     // const { name, value } = event.target;
//     setUserInput({
//       ...userInput,
//       [property]: newValue,
//       imageUrlList: JSON.stringify(imageURLS),
//     });

//     // console.log('Name:', name);
//     // console.log('Value:', value);
//   };

//   // const handleChooseFile = () => {
//   //   chooseFileRef.current?.click();
//   // };

//   // const handleFileChange = (event: any) => {
//   //   const fileObjs: [] = event.target.files;

//   //   if (!fileObjs) {
//   //     return;
//   //   }

//   //   const validFiles = Array.from(fileObjs).filter((fileObj) =>
//   //     ValidationUtiltiy.isValidImg(fileObj["name"])
//   //   );

//   //   if (validFiles.length === 0) {
//   //     toast.error("No valid files selected.");
//   //     return;
//   //   }
//   //   console.log({ validFiles });
//   //   setFiles(validFiles);

//   //   validFiles.forEach((file: any, index) => {
//   //     const newFileName = "123" + "_" + file.name.replace(/\..+$/, "");
//   //     uploadImage(file, newFileName, index);
//   //   });
//   // };

//   // const uploadImage = (file: any, newFile: any, index: any) => {
//   //   const ReactS3Client = new S3(awsConfig);
//   //   ReactS3Client.uploadFile(
//   //     file,
//   //     awsConfig.offeredFlightsSubFolder + "/" + newFile
//   //   )
//   //     .then((data) => {
//   //       if (data.status === 204) {
//   //         console.log(files);
//   //         const img = data.location;
//   //         setImageURLs((prevImageURLs: any) => [
//   //           ...prevImageURLs,
//   //           data.location,
//   //         ]);
//   //         console.log(data.location);

//   //         // alert("heloo")

//   //         // console.log(imageURLS,"updatedImageURLs",updatedImageURLs,index)
//   //         if (index === files.length - 1) {
//   //           updateUserInput(JSON.stringify(imageURLS));
//   //           toast.success("Flights Pictures Uploaded");
//   //         }
//   //       } else {
//   //         console.log("File Not Uploaded !!");
//   //         toast.error("Error Occurred");
//   //       }
//   //     })
//   //     .catch((err) => {
//   //       console.log({ err });
//   //       toast.error("Something Went Wrong.");
//   //     });
//   // };
//   // console.log(imageURLS);

//   // console.log({emptyLegDetails});

//   // const chooseFileRef: React.MutableRefObject<HTMLInputElement | null> = useRef(null);

//     const handleChooseFile = () => {
//         // 👇️ open file input box on click of another element
//         chooseFileRef.current?.click();
//     };
//     console.log({ imageURLS });

//     // new added
//     const handleFileChange = (event: any) => {
//         const fileObjs: [] = event.target.files;

//         if (!fileObjs) {
//             return;
//         }

//         const validFiles = Array.from(fileObjs).filter(fileObj => ValidationUtiltiy.isValidImg(fileObj["name"]));

//         if (validFiles.length === 0) {
//             toast.error("No valid files selected.");
//             return;
//         }

//         const uploadPromises = validFiles.map((file: any, index) => {
//             const newFileName = "123" + "_" + file.name.replace(/\..+$/, "");
//             return uploadImage(file, newFileName);
//         });

//         Promise.all(uploadPromises)
//             .then((uploadedURLs) => {
//                 setImageURLs((prevImageURLs: any) => [...prevImageURLs, ...uploadedURLs]);
//                 updateUserInput(JSON.stringify(uploadedURLs));
//                 toast.success("Image Uploaded");
//             })
//             .catch((error) => {
//                 console.error(error);
//                 toast.error("Error Occurred");
//             });
//     };

//     // new added

//     const uploadImage = (file: any, newFile: any) => {

//         return new Promise((resolve, reject) => {
//             const ReactS3Client = new S3(awsConfig);
//             ReactS3Client.uploadFile(
//                 file,
//                 awsConfig.emptyLegFlightsSubFolder + "/" + newFile
//             ).then((data) => {
//                 if (data.status === 204) {
//                     resolve(data.location);
//                 } else {
//                     reject("File Not Uploaded");
//                 }
//             }).catch((err: any) => {
//                 reject("Something Went Wrong.");
//             });
//         });
//     };
//     //   console.log({ files })

//   const updateUserInput = (imageUrl: string) => {
//     alert("udpate")
//     if (imageUrl !== "") setUserInput({ ...userInput, imageUrlList: imageUrl });

//   };

//   const getEmptyLeg = (flightId: any) => {
//     const param = `flightId=${flightId}&isActive=true`;
//     setLoader(true);
//     // setPageState(prev=>({...prev,loader:true}))
//     serviceProvider.flightService
//       .get(param)
//       .then((res: any) => {
//         if (res.responseCode === 200) {
//             setEmptyLegDetails(res.data[0]);
//           // alert(res.data.totalRecordCount)
//           //   setPageState(prev=>({...prev,loader:false,data:res.data,totalPages:10}))
//         }

//         setLoader(false);
//         // setPageState(prev=>({...prev,loader:false}))
//       })
//       .catch((err) => {
//         console.log({ err });
//         //   setPageState(prev=>({...prev,loader:false}))
//         setLoader(false);
//       });
//   };

//   const handleEditEmptyLeg = (flightId:any) => {
//     const startDt = startDateTime ? dayjs(new Date(startDateTime).toISOString()).format('YYYY-MM-DDTHH:mm:ss.SSSZ') : '';
//     const endDt = arrivalDateTime ? dayjs(new Date(arrivalDateTime).toISOString()).format('YYYY-MM-DDTHH:mm:ss.SSSZ') : '';
//     const payload = { ...userInput, startDt, endDt };

//     // const payload = {
//     //   active: true,
//     //   aircraftId: userInput?.aircraftId,
//     //   description: userInput?.description,
//     //   exclusiveCategoryId: "",
//     //   endAirportId: userInput?.endAirportId,
//     //   endDt: endDt,
//     //   endMonth: "",
//     //   flightId: "",
//     //   flightType: "EMPTYLEG",
//     //   headline: userInput?.headline,
//     //   imageUrlList: "",
//     //   offerList: "",
//     //   oldPrice: userInput.oldPrice,
//     //   passengerCapacity: userInput?.passengerCapacity,
//     //   price: userInput.price,
//     //   startAirportId: userInput?.startAirportId,
//     //   startDt: startDt,
//     //   startMonth: "",
//     // };

//     console.log({userInput});

//     setLoader(true);
//     serviceProvider.flightService
//       .put(flightId,payload)
//       .then((res) => {
//         // console.log({ res })
//         setLoader(false);
//         if (res.responseCode === 200) {
//           toast.success(res.message);
//           // emptyInputFields()
//           // setCabinSpecs()
//         }else{
//             toast.error(res.message || "Something Went Wrong!!")
//         }
//       })
//       .catch((err) => {
//         toast.error(err.data.message || "Something Went Wrong !!");
//         setLoader(false);
//         //   emptyInputFields()
//         console.log({ err });
//       });
//   };

//   const getAllAirportsList = () => {
//     // setLoader(true);
//     serviceProvider.airportService
//       .getAllAirports()
//       .then((res) => {
//         // console.log({res})
//         if (res.responseCode === 200) {
//           setAirports(res.data);
//         }
//         // setLoader(false);
//       })
//       .catch((err) => {
//         console.log({ err });
//         // setLoader(false);
//       });
//   };
//   const getAllAircraftsList = () => {
//     setLoader(true);
//     serviceProvider.aircraftService
//       .getAllAircrafts("NA")
//       .then((res) => {
//         // console.log({res})
//         // setLoader(false);
//         if (res.responseCode === 200) {
//           setAircrafts(res.data);
//         }
//       })
//       .catch((err) => {
//         // setLoader(false);
//         console.log({ err });
//       });
//   };

//   useEffect(() => {
//     getAllAirportsList();
//     getAllAircraftsList();
//   }, []);

//   // useEffect(()=>{
//   //     setStartDateTime(dayjs(userInput?.itinerary?.startDt))
//   //     setArrivalDateTime(dayjs(userInput?.itinerary?.endDt))
//   // },[userInput?.itinerary?.startDt,userInput?.itinerary?.endDt])

//   useEffect(() => {
//     // Update userInput when offeredFlightDetails changes;

//     const imageUrl =
//     emptyLegDetails &&
//       emptyLegDetails?.imageUrlList &&
//       JSON.parse(emptyLegDetails?.imageUrlList);
//     setImageURLs(imageUrl);
//     // const offeredFlight = {...offeredFlightDetails,imageUrlList:imageUrl}

//     const flightInput = {
//       active: true,
//       aircraftId: emptyLegDetails?.aircraftId,
//       description: emptyLegDetails?.description,
//       exclusiveCategoryId: "",
//       endAirportId: emptyLegDetails?.itinerary?.endAirportId,
//       endDt: emptyLegDetails?.endDt,
//       endMonth: "",
//       flightId: "",
//       flightType: "EMPTYLEG",
//       headline: emptyLegDetails?.headline,
//       imageUrlList: "",
//       offerList: "",
//       oldPrice: emptyLegDetails?.oldPrice,
//       passengerCapacity: emptyLegDetails?.passengerCapacity,
//       price: emptyLegDetails?.price,
//       startAirportId: emptyLegDetails?.itinerary?.startAirportId,
//       startDt: emptyLegDetails?.startDt,
//       startMonth: "",
//     };

//     // setUserInput(emptyLegDetails);

//     // const flightInput = {
//     //   ...initialFlightInput,
//     //   active: true,
//     //   aircraftId: offeredFlightDetails?.aircraftId,
//     //   description: offeredFlightDetails?.description,
//     //   endAirportId: offeredFlightDetails?.itinerary?.endAirportId,
//     //   headline: offeredFlightDetails?.headline,
//     //   imageUrlList: offeredFlightDetails?.imageUrl,
//     //   passengerCapacity: offeredFlightDetails?.passengerCapacity,
//     //   price: offeredFlightDetails?.price,
//     //   startAirportId: offeredFlightDetails?.itinerary?.startAirportId,
//     // };
//     setUserInput(flightInput);
//     setStartDateTime(dayjs(emptyLegDetails?.itinerary?.startDt));
//     setArrivalDateTime(dayjs(emptyLegDetails?.itinerary?.endDt));
//   }, [emptyLegDetails]);

//   useEffect(() => {
//     // Fetch offered flight details when flightId changes
//     getEmptyLeg(flightId);
//   }, [flightId]);

//   // console.log("sfhdgbfj", emptyLegDetails?.itinerary?.startAirport);

//   if(loader){
//    return  <SpinLoader/>
//   }

//   return (
//     <Grid container className="single-aircraft-container">
//       <Grid item xs={12}>
//         <Grid container mb="10px">
//           <Grid item xs={6}>
//             <Box className="back-btn-container">
//               <Tooltip title="back">
//                 <IconButton onClick={() => navigate(-1)}>
//                   <img src={backArrow} alt="back" />
//                 </IconButton>
//               </Tooltip>
//               <Typography variant="h6" component="h6">
//                 Edit Empty Leg
//               </Typography>
//             </Box>
//           </Grid>
//         </Grid>
//       </Grid>
//       <Grid item xs={12}>
//         <Grid container spacing={3} className="aircraft-image-container">
//           {imageURLS?.map((imageSrc: any) => {
//             return (
//               <Grid item xs={3}>
//                 <Card className="image-card">
//                   <img src={imageSrc} alt="img" width={"100%"} />
//                 </Card>
//               </Grid>
//             );
//           })}
//           <Grid item xs={3}>
//             <ImageUploadComponent
//               chooseFileRef={chooseFileRef}
//               handleChooseFile={handleChooseFile}
//               handleFileChange={handleFileChange}
//               isMultiple={false}
//             />
//           </Grid>
//         </Grid>
//       </Grid>
//       <Grid item xs={12}>
//         <Card className="aircraft-description-card">
//           <Typography
//             variant="body1"
//             component="p"
//             className="description-heading"
//           >
//             Headline
//           </Typography>
//           <InputFieldComponent
//             isMultiline={true}
//             minRows={1}
//             name="headline"
//             value={userInput?.headline}
//             onChange={(e: any) => handleInputChange("headline", e.target.value)}
//           />
//         </Card>
//       </Grid>
//       <Grid item xs={12}>
//         <Card className="aircraft-description-card">
//           <Typography
//             variant="body1"
//             component="p"
//             className="description-heading"
//           >
//             Description
//           </Typography>
//           <InputFieldComponent
//             isMultiline={true}
//             minRows={4}
//             name="description"
//             value={userInput?.description}
//             onChange={(e: any) =>
//               handleInputChange("description", e.target.value)
//             }
//           />
//         </Card>
//       </Grid>
//       <Grid item xs={12}>
//         <Grid container className="form">
//           <Grid item xs={12} className="modal-table-container">
//             <Grid container className="heading">
//               <Grid item xs={12}>
//                 <Typography variant="h6" component="h6">
//                   Flight Details
//                 </Typography>
//               </Grid>
//             </Grid>
//             <Grid container className="edit-detail-item">
//               <Grid item xs={3} className="edit-item dark-bg">
//                 <Typography variant="body1" component="p">
//                   Aircraft
//                 </Typography>
//               </Grid>
//               <Grid item xs={9} className="edit-item">
//                 <AutoCompleteComponent
//                   options={aircrafts}
//                   position="end"
//                   defaultValue={emptyLegDetails?.aircraftDetails}
//                 // defaultValue
//             //    value={offeredFlightDetails?.aircraftDetails}
//                 //   defaultValue={offeredFlightDetails?.aircraftDetails?.aircraftId}
//                   // getOptionLabel={(option: any) => `${option.name} (${option.icaoCode})`}
//                   getOptionLabel={(option: IAircraft) => `${option.name}`}
//                   renderOption={(props: any, option: any) => (
//                     <Box
//                       sx={{
//                         width: "100%",
//                         borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
//                       }}
//                       {...props}
//                     >
//                       {/* <Typography textAlign="left">{option.city}</Typography> */}
//                       <Typography textAlign="left">{option.name}</Typography>
//                     </Box>
//                   )}
//                   onChange={(e: any, newValue: any) =>
//                     handleInputChange("aircraftId", newValue?.aircraftId)
//                   }
//                   placeholder="Select Aircraft"
//                   // isIconVisible={true}
//                 >
//                   {/* <img src={"destinationIcon"} alt="icon" height="25px" width="25px" /> */}
//                 </AutoCompleteComponent>
//               </Grid>
//             </Grid>
//             <Grid container className="edit-detail-item">
//               <Grid item xs={3} className="edit-item dark-bg">
//                 <Typography variant="body1" component="p">
//                   Start Airport
//                 </Typography>
//               </Grid>
//               <Grid item xs={9} className="edit-item">
//                 <AutoCompleteComponent

//                   options={airports}
//                   defaultValue={emptyLegDetails?.itinerary?.startAirport}
//                   getOptionLabel={(option: IAirport) =>
//                     `${option.name} (${option.icaoCode}) - ${option.city}`
//                   }
//                   renderOption={(props: any, option: any) => (
//                     <Box
//                       sx={{
//                         width: "100%",
//                         borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
//                         display: "flex",
//                         flexDirection: "column",
//                         justifyContent: "flex-start",
//                         alignItems: "flex-start",
//                       }}
//                       {...props}
//                     >
//                       <Typography textAlign="left">{option.city}</Typography>
//                       <Typography textAlign="left">
//                         {option.name} ({option.icaoCode})
//                       </Typography>
//                     </Box>
//                   )}
//                   position="end"
//                   onChange={(e: any, newValue: any) =>
//                     handleInputChange("startAirportId", newValue?.airportId)
//                   }
//                   placeholder="Select Start Airport"
//                   // isIconVisible={true}
//                 >
//                   {/* <img src={"destinationIcon"} alt="destination-icon" height="25px" width="25px" /> */}
//                 </AutoCompleteComponent>
//               </Grid>
//             </Grid>
//             <Grid container className="edit-detail-item">
//               <Grid item xs={3} className="edit-item dark-bg">
//                 <Typography variant="body1" component="p">
//                   End Airport
//                 </Typography>
//               </Grid>
//               <Grid item xs={9} className="edit-item">
//                 <AutoCompleteComponent
//                   options={airports}
//                   position="end"
//                   defaultValue={emptyLegDetails?.itinerary?.endAirport}
//                   getOptionLabel={(option: IAirport) =>
//                     `${option.name} (${option.icaoCode}) - ${option.city}`
//                   }
//                   renderOption={(props: any, option: any) => (
//                     <Box
//                       sx={{
//                         width: "100%",
//                         display: "flex",
//                         flexDirection: "column",
//                         justifyContent: "flex-start",
//                         alignItems: "flex-start",
//                         borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
//                       }}
//                       {...props}
//                     >
//                       <Typography textAlign="left">{option.city}</Typography>
//                       <Typography textAlign="left">
//                         {option.name} ({option.icaoCode})
//                       </Typography>
//                     </Box>
//                   )}
//                   onChange={(e: any, newValue: any) =>
//                     handleInputChange("endAirportId", newValue?.airportId)
//                   }
//                   placeholder="Select End Airport"
//                   // isIconVisible={true}
//                 ></AutoCompleteComponent>

//                 {/* <SelectFieldComponent
//                   name="endAirportId"
//                   value={userInput?.itinerary?.endAirportId}
//                   options={airports}
//                   onChange={(e: any) => handleInputChange("endAirportId", e.target.value)}
//                   labelKey="name"
//                   optionKey="airportId"
//                 /> */}
//               </Grid>
//             </Grid>
//             <Grid container className="edit-detail-item">
//               <Grid item xs={3} className="edit-item dark-bg">
//                 <Typography variant="body1" component="p">
//                   Start date and time
//                 </Typography>
//               </Grid>
//               <Grid item xs={9} className="edit-item">
//                 <DateTimePickerComponent
//                   value={startDateTime}
//                   onChange={handleStartDateTime}
//                 />
//               </Grid>
//             </Grid>
//             <Grid container className="edit-detail-item">
//               <Grid item xs={3} className="edit-item dark-bg">
//                 <Typography variant="body1" component="p">
//                   End date and time
//                 </Typography>
//               </Grid>
//               <Grid item xs={9} className="edit-item">
//                 <DateTimePickerComponent
//                   value={arrivalDateTime}
//                   onChange={handleArrivalDateTime}
//                 />
//               </Grid>
//             </Grid>
//             <Grid container className="edit-detail-item">
//               <Grid item xs={3} className="edit-item dark-bg">
//                 <Typography variant="body1" component="p">
//                   Passenger Capacity
//                 </Typography>
//               </Grid>
//               <Grid item xs={9} className="edit-item">
//                 <InputFieldComponent
//                   name="passengerCapacity"
//                   value={userInput?.passengerCapacity}
//                   onChange={(e: any) =>
//                     handleInputChange("passengerCapacity", e.target.value)
//                   }
//                 />
//               </Grid>
//             </Grid>
//             {/* <Grid container className="edit-detail-item">
//                             <Grid item xs={3} className='edit-item dark-bg'>
//                                 <Typography variant='body1' component='p'>
//                                     Duration
//                                 </Typography>
//                             </Grid>
//                             <Grid item xs={9} className='edit-item'>
//                                 <InputFieldComponent
//                                     name="duration"
//                                     value={userInput?.duration}
//                                     onChange={(e:any) => handleInputChange(e, e.target.value)}
//                                 />
//                             </Grid>
//                         </Grid> */}
//             <Grid container className="edit-detail-item">
//               <Grid item xs={3} className="edit-item dark-bg">
//                 <Typography variant="body1" component="p">
//                   Price
//                 </Typography>
//               </Grid>
//               <Grid item xs={9} className="edit-item">
//                 <InputFieldComponent
//                   name="price"
//                   value={userInput?.price}
//                   onChange={(e: any) =>
//                     handleInputChange("price", e.target.value)
//                   }
//                 />
//               </Grid>
//             </Grid>
//             <Grid container className="edit-detail-item">
//               <Grid item xs={3} className="edit-item dark-bg">
//                 <Typography variant="body1" component="p">
//                  Old Price
//                 </Typography>
//               </Grid>
//               <Grid item xs={9} className="edit-item">
//                 <InputFieldComponent
//                   name="oldPrice"
//                   value={userInput?.oldPrice}
//                   onChange={(e: any) =>
//                     handleInputChange("oldPrice", e.target.value)
//                   }
//                 />
//               </Grid>
//             </Grid>
//           </Grid>
//         </Grid>
//       </Grid>
//       <Grid item xs={12} className="btn-container">
//         {/* variant, className, onClick, label  */}
//         <ButtonComponent
//           fullWidth={false}
//           variant="contained"
//           className="rounded-btn-fill"
//           label="Save"
//           onClick={()=>handleEditEmptyLeg(emptyLegDetails?.flightId)}
//         />
//       </Grid>
//     </Grid>
//   );
// };

// export default EditEmpty;

import React, { useEffect, useRef, useState } from "react";
import {
  Grid,
  Typography,
  Card,
  Box,
  Button,
  Tooltip,
  IconButton,
  MenuItem,
  Select,
  FormControl,
} from "@mui/material";
import editIcon from "../../assets/icons/edit-icon.svg";
import { toast } from "react-toastify";
import { serviceProvider } from "../../provider/serviceProvider";
import dayjs, { Dayjs } from "dayjs";
import DateTimePickerComponent from "../common/DateTimePickerComponent";
import InputFieldComponent from "../common/InputFieldComponent";
import SelectFieldComponent from "../common/SelectFieldComponent";
import ImageUploadComponent from "../common/ImageUploadComponent";
import ButtonComponent from "../common/ButtonComponent";
import S3 from "react-aws-s3-typescript";
import { awsConfig } from "../../configuration/config.json";
import { ValidationUtiltiy } from "../../utility/ValidationUtility";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import backArrow from "../../assets/icons/back-arrow.svg";
import AutoCompleteComponent from "../common/AutoCompleteComponent";
import { IAircraft } from "../../interface/IAircraft";
import { IAirport } from "../../interface/IAirport";
import Loader from "../loader/Loader";
import addImageIcon from "../../assets/icons/add-image.svg";
import SpinLoader from "../loader/SpinLoader";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  input: {
    // border:"1px solid red",
    height: "100%",
    color: "inherit", // Text color
    width: "100%",
    backgroundColor: "transparent", // Remove background
    fontSize: "16px", // Set font size
    padding: "0", // Set padding
    "&:focus": {
      outline: "none", // Remove outline on focus
    },
  },
}));




const initialFlightInput = {
  active: true,
  aircraftId: "",
  description: "",
  exclusiveCategoryId: "",
  endAirportId: "",
  endDt: "",
  endMonth: "",
  flightId: "",
  flightType: "EMPTYLEG",
  headline: "",
  imageUrlList: "",
  offerList: "",
  oldPrice: 0,
  passengerCapacity: 0,
  price: 0,
  startAirportId: "",
  startDt: "",
  startMonth: "",

};

const EditEmpty = () => {
  const [userInput, setUserInput] = useState<any>(initialFlightInput);
  const [images, setImages] = useState([] as any);

  const [loader, setLoader] = useState(false);
  const [startDateTime, setStartDateTime] = useState<any>(null);
  // const [arrivalDateTime, setArrivalDateTime] = useState<any>();
  const chooseFileRef: React.MutableRefObject<HTMLInputElement | null> =
    useRef(null);
  const [airports, setAirports] = useState([]);
  const [aircrafts, setAircrafts] = useState([]);
  const [file, setFile] = useState(null);
  const [emptyLegDetails, setEmptyLegDetails] = useState<any>({});

  const { flightId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  
  const searchParams = new URLSearchParams(location.search);
  const index = searchParams.get('index');

  // Convert the 'index' value to a number if needed
  const indexNumber = parseInt(index + "", 10);

  const classes = useStyles();

  const handleStartDateTime = (newDate: Dayjs | null) => {
    setStartDateTime(newDate);
  };

  
  // const handleArrivalDateTime = (newDate: Dayjs | null) => {
  //   setArrivalDateTime(newDate);
  // };

  // const emptyInputFields = () => {
  //     setUserInput(initialFlightInput);
  //     setImageURLs([]);
  //     setImages([])
  // }

  // console.log({ userInput });
  const handleInputChange = (property: any, newValue: any) => {
    // console.log({ property, newValue });
    // alert(newValue)

    // const { name, value } = event.target;
    setUserInput({
      ...userInput,
      [property]: newValue,
    });

    // console.log('Name:', name);
    // console.log('Value:', value);
  };

  // const handleChooseFile = () => {
  //   chooseFileRef.current?.click();
  // };

  // const handleFileChange = (event: any) => {
  //   const fileObjs: [] = event.target.files;

  //   if (!fileObjs) {
  //     return;
  //   }

  //   const validFiles = Array.from(fileObjs).filter((fileObj) =>
  //     ValidationUtiltiy.isValidImg(fileObj["name"])
  //   );

  //   if (validFiles.length === 0) {
  //     toast.error("No valid files selected.");
  //     return;
  //   }
  //   console.log({ validFiles });
  //   setFiles(validFiles);

  //   validFiles.forEach((file: any, index) => {
  //     const newFileName = "123" + "_" + file.name.replace(/\..+$/, "");
  //     uploadImage(file, newFileName, index);
  //   });
  // };

  // const uploadImage = (file: any, newFile: any, index: any) => {
  //   const ReactS3Client = new S3(awsConfig);
  //   ReactS3Client.uploadFile(
  //     file,
  //     awsConfig.offeredFlightsSubFolder + "/" + newFile
  //   )
  //     .then((data) => {
  //       if (data.status === 204) {
  //         console.log(files);
  //         const img = data.location;
  //         setImageURLs((prevImageURLs: any) => [
  //           ...prevImageURLs,
  //           data.location,
  //         ]);
  //         console.log(data.location);

  //         // alert("heloo")

  //         // console.log(imageURLS,"updatedImageURLs",updatedImageURLs,index)
  //         if (index === files.length - 1) {
  //           updateUserInput(JSON.stringify(imageURLS));
  //           toast.success("Flights Pictures Uploaded");
  //         }
  //       } else {
  //         console.log("File Not Uploaded !!");
  //         toast.error("Error Occurred");
  //       }
  //     })
  //     .catch((err) => {
  //       console.log({ err });
  //       toast.error("Something Went Wrong.");
  //     });
  // };
  // console.log(imageURLS);

  // console.log({emptyLegDetails});

  // const chooseFileRef: React.MutableRefObject<HTMLInputElement | null> = useRef(null);

  const handleChooseFile = () => {
    // 👇️ open file input box on click of another element
    chooseFileRef.current?.click();
  };

  // new added
  const handleFileChange = (event: any) => {
    const fileObj = event.target.files && event.target.files[0];
    if (!fileObj) {
      return;
    }

    const isValid = ValidationUtiltiy.isValidImg(fileObj.name);
    if (isValid) {
      setFile(fileObj);
      let newFileName =
        // userDetails?.uid +
        "123" + "_" + event.target.files[0].name.replace(/\..+$/, "");
      uploadImage(fileObj, newFileName);
    } else {
      toast.error("Not a valid File");
      // handleCloseM()
    }
  };

  // new added

  const uploadImage = (file: any, newFile: any) => {
    setLoader(true);
    // handleCloseM()
    const ReactS3Client = new S3(awsConfig);
    ReactS3Client.uploadFile(
      file,
      awsConfig.emptyLegFlightsSubFolder + "/" + newFile
    )
      .then((data) => {
        setLoader(false);
        // console.log("upload cover image", data);
        if (data.status === 204) {
          updateUserInput(data.location);
          toast.success("Image Uploaded Click Save Button to Update.");
        } else {
          // setLoader(false)
          console.log("File Not Uploaded !!");
          toast.error("Error Occured");
        }
      })
      .catch((err) => {
        console.log({ err });
        setLoader(false);
        toast.error("Something Went Wrong.");
      });
  };
  //   console.log({ files })

  const updateUserInput = (imageUrl: string) => {
    if (imageUrl !== "") setUserInput({ ...userInput, imageUrlList: imageUrl });
  };

  const getEmptyLeg = (flightId: any) => {
    const param = `flightId=${flightId}&isActive=${indexNumber=== 0 ? true : false}`;
    setLoader(true);
    // setPageState(prev=>({...prev,loader:true}))
    serviceProvider.flightService
      .get(param)
      .then((res: any) => {
        if (res.responseCode === 200) {
          setEmptyLegDetails(res.data[0]);
          // alert(res.data.totalRecordCount)
          //   setPageState(prev=>({...prev,loader:false,data:res.data,totalPages:10}))
        }

        setLoader(false);
        // setPageState(prev=>({...prev,loader:false}))
      })
      .catch((err) => {
        console.log({ err });
        //   setPageState(prev=>({...prev,loader:false}))
        setLoader(false);
      });
  };

  const handleEditEmptyLeg = (flightId: any) => {
    const startDt = startDateTime
      ? dayjs(new Date(startDateTime).toISOString()).format(
          "YYYY-MM-DDTHH:mm:ss.SSSZ"
        )
      : "";
      const showOnLandingPage = userInput?.showOnLandingPage === "true" ? true : false;
    // const endDt = arrivalDateTime
    //   ? dayjs(new Date(arrivalDateTime).toISOString()).format(
    //       "YYYY-MM-DDTHH:mm:ss.SSSZ"
    //     )
    //   : "";
    const payload = { ...userInput, startDt,showOnLandingPage };

    // const payload = {
    //   active: true,
    //   aircraftId: userInput?.aircraftId,
    //   description: userInput?.description,
    //   exclusiveCategoryId: "",
    //   endAirportId: userInput?.endAirportId,
    //   endDt: endDt,
    //   endMonth: "",
    //   flightId: "",
    //   flightType: "EMPTYLEG",
    //   headline: userInput?.headline,
    //   imageUrlList: "",
    //   offerList: "",
    //   oldPrice: userInput.oldPrice,
    //   passengerCapacity: userInput?.passengerCapacity,
    //   price: userInput.price,
    //   startAirportId: userInput?.startAirportId,
    //   startDt: startDt,
    //   startMonth: "",
    // };

    console.log({ userInput });

    setLoader(true);
    serviceProvider.flightService
      .put(flightId, payload)
      .then((res) => {
        // console.log({ res })
        setLoader(false);
        if (res.responseCode === 200) {
          toast.success(res.message);
          window.location.href = "/empty-leg-management";
          // emptyInputFields()
          // setCabinSpecs()
        } else {
          toast.error(res?.message || "Something Went Wrong!!");
        }
      })
      .catch((err) => {
        toast.error(err?.data?.message || "Something Went Wrong !!");
        setLoader(false);
        //   emptyInputFields()
        console.log({ err });
      });
  };

  const getAllAirportsList = () => {
    // setLoader(true);
    serviceProvider.airportService
      .getAllAirports()
      .then((res) => {
        // console.log({res})
        if (res.responseCode === 200) {
          setAirports(res.data);
        }
        // setLoader(false);
      })
      .catch((err) => {
        console.log({ err });
        // setLoader(false);
      });
  };
  const getAllAircraftsList = () => {
    setLoader(true);
    serviceProvider.aircraftService
      .getAllAircrafts("NA")
      .then((res) => {
        // console.log({res})
        // setLoader(false);
        if (res.responseCode === 200) {
          setAircrafts(res.data);
        }
      })
      .catch((err) => {
        // setLoader(false);
        console.log({ err });
      });
  };

  useEffect(() => {
    getAllAirportsList();
    getAllAircraftsList();
  }, []);

  // useEffect(()=>{
  //     setStartDateTime(dayjs(userInput?.itinerary?.startDt))
  //     setArrivalDateTime(dayjs(userInput?.itinerary?.endDt))
  // },[userInput?.itinerary?.startDt,userInput?.itinerary?.endDt])

  useEffect(() => {
    const flightInput = {
      // active: true,
      active:emptyLegDetails?.active,
      aircraftId: emptyLegDetails?.aircraftId,
      description: emptyLegDetails?.description,
      exclusiveCategoryId: "",
      endAirportId: emptyLegDetails?.itinerary?.endAirportId,
      endDt: "",
      endMonth: "",
      flightId: "",
      flightType: "EMPTYLEG",
      headline: emptyLegDetails?.headline,
      imageUrlList: emptyLegDetails?.imageUrlList,
      offerList: "",
      oldPrice: emptyLegDetails?.oldPrice,
      passengerCapacity: emptyLegDetails?.passengerCapacity,
      price: emptyLegDetails?.price,
      startAirportId: emptyLegDetails?.itinerary?.startAirportId,
      startDt: emptyLegDetails?.startDt,
      startMonth: "",
      showOnLandingPage:emptyLegDetails?.showOnLandingPage+""

    };
    setUserInput(flightInput);
    setStartDateTime(dayjs(emptyLegDetails?.itinerary?.startDt));
    // setArrivalDateTime(dayjs(emptyLegDetails?.itinerary?.endDt));
  }, [emptyLegDetails]);

  useEffect(() => {
    // Fetch offered flight details when flightId changes
    getEmptyLeg(flightId);
  }, [flightId]);

  if (loader) {
    return <SpinLoader />;
  }

  return (
    <Grid container className="single-aircraft-container">
      <Grid item xs={12}>
        <Grid container mb="10px">
          <Grid item xs={6}>
            <Box className="back-btn-container">
              <Tooltip title="back">
                <IconButton onClick={() => navigate(-1)}>
                  <img src={backArrow} alt="back" />
                </IconButton>
              </Tooltip>
              <Typography variant="h6" component="h6">
                Edit Empty Leg
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={3} className="aircraft-image-container">
          <Grid item xs={3}>
          <Tooltip title="Update Image">
          <Card className="image-card">
              {/* <img src={image} alt="img" width={"100%"} /> */}

              <div className="img-box">
                <img
                  src={
                    userInput?.imageUrlList
                      ? userInput?.imageUrlList
                      : addImageIcon
                  }
                  className={`${
                    emptyLegDetails?.imageUrlList || userInput?.imageUrlList
                      ? "card-img"
                      : "add-img-icon"
                  }`}
                  alt="icon"
                  onClick={handleChooseFile}
                />
              </div>

              <input
                style={{ display: "none" }}
                ref={chooseFileRef}
                type="file"
                onChange={handleFileChange}
              />
            </Card>
              </Tooltip>
            
          </Grid>

          {/* <Grid item xs={3}>
            <ImageUploadComponent
              chooseFileRef={chooseFileRef}
              handleChooseFile={handleChooseFile}
              handleFileChange={handleFileChange}
              isMultiple={false}
            />
          </Grid> */}
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Card className="aircraft-description-card">
          <Typography
            variant="body1"
            component="p"
            className="description-heading"
          >
            Headline
          </Typography>
          <InputFieldComponent
            isMultiline={true}
            minRows={1}
            name="headline"
            value={userInput?.headline}
            onChange={(e: any) => handleInputChange("headline", e.target.value)}
          />
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card className="aircraft-description-card">
          <Typography
            variant="body1"
            component="p"
            className="description-heading"
          >
            Description
          </Typography>
          <InputFieldComponent
            isMultiline={true}
            minRows={4}
            name="description"
            value={userInput?.description}
            onChange={(e: any) =>
              handleInputChange("description", e.target.value)
            }
          />
        </Card>
      </Grid>
      <Grid item xs={12}>
                <Card className="aircraft-description-card" >
                <Typography
              variant="body1"
              component="p"
              className="description-heading"
            >
              Show on landing page popup
            </Typography>
                  <FormControl fullWidth>
                    <Select
                      className={classes.input}
                      sx={{ padding: "0 10px" }}
                      value={userInput?.showOnLandingPage}
                      name="showOnLandingPage"
                      variant="standard"
                      disableUnderline
                      onChange={(e) => handleInputChange("showOnLandingPage", e.target.value)}
                    >
           
       <MenuItem value={"true"}>Yes</MenuItem>
       <MenuItem value={"false"}>No</MenuItem>
  


                      
                     
                    </Select>
                  </FormControl>
                  </Card>
                </Grid> 
      <Grid item xs={12}>
        <Grid container className="form">
          <Grid item xs={12} className="modal-table-container">
            <Grid container className="heading">
              <Grid item xs={12}>
                <Typography variant="h6" component="h6">
                  Flight Details
                </Typography>
              </Grid>
            </Grid>
            <Grid container className="edit-detail-item">
              <Grid item xs={3} className="edit-item dark-bg">
                <Typography variant="body1" component="p">
                  Aircraft
                </Typography>
              </Grid>
              <Grid item xs={9} className="edit-item">
                <AutoCompleteComponent
                  options={aircrafts}
                  position="end"
                  defaultValue={emptyLegDetails?.aircraftDetails}
                  // defaultValue
                  //    value={offeredFlightDetails?.aircraftDetails}
                  //   defaultValue={offeredFlightDetails?.aircraftDetails?.aircraftId}
                  // getOptionLabel={(option: any) => `${option.name} (${option.icaoCode})`}
                  getOptionLabel={(option: IAircraft) => `${option.name}`}
                  renderOption={(props: any, option: any) => (
                    <Box
                      sx={{
                        width: "100%",
                        borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
                      }}
                      {...props}
                    >
                      {/* <Typography textAlign="left">{option.city}</Typography> */}
                      <Typography textAlign="left">{option.name}</Typography>
                    </Box>
                  )}
                  onChange={(e: any, newValue: any) =>
                    handleInputChange("aircraftId", newValue?.aircraftId)
                  }
                  placeholder="Select Aircraft"
                  // isIconVisible={true}
                >
                  {/* <img src={"destinationIcon"} alt="icon" height="25px" width="25px" /> */}
                </AutoCompleteComponent>
              </Grid>
            </Grid>
            <Grid container className="edit-detail-item">
              <Grid item xs={3} className="edit-item dark-bg">
                <Typography variant="body1" component="p">
                  Start Airport
                </Typography>
              </Grid>
              <Grid item xs={9} className="edit-item">
                <AutoCompleteComponent
                  options={airports}
                  defaultValue={emptyLegDetails?.itinerary?.startAirport}
                  getOptionLabel={(option: IAirport) =>
                    `${option.name} (${option.icaoCode}) - ${option.city}`
                  }
                  renderOption={(props: any, option: any) => (
                    <Box
                      sx={{
                        width: "100%",
                        borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "flex-start",
                        alignItems: "flex-start",
                      }}
                      {...props}
                    >
                      <Typography textAlign="left">{option.city}</Typography>
                      <Typography textAlign="left">
                        {option.name} ({option.icaoCode})
                      </Typography>
                    </Box>
                  )}
                  position="end"
                  onChange={(e: any, newValue: any) =>
                    handleInputChange("startAirportId", newValue?.airportId)
                  }
                  placeholder="Select Start Airport"
                  // isIconVisible={true}
                >
                  {/* <img src={"destinationIcon"} alt="destination-icon" height="25px" width="25px" /> */}
                </AutoCompleteComponent>
              </Grid>
            </Grid>
            <Grid container className="edit-detail-item">
              <Grid item xs={3} className="edit-item dark-bg">
                <Typography variant="body1" component="p">
                  End Airport
                </Typography>
              </Grid>
              <Grid item xs={9} className="edit-item">
                <AutoCompleteComponent
                  options={airports}
                  position="end"
                  defaultValue={emptyLegDetails?.itinerary?.endAirport}
                  getOptionLabel={(option: IAirport) =>
                    `${option.name} (${option.icaoCode}) - ${option.city}`
                  }
                  renderOption={(props: any, option: any) => (
                    <Box
                      sx={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "flex-start",
                        alignItems: "flex-start",
                        borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
                      }}
                      {...props}
                    >
                      <Typography textAlign="left">{option.city}</Typography>
                      <Typography textAlign="left">
                        {option.name} ({option.icaoCode})
                      </Typography>
                    </Box>
                  )}
                  onChange={(e: any, newValue: any) =>
                    handleInputChange("endAirportId", newValue?.airportId)
                  }
                  placeholder="Select End Airport"
                  // isIconVisible={true}
                ></AutoCompleteComponent>

                {/* <SelectFieldComponent
                  name="endAirportId"
                  value={userInput?.itinerary?.endAirportId}
                  options={airports}
                  onChange={(e: any) => handleInputChange("endAirportId", e.target.value)}
                  labelKey="name"
                  optionKey="airportId"
                /> */}
              </Grid>
            </Grid>
            <Grid container className="edit-detail-item">
              <Grid item xs={3} className="edit-item dark-bg">
                <Typography variant="body1" component="p">
                  Start date and time
                </Typography>
              </Grid>
              <Grid item xs={9} className="edit-item">
                <DateTimePickerComponent
                  value={startDateTime}
                  onChange={handleStartDateTime}
                />
              </Grid>
            </Grid>
            {/* <Grid container className="edit-detail-item">
              <Grid item xs={3} className="edit-item dark-bg">
                <Typography variant="body1" component="p">
                  End date and time
                </Typography>
              </Grid>
              <Grid item xs={9} className="edit-item">
                <DateTimePickerComponent
                  value={arrivalDateTime}
                  onChange={handleArrivalDateTime}
                />
              </Grid>
            </Grid> */}
            <Grid container className="edit-detail-item">
              <Grid item xs={3} className="edit-item dark-bg">
                <Typography variant="body1" component="p">
                  Guest Capacity
                </Typography>
              </Grid>
              <Grid item xs={9} className="edit-item">
                <InputFieldComponent
                  name="passengerCapacity"
                  value={userInput?.passengerCapacity}
                  onChange={(e: any) =>
                    handleInputChange("passengerCapacity", e.target.value)
                  }
                />
              </Grid>
            </Grid>
            {/* <Grid container className="edit-detail-item">
                            <Grid item xs={3} className='edit-item dark-bg'>
                                <Typography variant='body1' component='p'>
                                    Duration
                                </Typography>
                            </Grid>
                            <Grid item xs={9} className='edit-item'>
                                <InputFieldComponent
                                    name="duration"
                                    value={userInput?.duration}
                                    onChange={(e:any) => handleInputChange(e, e.target.value)}
                                />
                            </Grid>
                        </Grid> */}
            <Grid container className="edit-detail-item">
              <Grid item xs={3} className="edit-item dark-bg">
                <Typography variant="body1" component="p">
                  Price (excluding gst)
                </Typography>
              </Grid>
              <Grid item xs={9} className="edit-item">
                <InputFieldComponent
                  name="price"
                  value={userInput?.price}
                  onChange={(e: any) =>
                    handleInputChange("price", e.target.value)
                  }
                />
              </Grid>
            </Grid>
            <Grid container className="edit-detail-item">
              <Grid item xs={3} className="edit-item dark-bg">
                <Typography variant="body1" component="p">
                  Old Price (excluding gst)
                </Typography>
              </Grid>
              <Grid item xs={9} className="edit-item">
                <InputFieldComponent
                  name="oldPrice"
                  value={userInput?.oldPrice}
                  onChange={(e: any) =>
                    handleInputChange("oldPrice", e.target.value)
                  }
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} className="btn-container">
        {/* variant, className, onClick, label  */}
        <ButtonComponent
          fullWidth={false}
          variant="contained"
          className="rounded-btn-fill"
          label="Save"
          onClick={() => handleEditEmptyLeg(emptyLegDetails?.flightId)}
        />
      </Grid>
    </Grid>
  );
};

export default EditEmpty;
