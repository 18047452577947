// import React, { useEffect, useRef, useState } from "react";
// import {
//   Grid,
//   Typography,
//   Card,
//   Box,
//   Button,
//   Tooltip,
//   IconButton,
// } from "@mui/material";
// import editIcon from "../../assets/icons/edit-icon.svg";
// import { toast } from "react-toastify";
// import { serviceProvider } from "../../provider/serviceProvider";
// import dayjs, { Dayjs } from "dayjs";
// import DateTimePickerComponent from "../common/DateTimePickerComponent";
// import InputFieldComponent from "../common/InputFieldComponent";
// import SelectFieldComponent from "../common/SelectFieldComponent";
// import ImageUploadComponent from "../common/ImageUploadComponent";
// import ButtonComponent from "../common/ButtonComponent";
// import S3 from "react-aws-s3-typescript";
// import { awsConfig } from "../../configuration/config.json";
// import { ValidationUtiltiy } from "../../utility/ValidationUtility";
// import { useParams, useNavigate } from "react-router-dom";
// import backArrow from "../../assets/icons/back-arrow.svg";
// import AutoCompleteComponent from "../common/AutoCompleteComponent";
// import { IAircraft } from "../../interface/IAircraft";
// import { IAirport } from "../../interface/IAirport";

// const initialFlightInput = {
//   active: true,
//   aircraftId: "",
//   description: "",
//   exclusiveCategoryId: "",
//   endAirportId: "",
//   endDt: "",
//   endMonth: "",
//   flightId: "",
//   flightType: "EXCLUSIVE",
//   headline: "",
//   imageUrlList: "",
//   offerList: "",
//   oldPrice: 0,
//   passengerCapacity: 0,
//   price: 0,
//   startAirportId: "",
//   startDt: "",
//   startMonth: "",
// };

// const Edit = () => {
//   const [userInput, setUserInput] = useState<any>(initialFlightInput);
//   const [images, setImages] = useState([] as any);
//   const [imageURLS, setImageURLs] = useState<any>([]);
//   const [loader, setLoader] = useState(false);
//   const [startDateTime, setStartDateTime] = useState<any>(null);
//   const [arrivalDateTime, setArrivalDateTime] = useState<any>();
//   const chooseFileRef: React.MutableRefObject<HTMLInputElement | null> =
//     useRef(null);
//   const [airports, setAirports] = useState([]);
//   const [aircrafts, setAircrafts] = useState([]);
//   const [files, setFiles] = useState([]);
//   const [offeredFlightDetails, setOfferedFlightDetails] = useState<any>({});

//   const { flightId } = useParams();
//   const navigate = useNavigate();

//   const handleStartDateTime = (newDate: Dayjs | null) => {
//     setStartDateTime(newDate);
//   };
//   const handleArrivalDateTime = (newDate: Dayjs | null) => {
//     setArrivalDateTime(newDate);
//   };

//   // const emptyInputFields = () => {
//   //     setUserInput(initialFlightInput);
//   //     setImageURLs([]);
//   //     setImages([])
//   // }

//   console.log(userInput);
//   const handleInputChange = (property: any, newValue: any) => {
//     // const { name, value } = event.target;
//     setUserInput({
//       ...userInput,
//       [property]: newValue,
//       imageUrlList: JSON.stringify(imageURLS),
//     });

//     // console.log('Name:', name);
//     // console.log('Value:', value);
//   };

//   const handleChooseFile = () => {
//     chooseFileRef.current?.click();
//   };

//   const handleFileChange = (event: any) => {
//     const fileObjs: [] = event.target.files;

//     if (!fileObjs) {
//       return;
//     }

//     const validFiles = Array.from(fileObjs).filter((fileObj) =>
//       ValidationUtiltiy.isValidImg(fileObj["name"])
//     );

//     if (validFiles.length === 0) {
//       toast.error("No valid files selected.");
//       return;
//     }
//     console.log({ validFiles });
//     setFiles(validFiles);

//     validFiles.forEach((file: any, index) => {
//       const newFileName = "123" + "_" + file.name.replace(/\..+$/, "");
//       uploadImage(file, newFileName, index);
//     });
//   };

//   const uploadImage = (file: any, newFile: any, index: any) => {
//     const ReactS3Client = new S3(awsConfig);
//     ReactS3Client.uploadFile(
//       file,
//       awsConfig.offeredFlightsSubFolder + "/" + newFile
//     )
//       .then((data) => {
//         if (data.status === 204) {
//           console.log(files);
//           const img = data.location;
//           setImageURLs((prevImageURLs: any) => [
//             ...prevImageURLs,
//             data.location,
//           ]);
//           console.log(data.location);

//           // alert("heloo")

//           // console.log(imageURLS,"updatedImageURLs",updatedImageURLs,index)
//           if (index === files.length - 1) {
//             updateUserInput(JSON.stringify(imageURLS));
//             toast.success("Flights Pictures Uploaded");
//           }
//         } else {
//           console.log("File Not Uploaded !!");
//           toast.error("Error Occurred");
//         }
//       })
//       .catch((err) => {
//         console.log({ err });
//         toast.error("Something Went Wrong.");
//       });
//   };
//   console.log(imageURLS);

//   console.log({offeredFlightDetails});

//   const updateUserInput = (imageUrl: string) => {
//     if (imageUrl !== "") setUserInput({ ...userInput, imageUrlList: imageUrl });
//   };

//   const getOfferedFlight = (flightId: any) => {
//     const param = `flightId=${flightId}&flightType=EXCLUSIVE&isActive=true`;
//     setLoader(true);
//     // setPageState(prev=>({...prev,loader:true}))
//     serviceProvider.flightService
//       .get(param)
//       .then((res: any) => {
//         if (res.responseCode === 200) {
//           setOfferedFlightDetails(res.data[1]);
//           // alert(res.data.totalRecordCount)
//           //   setPageState(prev=>({...prev,loader:false,data:res.data,totalPages:10}))
//         }

//         setLoader(false);
//         // setPageState(prev=>({...prev,loader:false}))
//       })
//       .catch((err) => {
//         console.log({ err });
//         //   setPageState(prev=>({...prev,loader:false}))
//         setLoader(false);
//       });
//   };

//   const handleEditOfferedFlight = (flightId:any) => {
//     const startDt = startDateTime ? dayjs(new Date(startDateTime).toISOString()).format('YYYY-MM-DDTHH:mm:ss.SSSZ') : '';
//     const endDt = arrivalDateTime ? dayjs(new Date(arrivalDateTime).toISOString()).format('YYYY-MM-DDTHH:mm:ss.SSSZ') : '';
//     const payload = { ...userInput, startDt, endDt };
//     setLoader(true);
//     serviceProvider.flightService
//       .put(flightId,payload)
//       .then((res) => {
//         // console.log({ res })
//         setLoader(false);
//         if (res.responseCode === 200) {
//           toast.success(res.message);
//           // emptyInputFields()
//           // setCabinSpecs()
//         }else{
//             toast.error(res.message || "Something Went Wrong!!")
//         }
//       })
//       .catch((err) => {
//         toast.error(err.data.message || "Something Went Wrong !!");
//         setLoader(false);
//         //   emptyInputFields()
//         console.log({ err });
//       });
//   };

//   const getAllAirportsList = () => {
//     setLoader(true);
//     serviceProvider.airportService
//       .getAllAirports()
//       .then((res) => {
//         // console.log({res})
//         if (res.responseCode === 200) {
//           setAirports(res.data);
//         }
//         setLoader(false);
//       })
//       .catch((err) => {
//         console.log({ err });
//         setLoader(false);
//       });
//   };
//   const getAllAircraftsList = () => {
//     setLoader(true);
//     serviceProvider.aircraftService
//       .getAllAircrafts("NA")
//       .then((res) => {
//         // console.log({res})
//         setLoader(false);
//         if (res.responseCode === 200) {
//           setAircrafts(res.data);
//         }
//       })
//       .catch((err) => {
//         setLoader(false);
//         console.log({ err });
//       });
//   };

//   useEffect(() => {
//     getAllAirportsList();
//     getAllAircraftsList();
//   }, []);

//   // useEffect(()=>{
//   //     setStartDateTime(dayjs(userInput?.itinerary?.startDt))
//   //     setArrivalDateTime(dayjs(userInput?.itinerary?.endDt))
//   // },[userInput?.itinerary?.startDt,userInput?.itinerary?.endDt])

//   useEffect(() => {
//     // Update userInput when offeredFlightDetails changes;

//     const imageUrl =
//       offeredFlightDetails &&
//       offeredFlightDetails?.imageUrlList &&
//       JSON.parse(offeredFlightDetails?.imageUrlList);
//     setImageURLs(imageUrl);
//     // const offeredFlight = {...offeredFlightDetails,imageUrlList:imageUrl}
//     setUserInput(offeredFlightDetails);

//     // const flightInput = {
//     //   ...initialFlightInput,
//     //   active: true,
//     //   aircraftId: offeredFlightDetails?.aircraftId,
//     //   description: offeredFlightDetails?.description,
//     //   endAirportId: offeredFlightDetails?.itinerary?.endAirportId,
//     //   headline: offeredFlightDetails?.headline,
//     //   imageUrlList: offeredFlightDetails?.imageUrl,
//     //   passengerCapacity: offeredFlightDetails?.passengerCapacity,
//     //   price: offeredFlightDetails?.price,
//     //   startAirportId: offeredFlightDetails?.itinerary?.startAirportId,
//     // };
//     // setUserInput(flightInput);
//     setStartDateTime(dayjs(offeredFlightDetails?.itinerary?.startDt));
//     setArrivalDateTime(dayjs(offeredFlightDetails?.itinerary?.endDt));
//   }, [offeredFlightDetails]);

//   useEffect(() => {
//     // Fetch offered flight details when flightId changes
//     getOfferedFlight(flightId);
//   }, [flightId]);

//   console.log("sfhdgbfj", offeredFlightDetails?.itinerary?.startAirport);

//   // useEffect(()=>{
//   //     getOfferedFlight(flightId)
//   // setUserInput(offeredFlightDetails)
//   // },[flightId])

//   return (
//     <Grid container className="single-aircraft-container">
//       <Grid item xs={12}>
//         <Grid container mb="10px">
//           <Grid item xs={6}>
//             <Box className="back-btn-container">
//               <Tooltip title="back">
//                 <IconButton onClick={() => navigate(-1)}>
//                   <img src={backArrow} alt="back" />
//                 </IconButton>
//               </Tooltip>
//               <Typography variant="h6" component="h6">
//                 Edit Offered Flight
//               </Typography>
//             </Box>
//           </Grid>
//         </Grid>
//       </Grid>
//       <Grid item xs={12}>
//         <Grid container spacing={3} className="aircraft-image-container">
//           {/* {imageURLS?.map((imageSrc: any) => {
//             return (
//               <Grid item xs={3}>
//                 <Card className="image-card">
//                   <img src={imageSrc} alt="img" width={"100%"} />
//                 </Card>
//               </Grid>
//             );
//           })} */}
//           <Grid item xs={3}>
//             <ImageUploadComponent
//               chooseFileRef={chooseFileRef}
//               handleChooseFile={handleChooseFile}
//               handleFileChange={handleFileChange}
//               isMultiple={true}
//             />
//           </Grid>
//         </Grid>
//       </Grid>
//       <Grid item xs={12}>
//         <Card className="aircraft-description-card">
//           <Typography
//             variant="body1"
//             component="p"
//             className="description-heading"
//           >
//             Headline
//           </Typography>
//           <InputFieldComponent
//             isMultiline={true}
//             minRows={1}
//             name="headline"
//             value={userInput?.headline}
//             onChange={(e: any) => handleInputChange("headline", e.target.value)}
//           />
//         </Card>
//       </Grid>
//       <Grid item xs={12}>
//         <Card className="aircraft-description-card">
//           <Typography
//             variant="body1"
//             component="p"
//             className="description-heading"
//           >
//             Description
//           </Typography>
//           <InputFieldComponent
//             isMultiline={true}
//             minRows={4}
//             name="description"
//             value={userInput?.description}
//             onChange={(e: any) =>
//               handleInputChange("description", e.target.value)
//             }
//           />
//         </Card>
//       </Grid>
//       <Grid item xs={12}>
//         <Grid container className="form">
//           <Grid item xs={12} className="modal-table-container">
//             <Grid container className="heading">
//               <Grid item xs={12}>
//                 <Typography variant="h6" component="h6">
//                   Flight Details
//                 </Typography>
//               </Grid>
//             </Grid>
//             <Grid container className="edit-detail-item">
//               <Grid item xs={3} className="edit-item dark-bg">
//                 <Typography variant="body1" component="p">
//                   Aircraft
//                 </Typography>
//               </Grid>
//               <Grid item xs={9} className="edit-item">
//                 <AutoCompleteComponent
//                   options={aircrafts}
//                   position="end"
//                   defaultValue={offeredFlightDetails?.aircraftDetails}
//                 // defaultValue
//             //    value={offeredFlightDetails?.aircraftDetails}
//                 //   defaultValue={offeredFlightDetails?.aircraftDetails?.aircraftId}
//                   // getOptionLabel={(option: any) => `${option.name} (${option.icaoCode})`}
//                   getOptionLabel={(option: IAircraft) => `${option.name}`}
//                   renderOption={(props: any, option: any) => (
//                     <Box
//                       sx={{
//                         width: "100%",
//                         borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
//                       }}
//                       {...props}
//                     >
//                       {/* <Typography textAlign="left">{option.city}</Typography> */}
//                       <Typography textAlign="left">{option.name}</Typography>
//                     </Box>
//                   )}
//                   onChange={(e: any, newValue: any) =>
//                     handleInputChange("aircraftId", newValue?.aircraftId)
//                   }
//                   placeholder="Select Aircraft"
//                   // isIconVisible={true}
//                 >
//                   {/* <img src={"destinationIcon"} alt="icon" height="25px" width="25px" /> */}
//                 </AutoCompleteComponent>
//               </Grid>
//             </Grid>
//             <Grid container className="edit-detail-item">
//               <Grid item xs={3} className="edit-item dark-bg">
//                 <Typography variant="body1" component="p">
//                   Start Airport
//                 </Typography>
//               </Grid>
//               <Grid item xs={9} className="edit-item">
//                 <AutoCompleteComponent

//                   options={airports}
//                   defaultValue={offeredFlightDetails?.itinerary?.startAirport}
//                   getOptionLabel={(option: IAirport) =>
//                     `${option.name} (${option.icaoCode}) - ${option.city}`
//                   }
//                   renderOption={(props: any, option: any) => (
//                     <Box
//                       sx={{
//                         width: "100%",
//                         borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
//                         display: "flex",
//                         flexDirection: "column",
//                         justifyContent: "flex-start",
//                         alignItems: "flex-start",
//                       }}
//                       {...props}
//                     >
//                       <Typography textAlign="left">{option.city}</Typography>
//                       <Typography textAlign="left">
//                         {option.name} ({option.icaoCode})
//                       </Typography>
//                     </Box>
//                   )}
//                   position="end"
//                   onChange={(e: any, newValue: any) =>
//                     handleInputChange("startAirportId", newValue?.airportId)
//                   }
//                   placeholder="Select Start Airport"
//                   // isIconVisible={true}
//                 >
//                   {/* <img src={"destinationIcon"} alt="destination-icon" height="25px" width="25px" /> */}
//                 </AutoCompleteComponent>
//               </Grid>
//             </Grid>
//             <Grid container className="edit-detail-item">
//               <Grid item xs={3} className="edit-item dark-bg">
//                 <Typography variant="body1" component="p">
//                   End Airport
//                 </Typography>
//               </Grid>
//               <Grid item xs={9} className="edit-item">
//                 <AutoCompleteComponent
//                   options={airports}
//                   position="end"
//                   defaultValue={offeredFlightDetails?.itinerary?.endAirport}
//                   getOptionLabel={(option: IAirport) =>
//                     `${option.name} (${option.icaoCode}) - ${option.city}`
//                   }
//                   renderOption={(props: any, option: any) => (
//                     <Box
//                       sx={{
//                         width: "100%",
//                         display: "flex",
//                         flexDirection: "column",
//                         justifyContent: "flex-start",
//                         alignItems: "flex-start",
//                         borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
//                       }}
//                       {...props}
//                     >
//                       <Typography textAlign="left">{option.city}</Typography>
//                       <Typography textAlign="left">
//                         {option.name} ({option.icaoCode})
//                       </Typography>
//                     </Box>
//                   )}
//                   onChange={(e: any, newValue: any) =>
//                     handleInputChange("endAirportId", newValue?.airportId)
//                   }
//                   placeholder="Select End Airport"
//                   // isIconVisible={true}
//                 ></AutoCompleteComponent>

//                 {/* <SelectFieldComponent
//                   name="endAirportId"
//                   value={userInput?.itinerary?.endAirportId}
//                   options={airports}
//                   onChange={(e: any) => handleInputChange("endAirportId", e.target.value)}
//                   labelKey="name"
//                   optionKey="airportId"
//                 /> */}
//               </Grid>
//             </Grid>
//             <Grid container className="edit-detail-item">
//               <Grid item xs={3} className="edit-item dark-bg">
//                 <Typography variant="body1" component="p">
//                   Start date and time
//                 </Typography>
//               </Grid>
//               <Grid item xs={9} className="edit-item">
//                 <DateTimePickerComponent
//                   value={startDateTime}
//                   onChange={handleStartDateTime}
//                 />
//               </Grid>
//             </Grid>
//             <Grid container className="edit-detail-item">
//               <Grid item xs={3} className="edit-item dark-bg">
//                 <Typography variant="body1" component="p">
//                   End date and time
//                 </Typography>
//               </Grid>
//               <Grid item xs={9} className="edit-item">
//                 <DateTimePickerComponent
//                   value={arrivalDateTime}
//                   onChange={handleArrivalDateTime}
//                 />
//               </Grid>
//             </Grid>
//             <Grid container className="edit-detail-item">
//               <Grid item xs={3} className="edit-item dark-bg">
//                 <Typography variant="body1" component="p">
//                   Passenger Capacity
//                 </Typography>
//               </Grid>
//               <Grid item xs={9} className="edit-item">
//                 <InputFieldComponent
//                   name="passengerCapacity"
//                   value={userInput?.passengerCapacity}
//                   onChange={(e: any) =>
//                     handleInputChange("passengerCapacity", e.target.value)
//                   }
//                 />
//               </Grid>
//             </Grid>
//             {/* <Grid container className="edit-detail-item">
//                             <Grid item xs={3} className='edit-item dark-bg'>
//                                 <Typography variant='body1' component='p'>
//                                     Duration
//                                 </Typography>
//                             </Grid>
//                             <Grid item xs={9} className='edit-item'>
//                                 <InputFieldComponent
//                                     name="duration"
//                                     value={userInput?.duration}
//                                     onChange={(e:any) => handleInputChange(e, e.target.value)}
//                                 />
//                             </Grid>
//                         </Grid> */}
//             <Grid container className="edit-detail-item">
//               <Grid item xs={3} className="edit-item dark-bg">
//                 <Typography variant="body1" component="p">
//                   Price
//                 </Typography>
//               </Grid>
//               <Grid item xs={9} className="edit-item">
//                 <InputFieldComponent
//                   name="price"
//                   value={userInput?.price}
//                   onChange={(e: any) =>
//                     handleInputChange("price", e.target.value)
//                   }
//                 />
//               </Grid>
//             </Grid>
//           </Grid>
//         </Grid>
//       </Grid>
//       <Grid item xs={12} className="btn-container">
//         {/* variant, className, onClick, label  */}
//         <ButtonComponent
//           fullWidth={false}
//           variant="contained"
//           className="rounded-btn-fill"
//           label="Save"
//           onClick={()=>handleEditOfferedFlight(offeredFlightDetails?.flightId)}
//         />
//       </Grid>
//     </Grid>
//   );
// };

// export default Edit;

import React, { useEffect, useRef, useState } from "react";
import {
  Grid,
  Typography,
  Card,
  Box,
  Button,
  Tooltip,
  IconButton,
  FormControl,
  TextField,
} from "@mui/material";
import editIcon from "../../assets/icons/edit-icon.svg";
import { toast } from "react-toastify";
import { serviceProvider } from "../../provider/serviceProvider";
import dayjs, { Dayjs } from "dayjs";
import DateTimePickerComponent from "../common/DateTimePickerComponent";
import InputFieldComponent from "../common/InputFieldComponent";
import SelectFieldComponent from "../common/SelectFieldComponent";
import ImageUploadComponent from "../common/ImageUploadComponent";
import ButtonComponent from "../common/ButtonComponent";
import S3 from "react-aws-s3-typescript";
import { awsConfig } from "../../configuration/config.json";
import { ValidationUtiltiy } from "../../utility/ValidationUtility";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import backArrow from "../../assets/icons/back-arrow.svg";
import AutoCompleteComponent from "../common/AutoCompleteComponent";
import { IAircraft } from "../../interface/IAircraft";
import { IAirport } from "../../interface/IAirport";
import SpinLoader from "../loader/SpinLoader";
import CloseIcon from "@mui/icons-material/Close";
import { makeStyles } from "@mui/styles";
import verifyIcon from "../../assets/icons/successIcon.svg";

// const initialFlightInput = {
//   active: true,
//   aircraftId: "",
//   description: "",
//   exclusiveCategoryId: "",
//   endAirportId: "",
//   endDt: "",
//   endMonth: "",
//   flightId: "",
//   flightType: "EXCLUSIVE",
//   headline: "",
//   imageUrlList: "",
//   offerList: "",
//   oldPrice: 0,
//   passengerCapacity: 0,
//   price: 0,
//   startAirportId: "",
//   startDt: "",
//   startMonth: "",
// };

const useStyles = makeStyles((theme) => ({
  input: {
    // border:"1px solid red",
    height: "100%",
    color: "inherit", // Text color
    width: "100%",
    backgroundColor: "transparent", // Remove background
    fontSize: "16px", // Set font size
    padding: "0", // Set padding
    "&:focus": {
      outline: "none", // Remove outline on focus
    },
  },
}));

const EditEmpty = () => {
  const [userInput, setUserInput] = useState<any>(null);
  const [images, setImages] = useState([] as any);
  const [imageURLS, setImageURLs] = useState<any>([]);
  const [loader, setLoader] = useState(false);
  const [startDateTime, setStartDateTime] = useState<any>(null);
  const [arrivalDateTime, setArrivalDateTime] = useState<any>(null);
  const chooseFileRef: React.MutableRefObject<HTMLInputElement | null> =
    useRef(null);
  const [airports, setAirports] = useState([]);
  const [aircrafts, setAircrafts] = useState([]);
  const [files, setFiles] = useState([]);
  const [offeredFlightDetails, setOfferedFlightDetails] = useState<any>({});
  const [customUrl, setCustomUrl] = useState("");
  const [isCustomUrlVerified, setIsCustomUrlVerified] =
    useState<boolean>(false);

  const { flightId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const index = searchParams.get("index");


  const classes = useStyles();
  // Convert the 'index' value to a number if needed
  const indexNumber = parseInt(index + "", 10);

  const handleStartDateTime = (newDate: Dayjs | null) => {
    setStartDateTime(newDate);
  };
  const handleArrivalDateTime = (newDate: Dayjs | null) => {
    setArrivalDateTime(newDate);
  };

  console.log({ userInput });
  const handleInputChange = (property: any, newValue: any) => {
    setUserInput({
      ...userInput,
      [property]: newValue,
      // imageUrlList: JSON.stringify(imageURLS),
    });
  };

  const handleChooseFile = () => {
    // 👇️ open file input box on click of another element
    chooseFileRef.current?.click();
  };

  // new added
  const handleFileChange = (event: any) => {
    const fileObjs: [] = event.target.files;

    if (!fileObjs) {
      return;
    }

    const validFiles = Array.from(fileObjs).filter((fileObj) =>
      ValidationUtiltiy.isValidImg(fileObj["name"])
    );

    if (validFiles.length === 0) {
      toast.error("No valid files selected.");
      return;
    }

    const uploadPromises = validFiles.map((file: any, index) => {
      const newFileName = "123" + "_" + file.name.replace(/\..+$/, "");
      return uploadImage(file, newFileName);
    });
    setLoader(true);
    Promise.all(uploadPromises)
      .then((uploadedURLs) => {
        setLoader(false);
        setImageURLs((prevImageURLs: any) => [
          ...prevImageURLs,
          ...uploadedURLs,
        ]);
        updateUserInput(JSON.stringify(uploadedURLs));
        // toast.success("Image Uploaded");
        toast.success("Image Uploaded Click Save Button to Update.");
      })
      .catch((error) => {
        setLoader(false);
        console.error(error);
        toast.error("Error Occurred");
      });
  };

  // new added

  const uploadImage = (file: any, newFile: any) => {
    // setLoader(true)
    return new Promise((resolve, reject) => {
      const ReactS3Client = new S3(awsConfig);
      ReactS3Client.uploadFile(
        file,
        awsConfig.offeredFlightsSubFolder + "/" + newFile
      )
        .then((data) => {
          if (data.status === 204) {
            resolve(data.location);
          } else {
            reject("File Not Uploaded");
          }
          // setLoader(false)
        })
        .catch((err: any) => {
          // setLoader(false)
          reject("Something Went Wrong.");
        });
    });
  };
  //   console.log({ files })

  const updateUserInput = (imageUrl: string) => {
    if (imageUrl !== "") setUserInput({ ...userInput, imageUrlList: imageUrl });
  };

  const getOfferedFlight = (flightId: any) => {
    const param = `flightId=${flightId}&isActive=${
      indexNumber === 0 ? true : false
    }`;
    setLoader(true);
    // setPageState(prev=>({...prev,loader:true}))
    serviceProvider.flightService
      .get(param)
      .then((res: any) => {
        if (res.responseCode === 200) {
          setOfferedFlightDetails(res.data[0]);
          // alert(res.data.totalRecordCount)
          //   setPageState(prev=>({...prev,loader:false,data:res.data,totalPages:10}))
        }

        setLoader(false);
        // setPageState(prev=>({...prev,loader:false}))
      })
      .catch((err) => {
        console.log({ err });
        //   setPageState(prev=>({...prev,loader:false}))
        setLoader(false);
      });
  };

  const customUrlVerify = async (customUrl: string) => {
    setLoader(true);
    try {
      const param = `customurls/valid/AIRCRAFT?customUrl=${customUrl}`;
      const res: any = await serviceProvider.commonService.verifyCustomUrl(
        param
      );
      if (res?.httpStatus === 200) {
        // setUrlVerified(true);
        setIsCustomUrlVerified(true);
        toast.success(res?.result || "Custom url verified.");
      } else {
        toast.error(res?.error?.message || "Something went wrong!");
      }
    } catch (err: any) {
      toast.error(err?.data?.error?.message || "Something went wrong!");
    } finally {
      setLoader(false);
    }
  };

  const handleEditOfferedFlight = (flightId: any) => {
    const startDt = startDateTime
      ? dayjs(new Date(startDateTime).toISOString()).format(
          "YYYY-MM-DDTHH:mm:ss.SSSZ"
        )
      : "";
    const endDt = arrivalDateTime
      ? dayjs(new Date(arrivalDateTime).toISOString()).format(
          "YYYY-MM-DDTHH:mm:ss.SSSZ"
        )
      : "";
    const payload = {
      ...userInput,
      imageUrlList: JSON.stringify(imageURLS),
      startDt,
      endDt,
      custUrl: isCustomUrlVerified ? customUrl : "",
    };

    // const payload = {
    //   active: true,
    //   aircraftId: userInput?.aircraftId,
    //   description: userInput?.description,
    //   exclusiveCategoryId: "",
    //   endAirportId: userInput?.endAirportId,
    //   endDt: endDt,
    //   endMonth: "",
    //   flightId: "",
    //   flightType: "EMPTYLEG",
    //   headline: userInput?.headline,
    //   imageUrlList: "",
    //   offerList: "",
    //   oldPrice: userInput.oldPrice,
    //   passengerCapacity: userInput?.passengerCapacity,
    //   price: userInput.price,
    //   startAirportId: userInput?.startAirportId,
    //   startDt: startDt,
    //   startMonth: "",
    // };

    // console.log({ userInput });


     if(customUrl && !isCustomUrlVerified) {
      toast.error("Please verify custom url.");
      return
    } 


    setLoader(true);
    serviceProvider.flightService
      .put(flightId, payload)
      .then((res) => {
        // console.log({ res })
        setLoader(false);
        if (res.responseCode === 200) {
          toast.success(res.message);
          window.location.href = `/offered-flight-management?index=${indexNumber}`;
          // emptyInputFields()
          // setCabinSpecs()
        } else {
          toast.error(res?.message || "Something Went Wrong!!");
        }
      })
      .catch((err) => {
        toast.error(err?.data?.message || "Something Went Wrong !!");
        setLoader(false);
        //   emptyInputFields()
        console.log({ err });
      });
  };

  const getAllAirportsList = () => {
    // setLoader(true);
    serviceProvider.airportService
      .getAllAirports()
      .then((res) => {
        // console.log({res})
        if (res.responseCode === 200) {
          setAirports(res.data);
        }
        // setLoader(false);
      })
      .catch((err) => {
        console.log({ err });
        // setLoader(false);
      });
  };
  const getAllAircraftsList = () => {
    // setLoader(true);
    serviceProvider.aircraftService
      .getAllAircrafts("NA")
      .then((res) => {
        // console.log({res})
        // setLoader(false);
        if (res.responseCode === 200) {
          setAircrafts(res.data);
        }
      })
      .catch((err) => {
        // setLoader(false);
        console.log({ err });
      });
  };

  const handleRemoveImages = (index: number) => {
    const newImageURLs = [...imageURLS];
    newImageURLs.splice(index, 1);
    setImageURLs(newImageURLs);
    // setImageURLs
  };

  useEffect(() => {
    getAllAirportsList();
    getAllAircraftsList();
  }, []);

  useEffect(() => {
    // Update userInput when offeredFlightDetails changes;

    const imageUrl =
      offeredFlightDetails &&
      offeredFlightDetails?.imageUrlList &&
      JSON.parse(offeredFlightDetails?.imageUrlList);
    setImageURLs(imageUrl);
    // const offeredFlight = {...offeredFlightDetails,imageUrlList:imageUrl}

    const flightInput = {
      active: true,
      aircraftId: offeredFlightDetails?.aircraftId,
      description: offeredFlightDetails?.description,
      exclusiveCategoryId: "",
      endAirportId: offeredFlightDetails?.itinerary?.endAirportId,
      endDt: offeredFlightDetails?.endDt,
      endMonth: "",
      flightId: "",
      flightType: "EXCLUSIVE",
      title: offeredFlightDetails?.title,
      headline: offeredFlightDetails?.headline,
      imageUrlList: "",
      offerList: "",
      oldPrice: 0,
      passengerCapacity: offeredFlightDetails?.passengerCapacity,
      price: offeredFlightDetails?.price,
      startAirportId: offeredFlightDetails?.itinerary?.startAirportId,
      startDt: offeredFlightDetails?.startDt,
      startMonth: "",
    };
    setCustomUrl(offeredFlightDetails?.custUrl);
    setIsCustomUrlVerified(true);
    setUserInput(flightInput);
    setStartDateTime(dayjs(offeredFlightDetails?.itinerary?.startDt));
    setArrivalDateTime(dayjs(offeredFlightDetails?.itinerary?.endDt));
  }, [offeredFlightDetails]);

  useEffect(() => {
    // Fetch offered flight details when flightId changes

    getOfferedFlight(flightId);
  }, [flightId, indexNumber]);

  // console.log("sfhdgbfj", emptyLegDetails?.itinerary?.startAirport);

  console.log({ imageURLS });

  if (loader) {
    return <SpinLoader />;
  }

  return (
    <Grid container className="single-aircraft-container">
      <Grid item xs={12}>
        <Grid container mb="10px">
          <Grid item xs={6}>
            <Box className="back-btn-container">
              <Tooltip title="back">
                <IconButton onClick={() => navigate(-1)}>
                  <img src={backArrow} alt="back" />
                </IconButton>
              </Tooltip>
              <Typography variant="h6" component="h6">
                Edit Offered Flight
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={3} className="aircraft-image-container">
          {imageURLS?.length >= 1 &&
            imageURLS?.map((imageSrc: any, index: number) => {
              return (
                <Grid item xs={3}>
                  <Card className="image-card">
                    <div className="img-box">
                      <img src={imageSrc} alt="img" width={"100%"} />
                      <div
                        className="delete-icon"
                        onClick={() => handleRemoveImages(index)}
                      >
                        <Tooltip title="Remove">
                          <CloseIcon />
                        </Tooltip>
                      </div>
                    </div>
                    {/* <img src={imageSrc} alt="img" width={"100%"} /> */}
                  </Card>
                </Grid>
              );
            })}
          <Grid item xs={3}>
            <ImageUploadComponent
              chooseFileRef={chooseFileRef}
              handleChooseFile={handleChooseFile}
              handleFileChange={handleFileChange}
              isMultiple={true}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Card className="aircraft-description-card">
          <Typography
            variant="body1"
            component="p"
            className="description-heading"
          >
            Title
          </Typography>
          <InputFieldComponent
            // isMultiline={true}
            // minRows={1}
            name="title"
            value={userInput?.title}
            onChange={(e: any) => handleInputChange("title", e.target.value)}
          />
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card className="aircraft-description-card" sx={{mt:"0 !important"}}>
          <Typography
            variant="body1"
            component="p"
            className="description-heading"
          >
            Headline
          </Typography>
          <InputFieldComponent
            isMultiline={true}
            minRows={1}
            name="headline"
            value={userInput?.headline}
            onChange={(e: any) => handleInputChange("headline", e.target.value)}
          />
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card className="aircraft-description-card" sx={{mt:"0 !important"}}>
          <Typography
            variant="body1"
            component="p"
            className="description-heading"
          >
            Description
          </Typography>
          <InputFieldComponent
            isMultiline={true}
            minRows={4}
            name="description"
            value={userInput?.description}
            onChange={(e: any) =>
              handleInputChange("description", e.target.value)
            }
          />
        </Card>
      </Grid>
      <Grid item xs={12}>
                <Card className="aircraft-description-card" sx={{mt:"0 !important"}} >
                <Typography
              variant="body1"
              component="p"
              className="description-heading"
            >
             Custom Url
            </Typography>
                  <FormControl fullWidth>
                  <TextField
                    fullWidth
                    size="small"
                    variant="standard"
                    // disableUnderline
                      className={classes.input}
                      id="custUrl"
                      value={customUrl}
                      onChange={(e) => {
                        setIsCustomUrlVerified(false);
                        setCustomUrl(e.target.value);
                      }}
                      // required
                      placeholder="Enter custom url"
                     
                      // sx={{ mt: 1 }}
                      InputProps={{
                      disableUnderline: true,
                        endAdornment: (
                          <Button
                            className="common-button-underlined"
                            onClick={() => customUrlVerify(customUrl)} // Replace handleVerify with your verify logic function
                            sx={{ padding:"0" }}
                            disabled={isCustomUrlVerified}
                          >
                          <p style={{fontSize:"14px",display:"flex",alignItems:"center",gap:"4px"}}>  
                            <span>{isCustomUrlVerified ? "Verified" : "Verify"}</span>
                            {isCustomUrlVerified ? (
                              <img src={verifyIcon} alt="verify-icon" />
                            ) : (
                              ""
                            )}</p>
                          </Button>
                        ),
                      }}
                    />
                  </FormControl>
                  </Card>
                </Grid>
      <Grid item xs={12}>
        <Grid container className="form">
          <Grid item xs={12} className="modal-table-container">
            <Grid container className="heading">
              <Grid item xs={12}>
                <Typography variant="h6" component="h6">
                  Flight Details
                </Typography>
              </Grid>
            </Grid>
            <Grid container className="edit-detail-item">
              <Grid item xs={3} className="edit-item dark-bg">
                <Typography variant="body1" component="p">
                  Aircraft
                </Typography>
              </Grid>
              <Grid item xs={9} className="edit-item">
                <AutoCompleteComponent
                  options={aircrafts}
                  position="end"
                  defaultValue={offeredFlightDetails?.aircraftDetails}
                  // defaultValue
                  //    value={offeredFlightDetails?.aircraftDetails}
                  //   defaultValue={offeredFlightDetails?.aircraftDetails?.aircraftId}
                  // getOptionLabel={(option: any) => `${option.name} (${option.icaoCode})`}
                  getOptionLabel={(option: IAircraft) => `${option.name}`}
                  renderOption={(props: any, option: any) => (
                    <Box
                      sx={{
                        width: "100%",
                        borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
                      }}
                      {...props}
                    >
                      {/* <Typography textAlign="left">{option.city}</Typography> */}
                      <Typography textAlign="left">{option.name}</Typography>
                    </Box>
                  )}
                  onChange={(e: any, newValue: any) =>
                    handleInputChange("aircraftId", newValue?.aircraftId)
                  }
                  placeholder="Select Aircraft"
                  // isIconVisible={true}
                >
                  {/* <img src={"destinationIcon"} alt="icon" height="25px" width="25px" /> */}
                </AutoCompleteComponent>
              </Grid>
            </Grid>
            <Grid container className="edit-detail-item">
              <Grid item xs={3} className="edit-item dark-bg">
                <Typography variant="body1" component="p">
                  Start Airport
                </Typography>
              </Grid>
              <Grid item xs={9} className="edit-item">
                <AutoCompleteComponent
                  options={airports}
                  defaultValue={offeredFlightDetails?.itinerary?.startAirport}
                  getOptionLabel={(option: IAirport) =>
                    `${option.name} (${option.icaoCode}) - ${option.city}`
                  }
                  renderOption={(props: any, option: any) => (
                    <Box
                      sx={{
                        width: "100%",
                        borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "flex-start",
                        alignItems: "flex-start",
                      }}
                      {...props}
                    >
                      <Typography textAlign="left">{option.city}</Typography>
                      <Typography textAlign="left">
                        {option.name} ({option.icaoCode})
                      </Typography>
                    </Box>
                  )}
                  position="end"
                  onChange={(e: any, newValue: any) =>
                    handleInputChange("startAirportId", newValue?.airportId)
                  }
                  placeholder="Select Start Airport"
                  // isIconVisible={true}
                >
                  {/* <img src={"destinationIcon"} alt="destination-icon" height="25px" width="25px" /> */}
                </AutoCompleteComponent>
              </Grid>
            </Grid>
            <Grid container className="edit-detail-item">
              <Grid item xs={3} className="edit-item dark-bg">
                <Typography variant="body1" component="p">
                  End Airport
                </Typography>
              </Grid>
              <Grid item xs={9} className="edit-item">
                <AutoCompleteComponent
                  options={airports}
                  position="end"
                  defaultValue={offeredFlightDetails?.itinerary?.endAirport}
                  getOptionLabel={(option: IAirport) =>
                    `${option.name} (${option.icaoCode}) - ${option.city}`
                  }
                  renderOption={(props: any, option: any) => (
                    <Box
                      sx={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "flex-start",
                        alignItems: "flex-start",
                        borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
                      }}
                      {...props}
                    >
                      <Typography textAlign="left">{option.city}</Typography>
                      <Typography textAlign="left">
                        {option.name} ({option.icaoCode})
                      </Typography>
                    </Box>
                  )}
                  onChange={(e: any, newValue: any) =>
                    handleInputChange("endAirportId", newValue?.airportId)
                  }
                  placeholder="Select End Airport"
                  // isIconVisible={true}
                ></AutoCompleteComponent>

                {/* <SelectFieldComponent
                  name="endAirportId"
                  value={userInput?.itinerary?.endAirportId}
                  options={airports}
                  onChange={(e: any) => handleInputChange("endAirportId", e.target.value)}
                  labelKey="name"
                  optionKey="airportId"
                /> */}
              </Grid>
            </Grid>
            <Grid container className="edit-detail-item">
              <Grid item xs={3} className="edit-item dark-bg">
                <Typography variant="body1" component="p">
                  Start date and time
                </Typography>
              </Grid>
              <Grid item xs={9} className="edit-item">
                <DateTimePickerComponent
                  value={startDateTime}
                  onChange={handleStartDateTime}
                />
              </Grid>
            </Grid>
            <Grid container className="edit-detail-item">
              <Grid item xs={3} className="edit-item dark-bg">
                <Typography variant="body1" component="p">
                  End date and time
                </Typography>
              </Grid>
              <Grid item xs={9} className="edit-item">
                <DateTimePickerComponent
                  value={arrivalDateTime}
                  onChange={handleArrivalDateTime}
                />
              </Grid>
            </Grid>
            <Grid container className="edit-detail-item">
              <Grid item xs={3} className="edit-item dark-bg">
                <Typography variant="body1" component="p">
                  Guest Capacity
                </Typography>
              </Grid>
              <Grid item xs={9} className="edit-item">
                <InputFieldComponent
                  name="passengerCapacity"
                  value={userInput?.passengerCapacity}
                  onChange={(e: any) =>
                    handleInputChange("passengerCapacity", e.target.value)
                  }
                />
              </Grid>
            </Grid>
            {/* <Grid container className="edit-detail-item">
                            <Grid item xs={3} className='edit-item dark-bg'>
                                <Typography variant='body1' component='p'>
                                    Duration
                                </Typography>
                            </Grid>
                            <Grid item xs={9} className='edit-item'>
                                <InputFieldComponent
                                    name="duration"
                                    value={userInput?.duration}
                                    onChange={(e:any) => handleInputChange(e, e.target.value)}
                                />
                            </Grid>
                        </Grid> */}
            <Grid container className="edit-detail-item">
              <Grid item xs={3} className="edit-item dark-bg">
                <Typography variant="body1" component="p">
                  Price
                </Typography>
              </Grid>
              <Grid item xs={9} className="edit-item">
                <InputFieldComponent
                  name="price"
                  value={userInput?.price}
                  onChange={(e: any) =>
                    handleInputChange("price", e.target.value)
                  }
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} className="btn-container">
        {/* variant, className, onClick, label  */}
        <ButtonComponent
          fullWidth={false}
          loader={loader}
          disabled={loader}
          variant="contained"
          className="rounded-btn-fill"
          label="Save"
          onClick={() =>
            handleEditOfferedFlight(offeredFlightDetails?.flightId)
          }
        />
      </Grid>
    </Grid>
  );
};

export default EditEmpty;
