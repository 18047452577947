import { Outlet } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";
import { Grid } from "@mui/material";
import { Sidebar } from "./Sidebar";
import { MobileSidebar } from "./MobileSidebar";


const Layout: React.FC = () => {

  
  return (
    <>
    <div style={{ minHeight:"100vh",maxHeight: "100vh",overflowY:"auto", background: "#F5F5F5" }}>
              
               <Grid container className="main-container" spacing={4}>
                 {/* { */}
                   {/* // authentication.authenticated === true ? */}
                     <>
                       <Grid item xs={1} md={1} display={{ xs: "none", md: "block" }} sx={{ height: "100%" }} >
                         <Sidebar/>
                      </Grid>
                      <Grid item xs={12} md={11}>
                        <Grid container>
                          <Grid item xs={12}>
                            <Header/>
                          </Grid>
                          <Grid item xs={12} textAlign="center" mb="20px" display={{ xs: "block", md: "none" }}>
                            <MobileSidebar/>
                          </Grid>
                          <Grid item xs={12}>
                          <Outlet />
                          </Grid>
                        </Grid>
                      </Grid>
                     
                    </>
             
              </Grid>
              <Grid container>
              <Grid item xs={12}><Footer/></Grid>
              </Grid>
         
      {/* <Header /> */}
     
      
      </div>
    </>
  );
};

export default Layout;