// import { IUserService } from '../../interface/IService';
// import { IUser } from '../../interface/IUser';
// // import { IUpdateUserLink, IUser } from '../../interface/IUser';
// import { UserStorage } from '../../storage/userStorage';
// export class UserMockService implements IUserService {
//     storage: UserStorage;
//     constructor() {
//         this.storage = new UserStorage();
//     }
//     // updateLink(uid: string, payload: IUpdateUserLink): Promise<boolean> {
//     //     throw new Error('Method not implemented.');
//     // }
//     // parseResume(uid: string): Promise<any> {
//     //     throw new Error('Method not implemented')
//     // }
//     // isResumeUploaded(uid: string): Promise<boolean> {
//     //     throw new Error('Method not implemented.');
//     // }
//     put(param: any, entity: any): Promise<any> {
//         throw new Error('Method not implemented.');
//     }
//     post(entity: IUser): Promise<any> {
//         throw new Error('Method not implemented.');
//     }
//     patch(param: any): Promise<any> {
//         throw new Error('Method not implemented.');
//     }
//     delete(param: any): Promise<any> {
//         throw new Error('Method not implemented.');
//     }
//     // getList(): Promise<IUser[]> {
//     //     return new Promise((resolve) => {
//     //         resolve(this.storage.get())
//     //     });
//     // }
//     get(param: any): Promise<IUser> {
//         return new Promise((resolve) => {
//             resolve(this.storage.getByScenario(param as string)[0]);
//         });
//     }


    

//     requestOtp(mobileNumber: string): Promise<boolean> {
//         return new Promise((resolve) => {
//             resolve(true);
//         });
//     }
//     // getCompleteUserDetails(param: any): Promise<IUser> {
//     //     return new Promise((resolve) => {
//     //         resolve(this.storage.getByScenario(param as string)[0]);
//     //     });
//     // }
// }



import { IUserService } from '../../interface/IService';
import { IUser } from '../../interface/IUser';
// import { IUpdateUserLink, IUser } from '../../interface/IUser';
import { UserStorage } from '../../storage/userStorage';
export class UserMockService implements IUserService {
    storage: UserStorage;
    constructor() {
        this.storage = new UserStorage();
    }
    getAllUsers(): Promise<any> {
        throw new Error('Method not implemented.');
    }
    getSearchResults(query: any): Promise<any> {
        throw new Error('Method not implemented.');
    }
    // updateLink(uid: string, payload: IUpdateUserLink): Promise<boolean> {
    //     throw new Error('Method not implemented.');
    // }
    // parseResume(uid: string): Promise<any> {
    //     throw new Error('Method not implemented')
    // }
    // isResumeUploaded(uid: string): Promise<boolean> {
    //     throw new Error('Method not implemented.');
    // }
    put(param: any, entity: any): Promise<any> {
        throw new Error('Method not implemented.');
    }
    post(entity: IUser): Promise<any> {
        throw new Error('Method not implemented.');
    }
    patch(param: any): Promise<any> {
        throw new Error('Method not implemented.');
    }
    delete(param: any): Promise<any> {
        throw new Error('Method not implemented.');
    }
    // getList(): Promise<IUser[]> {
    //     return new Promise((resolve) => {
    //         resolve(this.storage.get())
    //     });
    // }
    get(param: any): Promise<IUser> {
        return new Promise((resolve) => {
            resolve(this.storage.getByScenario(param as string)[0]);
        });
    }

    requestOtp(mobileNumber: string): Promise<boolean> {
        return new Promise((resolve) => {
            resolve(true);
        });
    }
    // getCompleteUserDetails(param: any): Promise<IUser> {
    //     return new Promise((resolve) => {
    //         resolve(this.storage.getByScenario(param as string)[0]);
    //     });
    // }
}