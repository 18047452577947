import React from 'react'
import CommonModal from '../modal/CommonModal'
import { Button, Grid, Typography } from '@mui/material'

const ConfirmationModal = ({open,onClose,onConfirm,children}) => {
  return (
    <CommonModal open={open} handleCloseModal={onClose} className='' modalBody={
          <Grid container>
  <Grid item xs={12}>
    <Typography variant="h6" component="h6" textAlign="center">{children}</Typography>
  </Grid>
  <Grid item xs={12}>
    <Grid container columnSpacing={2} className="btn-container">
      <Grid item xs={6} display="flex" justifyContent="flex-end">
        <Button variant="outlined" className="rounded-btn-outlined" onClick={onClose}>Cancel</Button>
      </Grid>
      <Grid item xs={6}>
        <Button variant="contained" className="rounded-btn-fill" onClick={onConfirm} >Confirm</Button>
      </Grid>
    </Grid>
  </Grid>
</Grid>
    }/>
  )
}

export default ConfirmationModal