import React from 'react';
import {Grid, Tabs, Tab, Box } from '@mui/material';
import { IFleet } from '../../interface/IFleet';
import { CustomTabPanel, a11yProps } from '../Tab/CustomTabPanel';
import Loader from '../loader/Loader';
import FleetCard from './FleetCard';
import Aircrafts from './Aircraft/Aircrafts';
import { IAircraft } from '../../interface/IAircraft';
import NoResult from '../noResult/NoResult';
import { useNavigate } from 'react-router-dom';

interface FleetTabsProps {
  value: number;
  allFleets: IFleet[];
  handleChange: (event: React.SyntheticEvent, newValue: number) => void;
  navigateToTab: (fleetId: string,index:any) => void;
  loader:boolean;
  fleetAircrafts: IAircraft[]
}

const FleetTabs: React.FC<FleetTabsProps> = ({ value, allFleets, handleChange, navigateToTab ,loader,fleetAircrafts}) => {

  const navigate= useNavigate();
  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value}  onChange={handleChange} aria-label="basic tabs example" >
          <Tab  label="All Fleet" {...a11yProps(0)}  onClick={() => navigate("/fleets-management")} />
          {allFleets?.map((fleet: IFleet, index) => (
            <Tab
              key={fleet?.fleetId}
              label={fleet?.name}
              {...a11yProps(index + 1)}
              onClick={() => navigateToTab(fleet?.fleetId || "",index+1)}
            />
          ))}
        </Tabs>
      </Box>

      {/* ... */}

      <CustomTabPanel value={value} index={0}>
        <Grid container spacing={4} className='fleets-container'>
                {
                  loader ?
                    new Array(3).fill(0).map((_, id) => {
                      return <Grid item xs={12} sm={6} md={4} key={id}>
                        <Loader height='300px' width='100%' />
                      </Grid>
                    }) :
                    allFleets.length>=1 && loader ===false ?  allFleets.map((fleet: IFleet) => {
                      return <Grid item xs={12} sm={6} md={4} key={fleet.fleetId}>
                        <FleetCard fleet={fleet} />
                      </Grid>
                    }): 
                    <Grid item xs={12}>
                    <NoResult title="Fleets"/>
                </Grid>
                }
              </Grid>
            </CustomTabPanel>
            {
              allFleets && allFleets?.map((fleet, index) => {
                return <CustomTabPanel key={fleet.fleetId} value={value} index={index + 1}>
                  <Aircrafts fleetAircrafts={fleetAircrafts} loader={loader} fleetType={fleet.name}  index={index + 1} />
                </CustomTabPanel>
              })
            }
    </Box>
  );
};

export default FleetTabs;
