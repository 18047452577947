import React, { useState } from "react";
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Card, Grid, InputBase } from '@mui/material';
import { useLocation, useParams } from "react-router-dom";




const AircraftDetails = ({aircraft}:any) => {
  // const {aircraftId}= useParams();
  // const parsedCabinSpecs =aircraft?.cabinSpecs && JSON.parse(aircraft?.cabinSpecs);

  return (
    <Grid container className="aircraft-details-container">
    <Grid item xs={12} className="details-table-container">
    <Grid container className="detail-item">
        <Grid item xs={3} className='item'>
          <Typography variant='body1' component='p'>
             Guest Capacity
          </Typography>
        </Grid>
        <Grid item xs={9} className='item'>
          <Typography variant='body1' component='p'>
            {aircraft?.passengerCapacity||"--"}
          </Typography>
        </Grid>
      </Grid>
    <Grid container className="detail-item">
        <Grid item xs={3} className='item'>
          <Typography variant='body1' component='p'>
          Number of pilots 
          </Typography>
        </Grid>
        <Grid item xs={9} className='item'>
          <Typography variant='body1' component='p'>
            {aircraft?.pilotCapacity||"--"}
          </Typography>
        </Grid>
      </Grid>
    <Grid container className="detail-item">
        <Grid item xs={3} className='item'>
          <Typography variant='body1' component='p'>
          Number of flight attendants
          </Typography>
        </Grid>
        <Grid item xs={9} className='item'>
          <Typography variant='body1' component='p'>
            {aircraft?.crewCapacity||"--"}
          </Typography>
        </Grid>
      </Grid>
    <Grid container className="detail-item">
        <Grid item xs={3} className='item'>
          <Typography variant='body1' component='p'>
          Luggage capacity (ft³)
          </Typography>
        </Grid>
        <Grid item xs={9} className='item'>
          <Typography variant='body1' component='p'>
          {aircraft?.luggageCapacity||"--"}
          </Typography>
        </Grid>
      </Grid>
    <Grid container className="detail-item">
        <Grid item xs={3} className='item'>
          <Typography variant='body1' component='p'>
          Cabin specs 
          </Typography>
        </Grid>
        <Grid item xs={9} className='item'>
          <Typography variant='body1' component='p'>
            {"--"}
          </Typography>
        </Grid>
      </Grid>
    <Grid container className="detail-item">
        <Grid item xs={3} className='item'>
          <Typography variant='body1' component='p'>
          Number of lavatory
          </Typography>
        </Grid>
        <Grid item xs={9} className='item'>
          <Typography variant='body1' component='p'>
            {aircraft?.cabinSpecs?.numberOfLavatory || "--"}

          </Typography>
          
        </Grid>
      </Grid>
    </Grid>

  </Grid>
  )
}

export default AircraftDetails;