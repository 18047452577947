import React,{useState} from 'react';
import { getFormattedDate } from '../../utility/formateDateAndTime';
import { IFlight } from '../../interface/IFlight';
import  { Dayjs } from 'dayjs';
import DataGridTable from '../DataGridTable/DataGridTable';
import { ManageOfferedFlight } from './ManageOfferedFlight';
import { Tooltip } from '@mui/material';
import { formatNumber } from '../../utility/formateNumber';


const tableCellStyle = {
  borderRight: '1px solid rgba(224, 224, 224, 1)',
};
interface OfferedFlightTableProps {
  flights: IFlight[];
  pageState:any,
  setPageState:any,
  indexNumber:number,
  getOfferedFlights:any
}



const OfferedFlightTable: React.FC<OfferedFlightTableProps> = ({ pageState,setPageState,flights ,indexNumber,getOfferedFlights}) => {
  const [startDateTime, setStartDateTime] = useState<Dayjs | null>(null);
  const [arrivalDateTime, setArrivalDateTime] = useState<Dayjs | null>(null);
 


  const rows = flights.map((flight:any) => ({
    ...flight,
    id: flight.flightId,
    startDt:getFormattedDate(flight?.itinerary?.startDt),
    endDt:getFormattedDate(flight?.itinerary?.endDt),
    startAirportId:flight?.itinerary?.startAirport?.name,
    endAirportId:flight?.itinerary?.endAirport?.name,
    price:formatNumber(flight?.price),
    // flightDuration:flight.itinerary.flightDuration || "--"

    // startDt:getFormattedDate(flight.startDt)
  }));


  const columns = [
    //   { field: 'id',
    //    headerName: '',
    //   //  width: 90
    //  },
      {
        field: 'startDt',
        headerName: 'Start Date',
        width: 200,
        editable: false,
        
      },
      {
        field: 'endDt',
        headerName: 'Arrival Date',
        width: 200,
        editable: false,

        
      },
      {
        field: 'startAirportId',
        headerName: 'Start airport',
        width: 300,
        editable: false,
        renderCell: (params: any) => {
          return (
            <Tooltip title={params.row?.startAirportId}>
              <span>{params.row?.startAirportId}</span>
            </Tooltip>
          );
        },
      },
      {
        field: 'endAirportId',
        headerName: 'Arrival Airport',
        // type: 'number',
        width: 300,
        // width: 300,
        editable: false,
        renderCell: (params: any) => {
          return (
            <Tooltip title={params.row?.endAirportId}>
              <span>{params.row?.endAirportId}</span>
            </Tooltip>
          );
        },
      },
      {
        field: 'title',
        headerName: 'Title',
        // type: 'number',
        width: 300,
        // width: 300,
        editable: false,
        renderCell: (params: any) => {
          return (
            <Tooltip title={params.row?.title}>
              <span>{params.row?.title}</span>
            </Tooltip>
          );
        },
      },
      // {
      //   field: 'flightDuration',
      //   headerName: 'Duration',
      //   width: 130,
      //   // width: 150,
      //   editable: false,
      // },
      {
        field: 'price',
        headerName: 'Price (INR)',
        width: 200,
        // width: 150,
        editable: false,
      },
      {
        field: 'manage',
        type: 'actions',
        headerName: 'Manage',
        width: 200,
        getActions: (params: any) =>  [
          <ManageOfferedFlight flightId={params.row.flightId} isActive={params.row.active} indexNumber={indexNumber} getOfferedFlights={getOfferedFlights} />
        ],
      },
      // {
      //   field: 'edit',
      //   type: 'actions',
      //   headerName: 'Edit',
      //   width: 200,
      //   getActions: (params: any) =>  [
      //     <Button onClick={()=>window.location.href=`offered-flight-management/edit/${params.row.flightId}`}>
      //     <img src={editIcon} alt="edit icon" height="20px" width="20px" />
      //   </Button>
      //     // <ManageUser userId={params.row.uId} roleId={params.row.roleId} />
      //     // <EditOfferedFlight flightDetails={params.row} />
      //   ],
      // },
     
    ];

  
 


  


const newRows=  rows?.slice(pageState.page * pageState.pageSize, (pageState.page + 1) * pageState.pageSize);

  return (
    <>
      
      <DataGridTable pageState={pageState} setPageState={setPageState} rows={newRows} columns={columns}/>
    </>

  );
}
export default OfferedFlightTable;

