import { Button, Grid, MenuItem, Select, TextField, Typography } from "@mui/material";
import { useState } from "react";
import { serviceProvider } from "../../provider/serviceProvider";
import { toast } from "react-toastify";
import ButtonComponent from "../common/ButtonComponent";
import styles from './GenerateQuotation/GenerateQuotation.module.scss';

interface EditValidityInfoProps {
  validityInfo: any;
  quotationGroups: any;
  quotationId: any;
  onSave: (updatedValidityInfo: any) => void;
  onClose: () => void;
}

const EditValidityInfo: React.FC<EditValidityInfoProps> = ({ 
  validityInfo, 
  quotationGroups, 
  onSave, 
  onClose, 
  quotationId 
}) => {
  const [validityNumber, setValidityNumber] = useState(validityInfo?.validityNumber || 2);
  const [validityType, setValidityType] = useState(validityInfo?.validityType || "DAYS");
console.log("quotationId"+quotationId);
  const handleSave = async () => {
    const updatedValidityInfo = { validityNumber, validityType };

    const payload = {
      quotationsRequest: quotationGroups, // Using the provided quotation groups
      validityInfo: updatedValidityInfo,
    };

    try {
      const response = await serviceProvider.InstantQuotationService.put(
        `${quotationId}`,
        payload
      );
      if (response.responseCode === 200) {
        console.log("Updated Validity Info Saved:", response.data);
        onSave(updatedValidityInfo); // Pass updated validity info
        toast.success(response.message);
        setTimeout(() => {
            window.location.reload();
          }, 2000); // Wait 2 seconds before reloading
      } else {
        console.error("Failed to update validity info:", response.message);
        toast.error(response.message);
      }
    } catch (error:any) {
      console.error("Error updating validity info:", error);
      toast.error(error.message);
      
    }
  };

  return (
    <Grid container spacing={2} padding={2}>
     <Grid item xs={12} mb={2}>
        <Typography variant="h5">Edit Validity Info</Typography>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Typography variant="h6">Validity Number: </Typography>
          <Grid container className={styles.quotationInput}>
          <TextField
            id="validityNumber"
            size="small"
      type="number"
      variant="standard"
            value={validityNumber}
            onChange={(e) => setValidityNumber(Number(e.target.value))}
            InputProps={{
                disableUnderline: true,
              }}
            
          />
        </Grid>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="h6">Validity Type: </Typography>
          <Grid container className={styles.quotationEditCurrency}>
          <Select
            value={validityType}
            onChange={(e) => setValidityType(e.target.value)}
            label="Validity Type"
            sx={{
                border: 'none', // Removes the border
                boxShadow: 'none', // Removes any shadow
                padding: ' 0', // Adjust padding if needed
                '.MuiOutlinedInput-notchedOutline': {
                  border: 'none', // Ensures no outline in certain variants
                },
              }}
          >
            <MenuItem value="DAYS">DAYS</MenuItem>
            <MenuItem value="HOURS">HOURS</MenuItem>
          </Select>
        </Grid>
        </Grid>
        <Grid container item spacing={2} justifyContent="flex-end">
        <Grid item>
          <ButtonComponent fullWidth={false} onClick={handleSave} label='Update' />
           
        </Grid>
        <Grid item>
        <ButtonComponent fullWidth={false} onClick={onClose} label='Cancel' />

        </Grid>
      </Grid>
      </Grid>
    </Grid>
  );
};

export default EditValidityInfo;
