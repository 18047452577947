
import React,{useState} from "react"

import { Button, FormControl, Grid, MenuItem, Select, TextField, Tooltip, Typography } from '@mui/material';

import { serviceProvider } from "../../provider/serviceProvider";
import {toast} from "react-toastify";
import { sessionUtility } from "../../utility/sessionUtility";

const DeactivateBlogModalBody = ({isActive,handleCloseModal,blogDetails,pageState,getBlogsList}:any) => {

    const [loader,setLoader] = useState(false);

    const uId = sessionUtility.getAuthentication().session?.uId as string;

    const handleDeactivateBlog = (blogId: string,uId:string,isActive:boolean) => {
        const payload = {
          isActive: !isActive,
        };
       
        const param = `${blogId}/isActive?uId=${uId}`
    
        serviceProvider.blogService.put(param, payload).then((res: any) => {
            handleCloseModal();
          if (res?.httpStatus === 200) {
            
            toast.success("Status Updated successfully!");
            const query = `search?uId=NA&categoryId=NA&name=${"NA"}&tags=NA&content=NA&categoryName=NA&blogpostId=NA&custUrl=NA&offset=${pageState.page}&limit=${pageState.pageSize}&order=recordUpdatedTs.desc&profile=BASIC&title=NA&isActive=${isActive}&isFeatured=false`;
            getBlogsList(query);
          }
          else {
            toast.error(res?.error?.message || "something went wrong!");
          }
         

        }).catch((err) => {
          toast.error(err?.message || "something went wrong!");
        }).finally(() => {
          setLoader(false);
        
        });
      }
    
    return (
        <Grid container>
        <Grid item xs={12}>
          <Typography variant="h6" component="h6" textAlign="center">Confirm to deactivate blog?</Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid container columnSpacing={2} className="btn-container">
            <Grid item xs={6} display="flex" justifyContent="flex-end">
              <Button variant="outlined" className="rounded-btn-outlined" onClick={handleCloseModal}>Cancel</Button>
            </Grid>
            <Grid item xs={6}>
              <Button variant="contained" className="rounded-btn-fill" onClick={()=>handleDeactivateBlog(blogDetails?.blogpostId,uId,isActive)}>Confirm</Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };


  export default DeactivateBlogModalBody;