// import React, { useEffect, useRef, useState } from 'react'
// import { Grid, Tabs, Tab, Typography, Card, Box, Button, TextField, InputBase, Autocomplete, MenuItem, Select, FormControl } from "@mui/material";
// import addImageIcon from "../../assets/icons/add-image.svg";
// import editIcon from "../../assets/icons/edit-icon.svg";
// import { makeStyles } from '@mui/styles';
// import { toast } from 'react-toastify';
// import { useParams } from 'react-router-dom';
// import { serviceProvider } from '../../provider/serviceProvider';
// import { LocalizationProvider, MobileDateTimePicker } from '@mui/x-date-pickers';
// import dayjs, { Dayjs } from 'dayjs';
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import { styled } from '@mui/system';
// import { IAirport } from '../../interface/IAirport';
// import { IAircraft } from '../../interface/IAircraft';

// const StyledAutocomplete = styled(Autocomplete)`
//   .MuiInputBase-root {
//     border: none;
//     background-color: transparent;
//     outline: none;
//   }

//   .MuiAutocomplete-inputRoot {
//     &:before {
//       border-bottom: none;
//     }
//   }

//   &:hover .MuiAutocomplete-inputRoot:before,
//   &.Mui-focused .MuiAutocomplete-inputRoot:before,
//   &.Mui-focused .MuiAutocomplete-inputRoot:hover:before,
//   &.Mui-focused .MuiAutocomplete-inputRoot.Mui-focused:before {
//     border-bottom: none;
//   }
// `;

// const useStyles = makeStyles((theme) => ({
//     input: {
//         height: "100%",
//         color: 'inherit', // Text color
//         width: '100%',
//         backgroundColor: 'transparent', // Remove background
//         fontSize: '16px', // Set font size
//         padding: '0', // Set padding
//         '&:focus': {
//             outline: 'none', // Remove outline on focus
//         },
//     },
// }));

// const initialFlightInput = {
//     active: true,
//     aircraftId: "",
//     description: "",
//     duration: "",
//     endAirportId: "",
//     endDt: "",
//     endMonth: "",
//     flightId: "",
//     flightType: "EMPTYLEG",
//     headline: "",
//     imageUrlList: "",
//     offerList: "",
//     oldPrice: 0,
//     passengerCapacity: 0,
//     price: 0,
//     recordCreatedBy: "",
//     recordCreatedTs: "",
//     recordUpdatedBy: "",
//     recordUpdatedTs: "",
//     startAirportId: "",
//     startDt: "",
//     startMonth: ""
// };

// const AddEmptyLegFlight = () => {
//     const [value, setValue] = React.useState(0);
//     const classes = useStyles();
//     const [userInput, setUserInput] = useState(initialFlightInput);
//     const { fleetId } = useParams()
//     const [images, setImages] = useState([] as any);
//     const [imageURLS, setImageURLs] = useState([]);
//     const [loader, setLoader] = useState(false)
//     const [startDateTime, setStartDateTime] = useState<Dayjs | null>(null);
//     const [arrivalDateTime, setArrivalDateTime] = useState<Dayjs | null>(null);
//     const chooseFileRef: React.MutableRefObject<HTMLInputElement | null> = useRef(null);
//     // const [fileName,setFileName]=useState("")
//     const [airports, setAirports] = useState([]);
//     const [aircrafts, setAircrafts] = useState([]);

//     const handleStartDateTime = (newDate: Dayjs | null) => {
//         setStartDateTime(newDate);
//     }
//     const handleArrivalDateTime = (newDate: Dayjs | null) => {
//         setArrivalDateTime(newDate);
//     }

//     const emptyInputFields = () => {
//         setUserInput(initialFlightInput);
//         setImageURLs([]);
//         setImages([])
//     }

//     console.log(userInput)
//     const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>, newValue) => {
//         // const jsonData = {
//         //     isWifiAvailable: cabinSpecs.isWifiAvailable,
//         //     numberOfLavatory: cabinSpecs.numberOfLavatory
//         // }
//         const { name, value } = event.target;

//         setUserInput({ ...userInput, [name]: value, imageUrlList: JSON.stringify(imageURLS) })

//     }

//     const handleChooseFile = () => {
//         chooseFileRef.current?.click();
//     };

//     const handleFileChange = (event: any) => {
//         setImages([...event.target.files]);
//     };

//     useEffect(() => {
//         if (images.length < 1) return;
//         const newImageUrls: any = [];
//         images.forEach((image: any) => newImageUrls.push(URL.createObjectURL(image)));
//         setImageURLs(newImageUrls);
//     }, [images]);

//     // console.log({ images })
//     // console.log("imagesurls", imageURLS)

//     const handleAddOfferedFlight = () => {
//         const startDt = startDateTime.toISOString();
//         const endDt = arrivalDateTime.toISOString();
//         const payload = { ...userInput, startDt, endDt, imageUrlList: JSON.stringify(imageURLS) }
//         setLoader(true)
//         serviceProvider.aircraftService.post(payload).then((res) => {
//             // console.log({ res })
//             setLoader(false)
//             if (res.responseCode === 200) {
//                 toast.success(res.message)
//                 // emptyInputFields()
//                 // setCabinSpecs()
//             }

//         }).catch((err) => {
//             toast.error(err.data.message || "Something Went Wrong !!")
//             setLoader(false)
//             //   emptyInputFields()
//             console.log({ err })
//         })
//     }

//     const getAllAirportsList = () => {
//         setLoader(true);
//         serviceProvider.airportService.getAllAirports().then((res) => {
//             // console.log({res})
//             if (res.responseCode === 200) {
//                 setAirports(res.data)
//             }
//             setLoader(false)

//         }).catch((err) => {
//             console.log({ err })
//             setLoader(false)
//         })
//     }
//     const getAllAircraftsList = () => {
//         setLoader(true);
//         serviceProvider.aircraftService.getAllAircrafts().then((res) => {
//             // console.log({res})
//             setLoader(false)
//             if (res.responseCode === 200) {
//                 setAircrafts(res.data)
//             }

//         }).catch((err) => {
//             setLoader(false)
//             console.log({ err })
//         })
//     }

//     useEffect(() => {
//         getAllAirportsList();
//         getAllAircraftsList()
//     }, [])

//     return (
//         <Grid container className="single-aircraft-container">
//             {/* <Grid item xs={8} >
//                 <Card >
//                     <Grid container className="form-container">
//                         <Grid item xs={3} display="flex" alignItems="center">
//                             <Typography variant="h6" component="h6" pl="20px">Name of the Flight</Typography>
//                         </Grid>
//                         <Grid item xs={9}>
//                             <InputBase
//                                 className={classes.input}
//                                 //   placeholder="Brand Name"
//                                 // startAdornment="Name of aircraft"
//                                 name="name"
//                                 value={userInput.name}
//                                 onChange={handleInputChange}
//                                 inputProps={{ 'aria-label': 'custom input' }}
//                             />
//                         </Grid>
//                     </Grid>
//                 </Card>
//             </Grid> */}
//             <Grid item xs={12}>
//                 <Box className="edit-heading-container">
//                     <Typography variant="h6" component="h6" textAlign="right">Edit Details</Typography>
//                     <Button>
//                         <img src={editIcon} alt="edit icon" width="20px" />
//                     </Button>
//                 </Box>

//             </Grid>
//             <Grid item xs={12}>
//                 <Grid container spacing={3} className="aircraft-image-container">
//                     {/* <Grid item xs={3}>
//                         <Card className='image-card'>
//                             <img src={addImageIcon} alt="img" />
//                         </Card>

//                     </Grid> */}

//                     {/* <Upload /> */}

//                     {imageURLS.map((imageSrc) => {
//                         return <Grid item xs={3}>
//                             <Card className='image-card'>
//                                 <img src={imageSrc} alt="img" width={"100%"} />
//                             </Card>
//                         </Grid>
//                     })}
//                     <Grid item xs={3}>
//                         <Card className='image-card'>
//                             <input type="file" multiple accept="image/*" ref={chooseFileRef} style={{ display: "none" }} onChange={handleFileChange} />
//                             <img src={addImageIcon} alt="img" onClick={handleChooseFile} className='add-img-icon' />
//                         </Card>
//                     </Grid>

//                 </Grid>
//             </Grid>
//             <Grid item xs={12}>
//                 <Card className="aircraft-description-card">
//                     <Typography variant='body1' component='p' className='description-heading'>
//                         Headline
//                     </Typography>
//                     <InputBase
//                         className={classes.input}
//                         multiline
//                         //   minRows={6}
//                         minRows={1}

//                         name="headline"
//                         value={userInput.headline}
//                         onChange={(e) => handleInputChange(e, e.target.value)}
//                         inputProps={{ 'aria-label': 'custom input' }}
//                     />

//                 </Card>
//             </Grid>
//             <Grid item xs={12}>
//                 <Card className="aircraft-description-card">
//                     <Typography variant='body1' component='p' className='description-heading'>
//                         Description
//                     </Typography>
//                     <InputBase
//                         className={classes.input}
//                         multiline
//                         //   minRows={6}
//                         minRows={4}
//                         //   maxRows={10}
//                         //   placeholder="Brand Name"
//                         name="description"
//                         value={userInput.description}
//                         onChange={(e) => handleInputChange(e, e.target.value)}
//                         inputProps={{ 'aria-label': 'custom input' }}
//                     />

//                 </Card>
//             </Grid>
//             <Grid item xs={12} >
//                 <Grid container className='form'>
//                     <Grid item xs={12} className="modal-table-container">
//                         <Grid container className='heading'>
//                             <Grid item xs={12}>
//                                 <Typography variant='h6' component='h6'>Flight Details</Typography>
//                             </Grid>
//                         </Grid>
//                         <Grid container className="edit-detail-item">

//                             <Grid item xs={3} className='edit-item dark-bg'>
//                                 <Typography variant='body1' component='p'>
//                                     Aircraft
//                                 </Typography>
//                             </Grid>
//                             <Grid item xs={9} className='edit-item'>
//                                 {/* <StyledAutocomplete
//                                     id="outlined-basic"
//                                     options={aircrafts}
//                                     // onChange={handleSearchInput}

//                                     onChange={(e, newValue) => handleInputChange(e,newValue)}
//                                     sx={{ width: "100%" }}
//                                     // getOptionLabel={(option:any) => option.name}
//                                     getOptionLabel={(option) => `${option.name} (${option.aircraftSizeCategory})`}
//                                     renderInput={(params) => (
//                                         <TextField
//                                             {...params}
//                                             // sx={{width:"100%"}}
//                                             placeholder="Aircraft"
//                                             id="outlined-basic" size='small' fullWidth variant="standard"
//                                         />
//                                     )}
//                                 /> */}

//                                 <FormControl fullWidth>
//                                     <Select
//                                         labelId="demo-simple-select-label"
//                                         id="demo-simple-select"
//                                         value={userInput.aircraftId}
//                                         // label="Age"
//                                         name="aircraftId"
//                                         variant='standard'
//                                         disableUnderline
//                                         onChange={(e) => handleInputChange(e, e.target.value)}
//                                     >
//                                         {
//                                             aircrafts && aircrafts?.map((aircraft: IAircraft) => {
//                                                 return <MenuItem key={aircraft.aircraftId} value={aircraft.aircraftId}>{aircraft.name}</MenuItem>
//                                             })
//                                         }
//                                     </Select>
//                                 </FormControl>
//                                 {/* <Typography variant='body1' component='p'>
//       Luxury Automobiles
//     </Typography> */}
//                             </Grid>
//                         </Grid>
//                         <Grid container className="edit-detail-item">

//                             <Grid item xs={3} className='edit-item dark-bg'>
//                                 <Typography variant='body1' component='p'>
//                                     Departure Airport
//                                 </Typography>
//                             </Grid>
//                             <Grid item xs={9} className='edit-item'>
//                                 {/* <StyledAutocomplete
//                                     id="outlined-basic"
//                                     options={airports}
//                                     // onChange={handleSearchInput}
//                                     // value={userInput.startAirportId}

//                                     onChange={(e, newValue) => handleInputChange(e,newValue)}
//                                     sx={{ width: "100%" }}
//                                     // getOptionLabel={(option:any) => option.name}
//                                     getOptionLabel={(option) => `${option.name} (${option.icaoCode})`}
//                                     renderInput={(params) => (
//                                         <TextField
//                                             {...params}
//                                             // sx={{width:"100%"}}

//                                             placeholder="Start airport"
//                                             id="outlined-basic" size='small' fullWidth variant="standard"
//                                         />
//                                     )}
//                                 /> */}

//                                 <FormControl fullWidth>
//                                     <Select
//                                         labelId="demo-simple-select-label"
//                                         id="demo-simple-select"
//                                         value={userInput.startAirportId}
//                                         // label="Age"
//                                         name="startAirportId"
//                                         variant='standard'
//                                         disableUnderline
//                                         onChange={(e) => handleInputChange(e, e.target.value)}
//                                     >
//                                         {
//                                             airports && airports?.map((airport) => {
//                                                 return <MenuItem value={airport.airportId}>{airport.name}</MenuItem>
//                                             })
//                                         }
//                                     </Select>
//                                 </FormControl>

//                             </Grid>
//                         </Grid>
//                         <Grid container className="edit-detail-item">

//                             <Grid item xs={3} className='edit-item dark-bg'>
//                                 <Typography variant='body1' component='p'>
//                                     Arrival Airport
//                                 </Typography>
//                             </Grid>
//                             <Grid item xs={9} className='edit-item'>
//                                 <FormControl fullWidth>
//                                     <Select
//                                         labelId="demo-simple-select-label"
//                                         id="demo-simple-select"
//                                         value={userInput.endAirportId}
//                                         // label="Age"
//                                         name="endAirportId"
//                                         variant='standard'
//                                         disableUnderline
//                                         onChange={(e) => handleInputChange(e, e.target.value)}
//                                     >
//                                         {
//                                             airports && airports?.map((airport: IAirport) => {
//                                                 return <MenuItem key={airport.airportId} value={airport.airportId}>{airport.name}</MenuItem>
//                                             })
//                                         }
//                                     </Select>
//                                 </FormControl>
//                                 {/* <StyledAutocomplete
//                                     id="outlined-basic"
//                                     options={airports}
//                                     onChange={(e, newValue) => handleInputChange(e,newValue)}
//                                     // onChange={(e, newValue) => handleChange("endAirportId", newValue)}
//                                     sx={{ width: "100%" }}
//                                     // getOptionLabel={(option:any) => option.name}
//                                     getOptionLabel={(option) => `${option.name} (${option.icaoCode})`}
//                                     renderInput={(params) => (
//                                         <TextField
//                                             {...params}
//                                             // sx={{width:"100%"}}
//                                             placeholder="Arrival airport"
//                                             id="outlined-basic" size='small' fullWidth variant="standard"
//                                         />
//                                     )}
//                                 /> */}
//                             </Grid>
//                         </Grid>
//                         <Grid container className="edit-detail-item">

//                             <Grid item xs={3} className='edit-item dark-bg'>
//                                 <Typography variant='body1' component='p'>
//                                     Start date and time
//                                 </Typography>
//                             </Grid>
//                             <Grid item xs={9} className='edit-item'>
//                                 <LocalizationProvider dateAdapter={AdapterDayjs}>
//                                     <MobileDateTimePicker value={startDateTime}
//                                         className={classes.input}
//                                         slotProps={{
//                                             textField: {
//                                                 variant: 'standard', inputProps: {
//                                                 }
//                                             }
//                                         }}
//                                         disablePast
//                                         onChange={handleStartDateTime}
//                                     />
//                                 </LocalizationProvider>
//                             </Grid>
//                         </Grid>
//                         <Grid container className="edit-detail-item">

//                             <Grid item xs={3} className='edit-item dark-bg'>
//                                 <Typography variant='body1' component='p'>
//                                     Arrival date and time
//                                 </Typography>
//                             </Grid>
//                             <Grid item xs={9} className='edit-item'>
//                                 <LocalizationProvider dateAdapter={AdapterDayjs}>
//                                     <MobileDateTimePicker value={arrivalDateTime}
//                                         className={classes.input}
//                                         slotProps={{
//                                             textField: {
//                                                 variant: 'standard', inputProps: {
//                                                 }
//                                             }
//                                         }}
//                                         disablePast
//                                         onChange={handleArrivalDateTime}
//                                     />
//                                 </LocalizationProvider>
//                             </Grid>
//                         </Grid>

//                         <Grid container className="edit-detail-item">

//                             <Grid item xs={3} className='edit-item dark-bg'>
//                                 <Typography variant='body1' component='p'>
//                                     Passenger Capacity
//                                 </Typography>
//                             </Grid>
//                             <Grid item xs={9} className='edit-item'>
//                                 <InputBase
//                                     className={classes.input}
//                                     // placeholder="Expiry Date"
//                                     name="passengerCapacity"
//                                     onChange={(e) => handleInputChange(e, e.target.value)}
//                                     value={userInput.passengerCapacity}
//                                     // onChange={handleInputChange}
//                                     inputProps={{ 'aria-label': 'custom input' }}
//                                 />
//                             </Grid>
//                         </Grid>
//                         <Grid container className="edit-detail-item">

//                             <Grid item xs={3} className='edit-item dark-bg'>
//                                 <Typography variant='body1' component='p'>
//                                     Duration
//                                 </Typography>
//                             </Grid>
//                             <Grid item xs={9} className='edit-item'>
//                                 <InputBase
//                                     className={classes.input}
//                                     // placeholder="Enter Coupon Details"
//                                     name="duration"
//                                     value={userInput.duration}
//                                     // onChange={handleInputChange}
//                                     onChange={(e) => handleInputChange(e, e.target.value)}
//                                     inputProps={{ 'aria-label': 'custom input' }}
//                                 />
//                                 {/* <Typography variant='body1' component='p'>
// Enjoy a 25% discount on BMW vehicle maintenance and service.
// </Typography> */}
//                             </Grid>
//                         </Grid>
//                         <Grid container className="edit-detail-item">
//                             <Grid item xs={3} className='edit-item dark-bg'>
//                                 <Typography variant='body1' component='p'>
//                                     Price
//                                 </Typography>
//                             </Grid>
//                             <Grid item xs={9} className='edit-item'>
//                                 <InputBase
//                                     className={classes.input}
//                                     // placeholder="Headline"
//                                     name="price"
//                                     value={userInput.price}
//                                     // onChange={handleInputChange}
//                                     onChange={(e) => handleInputChange(e, e.target.value)}
//                                     inputProps={{
//                                         'aria-label': 'custom input',
//                                         maxLength: 50,
//                                     }}
//                                 />
//                             </Grid>
//                         </Grid>
//                         <Grid container className="edit-detail-item">
//                             <Grid item xs={3} className='edit-item dark-bg'>
//                                 <Typography variant='body1' component='p'>
//                                   Old Price
//                                 </Typography>
//                             </Grid>
//                             <Grid item xs={9} className='edit-item'>
//                                 <InputBase
//                                     className={classes.input}
//                                     // placeholder="Headline"
//                                     name="oldPrice"
//                                     value={userInput.oldPrice}
//                                     // onChange={handleInputChange}
//                                     onChange={(e) => handleInputChange(e, e.target.value)}
//                                     inputProps={{
//                                         'aria-label': 'custom input',
//                                         maxLength: 50,
//                                     }}
//                                 />
//                             </Grid>
//                         </Grid>

//                     </Grid>

//                 </Grid>

//             </Grid>
//             <Grid item xs={12} className="btn-container">
//                 <Button variant="contained" className="rounded-btn-fill" onClick={handleAddOfferedFlight}>Save</Button>
//             </Grid>
//         </Grid>
//     )
// }

// export default AddEmptyLegFlight;

import React, { useEffect, useRef, useState } from "react";
import {
  Grid,
  Typography,
  Card,
  Box,
  Button,
  Tooltip,
  IconButton,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";
import editIcon from "../../assets/icons/edit-icon.svg";
import { toast } from "react-toastify";
import { serviceProvider } from "../../provider/serviceProvider";
import dayjs, { Dayjs } from "dayjs";
import DateTimePickerComponent from "../common/DateTimePickerComponent";
import InputFieldComponent from "../common/InputFieldComponent";
import SelectFieldComponent from "../common/SelectFieldComponent";
import ImageUploadComponent from "../common/ImageUploadComponent";
import ButtonComponent from "../common/ButtonComponent";
import S3 from "react-aws-s3-typescript";
import { awsConfig } from "../../configuration/config.json";
import { ValidationUtiltiy } from "../../utility/ValidationUtility";
import { useNavigate } from "react-router-dom";
import backArrow from "../../assets/icons/back-arrow.svg";
import addImageIcon from "../../assets/icons/add-image.svg";
import AutoCompleteComponent from "../common/AutoCompleteComponent";
import { IAircraft } from "../../interface/IAircraft";
import { IAirport } from "../../interface/IAirport";
import SpinLoader from "../loader/SpinLoader";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  input: {
    // border:"1px solid red",
    height: "100%",
    color: "inherit", // Text color
    width: "100%",
    backgroundColor: "transparent", // Remove background
    fontSize: "16px", // Set font size
    padding: "0", // Set padding
    "&:focus": {
      outline: "none", // Remove outline on focus
    },
  },
}));


const initialFlightInput = {
  active: true,
  aircraftId: "",
  description: "",
  duration: "",
  endAirportId: "",
  endDt: "",
  endMonth: "",
  flightId: "",
  flightType: "EMPTYLEG",
  headline: "",
  imageUrlList: "",
  offerList: "",
  oldPrice: 0,
  passengerCapacity: 0,
  price: 0,
  startAirportId: "",
  startDt: "",
  startMonth: "",
  showOnLandingPage:"true",
};

const AddEmptyLegFlight = () => {
  const [userInput, setUserInput] = useState(initialFlightInput);
  // const [images, setImages] = useState([] as any);
  // const [imageURLS, setImageURLs] = useState<any>([]);
  const [loader, setLoader] = useState(false);
  const [startDateTime, setStartDateTime] = useState<any>(null);
//   const [arrivalDateTime, setArrivalDateTime] = useState<Dayjs | null>(null);
  const chooseFileRef: React.MutableRefObject<HTMLInputElement | null> =
    useRef(null);
  const [airports, setAirports] = useState([]);
  const [aircrafts, setAircrafts] = useState([]);
  // const [files, setFiles] = useState([]);

  const [file, setFile] = useState<any>(null);
  //   const chooseFileRef: React.MutableRefObject<HTMLInputElement | null> = useRef(null);

  const classes = useStyles();

  const handleChooseFile = () => {
    chooseFileRef.current?.click();
  };

  const navigate = useNavigate();

  const handleStartDateTime = (newDate: any) => {
    setStartDateTime(newDate);
  };
//   const handleArrivalDateTime = (newDate:any) => {
//     setArrivalDateTime(newDate);
//   };

  const emptyInputFields = () => {
    setUserInput(initialFlightInput);
    // setImageURLs([]);
    // setImages([])
  };

  console.log(userInput);
  const handleInputChange = (property: any, newValue: any) => {
    // const { name, value } = event.target;
    // setUserInput({ ...userInput, [name]: value, imageUrlList: JSON.stringify(imageURLS) })
    setUserInput({ ...userInput, [property]: newValue });
  };

  // const handleChooseFile = () => {
  //     chooseFileRef.current?.click();
  // };

  // const handleFileChange = (event: any) => {
  //     setImages([...event.target.files]);
  // };

  const handleFileChange = (event: any) => {
    const fileObj = event.target.files && event.target.files[0];
    if (!fileObj) {
      return;
    }
    const isValid = ValidationUtiltiy.isValidImg(fileObj.name);
    if (isValid) {
      setFile(fileObj);
      let newFileName =
        // userDetails?.uid +
        "123" + "_" + event.target.files[0].name.replace(/\..+$/, "");
      uploadImage(fileObj, newFileName);
    } else {
      toast.error("Not a valid File");
      // handleCloseM()
    }
  };

  const uploadImage = (file: any, newFile: any) => {
    setLoader(true)
    // handleCloseM()
    const ReactS3Client = new S3(awsConfig);
    ReactS3Client.uploadFile(
      file,
      awsConfig.emptyLegFlightsSubFolder + "/" + newFile
    )
      .then((data) => {
        setLoader(false)
        // console.log("upload cover image", data);
        if (data.status === 204) {
          updateUserInput(data.location);
          toast.success("Image Uploaded");
        } else {
          // setLoader(false)
          console.log("File Not Uploaded !!");
          toast.error("Error Occured");
        }
      })
      .catch((err) => {
        console.log({ err });
        setLoader(false)
        toast.error("Something Went Wrong.");
      });
  };
  //   console.log(imageURLS)

  const updateUserInput = (imageUrl: string) => {
    if (imageUrl !== "") setUserInput({ ...userInput, imageUrlList: imageUrl });
  };

  // useEffect(() => {
  //     if (images.length < 1) return;
  //     const newImageUrls: any = [];
  //     images.forEach((image: any) => newImageUrls.push(URL.createObjectURL(image)));
  //     setImageURLs(newImageUrls);
  // }, [images]);

  const handleAddOfferedFlight = () => {
    // const formattedDate = startDateTime ? dayjs(new Date(startDateTime).toISOString()).format('YYYY-MM-DDTHH:mm:ss.SSSZ') : '';
    const startDt = startDateTime ? dayjs(new Date(startDateTime).toISOString()).format('YYYY-MM-DDTHH:mm:ss.SSSZ') : '';
    // const endDt = arrivalDateTime ? dayjs(new Date(arrivalDateTime).toISOString()).format('YYYY-MM-DDTHH:mm:ss.SSSZ') : '';
    // const startDt =
    //   startDateTime != null
    //     ? startDateTime.toISOString()
    //     : new Date().toISOString();
    // const endDt =
    //   arrivalDateTime != null
    //     ? arrivalDateTime.toISOString()
    //     : new Date().toISOString();
    // const payload = { ...userInput, startDt, endDt, imageUrlList: JSON.stringify(imageURLS) }

    const showOnLandingPage = userInput?.showOnLandingPage === "true" ? true : false;
    const payload = { ...userInput, startDt,showOnLandingPage };
    setLoader(true);
    serviceProvider.flightService
      .post(payload)
      .then((res) => {
        // console.log({ res })
        setLoader(false);
        if (res.responseCode === 200) {
          toast.success(res.message);
          window.location.href="/empty-leg-management";
          // emptyInputFields()
          // setCabinSpecs()
        }else{
            toast.error(res?.message || "Something Went Wrong !!")
        }
      })
      .catch((err) => {
        toast.error(err?.data?.message || "Something Went Wrong !!");
        setLoader(false);
        //   emptyInputFields()
        console.log({ err });
      });
  };

  const getAllAirportsList = () => {
    setLoader(true);
    serviceProvider.airportService
      .getAllAirports()
      .then((res) => {
        // console.log({res})
        if (res.responseCode === 200) {
          setAirports(res.data);
        }
        setLoader(false);
      })
      .catch((err) => {
        console.log({ err });
        setLoader(false);
      });
  };
  const getAllAircraftsList = () => {
    setLoader(true);
    serviceProvider.aircraftService
      .getAllAircrafts("NA")
      .then((res) => {
        // console.log({res})
        setLoader(false);
        if (res.responseCode === 200) {
          setAircrafts(res.data);
        }
      })
      .catch((err) => {
        setLoader(false);
        console.log({ err });
      });
  };

  useEffect(() => {
    getAllAirportsList();
    getAllAircraftsList();
  }, []);

  if (loader) {
    return <SpinLoader />;
  }


  return (
    <Grid container className="single-aircraft-container">
      <Grid item xs={12}>
        <Grid container mb="10px">
          <Grid item xs={6}>
            <Box className="back-btn-container">
              <Tooltip title="back">
                <IconButton onClick={() => navigate(-1)}>
                  <img src={backArrow} alt="back" />
                </IconButton>
              </Tooltip>
              <Typography variant="h6" component="h6">
                Add Empty Leg
              </Typography>
            </Box>
          </Grid>
          {/* <Grid item xs={6}>
    <Box className="edit-heading-container">

 
<Typography variant="h6" component="h6" textAlign="right">Edit Details</Typography>
 <Button>
     <img src={editIcon} alt="edit icon" width="20px" />
 </Button>

</Box>
    </Grid> */}
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={3} className="aircraft-image-container">
          <Grid item xs={3}>
            <Card className="image-card">
            <div className="img-box">
              <img
                src={
                  userInput.imageUrlList ? userInput.imageUrlList : addImageIcon
                }
                className={`${userInput.imageUrlList ? "card-img" : "add-img-icon"}`}
                alt="icon"
                onClick={handleChooseFile}
              />
              </div>
              <input
                style={{ display: "none" }}
                ref={chooseFileRef}
                type="file"
                onChange={handleFileChange}
              />
              {/* <img src={image} alt="img" width={"100%"} /> */}
            </Card>
          </Grid>

          <Grid item xs={3}>
            {/* <ImageUploadComponent
                            chooseFileRef={chooseFileRef}
                            handleChooseFile={handleChooseFile}
                            handleFileChange={handleFileChange}
                            isMultiple={true}
                        /> */}
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Card className="aircraft-description-card">
          <Typography
            variant="body1"
            component="p"
            className="description-heading"
          >
            Headline
          </Typography>
          <InputFieldComponent
            isMultiline={true}
            minRows={1}
            name="headline"
            value={userInput.headline}
            onChange={(e: any) => handleInputChange("headline", e.target.value)}
          />
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card className="aircraft-description-card">
          <Typography
            variant="body1"
            component="p"
            className="description-heading"
          >
            Description
          </Typography>
          <InputFieldComponent
            isMultiline={true}
            minRows={4}
            name="description"
            value={userInput.description}
            onChange={(e: any) =>
              handleInputChange("description", e.target.value)
            }
          />
        </Card>
      </Grid>
      
                <Grid item xs={12}>
                <Card className="aircraft-description-card" >
                <Typography
              variant="body1"
              component="p"
              className="description-heading"
            >
              Show on landing page popup
            </Typography>
                  <FormControl fullWidth>
                    <Select
                      className={classes.input}
                      sx={{ padding: "0 10px" }}
                      value={userInput?.showOnLandingPage}
                      name="showOnLandingPage"
                      variant="standard"
                      disableUnderline
                      onChange={(e) => handleInputChange("showOnLandingPage", e.target.value)}
                    >
           
       <MenuItem value={"true"}>Yes</MenuItem>
       <MenuItem value={"false"}>No</MenuItem>
  


                      
                     
                    </Select>
                  </FormControl>
                  </Card>
                </Grid> 
              
      <Grid item xs={12}>
        <Grid container className="form">
          <Grid item xs={12} className="modal-table-container">
            <Grid container className="heading">
              <Grid item xs={12}>
                <Typography variant="h6" component="h6">
                  Flight Details
                </Typography>
              </Grid>
            </Grid>
            <Grid container className="edit-detail-item">
              <Grid item xs={3} className="edit-item dark-bg">
                <Typography variant="body1" component="p">
                  Aircraft
                </Typography>
              </Grid>
              <Grid item xs={9} className="edit-item">
                {/* <SelectFieldComponent
                                    name="aircraftId"
                                    value={userInput.aircraftId}
                                    options={aircrafts}
                                    onChange={(e:any) => handleInputChange(e, e.target.value)}
                                    labelKey="name"
                                    optionKey="aircraftId"
                                /> */}

                <AutoCompleteComponent
                  options={aircrafts}
                  position="end"
                  // getOptionLabel={(option: any) => `${option.name} (${option.icaoCode})`}
                  getOptionLabel={(option: IAircraft) => `${option.name}`}
                  renderOption={(props: any, option: any) => (
                    <Box
                      sx={{
                        width: "100%",
                        borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
                      }}
                      {...props}
                    >
                      {/* <Typography textAlign="left">{option.city}</Typography> */}
                      <Typography textAlign="left">{option.name}</Typography>
                    </Box>
                  )}
                  onChange={(e: any, newValue: any) =>
                    handleInputChange("aircraftId", newValue?.aircraftId)
                  }
                  placeholder="Select Aircraft"
                  // isIconVisible={true}
                >
                  {/* <img src={"destinationIcon"} alt="icon" height="25px" width="25px" /> */}
                </AutoCompleteComponent>
              </Grid>
            </Grid>
            <Grid container className="edit-detail-item">
              <Grid item xs={3} className="edit-item dark-bg">
                <Typography variant="body1" component="p">
                  Start Airport
                </Typography>
              </Grid>
              <Grid item xs={9} className="edit-item">
                {/* <SelectFieldComponent
                  name="startAirportId"
                  value={userInput.startAirportId}
                  options={airports}
                  onChange={(e: any) => handleInputChange(e, e.target.value)}
                  labelKey="name"
                  optionKey="airportId"
                /> */}

                <AutoCompleteComponent
                  options={airports}
                  getOptionLabel={(option: IAirport) =>
                    `${option.name} (${option.icaoCode}) - ${option.city}`
                  }
                  renderOption={(props: any, option: any) => (
                    <Box
                      sx={{
                        width: "100%",
                        borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
                        display:"flex",
                        flexDirection:"column",
                        justifyContent:"flex-start",
                        alignItems:"flex-start",
                      }}
                      {...props}
                    >
                      <Typography textAlign="left">{option.city}</Typography>
                      <Typography textAlign="left">
                        {option.name} ({option.icaoCode})
                      </Typography>
                    </Box>
                  )}
                  position="end"
                  onChange={(e: any, newValue: any) =>
                    handleInputChange("startAirportId", newValue?.airportId)
                  }
                  placeholder="Select Start Airport"
                  // isIconVisible={true}
                >
                  {/* <img src={"destinationIcon"} alt="destination-icon" height="25px" width="25px" /> */}
                </AutoCompleteComponent>
              </Grid>
            </Grid>
            <Grid container className="edit-detail-item">
              <Grid item xs={3} className="edit-item dark-bg">
                <Typography variant="body1" component="p">
                  End Airport
                </Typography>
              </Grid>
              <Grid item xs={9} className="edit-item">
                {/* <SelectFieldComponent
                  name="endAirportId"
                  value={userInput.endAirportId}
                  options={airports}
                  onChange={(e: any) => handleInputChange(e, e.target.value)}
                  labelKey="name"
                  optionKey="airportId"
                /> */}

                <AutoCompleteComponent
                  options={airports}
                  position="end"
                  getOptionLabel={(option: IAirport) =>
                    `${option.name} (${option.icaoCode}) - ${option.city}`
                  }
                  renderOption={(props: any, option: any) => (
                    <Box
                      sx={{
                        width: "100%",
                        display:"flex",
                        flexDirection:"column",
                        justifyContent:"flex-start",
                        alignItems:"flex-start",
                        borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
                      }}
                      {...props}
                    >
                      <Typography textAlign="left">{option.city}</Typography>
                      <Typography textAlign="left">
                        {option.name} ({option.icaoCode})
                      </Typography>
                    </Box>
                  )}
                  onChange={(e: any, newValue: any) =>
                    handleInputChange("endAirportId", newValue?.airportId)
                  }
                  placeholder="Select End Airport"
                  // isIconVisible={true}
                >
                  {/* <img src={destinationIcon} alt="destination-icon" height="25px" width="25px" /> */}
                </AutoCompleteComponent>
              </Grid>
            </Grid>
            <Grid container className="edit-detail-item">
              <Grid item xs={3} className="edit-item dark-bg">
                <Typography variant="body1" component="p">
                  Start date and time
                </Typography>
              </Grid>
              <Grid item xs={9} className="edit-item">
                <DateTimePickerComponent
                  value={startDateTime}
                  onChange={handleStartDateTime}
                />
              </Grid>
            </Grid>
            {/* <Grid container className="edit-detail-item">
                            <Grid item xs={3} className='edit-item dark-bg'>
                                <Typography variant='body1' component='p'>
                                    Arrival date and time
                                </Typography>
                            </Grid>
                            <Grid item xs={9} className='edit-item'>
                                <DateTimePickerComponent
                                    value={arrivalDateTime}
                                    onChange={handleArrivalDateTime}
                                />
                            </Grid>
                        </Grid> */}
            <Grid container className="edit-detail-item">
              <Grid item xs={3} className="edit-item dark-bg">
                <Typography variant="body1" component="p">
                  Passenger Capacity
                </Typography>
              </Grid>
              <Grid item xs={9} className="edit-item">
                <InputFieldComponent
                  name="passengerCapacity"
                  type="number"
                  value={userInput.passengerCapacity}
                  onChange={(e: any) => handleInputChange("passengerCapacity", e.target.value)}
                />
              </Grid>
            </Grid>
            <Grid container className="edit-detail-item">
              <Grid item xs={3} className="edit-item dark-bg">
                <Typography variant="body1" component="p">
                  Duration
                </Typography>
              </Grid>
              <Grid item xs={9} className="edit-item">
                <InputFieldComponent
                  name="duration"
                  type="number"
                  value={userInput.duration}
                  onChange={(e: any) => handleInputChange("duration", e.target.value)}
                />
              </Grid>
            </Grid>
            <Grid container className="edit-detail-item">
              <Grid item xs={3} className="edit-item dark-bg">
                <Typography variant="body1" component="p">
                  Price (excluding gst)
                </Typography>
              </Grid>
              <Grid item xs={9} className="edit-item">
                <InputFieldComponent
                  name="price"
                  type="number"
                  value={userInput.price}
                  onChange={(e: any) => handleInputChange("price", e.target.value)}
                />
              </Grid>
            </Grid>
            <Grid container className="edit-detail-item">
              <Grid item xs={3} className="edit-item dark-bg">
                <Typography variant="body1" component="p">
                  Old Price (excluding gst)
                </Typography>
              </Grid>
              <Grid item xs={9} className="edit-item">
                <InputFieldComponent
                  name="oldPrice"
                  type="number"
                  value={userInput.oldPrice}
                  onChange={(e: any) => handleInputChange("oldPrice", e.target.value)}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} className="btn-container">
        {/* variant, className, onClick, label  */}
        <ButtonComponent
          fullWidth={false}
          variant="contained"
          className="rounded-btn-fill"
          label="Save"
          onClick={handleAddOfferedFlight}
        />
      </Grid>
    </Grid>
  );
};

export default AddEmptyLegFlight;
