// import React, { useEffect, useState } from 'react'
// import { Typography, Grid, Button, TextField, InputAdornment, Box, Tabs, Tab, FormControlLabel, Checkbox } from "@mui/material";
// import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
// // import AirportTable from './AirportTable';
// import { serviceProvider } from '../../provider/serviceProvider';
// import Loader from '../loader/Loader';
// import CommonModal from '../modal/CommonModal';
// // import AddAirportForm from './AddAirportForm';
// // import FilterSection from './FilterSection';
// import SearchBar from '../searchBar/SearchBar';
// import { toast } from 'react-toastify';
// import OperatorTable from './OperatorTable';
// import { CustomTabPanel, a11yProps } from '../Tab/CustomTabPanel';
// import AircraftAvailabiltyCard from './AircraftAvailabiltyCard';
// import OperatorRequestCard from './OperatorRequestCard';
// import CommonPagination from '../common/CommonPagination';
// import NoResult from '../noResult/NoResult';


// // function valuetext(value: number) {
// //   return `${value}°C`;
// // }



// // const useStyles = makeStyles((theme) => ({
// //   input: {
// //     // border:"1px solid red",
// //     height: "100%",
// //     color: 'inherit', // Text color
// //     width: '100%',
// //     backgroundColor: 'transparent', // Remove background
// //     fontSize: '16px', // Set font size
// //     padding: '0', // Set padding
// //     '&:focus': {
// //       outline: 'none', // Remove outline on focus
// //     },
// //   },
// // }));

// // const initalUserInput = {
// //   active: true,
// //   airportId: "",
// //   city: "",
// //   contactDetails: "",
// //   governingBody: "",
// //   iataCode: "",
// //   icaoCode: "",
// //   imageUrlList: "",
// //   latitude: "",
// //   longitude: "",
// //   name: "",
// //   nightLanding: true,
// //   operatingEndTime: "",
// //   operatingStartTime: "",
// //   otherInformation: "",
// //   permanentNotoms: "",
// //   recordCreatedBy: "",
// //   recordCreatedTs: "",
// //   recordUpdatedBy: "",
// //   recordUpdatedTs: "",
// //   restrictedAircraftsList: "",
// //   runwayLength: "",
// //   state: "",
// //   timezone: ""
// // }
// const OperatorManagement: React.FC = () => {
//   const [loader, setLoader] = React.useState<boolean>(false);
//   const [open, setOpen] = useState(false);
//   const [value, setValue] = React.useState(0);
//   const [aircraftAvailabilityLists, setAircraftAvailabilityLists] = useState([]);
//   const [operatorRequestsLists, setOperatorRequestsLists] = useState([]);
//   const [page, setPage] = useState(0);
//   const [totalPages, setTotalPages] = useState(0);
//   const [pageState, setPageState] = React.useState({
//     loader: false,
//     data: [],
//     totalPages: 0,
//     page: 0,
//     pageSize: 5,

//   });


//   const handleChange = (event: React.SyntheticEvent, newValue: number) => {
//     console.log(event)
//     setValue(newValue);
//   };



//   const handleOpenModal = () => setOpen(true)
//   const handleCloseModal = () => setOpen(false);

//   // const [value, setValue] = React.useState<number[]>([20, 37]);
//   // const [runwayValue,setRunwayValue]= React.useState<number | null>(null);
//   // const minDistance = 10;


//   const handleSearchAircraftAvailability =(e:any)=>{
//     const searchQuery = e.target.value
//     const param =`search?operatorUid=all&fullName=${searchQuery}&isActive=NA&offset=${page}&limit=2&order=updatedTs.desc&profile=BASIC`
//     setLoader(true);
//     serviceProvider.aircraftAvailabilityService.get(param).then((res:any)=>{
//       // console.log(res);
//       if (res.responseCode === 200) {
//         setAircraftAvailabilityLists(res.data.data)
//       }else{
//         toast.error("Something Went Wrong!!")
//       }
//       setLoader(false)

//     }).catch((err:any)=>{
//       setLoader(false)
//     })
//   }

//   const handleSearchOperator = (event: React.ChangeEvent<HTMLInputElement>) => {
//     const searchQuery = event.target.value;
//     // setValue(et as number[]); 
//     // const query = `airportName=${searchQuery}&city=all&state=all&runwayLength=all&operatingStartTime=all&operatingEndTime=all&aircraftName=all&offset=${pageState.page}&limit=${pageState.pageSize}&order=updatedTs.desc&profile=BASIC`

//     const query = `search?roleId=eaf1aa60-fee7-4e32-9afc-e04fc8f61c48&uId=NA&email=all&fullName=${searchQuery}&isActive=NA&offset=${pageState.page}&limit=${pageState.pageSize}&order=recordUpdatedts.desc&profile=BASIC`
//     // setLoader(true)
//     setPageState(prev=>({...prev,loader:true}))
//     serviceProvider.userService.get(query).then((res) => {
//       if (res.responseCode === 200) {
//         // setAllAirports(res.data)
//         setPageState(prev=>({...prev,loader:false,data:res.data.details,totalPages:res.data.totalRecordCount}))
//       } else {
//         toast.error("Something Went Wrong !!")
//       }
//       // setLoader(false)
//       // setPageState(prev=>({...prev,loader:false,data:res.data,totalPages:res.totalRecordCount}))
//       // setAllAirports(res.data)

//     }).catch((err) => {
//       console.log({ err })
//       setPageState(prev=>({...prev,loader:false}))
//       // setLoader(false)
//     })
//   }






//   const getAllOperators = () => {
//     // setLoader(true);
//     setPageState(prev=>({...prev,loader:true}))
//     const query = `search?roleId=eaf1aa60-fee7-4e32-9afc-e04fc8f61c48&uId=NA&email=all&fullName=all&isActive=NA&offset=${pageState.page}&limit=${pageState.pageSize}&order=recordUpdatedts.desc&profile=BASIC`
//     serviceProvider.userService.get(query).then((res) => {
//       // setLoader(false)
//       // setPageState(prev=>({...prev,loader:false,data:res.data,totalPages:res.totalRecordCount}))
//       if (res.responseCode === 200) {
//         // setAllAirports(res.data)
//         setPageState(prev=>({...prev,loader:false,data:res.data.details,totalPages:res.data.totalRecordCount}))
//       } else {
//         toast.error("Something Went Wrong !!")
//       }
//       setPageState(prev=>({...prev,loader:false}))
//       // console.log({ res })
//     }).catch((err) => {
//       // setLoader(false)
//       setPageState(prev=>({...prev,loader:false}))
//       toast.error(err?.data?.message || "Something Went Wrong !!")
//       // console.log({ err })
//     })
//   }

//   // operatorrequest/search?operatorUid=all&query=all&status=all&offset=0&limit=10&order=updatedTs.desc&profile=BASIC


//   const getAircraftAvailabilityList=()=>{
//     const param =`search?operatorUid=all&fullName=all&isActive=NA&offset=${page}&limit=2&order=updatedTs.desc&profile=BASIC`
//     setLoader(true);
//     serviceProvider.aircraftAvailabilityService.get(param).then((res:any)=>{
//       // console.log(res);
//       if (res.responseCode === 200) {
//         setAircraftAvailabilityLists(res.data.data)
//         setTotalPages(res.data.totalRecordCount)
//       }else{
//         toast.error("Something Went Wrong!!")
//       }
//       setLoader(false)

//     }).catch((err:any)=>{
//       setLoader(false)
//     })
//   }
//   const getOperatorRequestsList=()=>{
//     const param =`search?operatorUid=all&query=all&status=all&offset=${page}&limit=2&order=updatedTs.desc&profile=BASIC`
//     setLoader(true);
//     serviceProvider.operatorRequestService.get(param).then((res:any)=>{
//       // console.log(res);
//       if (res.responseCode === 200) {
//         setOperatorRequestsLists(res.data.data)
//         setTotalPages(res.data.totalRecordCount)
//       }else{
//         toast.error("Something Went Wrong!!")
//       }
//       setLoader(false)

//     }).catch((err:any)=>{
//       setLoader(false)
//     })
//   }
//   // const handleSearch =(e:any)=>{
//   //   const searchQuery = e.target.value
//   //   const param =`search?operatorUid=all&fullName=all&isActive=NA&offset=${page}&limit=3&order=updatedTs.desc&profile=BASIC`
//   //   setLoader(true);
//   //   serviceProvider.aircraftAvailabilityService.get(param).then((res:any)=>{
//   //     // console.log(res);
//   //     if (res.responseCode === 200) {
//   //       setAircraftAvailabilityLists(res.data.data)
//   //     }else{
//   //       toast.error("Something Went Wrong!!")
//   //     }
//   //     setLoader(false)

//   //   }).catch((err:any)=>{
//   //     setLoader(false)
//   //   })
//   // }

//   useEffect(() => {
//     getAllOperators();

//   }, [pageState.page,pageState.pageSize])


// useEffect(()=>{
//  if(value===1)  getAircraftAvailabilityList()
//  else if(value===2) getOperatorRequestsList()
// },[page,value])




// //   const modalBody = <AddAirportForm handleCloseModal={handleCloseModal} />;


//   return (
//     <>
//       {/* <CommonModal open={open} handleCloseModal={handleCloseModal} modalBody={modalBody} /> */}
//       <Grid container spacing={2} className="operator-container">
//         <Grid item xs={12}>
//           <Grid container>
//             <Grid item xs={5} md={5} className="operator-heading">
//               {/* <Typography variant='h6' component='h6'>List of Airports</Typography> */}
//             </Grid>
//             <Grid item xs={7} md={7}>
//               <SearchBar onChange={ value === 0 ?handleSearchOperator : value === 1 ? handleSearchAircraftAvailability : null} placeholder={`Search by ${value === 0 ? "operator name" : value === 1 ? "aircraft name":"operator name, aircraft name"}`} />
//             </Grid>
//           </Grid>
//         </Grid>
//         {/* <Grid item xs={12}>
//           <Button variant="contained" className="rounded-btn-fill" onClick={handleOpenModal}>Add Airport</Button>

//         </Grid> */}

//         {/* <Grid item xs={12}>
//           <FilterSection  />
//         </Grid> */}

// <Grid item xs={12} className="tabs-container">
//         <Box sx={{ width: '100%' }}>
//           <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
//             <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
//               <Tab label="List of Operators" {...a11yProps(0)} />
//               <Tab label="List of Aircrafts Availability" {...a11yProps(1)} />
//               <Tab label="Request of Operators" {...a11yProps(2)} />
//             </Tabs>
//           </Box>
//           <CustomTabPanel value={value} index={0}>
//           <OperatorTable pageState={pageState} setPageState={setPageState} />
//           </CustomTabPanel>
//           <CustomTabPanel value={value} index={1}>
//           <Box  display="flex" alignItems="center" justifyContent="flex-end" mb="20px">
//                 <FormControlLabel
//                     value="Yes"
//                     control={<Checkbox  size="small" />}
//                     label="Available Aircraft"
//                     labelPlacement="start"
//                 />

//             </Box>
//          <Grid rowSpacing={3} container>

//           {/* {
//             aircraftAvailabilityLists?.length>=1 && aircraftAvailabilityLists?.map((aircraft:any)=>{
//               return   <Grid item xs={12} key={aircraft?.availabilityId}>
//               <AircraftAvailabiltyCard aircraft={aircraft}/>
//               </Grid>
//             })
//           }  */}


//            {
//                 loader ?
//                 new Array(3).fill(0).map((_, id) => {
//                   return <Grid item xs={12} key={id}>
//                     <Loader height='250px' width='100%' />
//                   </Grid>
//                 }) : aircraftAvailabilityLists.length >=1 && loader === false ?  aircraftAvailabilityLists.map((aircraft: any) => {
//                     return <Grid key={aircraft?.aircraftId} item xs={12}>
//                         <AircraftAvailabiltyCard aircraft={aircraft}/>
//                     </Grid>
//                 }) : 
//                 <Grid item xs={12}>
//                     <NoResult title={"Aircrafts Availability."}/>
//                 </Grid>
//             } 
//          </Grid>
//           </CustomTabPanel>
//           <CustomTabPanel value={value} index={2}>
//           <Box  display="flex" alignItems="center" justifyContent="flex-end" mb="20px">
//                 <FormControlLabel
//                     value="No"
//                     control={<Checkbox  size="small" />}
//                     label="Not Approved"
//                     labelPlacement="start"
//                 />
//                 <FormControlLabel
//                     value="Yes"
//                     control={<Checkbox  size="small" />}
//                     label="Approved"
//                     labelPlacement="start"
//                 />

//             </Box>
//           <Grid rowSpacing={3} container>



//           {
//                 loader ?
//                 new Array(3).fill(0).map((_, id) => {
//                   return <Grid item xs={12} key={id}>
//                     <Loader height='250px' width='100%' />
//                   </Grid>
//                 }) : operatorRequestsLists.length >=1 && loader === false ?  operatorRequestsLists.map((operatorRequest: any) => {
//                     return <Grid key={operatorRequest?.operatorAdminRequestId} item xs={12}>

//           <OperatorRequestCard operatorRequest={operatorRequest}/>
//                     </Grid>
//                 }) : 
//                 <Grid item xs={12}>
//                     <NoResult title={"Operator Request."}/>
//                 </Grid>
//             } 
//           {/* <Grid item xs={12}>
//           <OperatorRequestCard/>
//           </Grid>
//           <Grid item xs={12}>
//           <OperatorRequestCard/>
//           </Grid> */}


//          </Grid>
//           </CustomTabPanel>


//         </Box>

//        {
//         value !== 0 &&  <Box className="pagination-box">
//         <CommonPagination page={page} setPage={setPage} totalPages={totalPages} />
//       </Box>
//        }

//       </Grid>

//         {/* <Grid item xs={12}>

//           <OperatorTable pageState={pageState} setPageState={setPageState} />
//         </Grid> */}

//       </Grid>
//     </>
//   )
// }

// export default OperatorManagement;








import React, { useEffect, useState } from 'react'
import { Typography, Grid, Button, TextField, InputAdornment, Box, Tabs, Tab, FormControlLabel, Checkbox } from "@mui/material";
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
// import AirportTable from './AirportTable';
import { serviceProvider } from '../../provider/serviceProvider';
import Loader from '../loader/Loader';
import CommonModal from '../modal/CommonModal';
// import AddAirportForm from './AddAirportForm';
// import FilterSection from './FilterSection';
import SearchBar from '../searchBar/SearchBar';
import { toast } from 'react-toastify';
import OperatorTable from './OperatorTable';
import { CustomTabPanel, a11yProps } from '../Tab/CustomTabPanel';
import AircraftAvailabiltyCard from './AircraftAvailabiltyCard';
import OperatorRequestCard from './OperatorRequestCard';
import CommonPagination from '../common/CommonPagination';
import NoResult from '../noResult/NoResult';
import AircraftsAvailability from './AircraftsAvailability';
import OperatorRequestList from './OperatorRequestList';
import AssignedAircrafts from './AssignedAircrafts';
import { useLocation, useNavigate } from 'react-router-dom';


// function valuetext(value: number) {
//   return `${value}°C`;
// }



// const useStyles = makeStyles((theme) => ({
//   input: {
//     // border:"1px solid red",
//     height: "100%",
//     color: 'inherit', // Text color
//     width: '100%',
//     backgroundColor: 'transparent', // Remove background
//     fontSize: '16px', // Set font size
//     padding: '0', // Set padding
//     '&:focus': {
//       outline: 'none', // Remove outline on focus
//     },
//   },
// }));

// const initalUserInput = {
//   active: true,
//   airportId: "",
//   city: "",
//   contactDetails: "",
//   governingBody: "",
//   iataCode: "",
//   icaoCode: "",
//   imageUrlList: "",
//   latitude: "",
//   longitude: "",
//   name: "",
//   nightLanding: true,
//   operatingEndTime: "",
//   operatingStartTime: "",
//   otherInformation: "",
//   permanentNotoms: "",
//   recordCreatedBy: "",
//   recordCreatedTs: "",
//   recordUpdatedBy: "",
//   recordUpdatedTs: "",
//   restrictedAircraftsList: "",
//   runwayLength: "",
//   state: "",
//   timezone: ""
// }
const OperatorManagement: React.FC = () => {
  const [loader, setLoader] = React.useState<boolean>(false);
  const [open, setOpen] = useState(false);
  const [value, setValue] = React.useState(0);
  // const [aircraftAvailabilityLists, setAircraftAvailabilityLists] = useState([]);
  // const [operatorRequestsLists, setOperatorRequestsLists] = useState([]);
  // const [page, setPage] = useState(0);
  // const [totalPages, setTotalPages] = useState(0);
 const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const index = searchParams.get('index');

  // Convert the 'index' value to a number if needed
  const indexNumber = parseInt(index + "", 10);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    console.log(event)
    setValue(newValue);
    navigate(`/operator-management?index=${newValue}`)
  };



  const handleOpenModal = () => setOpen(true)
  const handleCloseModal = () => setOpen(false);

  // const [value, setValue] = React.useState<number[]>([20, 37]);
  // const [runwayValue,setRunwayValue]= React.useState<number | null>(null);
  // const minDistance = 10;


 










  // operatorrequest/search?operatorUid=all&query=all&status=all&offset=0&limit=10&order=updatedTs.desc&profile=BASIC


  // const getAircraftAvailabilityList=()=>{
  //   const param =`search?operatorUid=all&fullName=all&isActive=NA&offset=${page}&limit=2&order=updatedTs.desc&profile=BASIC`
  //   setLoader(true);
  //   serviceProvider.aircraftAvailabilityService.get(param).then((res:any)=>{
  //     // console.log(res);
  //     if (res.responseCode === 200) {
  //       setAircraftAvailabilityLists(res.data.data)
  //       setTotalPages(res.data.totalRecordCount)
  //     }else{
  //       toast.error("Something Went Wrong!!")
  //     }
  //     setLoader(false)

  //   }).catch((err:any)=>{
  //     setLoader(false)
  //   })
  // }
  // const getOperatorRequestsList=()=>{
  //   const param =`search?operatorUid=all&query=all&status=all&offset=${page}&limit=2&order=updatedTs.desc&profile=BASIC`
  //   setLoader(true);
  //   serviceProvider.operatorRequestService.get(param).then((res:any)=>{
  //     // console.log(res);
  //     if (res.responseCode === 200) {
  //       setOperatorRequestsLists(res.data.data)
  //       setTotalPages(res.data.totalRecordCount)
  //     }else{
  //       toast.error("Something Went Wrong!!")
  //     }
  //     setLoader(false)

  //   }).catch((err:any)=>{
  //     setLoader(false)
  //   })
  // }
  // const handleSearch =(e:any)=>{
  //   const searchQuery = e.target.value
  //   const param =`search?operatorUid=all&fullName=all&isActive=NA&offset=${page}&limit=3&order=updatedTs.desc&profile=BASIC`
  //   setLoader(true);
  //   serviceProvider.aircraftAvailabilityService.get(param).then((res:any)=>{
  //     // console.log(res);
  //     if (res.responseCode === 200) {
  //       setAircraftAvailabilityLists(res.data.data)
  //     }else{
  //       toast.error("Something Went Wrong!!")
  //     }
  //     setLoader(false)

  //   }).catch((err:any)=>{
  //     setLoader(false)
  //   })
  // }




  // useEffect(()=>{
  //  if(value===1)  getAircraftAvailabilityList()
  //  else if(value===2) getOperatorRequestsList()
  // },[page,value])




  //   const modalBody = <AddAirportForm handleCloseModal={handleCloseModal} />;
  useEffect(()=>{
    if(indexNumber){
      setValue(indexNumber)
    }else{
      setValue(0)
    }
  },[indexNumber])

  return (
    <>
      {/* <CommonModal open={open} handleCloseModal={handleCloseModal} modalBody={modalBody} /> */}
      <Grid container spacing={2} className="operator-container">
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={12}  className="operator-heading">
              {/* <Typography variant='h6' component='h6'>List of Airports</Typography> */}
            </Grid>
            {/* <Grid item xs={7} md={7}>
              <SearchBar onChange={value === 0 ? handleSearchOperator : value === 1 ? null : null} placeholder={`Search by ${value === 0 ? "operator name" : value === 1 ? "aircraft name" : "operator name, aircraft name"}`} />
            </Grid> */}
          </Grid>
        </Grid>
      

        <Grid item xs={12} className="tabs-container">
          <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                <Tab label="List of Operators" {...a11yProps(0)} />
                <Tab label="List of Aircrafts Availability" {...a11yProps(1)} />
                <Tab label="List of Assigned Aircrafts" {...a11yProps(2)} />
                {/* <Tab label="Request of Operators" {...a11yProps(2)} /> */}
              </Tabs>
            </Box>
            <CustomTabPanel value={value} index={0}>
              <OperatorTable  />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
              <AircraftsAvailability />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={2}>
              <AssignedAircrafts />
            </CustomTabPanel>
            {/* <CustomTabPanel value={value} index={2}>
              <OperatorRequestList />
            </CustomTabPanel> */}


          </Box>




        </Grid>

        {/* <Grid item xs={12}>
            
          <OperatorTable pageState={pageState} setPageState={setPageState} />
        </Grid> */}

      </Grid>
    </>
  )
}

export default OperatorManagement;