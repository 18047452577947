import { Box, Button, Card, Grid, IconButton, Tooltip, Typography } from '@mui/material';
import editIcon from "../../assets/icons/edit-icon.svg";
import ItineraryBookingDetails from './ItineraryBookingDetails';
import UserBookingDetails from './UserBookingDetails';
import { useEffect, useState } from 'react';
import { serviceProvider } from '../../provider/serviceProvider';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import PricingDetails from './PricingDetails';
import SpinLoader from '../loader/SpinLoader';
import backArrow from "../../assets/icons/back-arrow.svg";


const SingleCompleteBooking: React.FC = () => {
  const [bookingDetails, setBookingDetails] = useState<any>({});
  const [loader, setLoader] = useState<boolean>(false);

  const navigate = useNavigate();
  const {bookingId}=useParams();

  


  let parsedOrderOfCities;

  parsedOrderOfCities =
    bookingDetails &&
    bookingDetails?.itineraryDetails &&
    bookingDetails?.itineraryDetails?.orderOfCities &&
    JSON.parse(bookingDetails?.itineraryDetails?.orderOfCities);

  // console.log({ parsedOrderOfCities });

  const orderOfCitiesArr: any =
    parsedOrderOfCities !== undefined &&
    parsedOrderOfCities !== null &&
    Object.values(parsedOrderOfCities);



  const getBookingDetails = (bookingId: any) => {
    setLoader(true)
    serviceProvider.bookingService.get(bookingId).then((res: any) => {
      if (res.responseCode === 200) {
        setBookingDetails(res.data)
      }
      setLoader(false)
    }).catch((err) => {
      console.log({ err })
      setLoader(false)
    })
  }
  useEffect(() => {
    getBookingDetails(bookingId)
  }, [bookingId]);

  if(loader){
    return  <SpinLoader/>
   }

  return (
   <Grid container columnSpacing={4}>
    <Grid item xs={12}>
          <Box mb="10px" className="back-btn-container">
            <Tooltip title="back">
              <IconButton onClick={() => navigate(-1)}>
                <img src={backArrow} alt="back" />
              </IconButton>
            </Tooltip>
          </Box>
        </Grid>
    <Grid item xs={12} md={8}>
      <Grid container>
        <Grid item xs={12}>
          <UserBookingDetails bookingDetails={bookingDetails}/>
        </Grid>
        <Grid item xs={12}>
          <Grid container rowSpacing={3} className='itinerary-details-container'>
            <Grid item xs={12}>
              <Typography variant="h6" component="h6">
              Itinerary details
              </Typography>
              
            </Grid>
            {/* <Grid item xs={3}>
              <Box className="edit-container">
              <Typography variant="h6" component="h6" textAlign="right">
              Edit Itinerary
              </Typography>
              <Button>
              <img src={editIcon} alt="edit icon" height="20px" width="20px" />
              </Button>
              </Box>
            </Grid> */}
            {/* {bookingDetails?.itineraryDetails?.itineraryType !==
                  "MULTICITY" && (
                  <Grid item xs={12}>
                    <ItineraryBookingDetails
                      itineraryDetails={bookingDetails?.itineraryDetails}
                    />
                  </Grid>
                )} */}
                {/* {bookingDetails?.itineraryDetails?.itineraryType ===
                  "MULTICITY" &&
                  orderOfCitiesArr?.length &&
                  orderOfCitiesArr?.map((city: any) => {
                    const startAirport =
                      city?.startAirportDetails &&
                      JSON.parse(city?.startAirportDetails);
                    const endAirport =
                      city?.endAirportDetails &&
                      JSON.parse(city?.endAirportDetails);
                    const updatedCity = { ...city, startAirport, endAirport };

                    return (
                      <Grid item xs={12}>
                        <ItineraryBookingDetails
                          itineraryDetails={updatedCity}
                        />
                      </Grid>
                    );
                  })} */}

{bookingDetails?.pathDetails?.itineraryPathDetails?.length >= 1 &&
                bookingDetails?.pathDetails?.itineraryPathDetails
                  .filter(
                    (itineraryPath: any) =>
                      itineraryPath?.flightType === "SEARCHED"
                  )
                  .map((filteredItineraryPath: any) => (
                    <Grid item xs={12} key={filteredItineraryPath?.itineraryId}>
                      <ItineraryBookingDetails
                        itineraryDetails={filteredItineraryPath}
                      />
                    </Grid>
                  ))}
          </Grid>
        </Grid>
        {/* <Grid item xs={12}>
          <ItineraryBookingDetails bookingDetails={bookingDetails}/>
        </Grid> */}


      </Grid>
    </Grid>
    <Grid item xs={12} md={4}>
      <PricingDetails bookingDetails={bookingDetails}/>
    </Grid>
   </Grid>
  )
}

export default SingleCompleteBooking;