import React, { useState } from "react";
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Card, Grid, InputBase } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { log } from "console";




const useStyles = makeStyles((theme) => ({
  input: {
    // border:"1px solid red",
    height: "100%",
    color: 'inherit', // Text color
    width: '100%',
    backgroundColor: 'transparent', // Remove background
    fontSize: '16px', // Set font size
    padding: '0', // Set padding
    '&:focus': {
      outline: 'none', // Remove outline on focus
    },
  },
}));


const TechnicalSpecs = ({ technicalSpecs }: any) => {
  // console.log({aircraft})
  // const parsedCabinSpecs =technicalSpecs && JSON.parse(technicalSpecs);
  const classes = useStyles()
  // console.log("user input",userInput)
  return (
    <>
      <Grid container className="aircraft-details-container">
        <Grid item xs={12} className="details-table-container">
          <Grid container className="detail-item">

            <Grid item xs={3} className='item'>
              <Typography variant='body1' component='p'>
                Exterior
              </Typography>
            </Grid>
            <Grid item xs={9} className='item'>
              <Grid container>
                <Grid item xs={4} >
                  <Typography variant='body1' component='p'>
                    {technicalSpecs?.exteriorLength || "--"}
                  </Typography>
                  <span className="label">Length</span>
                </Grid>
                <Grid item xs={4}>
                  <Typography variant='body1' component='p'>
                    {technicalSpecs?.exteriorWingspan || "--"}
                  </Typography>
                  <span className="label">Wingspan</span>
                </Grid>
                <Grid item xs={4}>
                  <Typography variant='body1' component='p'>
                    {technicalSpecs?.exteriorHeight || "--"}
                  </Typography>
                  <span className="label">Height</span>
                </Grid>
              </Grid>

            </Grid>
          </Grid>
          <Grid container className="detail-item">
            <Grid item xs={3} className='item'>
              <Typography variant='body1' component='p'>
                Range
              </Typography>
            </Grid>
            <Grid item xs={9} className='item'>
              <Typography variant='body1' component='p'>
                {technicalSpecs?.range || "--"}
              </Typography>
            </Grid>
          </Grid>
          <Grid container className="detail-item">
            <Grid item xs={3} className='item'>
              <Typography variant='body1' component='p'>
                Speed
              </Typography>
            </Grid>
            <Grid item xs={9} className='item'>
              <Grid container>
                <Grid item xs={4}>
                  <Typography variant='body1' component='p'>
                    {technicalSpecs?.highSpeed || "--"}
                  </Typography>
                  <span className="label">High speed</span>
                </Grid>
                <Grid item xs={4}>
                  <Typography variant='body1' component='p'>
                    {technicalSpecs?.typicalCruiseSpeed || "--"}
                  </Typography>
                  <span className="label">Typical cruise speed</span>
                </Grid>

              </Grid>

            </Grid>
          </Grid>
          <Grid container className="detail-item">
            <Grid item xs={3} className='item'>
              <Typography variant='body1' component='p'>
                Engines
              </Typography>
            </Grid>
            <Grid item xs={9} className='item'>
              <Grid container>
                <Grid item xs={4}>
                  <Typography variant='body1' component='p'>
                    {technicalSpecs?.engineModel || "--"}
                  </Typography>
                  <span className="label">Engine model</span>
                </Grid>
                <Grid item xs={4}>
                  <Typography variant='body1' component='p'>
                    {technicalSpecs?.thrust || "--"}
                  </Typography>
                  <span className="label">Thrust</span>
                </Grid>
                <Grid item xs={4}>
                  <Typography variant='body1' component='p'>
                    {technicalSpecs?.flatRelatedTo || "--"}
                  </Typography>
                  <span className="label">Flat related to</span>
                </Grid>
              </Grid>

            </Grid>
          </Grid>
          <Grid container className="detail-item">
            <Grid item xs={3} className='item'>
              <Typography variant='body1' component='p'>
                Airfield performance
              </Typography>
            </Grid>
            <Grid item xs={9} className='item'>
              <Grid container>
                <Grid item xs={4}>
                  <Typography variant='body1' component='p'>
                    {technicalSpecs?.takeOffDistance || "--"}
                  </Typography>
                  <span className="label">Take-off distance</span>

                </Grid>
                <Grid item xs={4}>
                  <Typography variant='body1' component='p'>
                    {technicalSpecs?.landingDistance || "--"}
                  </Typography>
                  <span className="label">Landing distance</span>
                </Grid>

              </Grid>

            </Grid>
          </Grid>
          <Grid container className="detail-item">
            <Grid item xs={3} className='item'>
              <Typography variant='body1' component='p'>
                Avionics
              </Typography>
            </Grid>
            <Grid item xs={9} className='item'>
              <Typography variant='body1' component='p'>
                {technicalSpecs?.avionics || "--"}
              </Typography>

            </Grid>
          </Grid>
          <Grid container className="detail-item">
            <Grid item xs={3} className='item'>
              <Typography variant='body1' component='p'>
                Operating altitude
              </Typography>
            </Grid>
            <Grid item xs={9} className='item'>
              <Grid container>
                <Grid item xs={4}>
                  <Typography variant='body1' component='p'>
                    {technicalSpecs?.maximumOperationAlt || "--"}
                  </Typography>
                  <span className="label">Maximum operation alt</span>
                </Grid>
                <Grid item xs={4}>
                  <Typography variant='body1' component='p'>
                    {technicalSpecs?.initialCruiseAlt || "--"}
                  </Typography>
                  <span className="label">Initial cruise alt</span>
                </Grid>

              </Grid>
            </Grid>
          </Grid>
        </Grid>

      </Grid>

    </>
  )
}

export default TechnicalSpecs;