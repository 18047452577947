import { Box, FormControl, Grid, MenuItem, Select } from '@mui/material'
import React from 'react'
import DatePickerComponent from '../common/DatePickerComponent'

const Filters = ({fromDate,toDate,handleFromDateChange,handleToDateChange,handleSelectChange,status}:any) => {
  return (
    <Grid container columnSpacing={4} display="flex" justifyContent="flex-end">
                    <Grid item xs={2}>
                        
                        <DatePickerComponent
                            className="date-input"
                            value={fromDate}
                            placeholder="From"
                            onChange={(date: any) => handleFromDateChange(date)}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <DatePickerComponent
                            className="date-input"
                            value={toDate}
                            placeholder="To"
                            onChange={(date: any) => handleToDateChange(date)}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <Box
                            sx={{
                                border: "1px solid gray",
                                borderRadius: "20px",
                                // width: "200px",
                            }}
                        >
                            <FormControl fullWidth>
                                <Select
                                    sx={{ padding: "0 10px" }}
                                    value={status}
                                    // name={name}
                                    slotProps={{
                                        input: {
                                            id: "",
                                        },
                                    }}
                                    // id={id}
                                    variant="standard"
                                    disableUnderline
                                    onChange={handleSelectChange}
                                >
                                    <MenuItem value="PENDING">PENDING</MenuItem>
                                    <MenuItem value="RESOLVED">RESOLVED</MenuItem>
                                </Select>
                            </FormControl>
                        </Box>
                    </Grid>
                </Grid>
  )
}

export default Filters