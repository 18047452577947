import React from "react";
import { serviceProvider } from "../../provider/serviceProvider";
import { useEffect, useState } from "react";
import {
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  BarChart,
  Bar,
  AreaChart,
  Area,
  CartesianGrid,
} from "recharts";
import moment from "moment";
import {
  Paper,
  Box,
  Grid,
  Typography,
  TextField,
  MenuItem,
  Button,
  Divider,
} from "@mui/material";
import { IView } from "../../interface/IBase";
import { Stack, Card } from "@mui/material";
import SpinLoader from "../loader/SpinLoader";
export const BookingStats: React.FC<IView> = () => {
  const [days, setDays] = useState("7");
  const [loader, setLoader] = useState<boolean>(false);
  const [cumulativeData, setCumulativeData] = useState({
    numberOfQueries: "",
    numberOfBookings: "",
    numberOfQuotations: "",
    numberofSiteViews: "",
  });
  const [graphData, setGraphData] = useState([]);
  const dateFormatter = (item: any) => {
    return moment(item).format("MMM DD HH:mm");
  };

  const refreshStats = () => {
    serviceProvider.statsService
      .post()
      .then((res: any) => {
        console.log("./././.");
        window.location.reload();
      })
      .catch(() => {});
  };

  const dropdownOnChange = (value: string) => {
    setDays(value);
    //console.log(value);
    serviceProvider.statsService
      .get(0)
      .then((res: any) => {
        res.map((ele: any) => {
          ele.recordInsertedts = ele.recordInsertedts.slice(5, 10);
        });
        setGraphData(res);
      })
      .catch(() => {});
  };
  useEffect(() => {
    reqData();
  }, []);

  function reqData() {
    setLoader(true)
    serviceProvider.statsService
      .getList("cumulative")
      .then((res: any) => {
        setLoader(false)
        setCumulativeData(res);
        console.log(res);
      })
      .catch(() => {
        setLoader(false)
        console.log("err");
      });

      setLoader(true)
    serviceProvider.statsService
      .get(days)
      .then((res: any) => {
        setLoader(false)
        setGraphData(res);
      })
      .catch(() => {
        setLoader(false)
      });
  }

  if(loader){
    return  <SpinLoader/>
   }

  return (
    <>
      {/* <Grid
                sx={{
                    ml: { xs: 0, md: 6 },
                    pb: 2,

                }} >
                <Stack spacing={2} direction="row" >
                    <Button variant="text" color="info" sx={{ borderBottom: 2, borderRadius: 0, fontSize: "1rem" }} >Stats</Button>
                </Stack>
                <Divider sx={{ borderBottomWidth: 2, background: "black" }} />
            </Grid>  */}
      <Card
        sx={
          {
            //   ml: { xs: 0, md: 5 },
            //   pb: 2,
            //   borderRadius: 5,
            //   boxShadow: 5,
          }
        }
      >
        <Grid justifyContent="center" container spacing={5} mt={1} px={1}>
          <Grid item xs={8} sm={6} lg={3}>
            <Box
              sx={{
                padding: 1,
                borderRadius: 5,
                border: "4px solid #FFF8DD",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Typography gutterBottom variant="body1" component="div">
                {" "}
                <b
                  style={{ fontSize: "25px" }}
                >{`${cumulativeData.numberOfQueries} `}</b>
                &nbsp;&nbsp;
                {`Total queries`}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={8} sm={6} lg={3}>
            <Box
              sx={{
                padding: 1,
                borderRadius: 5,
                border: "4px solid #F0F9FE",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Typography gutterBottom variant="body1" component="div">
                {" "}
                <b
                  style={{ fontSize: "25px" }}
                >{`${cumulativeData.numberOfQuotations}`}</b>&nbsp;&nbsp;
                {`Total quotations`}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={8} sm={6} lg={3}>
            <Box
              sx={{
                padding: 1,
                borderRadius: 5,
                justifyContent: "center",
                display: "flex",
                border: "4px solid #FEF4F8",
              }}
            >
              <Typography gutterBottom variant="body1" component="div">
                {" "}
                <b style={{ fontSize: "25px" }}>
                  {`${cumulativeData.numberOfBookings}`}{" "}
                </b>
                &nbsp;&nbsp;
                {"Total bookings"}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={8} sm={6} lg={3}>
            <Box
              sx={{
                padding: 1,
                borderRadius: 5,
                border: "4px solid #E7FFF3",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Typography gutterBottom variant="body1" component="div">
                {" "}
                <b
                  style={{ fontSize: "25px" }}
                >{`${cumulativeData.numberofSiteViews}`}</b>
                &nbsp;&nbsp;
                {`Total Views`}
              </Typography>
            </Box>
          </Grid>
          
        </Grid>
        <Grid container sx={{ mt: 1 }}>
          {/* <Grid xs={1}></Grid> */}

          <Grid md={12} sx={{ mb: 4, padding: "5px" }}>
            <Divider />
          </Grid>
          {/* <Grid xs={1}></Grid> */}

          {/* <Grid item xs={1}></Grid> */}
          <Grid item xs={12} md={12}>
            <Grid
              container
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignContent: "center",
              }}
            >
              {/* <Grid item xs={12} md={4} marginLeft={5}>
                <Typography gutterBottom variant="h6">
                  <strong> Stats</strong>
                  <Button onClick={refreshStats}>
                    &nbsp;<u>Refresh to get latest stats</u>
                  </Button>
                </Typography>
              </Grid>
              {/* <Grid item xs={12} md={4}></Grid> */}
              {/* <Grid item xs={12} md={2} marginRight={8}>
                <TextField
                  select
                  label="Time"
                  value={days}
                  variant="standard"
                  className="textFieldForInput"
                  fullWidth
                  onChange={(e) => dropdownOnChange(e.target.value)}
                >
                  <MenuItem key={"7"} value={"7"}>
                    Last 7 Days
                  </MenuItem>
                  <MenuItem key={"30"} value={"30"}>
                    Last 1 Month
                  </MenuItem>
                  <MenuItem key={"90"} value={"90"}>
                    Last 1 Quarter
                  </MenuItem>
                </TextField>
              </Grid> */}
            </Grid>
          </Grid>
        </Grid>
        <Grid container spacing={5} marginTop={1}>
          <Grid
            item
            xs={12}
            md={12}
            sx={{
              display: "flex",
              justifyContent: "space-around",
              flexDirection: "column",
            }}
          >
            <ResponsiveContainer width="100%" height={300}>
              <BarChart
                data={graphData}
                margin={{
                  top: 20,
                  right: 30,
                  left: 20,
                  bottom: 10,
                }}
              >
                <XAxis
                  dataKey="recordInsertedts"
                  tickFormatter={dateFormatter}
                />
                <YAxis />
                <Tooltip labelFormatter={dateFormatter} />
                <Bar
                  dataKey="num"
                  name="Queries"
                  //stroke="rgb(106, 110, 320)"
                  fill="#337DD9"
                  barSize={12}
                  radius={[10, 10, 0, 0]}
                />
                <Bar
                  dataKey="numberOfQueries"
                  name="Queries Flow"
                  //stroke="rgb(67,107,150)"
                  fill="#2f426f"
                  barSize={12}
                  radius={[10, 10, 0, 0]}
                />
                <Bar
                  dataKey="numberOfQuotations"
                  name="Quotation Flow"
                  //stroke="rgb(3, 207, 252)"
                  fill="#00C49F"
                  barSize={12}
                  radius={[10, 10, 0, 0]}
                />
                <Bar
                  dataKey="numberOfBookings"
                  name="Booking Flow"
                  //stroke="rgb(3, 207, 252)"
                  fill="#00C49F"
                  barSize={12}
                  radius={[10, 10, 0, 0]}
                />
                <Bar
                  dataKey="numberOfSiteviews"
                  name="Site views Flow"
                  //stroke="rgb(3, 207, 252)"
                  fill="#00C49F"
                  barSize={12}
                  radius={[10, 10, 0, 0]}
                />
              </BarChart>
            </ResponsiveContainer>
            <text
              x={290}
              y={5}
              fill="black"
              textAnchor="middle"
              dominantBaseline="top"
              style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            >
              <tspan fontSize="30">Quotation flow</tspan>
            </text>
          </Grid>
          
        </Grid>
        
        {/* <CandidateCard /> */}
      </Card>
    </>
  );
};

export default BookingStats;