import { HttpService } from '../commonService/httpService';
import { HttpMethod } from '../../enum/httpMethod';
import { sessionUtility } from '../../utility/sessionUtility';
import { IAircraftService, IFleetService } from '../../interface/IService';

export class AircraftService extends HttpService implements IAircraftService {
    urls: { get: string; post: string; delete: string; put: string; patch: string; };
    userDetails = sessionUtility.getAuthentication();
    constructor() {
        super();
        this.urls={
            get:"aircrafts?aircraftId=NA",
            post:"aircrafts",
            put:"aircrafts",
            delete:"aircrafts",
            patch:"",
        }
        if (!this.userDetails) {
            this.userDetails = sessionUtility.getAuthentication();
        }
    }
    header: Headers | string[][] | Record<string, string>=sessionUtility.getHeaderWithToken(this.userDetails);
    getAllAircrafts(fleetId:string): Promise<any[]> {
        const url=`aircrafts?fleetId=${fleetId}`;
        return this.fetch(HttpMethod.Get, url, this.header) as Promise<any[]>;
    }
    getAllAssingedAircrafts(operatorId:any, status:boolean,callSign:string="NA"): Promise<any[]> {
        const url=`aircrafts/assign?operatorId=${operatorId}&status=${status}&callSign=${callSign}`;
        return this.fetch(HttpMethod.Get, url, this.header) as Promise<any[]>;
    }
 
    // getAircraftDetails
    getAircraftDetails(param:any):Promise<any[]> {
        const url=`aircrafts?aircraftId=${param}`;
        return this.fetch(HttpMethod.Get, url, this.header) as Promise<any>;
    }
    editAircraft<E>(aircraftId:string,entity:E): Promise<any[]> {
        const url =`aircrafts?aircraftId=${aircraftId}`
        return this.postQuery(url, this.header, entity) as Promise<any>;
    }
    get(param: any): Promise<any> {
        // const url=this.setUrlParam(param,this.urls.get);
        return this.fetch(HttpMethod.Get, param, this.header) as Promise<any>;
    }
    put(param: any, entity: any): Promise<any> {
        // const url =`${param.status}`!='undefined'? `${this.urls.put}/${param.jobId}/activate/${param.status}`:`${this.urls.put}/${param.jobId}`;
        const url=this.setUrlParam(param,this.urls.put);
        return this.putQuery(url, this.header, entity) as Promise<any>;
    }
    
    post<E>(entity: E): Promise<any> {
        return this.postQuery(this.urls.post, this.header, entity) as Promise<any>;
    }
    patch(param: any): Promise<any> {
        const url = this.setUrlParam(param, this.urls.patch);
        return this.postQuery(url, this.header) as Promise<any>;
    }
    delete(param: any): Promise<any> {
        const url = this.setUrlParam(param, this.urls.delete);
        return this.deleteQuery(url, this.header) as Promise<any>;
    }
    assignAircraft<E>(param:any,entity: E): Promise<any> {
        const url = this.setUrlParam(param, this.urls.post);
        return this.postQuery(url, this.header, entity) as Promise<any>;
    }
    verifyCallSign(param: any): Promise<any> {
        const url = this.setUrlParam(param, this.urls.post);
        return this.postQuery(url, this.header) as Promise<any>;
    }
    // sendJobMail(jobId:string, uId:string, emailIdList:string, mailSubject:string, mailBody:string): Promise<any> {
    //     const url = `jobs/mails?type=invite&jobId=${jobId}&recruiterUId=${uId}&emailIdList=${emailIdList}&mailSubject=${mailSubject}&mailBody=${mailBody}`
    //     return this.postQuery(url, this.header) as Promise<any>;
    // }
    // postJobShortUrl(jobId: string, shortURL: string)
    // {
    //     const url = `customurls?customUrlFromUser=${shortURL}&entityId=${jobId}&originalUrl=desc/${jobId}`;
    //     return this.postQuery(url, this.header, {}) as Promise<any>;
    // }
    // getLongUrl(shortURl:string, EntityId:string) {
    //     const url = `customurls?entityId=${EntityId}&shortUrl=${shortURl}`;
    //     return this.fetch(HttpMethod.Get, url, this.header) as Promise<any>;
    // }
   
    // getJobDesignation(){
    //     const url = "common/jobdesignation"
    //     return this.fetch(HttpMethod.Get, url,this.header) as Promise<any>;
    // }
    // parseJD(param:any):Promise<any>{
    //     const url = `jobs/jobdescription?jobDescriptionLink=${param}`
    //     return this.fetch(HttpMethod.Post,url,this.header) as Promise<any>;
    // }
    // deleteScreenQues(param:any):Promise<any>{
    //     const url = `jobs/screeningquestions/${param}`;
    // return this.fetch(HttpMethod.DELETE,url,this.header) as Promise<any>;
    // }
} 