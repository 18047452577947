import React from 'react';
import { Tabs, Tab, Box } from '@mui/material';
import BlogsTable from './BlogsTable';
import { a11yProps, CustomTabPanel } from '../../components/Tab/CustomTabPanel';


  const BlogTabs = ({pageState,setPageState,value,handleChange,getBlogsList}:any) => {
  
  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab label="Active" {...a11yProps(0)} />
          <Tab label="Inactive" {...a11yProps(1)} />
          
        </Tabs>
      </Box>

      {/* ... */}

      <CustomTabPanel value={value} index={0}>
        <BlogsTable pageState={pageState} setPageState={setPageState} getBlogsList={getBlogsList}/>
            </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
      <BlogsTable pageState={pageState} setPageState={setPageState}  getBlogsList={getBlogsList} />
            </CustomTabPanel>
         
    </Box>
  );
};

export default BlogTabs;
