import { Button, Grid, Tooltip, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { serviceProvider } from "../../provider/serviceProvider";
import DataGridTable from "../DataGridTable/DataGridTable";
import Filters from "./Filters";
import dayjs from "dayjs";
import { getFormattedDate } from "../../utility/formateDateAndTime";
import ButtonComponent from "../common/ButtonComponent";
import CommonModal from "../modal/CommonModal";
import editIcon from "../../assets/icons/edit-icon.svg";
import queryIcon from "../../assets/icons/query-management.svg";
import { toast } from "react-toastify";
import { Box } from "@mui/system";
import { IAircraft } from "../../interface/IAircraft";
import AutoCompleteComponent from "../common/AutoCompleteComponent";
import GenerateQuotation from "./GenerateQuotation";

const columns = [
  //   { field: 'id',
  //    headerName: '',
  //   //  width: 90
  //  },
  {
    field: "name",
    headerName: "Name",
    width: 200,
    editable: false,
    renderCell: (params: any) => {
      return (
        <Tooltip title={params.row?.name}>
          <span>{params.row?.name}</span>
        </Tooltip>
      );
    },
  },
  {
    field: "email",
    headerName: "Email",
    width: 250,
    editable: false,
    renderCell: (params: any) => {
      return (
        <Tooltip title={params.row?.email}>
          <span>{params.row?.email}</span>
        </Tooltip>
      );
    },
  },
  {
    field: "mobileNumber",
    headerName: "Contact",
    // type: 'number',
    width: 150,
    // width: 300,
    editable: false,
  },
  
  {
    field: "status",
    headerName: "Status",
    width: 130,
    // width: 150,
    editable: false,
  },
  {
    field: "otherDetails",
    headerName: "Query",
    width: 150,
    // width: 150,
    editable: false,
    renderCell: (params: any) => {
      return (
        <Tooltip title={params.row.otherDetails}>
          <span>{params.row.otherDetails}</span>
        </Tooltip>
      );
    },
  },
  {
    field: "itineraryType",
    headerName: "Type",
    width: 150,
    // width: 150,
    editable: false,
    renderCell: (params: any) => {
      return (
          <span>{params.row.itineraryDetails.itineraryType}</span>
      );
    },
  },
  {
    field: "recordCreatedTs",
    headerName: "Query raised on",
    width: 200,
    // width: 150,
    editable: false,
    // renderCell: (params:any)=>{
    //     return new Date(params.row.recordCreatedTs).toLocaleString()
    // }
  },

 
  //   {
  //     field: 'manage',
  //     type: 'actions',
  //     headerName: 'Manage',
  //     width: 200,
  //     getActions: (params: any) =>  [
  //       <ManageUser userId={params.row.uId} roleId={params.row.roleId} isActive={params.row.isActive} />
  //     ],
  //   },
];
const QuotationQuery = () => {
  const [pageState, setPageState] = React.useState({
    loader: false,
    data: [],
    totalPages: 0,
    page: 0,
    pageSize: 5,
  });

  const [status, setStatus] = useState("PENDING");
  const [fromDate, setFromDate] = useState<any>(null);
  const [toDate, setToDate] = useState<any>(null);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [openGenerateQuotationModal, setOpenGenerateQuotationModal] = useState(false);
  const [loader, setLoader] = useState(false);
  const [queryId, setQueryId] = useState("");
  // const [itineraryDetails, setItineraryDetails] = useState<any>({});
  const [queryDetails, setQueryDetails] = useState<any>({});
  

  const handleSelectChange = (e: any) => {
    setStatus(e.target.value);
  };

  const handleFromDateChange = (date: any) => {
    setFromDate(date);
  };
  const handleToDateChange = (date: any) => {
    setToDate(date);
  };

  const getAllQueries = (param: any) => {
    // const query = `search?uId=NA&email=all&fullName=all&isActive=${false}&offset=${pageState.page}&limit=${pageState.pageSize}&order=recordUpdatedts.desc&profile=BASIC`
    // setLoader(true);
    setPageState((prev) => ({ ...prev, loader: true }));

    serviceProvider.commonService
      .get(param)
      .then((res: any) => {
        //   setLoader(false)
        if (res.responseCode === 200) {
          // setInActiveUsers(res.data.details)
          setPageState((prev) => ({
            ...prev,
            loader: false,
            data: res.data.data,
            totalPages: res.data.totalRecordCount,
          }));
        }
        setPageState((prev) => ({ ...prev, loader: false }));
      })
      .catch((err) => {
        // setLoader(false)
        setPageState((prev) => ({ ...prev, loader: false }));
        console.log({ err });
      });
  };

  const rows = pageState?.data?.map((query: any, index: any) => ({
    ...query,
    id: query.quoteEnquiryId,
    recordCreatedTs: getFormattedDate(query.recordCreatedTs),
    // roleName: JSON.parse(user.role).roleName,
    // roleId : JSON.parse(user.role).roleId,
  }));

  const newColumns = [
    ...columns,
    {
      field: "manage",
      type: "actions",
      headerName: "Manage",
      width: 200,
      getActions: (params: any) => [
       <Box sx={{display:"flex",alignItems:"center"}}>
       <Tooltip title="Edit">
       <Button onClick={() => handleOpenConfirmModal(params.row)}>
          <img src={editIcon} alt="edit icon" height="20px" width="20px" />
        </Button>
       </Tooltip>
       <Tooltip title="Generate Quotation">
       <Button onClick={() => handleOpenGenerateQuotationModal(params.row)}>
          <img src={queryIcon} alt="edit icon" height="20px" width="20px" />
        </Button>
       </Tooltip>
       </Box>
      ],
    },
  ];



  
  const handleOpenConfirmModal = (row: any) => {
    setOpenConfirmModal(true);
    setQueryId(row.quoteEnquiryId);
  };
  const handleCloseConfirmModal = () => setOpenConfirmModal(false);


  const handleOpenGenerateQuotationModal = (row: any) => {
    setOpenGenerateQuotationModal(true);
    setQueryDetails(row);
    // setItineraryDetails(row?.itineraryDetails);
  };
  const handleCloseGenerateQuotationModal = () => setOpenGenerateQuotationModal(false);

  const handleUpdateQueryStatus = (queryId: any) => {
    // alert("hello")
    const param = `${queryId}/status?status=RESOLVED`;

    setLoader(true);
    serviceProvider.quoteService
      .put(param, "")
      .then((res: any) => {
        if (res.responseCode === 200) {
          toast.success(res.message);
          handleCloseConfirmModal();
          const param = `allqueries/search?status=${status}&queryType=QUOTATION&from=all&to=all&offset=${pageState.page}&limit=${pageState.pageSize}&order=updatedTs.desc&profile=BASIC`;
          getAllQueries(param);
          // setTimeout(() => {
          //   // window.location.reload();
          //   window.location.href=`/query-management?index=${1}`
          // }, 100);
        } else {
          toast.error(res?.message || "Something Went Wrong !");
        }
        setLoader(false);
      })
      .catch((err: any) => {
        setLoader(false);
        toast.error(err?.data?.message || "Something Went Wrong !");
      });
  };



 




  //   const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
  //     // const searchQuery = e.target.value;
  //    const param = `allqueries?status=PENDING&queryType=QUOTATION&from=all&to=all&offset=${pageState.page}&limit=${pageState.pageSize}&order=updatedTs.desc&profile=BASIC`
  //     getAllQueries(param)
  //   }

  useEffect(() => {
    const formattedFromDate = fromDate
      ? dayjs(new Date(fromDate).toISOString()).format(
          "YYYY-MM-DDTHH:mm:ss.SSSZ"
        )
      : "all";
    const formattedToDate = toDate
      ? dayjs(new Date(toDate).toISOString()).format("YYYY-MM-DDTHH:mm:ss.SSSZ")
      : "all";
    const param = `allqueries/search?status=${status}&queryType=QUOTATION&from=${formattedFromDate}&to=${formattedToDate}&offset=${pageState.page}&limit=${pageState.pageSize}&order=updatedTs.desc&profile=BASIC`;
    getAllQueries(param);
    // getActiveUsers();
  }, [status, fromDate, toDate, pageState.page, pageState.pageSize]);

  const confirmModalBody = (
    <Grid container rowSpacing={4} className="confirm-request-modal">
      <Grid item xs={12}>
        <Typography variant="h6" component="h6">
          Resolve Query
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body1" component="p" textAlign="center">
          Are you sure you want to resolve this query?
        </Typography>
      </Grid>
      <Grid item xs={12} className="btn-container">
        <ButtonComponent
          className="rounded-btn-outlined"
          fullWidth={false}
          label="Cancel"
          onClick={handleCloseConfirmModal}
        />
        <ButtonComponent
        loader={loader}
        disabled={loader}
          fullWidth={false}
          label="Resolve"
          onClick={() => handleUpdateQueryStatus(queryId)}
        />
      </Grid>
    </Grid>
  );
 

  return (
    <>
      <CommonModal
        open={openConfirmModal}
        handleCloseModal={handleCloseConfirmModal}
        modalBody={confirmModalBody}
      />
      <CommonModal
        open={openGenerateQuotationModal}
        handleCloseModal={handleCloseGenerateQuotationModal}
        modalBody={<GenerateQuotation queryDetails={queryDetails} handleCloseGenerateQuotationModal={handleCloseGenerateQuotationModal} indexNumber={1}/>}
      />
      <Grid container rowSpacing={2}>
        <Grid
          item
          xs={12}
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
        >
          <Filters
            fromDate={fromDate}
            toDate={toDate}
            handleFromDateChange={handleFromDateChange}
            handleToDateChange={handleToDateChange}
            handleSelectChange={handleSelectChange}
            status={status}
          />
        </Grid>
        <Grid item xs={12}>
          <DataGridTable
            pageState={pageState}
            setPageState={setPageState}
            rows={rows}
            columns={newColumns}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default QuotationQuery;
