import { Modal, Grid, Button } from '@mui/material';
import CloseIcon from "@mui/icons-material/Close";
import { ICommonModalProps } from '../../interface/ICommonModalProps';


const CommonModal:React.FC<ICommonModalProps> = ({className="", open, handleCloseModal, modalBody }) => {
  return (
    <Modal
      open={open}
      onClose={handleCloseModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Grid container className={`modal-container ${className}`}>
        <Grid item xs={12}>
          <Button className="modal-close-btn" onClick={handleCloseModal}>
            <CloseIcon  />
          </Button>
        </Grid>

        {/* MODAL BODY */}
        <Grid item xs={12} className='modal-body'>
        {modalBody}
        </Grid>
        
      </Grid>
    </Modal>
  );
};

export default CommonModal;
