import React, { useEffect, useState } from 'react'
import { Typography, Grid } from "@mui/material";
import BMJRewardPointTable from './BMJRewardPointTable';
import { serviceProvider } from '../../provider/serviceProvider';
import { toast } from 'react-toastify';
import DatePickerComponent from '../common/DatePickerComponent';
import dayjs from 'dayjs';
import SearchBar from '../searchBar/SearchBar';

const ManageBMJRewardPoints = () => {
  const [pageState, setPageState] = useState({
    loader: false,
    data: [],
    totalPages: 0,
    page: 0,
    pageSize: 5,
  });

  const [fromDate, setFromDate] = useState<any>(null);
  const [toDate, setToDate] = useState<any>(null);
  const [searchQuery, setSearchQuery] = useState<string>("");



  const handleFromDateChange = (date: any) => {
    setFromDate(date);
  };
  const handleToDateChange = (date: any) => {
    setToDate(date);
  };






  // const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   const searchQuery = event.target.value || "all";
  //   // setValue(et as number[]); 
  //   const param = `search?uId=${searchQuery}&from=all&to=all&offset=${pageState.page}&limit=${pageState.pageSize}&order=recordCreatedTs.desc&profile=BASIC`
   
  //   getBmjRewardPoints(param)
  // }



  const getBmjRewardPoints = (param:any) => {
    // setLoader(true);
    setPageState(prev=>({...prev,loader:true}))
   
    serviceProvider.rewardPointsUsageService.get(param).then((res:any) => {
      // setLoader(false)
      // setPageState(prev=>({...prev,loader:false,data:res.data,totalPages:res.totalRecordCount}))
      if (res.responseCode === 200) {
        // setAllAirports(res.data)
        setPageState(prev=>({...prev,loader:false,data:res.data.data,totalPages:res.data.totalRecordCount}))
      } else {
        toast.error(res?.message ||"Something Went Wrong !!")
      }
      setPageState(prev=>({...prev,loader:false}))
      // console.log({ res })
    }).catch((err) => {
      // setLoader(false)
      setPageState(prev=>({...prev,loader:false}))
      toast.error(err?.data?.message || "Something Went Wrong !!")
      // console.log({ err })
    })
  }

  // useEffect(() => {
  //   const param = `search?uId=all&from=all&to=all&offset=${pageState.page}&limit=${pageState.pageSize}&order=recordCreatedTs.desc&profile=BASIC`
  //   getBmjRewardPoints(param)
  // }, [pageState.page,pageState.pageSize])


  useEffect(() => {
    const formattedFromDate = fromDate
      ? dayjs(new Date(fromDate).toISOString()).format(
          "YYYY-MM-DDTHH:mm:ss.SSSZ"
        )
      : "all";
    const formattedToDate = toDate
      ? dayjs(new Date(toDate).toISOString()).format("YYYY-MM-DDTHH:mm:ss.SSSZ")
      : "all";
    // const param = `allqueries/search?status=${status}&queryType=GENERAL&from=${formattedFromDate}&to=${formattedToDate}&offset=${pageState.page}&limit=${pageState.pageSize}&order=updatedTs.desc&profile=BASIC`;
    // getBmjRewardPoints(param);
    
        const param = `search?uId=${searchQuery || "all"}&from=${formattedFromDate}&to=${formattedToDate}&offset=${pageState.page}&limit=${pageState.pageSize}&order=recordCreatedTs.desc&profile=BASIC`
    getBmjRewardPoints(param)
    // getActiveUsers();
  }, [ fromDate, toDate, pageState.page, pageState.pageSize,searchQuery]);


  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          {/* <Grid item xs={12} md={12} mb="20px">
            <Typography variant='h6' gutterBottom component='h6'>BMJ reward points</Typography>
          </Grid> */}
           <Grid item xs={12}>
          <Grid container>
            <Grid item xs={5} md={5} className="fleet-heading">
              <Typography variant='h6' component='h6'>BMJ reward points</Typography>
            </Grid>
            <Grid item xs={7} md={7}>
              <SearchBar 
              value={searchQuery}
              onChange={(e:any)=>setSearchQuery(e.target.value)}
              // onChange={handleSearch}
              placeholder="Search by User Id" />
            </Grid>
          </Grid>
        </Grid>
          <Grid item xs={12} md={12}>

          <Grid container columnSpacing={4}>
            <Grid item xs={12}>
            <Typography variant='h6' component='h6' letterSpacing="1.2px">Redeemed / Added</Typography>
            </Grid>
                    {/* <Grid item xs={2}>
                        
                        <DatePickerComponent
                            className="date-input"
                            // disableFuture={true}
                            value={fromDate}
                            placeholder="From"
                            onChange={(date: any) => handleFromDateChange(date)}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <DatePickerComponent
                            className="date-input"
                            // disableFuture={true}
                            value={toDate}
                            placeholder="To"
                            onChange={(date: any) => handleToDateChange(date)}
                        />
                    </Grid> */}
                    
                </Grid>
          
          </Grid>

        </Grid>
      </Grid>

      

      <Grid item xs={12} >
        <BMJRewardPointTable pageState={pageState} setPageState={setPageState} />
      </Grid>
    </Grid>
  )
}

export default ManageBMJRewardPoints;