export const getFormattedTime=(dateTimeString:string)=>{
    // const dateTimeObj = new Date(dateTimeString);
          
    //       const time = dateTimeString ? dateTimeObj.toLocaleTimeString() :"--"
    //       return time
    const dateTimeObj = new Date(dateTimeString);

    const options: Intl.DateTimeFormatOptions = { hour: 'numeric', minute: 'numeric', second: 'numeric' };
    const time = dateTimeString ? dateTimeObj.toLocaleTimeString(undefined, options).replace(/:\d{2}\s/, ' ') : "--";

    return time;
  }
 export const getFormattedDate=(dateTimeString:string)=>{
    const dateTimeObj = new Date(dateTimeString);
    const day = dateTimeObj.getDate();
    const monthName = dateTimeObj.toLocaleString('default', { month: 'short' });
    const year = dateTimeObj.getFullYear();
  
    const formattedDate = dateTimeString ? `${day} ${monthName} ${year}` : "--"
    return formattedDate
  }