import React, { useRef, useState } from 'react'
import { Typography, Grid, Card } from "@mui/material";
import InputFieldComponent from '../common/InputFieldComponent';
import ButtonComponent from '../common/ButtonComponent';
import { serviceProvider } from '../../provider/serviceProvider';
import { toast } from "react-toastify";
import dayjs, { Dayjs } from 'dayjs';
import DateTimePickerComponent from '../common/DateTimePickerComponent';
import { IValidationResponse } from '../../interface/IValidation';
import { ValidationType } from '../../enum/validationType';
import { ValidationUtiltiy } from '../../utility/ValidationUtility';
import Loader from '../loader/Loader';
import addImageIcon from "../../assets/icons/add-image.svg";
import S3 from "react-aws-s3-typescript";
import { awsConfig } from "../../configuration/config.json";

// title, date to deactivate, redirect link, image  


const initalUserInput = {
    active: true,
    datetimeToDeactivate:"",
    feedDescription: "",
    feedId: 0,
    feedTitle: "",
    redirect_link: "",
    imageUrl: "",
}

const AddFeedForm = ({ handleCloseModal ,getAllFeeds}: any) => {
    const [userInput, setUserInput] = useState(initalUserInput);
    const [loader, setLoader] = useState(false);
    const [imgLoader,setImgLoader]=useState(false)
    const [deactivateDateTime, setDeactivateDateTime] = useState(null);
    const [file, setFile] = useState<any>(null);

    const chooseFileRef: React.MutableRefObject<HTMLInputElement | null> = useRef(null);

    const handleChooseFile = () => {
      chooseFileRef.current?.click();
    };

    
    
    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;

        setUserInput({ ...userInput, [name]: value })
        validateFieldsOnInput(name)
    }



    const validateFieldsOnInput = (key: string) => {
        switch (key) {
            case 'feedTitle':
                validateFeedTitle();
                break;
            // case 'feedDescription':
            //     validateFeedDescription();
            //     break;
            case 'redirect_link':
                validateRedirectLink();
                break;
        }
    };
    const handleDeactivateDateTime = (newDate: any) => {
        setDeactivateDateTime(newDate);
    }
   

    const handleFileChange = (event: any) => {
        const fileObj = event.target.files && event.target.files[0];
        if (!fileObj) {
          return;
        }
        const isValid = ValidationUtiltiy.isValidImg(fileObj.name);
        if (isValid) {
          setFile(fileObj);
          let newFileName =
            // userDetails?.uid +
            "123" +
            "_" +
            event.target.files[0].name.replace(/\..+$/, "");
          uploadImage(fileObj, newFileName);
        } else {
          toast.error("Not a valid File");
          // handleCloseM()
        }
    
    
    
      };
    
    
      const uploadImage = (file: any, newFile: any) => {
        setImgLoader(true)
        // handleCloseM()
        const ReactS3Client = new S3(awsConfig);
        ReactS3Client.uploadFile(
          file,
          awsConfig.feedsSubFolder + "/" + newFile
        ).then((data) => {
          // console.log("upload cover image", data);
          if (data.status === 204) {
            updateUserInput(data.location)
            toast.success("Image Uploaded Click Save Button to Update.");
    
          } else {
            // setLoader(false)
            console.log("File Not Uploaded !!");
            toast.error("Error Occured");
          }
          setImgLoader(false)
        }).catch((err) => {
          setImgLoader(false)
          console.log({ err })
          toast.error("Something Went Wrong.")
        })
      };
    
    
      const updateUserInput = (imageUrl: string) => {
        console.log(imageUrl)
        // if (couponImage !== "") userInput["couponImgUrl"] = couponImage;
        if (imageUrl !== "") setUserInput({ ...userInput, imageUrl: imageUrl })
      }

    const handleAddFeed = () => {
        const formattedDeactivateDateTime = deactivateDateTime
      ? dayjs(new Date(deactivateDateTime).toISOString()).format("YYYY-MM-DDTHH:mm:ss")
      : "";

       
        const payload = { ...userInput, datetimeToDeactivate: formattedDeactivateDateTime }

        isValidRequest().then(() => {
            setLoader(true)
            serviceProvider.feedService.post(payload).then((res) => {
                // console.log({ res })
                if (res.responseCode === 200) {

                    toast.success(res.message)
                    handleCloseModal();
                    const query = `search?feedTitle=all&feedId=all&isActive=true&offset=0&limit=10&order=updatedTs.desc&profile=BASIC`
                    getAllFeeds(query)
                    // setTimeout(() => {
                    //     window.location.reload()
                    // }, 100)
                } else {
                    toast.error(res?.message||"Something Went Wrong !!")
                }
                setLoader(false)

            }).catch((err) => {
                toast.error(err?.data?.message || "Something Went Wrong!!")
                console.log({ err })
                setLoader(false)
            })
        }).catch(() => {
            toast.error('Please fill all the Required Fields!');
        })
    }


    return (
        <Grid container spacing={3} className='add-fleet-container'>
            <Grid item xs={12} className="modal-table-container">
                <Grid container className='heading'>
                    <Grid item xs={12}>
                        <Typography variant='h6' component='h6'>Add Feed</Typography>
                    </Grid>
                </Grid>
                <Grid container className="edit-detail-item">
                    <Grid item xs={6} className='edit-item dark-bg'>
                        <Typography variant='body1' component='p'>
                            Title
                        </Typography>
                    </Grid>
                    <Grid item xs={6} className='edit-item'>
                        <div>
                            <InputFieldComponent
                                id="feed-title"
                                type="text"
                                name="feedTitle"
                                value={userInput.feedTitle}
                                onChange={handleInputChange}
                            />
                            <span className="modalError" id='errorDiv-feed-title'></span>
                        </div>


                    </Grid>
                </Grid>
                {/* <Grid container className="edit-detail-item">
                    <Grid item xs={6} className='edit-item dark-bg'>
                        <Typography variant='body1' component='p'>
                            Description
                        </Typography>
                    </Grid>
                    <Grid item xs={6} className='edit-item'>
                        <div>
                            <InputFieldComponent
                                id="feed-description"
                                type="text"
                                name="feedDescription"
                                value={userInput.feedDescription}
                                onChange={handleInputChange}
                                isMultiline={true}
                                minRows= {3}
                            />
                            <span className="modalError" id='errorDiv-feed-description'></span>
                        </div>
                    </Grid>
                </Grid> */}
                <Grid container className="edit-detail-item">
                    <Grid item xs={6} className='edit-item dark-bg'>
                        <Typography variant='body1' component='p'>
                            Redirect Url
                        </Typography>
                    </Grid>
                    <Grid item xs={6} className='edit-item'>
                        <div>
                            <InputFieldComponent
                                id="redirect-link"
                                type="url"
                                name="redirect_link"
                                value={userInput.redirect_link}
                                onChange={handleInputChange}
                            />
                            <span className="modalError" id='errorDiv-redirect-link'></span>
                        </div>

                    </Grid>
                </Grid>
               
                <Grid container className="edit-detail-item">
                    <Grid item xs={6} className='edit-item dark-bg'>
                        <Typography variant='body1' component='p'>
                            Deactivate Date Time
                        </Typography>
                    </Grid>
                    <Grid item xs={6} className='edit-item'>
                        <DateTimePickerComponent
                            placeholder="Deactivate Date Time"
                            value={deactivateDateTime}
                            disablePast={true}
                            onChange={handleDeactivateDateTime}
                        />

                    </Grid>
                </Grid>
               

            </Grid>
            <Grid item xs={12}>
        {
          imgLoader ? <Loader height='230px' width='100%'/> : <Card className='fleet-image-card'>
          <img src={userInput.imageUrl ? userInput.imageUrl : addImageIcon} className={`${userInput.imageUrl ? "card-img" : "add-img"}`} alt="add icon" onClick={handleChooseFile} />
          <input
            style={{ display: 'none' }}
            ref={chooseFileRef}
            type="file"
            onChange={handleFileChange}
          />
        </Card>
        }
       
      </Grid>
            <Grid item xs={12} >
                <Grid container columnSpacing={2} display="flex" justifyContent="center" alignItems="center" mt="20px">
                    <Grid item xs={2}>
                        <ButtonComponent loader={loader} disabled={loader} label="Save" onClick={handleAddFeed} />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default AddFeedForm;




const isValidRequest = (): Promise<boolean> => {
    let responseArray: IValidationResponse[] = [];
    responseArray.push(validateFeedTitle());
    // responseArray.push(validateFeedDescription());
    responseArray.push(validateRedirectLink());
    // responseArray.push(ValidationUtiltiy.validateElement("signup-confirmPassword", ValidationType.Text, true, "errorDiv-cpwd", "Confirm Password"));
    let haveErrors = responseArray.filter((x) => {
        return !x.isValid;
    }).length <= 0;
    return new Promise((resolve, reject) => {
        if (haveErrors) {
            resolve(true);
        } else {
            reject(false);
        }
    });
}

const validateFeedTitle = () => {
    return ValidationUtiltiy.validateElement("feed-title", ValidationType.Text, true, "errorDiv-feed-title", "Title");
}
// const validateFeedDescription = () => {
//     return ValidationUtiltiy.validateElement("feed-description", ValidationType.Text, true, "errorDiv-feed-description", "Description");
// }
const validateRedirectLink = () => {
    return ValidationUtiltiy.validateElement("redirect-link", ValidationType.URL, true, "errorDiv-redirect-link", "Redirect Url");
}



