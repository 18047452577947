import React, { useState, useRef } from 'react';
import { Typography, Card, TextField, Button, Grid } from '@mui/material';
import addImageIcon from "../../assets/icons/add-image.svg";
import { serviceProvider } from '../../provider/serviceProvider';
import { toast } from "react-toastify";
import S3 from "react-aws-s3-typescript";
import { awsConfig } from "../../configuration/config.json";
import { ValidationUtiltiy } from '../../utility/ValidationUtility';
import { ValidationType } from '../../enum/validationType';
import { IValidationResponse } from '../../interface/IValidation';
import SpinLoader from '../loader/SpinLoader';
import Loader from '../loader/Loader';
import ButtonComponent from '../common/ButtonComponent';

// interface FleetDetailsFormProps {
//   userInput: any; // Replace 'any' with appropriate type
//   image: string | null;
//   handleInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
//   handleAddFleet: () => void;
//   handleChooseFile: () => void;
//   chooseFileRef: string | null;
//   handleFileChange: ()=> void;
// }
interface AddFleetProps {
  setAllFleets: any;
  handleCloseModal: () => void;
  getAllFleets:any;
}



const inititalFleet = {
  fleetId: "",
  imageUrl: "",
  name: "",
  numberOfAircrafts: 0,
  recordCreatedBy: "",
  recordCreatedTs: "",
  recordUpdatedBy: "",
  recordUpdatedTs: ""

}
const AddFleet: React.FC<AddFleetProps> = ({ handleCloseModal, setAllFleets,getAllFleets }) => {


  const [userInput, setUserInput] = useState(inititalFleet)
  const [image, setImage] = useState<string | null>(null);
  const [file, setFile] = useState<any>(null);
  const [loader,setLoader]=useState(false)
  const [imgLoader,setImgLoader]=useState(false)
  const chooseFileRef: React.MutableRefObject<HTMLInputElement | null> = useRef(null);

  const handleChooseFile = () => {
    chooseFileRef.current?.click();
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setUserInput({ ...userInput, [name]: value });
    validateFieldsOnInput(name)
  }


  const validateFieldsOnInput = (key: string) => {
    switch (key) {
      case 'name':
        validateName();
        break;
      default:
        break;
    }
  };
  // const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   const fileObj = event.target.files && event.target.files[0];
  //   if (!fileObj) {
  //     return;
  //   }
  //   if (event.target.files && event.target.files[0]) {
  //     setImage(URL.createObjectURL(event.target.files[0]));
  //   }
  // };

  const handleFileChange = (event: any) => {
    const fileObj = event.target.files && event.target.files[0];
    if (!fileObj) {
      return;
    }
    const isValid = ValidationUtiltiy.isValidImg(fileObj.name);
    if (isValid) {
      setFile(fileObj);
      let newFileName =
        // userDetails?.uid +
        "123" +
        "_" +
        event.target.files[0].name.replace(/\..+$/, "");
      uploadImage(fileObj, newFileName);
    } else {
      toast.error("Not a valid File");
      // handleCloseM()
    }



  };


  const uploadImage = (file: any, newFile: any) => {
    setImgLoader(true)
    // handleCloseM()
    const ReactS3Client = new S3(awsConfig);
    ReactS3Client.uploadFile(
      file,
      awsConfig.fleetsSubFolder + "/" + newFile
    ).then((data) => {
      // console.log("upload cover image", data);
      if (data.status === 204) {
        updateUserInput(data.location)
        toast.success("Image Uploaded Click Save Button to Update.");

      } else {
        // setLoader(false)
        console.log("File Not Uploaded !!");
        toast.error("Error Occured");
      }
      setImgLoader(false)
    }).catch((err) => {
      setImgLoader(false)
      console.log({ err })
      toast.error("Something Went Wrong.")
    })
  };


  const updateUserInput = (imageUrl: string) => {
    console.log(imageUrl)
    // if (couponImage !== "") userInput["couponImgUrl"] = couponImage;
    if (imageUrl !== "") setUserInput({ ...userInput, imageUrl: imageUrl })
  }

  console.log({ file });

  const handleAddFleet = () => {
    // const payload = { ...userInput }
    isValidRequest().then(() => {
      if (file === null || file === undefined) {
        toast.error("Please Select Fleet Image.");
        return
      }

      setLoader(true)
      serviceProvider.fleetService.post(userInput).then((res) => {
        console.log({ res })
        if (res.responseCode === 200) {
          toast.success(res.message)
          setAllFleets((prevFleets: any) => [...prevFleets, res.data]);
          handleCloseModal();
          // setTimeout(() => {
          //   window.location.reload();
          // }, 500)
          getAllFleets()
        } else {
          toast.error(res?.message || "Something Went Wrong")
        }
        setLoader(false)

      }).catch((err) => {
        toast.error(err?.data?.message || "Something Went Wrong !!")
        handleCloseModal()
        console.log({ err })
        setLoader(false)
      })
    }).catch(() => {
      toast.error('Please fill all the Required Fields!');
    })
  }

  // if(loader){
  //   return <SpinLoader/>
  // }

  return (
    <Grid container rowSpacing={4} className='add-fleet-container'>
      <Grid item xs={12}>
        {
          imgLoader ? <Loader height='230px' width='100%'/> : <Card className='fleet-image-card'>
          <img src={userInput.imageUrl ? userInput.imageUrl : addImageIcon} className={`${userInput.imageUrl ? "card-img" : "add-img"}`} alt="add image icon" onClick={handleChooseFile} />
          <input
            style={{ display: 'none' }}
            ref={chooseFileRef}
            type="file"
            onChange={handleFileChange}
          />
        </Card>
        }
       
      </Grid>
      <Grid item xs={12}>
        <Grid container rowSpacing={2}>
          <Grid item xs={12}>
            <Typography variant='h6' component='h6'>Fleet details</Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField id="fleet-name" className='search-input' size='small' placeholder='Fleet Name' name="name" value={userInput.name} onChange={handleInputChange} fullWidth variant="standard" InputProps={{ disableUnderline: true, sx: { mx: "10px" } }} />
            <span className="modalError" id='errorDiv-fleet-name'></span>
          </Grid>
          <Grid item xs={12} className="btn-container">
          <ButtonComponent
          fullWidth={false}
          loader={loader}
          disabled={loader}
          label="Save"
          onClick={handleAddFleet}
        />
            {/* <Button variant="contained" className="rounded-btn-fill" onClick={handleAddFleet}>Save</Button> */}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AddFleet;




const isValidRequest = (): Promise<boolean> => {
  let responseArray: IValidationResponse[] = [];
  responseArray.push(validateName());

  // responseArray.push(ValidationUtiltiy.validateElement("signup-confirmPassword", ValidationType.Text, true, "errorDiv-cpwd", "Confirm Password"));
  let haveErrors = responseArray.filter((x) => {
    return !x.isValid;
  }).length <= 0;
  return new Promise((resolve, reject) => {
    if (haveErrors) {
      resolve(true);
    } else {
      reject(false);
    }
  });
}

const validateName = () => {
  return ValidationUtiltiy.validateElement("fleet-name", ValidationType.Text, true, "errorDiv-fleet-name", "Name");
}

