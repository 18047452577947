import { HttpService } from '../commonService/httpService';
import { HttpMethod } from '../../enum/httpMethod';
import { IUserService, IApiIntegration } from '../../interface/IService';
import { IUser } from '../../interface/IUser';
// import { ICompleteUserDetails, IUpdateUserLink, IUser } from '../../interface/IUser';
import { sessionUtility } from '../../utility/sessionUtility';
export class UserService extends HttpService implements IUserService, IApiIntegration {
    urls: { get: string; post: string; delete: string; put: string; patch: string; };
    userDetails = sessionUtility.getAuthentication();
    constructor() {
        super();
        this.urls = {
            get: "users",
            post: "users/register",
            put: `users`,
            delete: "",
            patch: "",
        }
        if (!this.userDetails) {
            this.userDetails = sessionUtility.getAuthentication();
        }
    }
    header: Headers | string[][] | Record<string, string>=sessionUtility.getHeaderWithToken(this.userDetails);

    // getAllUsers(): Promise<IUser[]> {
    //     const url = "users/search?uId=NA&email=all&fullName=all&isActive=NA&offset=0&limit=10&order=recordUpdatedts.desc&profile=BASIC"
    //     return this.fetch(HttpMethod.Get, url, this.header) as Promise<IUser[]>;
    // }
   
    // getSearchResults(param: any): Promise<any> {
    //     const url = "users/search?" + param
    //     return this.fetch(HttpMethod.Get, url, this.header) as Promise<any>;
    // }
    // getCompleteUserDetails(uid: string): Promise<ICompleteUserDetails[]> {
    //     const url = `${this.urls.get}/${uid}`
    //     return this.fetch(HttpMethod.Get, url, this.header) as Promise<ICompleteUserDetails[]>;
    // }
    get(param: any): Promise<IUser> {
        const url = this.setUrlParam(param, this.urls.get);
        return this.fetch(HttpMethod.Get, url, this.header) as Promise<IUser>;
    }
    put<E>(param: E, entity: any): Promise<any> {
        const url = this.setUrlParam(param, this.urls.put);
        return this.putQuery(url, this.header, entity) as Promise<any>;
    }

    post<E>(entity: E): Promise<any> {
        return this.postQuery(this.urls.post, this.header, entity) as Promise<any>;
    }

    patch(param: any): Promise<any> {
        const url = this.setUrlParam(param, this.urls.patch);
        return this.postQuery(url, this.header) as Promise<any>;
    }
    delete(param: any): Promise<any> {
        const url = this.setUrlParam(param, this.urls.delete);
        return this.postQuery(url, this.header) as Promise<any>;
    }
    requestOtp(mobileNumber: string): Promise<boolean> {
        const url = `users/requestOtp?mobileNumber=${mobileNumber}`
        return this.fetch(HttpMethod.Get, url, this.header) as Promise<any>;
    }
    // updateLink(uid: string, payload: IUpdateUserLink): Promise<boolean> {
    //     const url = `users/profile/${uid}/upload`;
    //     return this.put(url, payload) as Promise<any>;
    // }
    // parseResume(uid: string): Promise<any> {
    //     const url = `user/${uid}/resumes/parse`;
    //     return this.fetch(HttpMethod.Get, url, this.header) as Promise<any>;
    // }
}