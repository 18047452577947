import React, { useState } from 'react';
import { Grid, Typography } from '@mui/material';
import QuotationTabs from './QuotationTabs';

const InstantQuotation = () => {
  const [value, setValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Grid container>
          <Grid item xs={5} md={5}>
            <Typography variant="h6" component="h6">
              Instant Quotation
            </Typography>
          </Grid>
          
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <QuotationTabs value={value} handleChange={handleChange} />
      </Grid>
      
    </Grid>
  );
};

export default InstantQuotation;
