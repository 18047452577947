import {  DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import calendarIcon from '../../assets/icons/calender-icon.svg';
import { IconButton } from '@mui/material';
import dayjs from 'dayjs';

const DatePickerComponent = ({ disableFuture =false,className="",defaultValue,disablePast=false,placeholder,value, onChange }: any) => {

    const defaultDate= defaultValue ? dayjs(defaultValue) : undefined;
    return (<LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
            value={value}
            defaultValue={defaultDate}
            onChange={onChange}
            className={className}
            disableFuture={disableFuture}
            disablePast={disablePast}
            format='DD-MM-YYYY'
            slotProps={{
                textField: {
                    fullWidth: true,
                    inputProps: {
                        placeholder: placeholder,
                    }
                }
            }}
            slots={{
                openPickerIcon: () => (
                    <IconButton sx={{zIndex:10}}>
                        <img src={calendarIcon} alt="icon" height="20px" width="20px" />
                    </IconButton>
                ),

            }}
        />
        

    </LocalizationProvider>
    );
};

export default DatePickerComponent;
