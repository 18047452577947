import React from 'react';
import { Tabs, Tab, Box } from '@mui/material';
import { CustomTabPanel, a11yProps } from '../Tab/CustomTabPanel';
import FeedTable from './FeedTable';



  const FeedTabs = ({pageState,setPageState,value,handleChange,getAllFeeds}:any) => {
  
  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab label="Active" {...a11yProps(0)} />
          <Tab label="Inactive" {...a11yProps(1)} />
        </Tabs>
      </Box>

      {/* ... */}

      <CustomTabPanel value={value} index={0}>
      <FeedTable pageState={pageState} setPageState={setPageState} getAllFeeds={getAllFeeds}  />
            </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
      <FeedTable pageState={pageState} setPageState={setPageState} getAllFeeds={getAllFeeds}  />
            </CustomTabPanel>
         
    </Box>
  );
};

export default FeedTabs;
