import { HttpService } from '../commonService/httpService';
import { HttpMethod } from '../../enum/httpMethod';
import { sessionUtility } from '../../utility/sessionUtility';
import { IFlightService } from '../../interface/IService';


export class FlightService extends HttpService implements IFlightService {
    urls: { get: string; post: string; delete: string; put: string; patch: string; };
    userDetails = sessionUtility.getAuthentication();
    constructor() {
        super();
        this.urls={
            get:"flights",
            post:"flights",
            put:"flights",
            delete:"",
            patch:"",
        }
        if (!this.userDetails) {
            this.userDetails = sessionUtility.getAuthentication();
        }
    }
    header: Headers | string[][] | Record<string, string>=sessionUtility.getHeaderWithToken(this.userDetails);
    // getAllFlights(param: any): Promise<any[]> {
    //     const url = "flights?" + param
    //     return this.fetch(HttpMethod.Get, url, this.header) as Promise<any[]>;
    // }

    // editFlightDetails<E>(flightId:string,entity:E): Promise<any[]> {
    //     const url =`flights?flightId=${flightId}`
    //     return this.putQuery(url, this.header, entity) as Promise<any>;
    // }
   
    // getDescription(param:any):Promise<any[]> {
    //     const url=`jobs/${param}`;
    //     return this.fetch(HttpMethod.Get, url, this.header) as Promise<any>;
    // }
    get(param: any): Promise<any> {
        // const url=this.setUrlParam(param,this.urls.get);
        const url = "flights?" + param
        return this.fetch(HttpMethod.Get, url, this.header) as Promise<any>;
    }
    put(param: any, entity: any): Promise<any> {
        // const url = `flightsflightId=${param}`
        // const url =`${param.status}`!='undefined'? `${this.urls.put}/${param.jobId}/activate/${param.status}`:`${this.urls.put}/${param.jobId}`;
        const url = this.setUrlParam(param, this.urls.put);
        return this.putQuery(url, this.header, entity) as Promise<any>;
    }
    
    post<E>(entity: E): Promise<any> {
        return this.postQuery(this.urls.post, this.header, entity) as Promise<any>;
    }
    patch(param: any): Promise<any> {
        const url = this.setUrlParam(param, this.urls.patch);
        return this.postQuery(url, this.header) as Promise<any>;
    }
    delete(param: any): Promise<any> {
        const url = this.setUrlParam(param, this.urls.delete);
        return this.postQuery(url, this.header) as Promise<any>;
    }
    
    // addAircraft<E>(entity: E): Promise<any>{
    //     const url = 
    //     return this.postQuery(url, this.header, {}) as Promise<any>;
    // }
    // sendJobMail(jobId:string, uId:string, emailIdList:string, mailSubject:string, mailBody:string): Promise<any> {
    //     const url = `jobs/mails?type=invite&jobId=${jobId}&recruiterUId=${uId}&emailIdList=${emailIdList}&mailSubject=${mailSubject}&mailBody=${mailBody}`
    //     return this.postQuery(url, this.header) as Promise<any>;
    // }
    // postJobShortUrl(jobId: string, shortURL: string)
    // {
    //     const url = `customurls?customUrlFromUser=${shortURL}&entityId=${jobId}&originalUrl=desc/${jobId}`;
    //     return this.postQuery(url, this.header, {}) as Promise<any>;
    // }
    // getLongUrl(shortURl:string, EntityId:string) {
    //     const url = `customurls?entityId=${EntityId}&shortUrl=${shortURl}`;
    //     return this.fetch(HttpMethod.Get, url, this.header) as Promise<any>;
    // }
   
    // getJobDesignation(){
    //     const url = "common/jobdesignation"
    //     return this.fetch(HttpMethod.Get, url,this.header) as Promise<any>;
    // }
    // parseJD(param:any):Promise<any>{
    //     const url = `jobs/jobdescription?jobDescriptionLink=${param}`
    //     return this.fetch(HttpMethod.Post,url,this.header) as Promise<any>;
    // }
    // deleteScreenQues(param:any):Promise<any>{
    //     const url = `jobs/screeningquestions/${param}`;
    // return this.fetch(HttpMethod.DELETE,url,this.header) as Promise<any>;
    // }
} 
