import React, { useState } from "react";
import { Grid, TextField, Button, Box, Typography, Alert, InputAdornment } from "@mui/material";
import AutoCompleteComponent from '../../common/AutoCompleteComponent';
import { IAirport } from "../../../interface/IAirport";
import DateTimePickerComponent from "../../common/DateTimePickerComponent";
import dayjs from "dayjs";
import styles from "./GenerateQuotation.module.scss";
import seatIcon from "../../../assets/icons/seat.svg";
import departureIcon from "../../../assets/icons/depature-icon.svg";
import destinationIcon from "../../../assets/icons/destination-icon.svg";
import ButtonComponent from "../../common/ButtonComponent";

interface Route {
  from: string;
  to: string;
  fromAirportName: string;
  toAirportName: string;
  fhandler: string;
  thandler: string;
  pcount: string;
  date: string;
}

const EditRouteQuotation = ({
  globalRoutes,
  onUpdateQuotation,
  airports,
  onClose,
}) => {
  const [editableRoutes, setEditableRoutes] = useState<Route[]>([...globalRoutes]);
  const [errors, setErrors] = useState<{ [index: number]: string }>({});

  const handleInputChange = (index: number, field: keyof Route, value: string) => {
    const updatedRoutes = [...editableRoutes];
    updatedRoutes[index] = { ...updatedRoutes[index], [field]: value };
    setEditableRoutes(updatedRoutes);
    validateRoutes(index, updatedRoutes[index]); // Validate after updating
  };

  const handleAirportChange = (
    index: number,
    field: "from" | "to",
    selectedOption: IAirport | null
  ) => {
    const updatedRoutes = [...editableRoutes];
    updatedRoutes[index] = {
      ...updatedRoutes[index],
      [field]: selectedOption ? selectedOption.airportId : "",
      [`${field}AirportName`]: selectedOption ? selectedOption.name : "",
    };
    setEditableRoutes(updatedRoutes);
    validateRoutes(index, updatedRoutes[index]); // Validate after updating
  };

  const validateRoutes = (index: number, route: Route) => {
    const validationErrors = { ...errors };
    if (route.from === route.to && route.from) {
      validationErrors[index] = "From and To airports cannot be the same.";
    } 
    else if (!route.pcount || isNaN(Number(route.pcount)) || Number(route.pcount) <= 0) {
        validationErrors[index] = "Passenger count must be a valid number greater than 0.";
      } 
    else {
      delete validationErrors[index];
    }
    
      
    setErrors(validationErrors);
  };

  const hasValidationErrors = () => Object.keys(errors).length > 0;

const handleSave = () => {
  if (!hasValidationErrors()) {
    onUpdateQuotation(editableRoutes);
    console.log("editableRoutes"+editableRoutes);
  }
};


  return (
    <Grid container spacing={2} padding={2}>
      {editableRoutes.map((route, index) => (
        <Grid key={index} container item spacing={2}>
          {/* Validation Message */}
          {errors[index] && (
            <Grid item xs={12}>
              <Alert severity="error">{errors[index]}</Alert>
            </Grid>
          )}

          {/* From Airport */}
          <Grid item xs={12} md={3}>
          <Typography>From Airport:<b>{route.fromAirportName}</b></Typography>
          <Grid container className={styles.depatureArrivalAirportBox}>
          <Grid item xs={12} className={styles.border}>
            <AutoCompleteComponent
              options={airports}
              getOptionLabel={(option: IAirport) =>
                `${option.name} (${option.icaoCode}) - ${option.city}`
              }
              renderOption={(props: any, option: IAirport) => (
                <Box
                  sx={{
                    width: "100%",
                    borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
                  }}
                  {...props}
                >
                  <Typography textAlign="left">
                    {option.name} ({option.icaoCode}) - {option.city}
                  </Typography>
                </Box>
              )}
              label="From Airport"
              value={
                airports.find((airport) => airport.airportId === route.from) || null
              }
              fullWidth
              onChange={(event, selectedOption) =>
                handleAirportChange(index, "from", selectedOption)
              }
            >
            <img
          src={departureIcon}
          alt="departure-icon"
          height="25px"
          width="25px"
        />
        </AutoCompleteComponent>
          </Grid>
          </Grid>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
          <Typography>From Handler:</Typography>
          <Grid container className={styles.quotationInput}>
            <TextField
                size="small"
                type="text"
                variant="standard"
                placeholder="Enter from handler"
              value={route.fhandler}
              fullWidth
              InputProps={{
                disableUnderline: true,
              }}
              onChange={(e) => handleInputChange(index, "fhandler", e.target.value)}
            />
          </Grid>
          </Grid>
          {/* To Airport */}
          <Grid item xs={12} md={3}>
          <Typography>To Airport:<b>{route.toAirportName}</b></Typography>
  <Grid container className={styles.depatureArrivalAirportBox}>
    <Grid item xs={12} className={styles.border}>
            <AutoCompleteComponent
              options={airports}
              getOptionLabel={(option: IAirport) =>
                `${option.name} (${option.icaoCode}) - ${option.city}`
              }
              renderOption={(props: any, option: IAirport) => (
                <Box
                  sx={{
                    width: "100%",
                    borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
                  }}
                  {...props}
                >
                  <Typography textAlign="left">
                    {option.name} ({option.icaoCode}) - {option.city}
                  </Typography>
                </Box>
              )}
              
              value={
                airports.find((airport) => airport.airportId === route.to) || null
              }
              fullWidth
              onChange={(event, selectedOption) =>
                handleAirportChange(index, "to", selectedOption)
              }
              >
              <img
                 src={destinationIcon}
                 alt="departure-icon"
                 height="25px"
                 width="25px"
               />
               </AutoCompleteComponent>
          </Grid>
          </Grid>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
          <Typography>To Handler:</Typography>
            <Grid container className={styles.quotationInput}>
            <TextField
            size="small"
                type="text"
              variant="standard"
                placeholder="Enter to handler"
              value={route.thandler}
              fullWidth
              InputProps={{
                disableUnderline: true,
              }}
              onChange={(e) => handleInputChange(index, "thandler", e.target.value)}
            />
          </Grid>
          </Grid>
          {/* Passengers Count */}
          <Grid item xs={12} md={6} sm={6}>
          <Typography>Passenger Count:</Typography>
            <Grid container className={styles.searchInput}>
            <TextField
            className={styles.customInput}
            size="small"
                type="number"
            //   label="Passengers Count"
              value={route.pcount}
              fullWidth
              variant="standard"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <img
                        src={seatIcon}
                        alt="seat icon"
                        height="25px"
                        width="25px"
                      />
                    </InputAdornment>
                  ),
                  disableUnderline: true,
                }}
              
              onChange={(e) => handleInputChange(index, "pcount", e.target.value)}
            />
          </Grid>
          </Grid>

          {/* Date */}
          <Grid item xs={12} sm={6}>
          <Typography>Start Date:</Typography>
            <Grid container className={styles.depatureArrivalAirportBox}>
          <DateTimePickerComponent
          disablePast={true}
  placeholder="Start Date and Time"
  value={route.date ? dayjs(route.date) : null}
  fullWidth
  onChange={(value) => handleInputChange(index, "date", value ? value.toISOString() : "")}
/>

          </Grid>
          </Grid>
        </Grid>
      ))}

      {/* Save and Cancel Buttons */}
      <Grid container item spacing={2} justifyContent="flex-end">
        <Grid item>
          <ButtonComponent fullWidth={false} onClick={handleSave} label='Update' />
           
        </Grid>
        <Grid item>
        <ButtonComponent fullWidth={false} onClick={onClose} label='Cancel' />

        </Grid>
      </Grid>
    </Grid>
  );
};

export default EditRouteQuotation;
