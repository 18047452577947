import React, { useState } from 'react'
import { Typography, Card, Grid, CardMedia, CardContent, Button } from "@mui/material";
import turboprop from "../../../assets/images/turboprops.svg";
import deleteIcon from "../../../assets/icons/delete-icon.svg";
import { useLocation, useNavigate } from 'react-router-dom';
import { serviceProvider } from '../../../provider/serviceProvider';
import { toast } from "react-toastify";
const AircaftCard: React.FC<any> = ({ item }) => {

  const { aircraftId, name, passengerCapacity, imageUrl, technicalSpecs } = item;
  const images = imageUrl !== "" && JSON.parse(imageUrl);
  const [loader, setLoader] = useState(false)

  const location = useLocation();

  // Accessing the search property which contains the query parameters
  const queryParams = new URLSearchParams(location.search);

  // Getting the value of the 'index' parameter
  const index = queryParams.get('index');

  // Convert the 'index' value to a number if needed
  // const indexNumber = parseInt(index, 10);
  // console.log(imageUrl,JSON.parse(technicalSpecs))
  // const image= imageUrl &&  JSON.parse(imageUrl)[0];
  // console.log("iamge",image)
  // console.log("technical",technicalSpecs)
  // const paresdTechnicalSpecs = JSON.parse(technicalSpecs);
  // console.log({paresdTechnicalSpecsData});
  // console.log(JSON.parse(paresdTechnicalSpecsData).exteriorLength)

  // console.log({paresdTechnicalSpecs})
  // console.log(images[0]);

  const handleDeleteAircraft = (aircraftId: string) => {
    setLoader(true);
    serviceProvider.aircraftService.delete(aircraftId).then((res: any) => {
      setLoader(false)
      if (res.responseCode === 204) {
        toast.success(res.message);
        window.location.reload()
      } else {
        toast.error(res?.message||"Something Went Wrong.")
      }
    }).catch((err) => {
      console.log(err)
      setLoader(false)
      toast.error(err?.data?.message || "Something Went Wrong.")
    })
  }

  const navigate = useNavigate()
  return (
    <Card className="fleet-card">
      <CardMedia
        component="img"
        height="140"
        image={images[0]}
        alt="turboProp"
      />
      <CardContent className='card-content'>
        <Typography gutterBottom variant="h6" component="h6">
          {/* Beechcraft King Air C90 */}
          {name}
        </Typography>
        <Typography variant="body1" component="p">
          {/* Model :{paresdTechnicalSpecs.engineModel} */}
        </Typography>
        <Typography variant="body1" component="p">
        Guest Capacity : {passengerCapacity}
        </Typography>
        <Typography variant="body1" component="p">
          {/* Maximum Speed  : {paresdTechnicalSpecs.highSpeed} */}
        </Typography>
        <Grid container>
          <Grid item xs={12} className='btns-container'>
            {/* <Button variant="contained" className="rounded-btn-fill" onClick={() => navigate(`/fleet/aircraft/${aircraftId}`)}>View more</Button> */}
            <Button variant="contained" className="rounded-btn-fill" onClick={() => navigate(`/fleets/aircraft/${aircraftId}?index=${index}`)}>View more</Button>

            <Button variant='text' onClick={() => handleDeleteAircraft(aircraftId)}>
              <img src={deleteIcon} alt="delete Icon" width="15px" />
            </Button>

          </Grid>
        </Grid>
      </CardContent>

    </Card>
  )
}

export default AircaftCard