// UserAddModal.js
import React, { useEffect, useState } from 'react';
import { Button, InputBase, Typography, Grid, FormControl, Select, MenuItem } from "@mui/material";
import { makeStyles } from '@mui/styles';
import { serviceProvider } from '../../provider/serviceProvider';
import { IRole } from '../../interface/IRole';
import InputFieldComponent from '../common/InputFieldComponent';
import SelectFieldComponent from '../common/SelectFieldComponent';
import { ValidationUtiltiy } from '../../utility/ValidationUtility';
import { ValidationType } from '../../enum/validationType';
import { IValidationResponse } from '../../interface/IValidation';
import { toast } from 'react-toastify';
import ButtonComponent from '../common/ButtonComponent';

const useStyles = makeStyles((theme) => ({
  input: {
    // border:"1px solid red",
    height: "100%",
    color: 'inherit', // Text color
    width: '100%',
    backgroundColor: 'transparent', // Remove background
    fontSize: '16px', // Set font size
    padding: '0', // Set padding
    '&:focus': {
      outline: 'none', // Remove outline on focus
    },
  },
  select: {
    borderBottom: "0px solid red !important",
    "&:hover": {
      borderBottom: "0px solid rgba(0,0,0,0)"
    }
  }
}));

interface UserInput {
  agree_promotions: boolean;
  agree_terms: boolean;
  emailId: string;
  fullName: string;
  mobileNumber: string;
  roleId: string;
}

const initialUserInput: UserInput = {
  agree_promotions: true,
  agree_terms: true,
  emailId: "",
  fullName: "",
  mobileNumber: "",
  roleId: ""
}
interface AddUserProps {
  handleCloseModal: () => void;
  getUsersList:any,
  pageState:any
  // onAddUser: (user: UserInput) => void;
}
const AddUser: React.FC<AddUserProps> = ({  handleCloseModal,getUsersList,pageState }) => {
  const classes = useStyles();
  const [userInput, setUserInput] = useState<UserInput>(initialUserInput);
  const [loader, setLoader] = useState<boolean>(false);
  const [allRoles, setAllRoles] = useState([]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    console.log("Selected Role:", event.target.value);
    
    const { name, value } = event.target;
    setUserInput((prevUserInput) => ({
      ...prevUserInput,
      [name]: value 
    }));
    // setUserInput({ ...userInput, [name]: value });
    validateFieldsOnInput(name)

  };

  const validateFieldsOnInput = (key: string) => {
    switch (key) {
      case 'fullName':
        validateName();
        break;
      case 'emailId':
        validateEmail();
        break;
      case 'mobileNumber':
        validateMobile();
        break;
      case 'roleId':
        validateRole();
        break;
      default:
        break;
    }
  };


  // const handleAddUser = (userInput:any) => {
   
  // };

  const handleAddUser = (userInput:any) => {
    // Validate and process user input
    isValidRequest().then(() => {
      // onAddUser(userInput);
      // setUserInput(initialUserInput);
      // handleCloseModal()

      setLoader(true);
      serviceProvider.userService.post(userInput).then((res) => {
        // console.log({ res });
        if (res.responseCode === 200) {
          toast.success(res.message);
          handleCloseModal();
          // setTimeout(()=>{
          //   window.location.reload();
          // },100)
          const query = `search?uId=NA&email=all&fullName=all&isActive=${true}&offset=${pageState.page}&limit=${pageState.pageSize}&order=recordUpdatedts.desc&profile=BASIC`
          getUsersList(query)
        }else{
          toast.error(res?.message || "Something Went Wrong!!");
        }
        setLoader(false);
      }).catch((err) => {
        toast.error(err?.data?.message || "Something Went Wrong!!");
        console.log({ err });
        setLoader(false);
        // handleCloseModal()
      })
    }).catch(() => {
      toast.error('Please fill all the Required Fields!');
    })

  };

  console.log({ userInput })


  const getAllRoles = () => {

    // setLoader(true)
    serviceProvider.roleService.getAllRoles().then((res) => {
      if (res.responseCode === 200) {
        setAllRoles(res.data)
      }
      // setLoader(false)
      console.log({ res })
    }).catch((err) => {
      console.log({ err })
      // setLoader(false)
    })
  }

  useEffect(() => {
    getAllRoles()
  }, [])




  return (
    <Grid container spacing={1}>
      <Grid item xs={12} className="modal-table-container">
        <Grid container className='heading'>
          <Grid item xs={12}>
            <Typography variant='h6' component='h6'>Add User</Typography>

          </Grid>
        </Grid>
        <Grid container className="edit-detail-item">

          <Grid item xs={6} className='edit-item dark-bg'>
            <Typography variant='body1' component='p'>
              Full Name
            </Typography>
          </Grid>
          <Grid item xs={6} className='edit-item'>
            <div>
              <InputFieldComponent
                id="user-fullName"
                name="fullName"
                type="text"
                placeholder="Full Name"
                value={userInput.fullName}
                onChange={handleInputChange}
              />

              <span className="modalError" id='errorDiv-user-fullName'></span>
            </div>


            {/* <InputBase
              className={classes.input}
              placeholder="Full Name"
              name="fullName"
            value={userInput.fullName}
            onChange={handleInputChange}
              inputProps={{ 'aria-label': 'custom input' }}
            /> */}
            {/* <Typography variant='body1' component='p'>
        BMW
      </Typography> */}
          </Grid>
        </Grid>
        <Grid container className="edit-detail-item">

          <Grid item xs={6} className='edit-item dark-bg'>
            <Typography variant='body1' component='p'>
              Email
            </Typography>
          </Grid>
          <Grid item xs={6} className='edit-item'>
            <div>
              <InputFieldComponent
                id="user-emailId"
                name="emailId"
                type="email"
                placeholder="Email"
                value={userInput.emailId}
                onChange={handleInputChange}
              />
              <span className="modalError" id='errorDiv-user-emailId'></span>
            </div>

            {/* <InputBase
              className={classes.input}
              name="emailId"
            placeholder="Email"
            value={userInput.emailId}
            onChange={handleInputChange}
              inputProps={{ 'aria-label': 'custom input' }}
            /> */}
            {/* <Typography variant='body1' component='p'>
        BMW
      </Typography> */}
          </Grid>
        </Grid>
        <Grid container className="edit-detail-item">

          <Grid item xs={6} className='edit-item dark-bg'>
            <Typography variant='body1' component='p'>
              Contact
            </Typography>
          </Grid>
          <Grid item xs={6} className='edit-item'>
            <div>
              <InputFieldComponent
                id={"user-mobileNumber"}
                name="mobileNumber"
                type="tel"
                maxLength={10}
                placeholder="Contact"
                value={userInput.mobileNumber}
                onChange={handleInputChange}
              />
              <span className="modalError" id='errorDiv-user-mobileNumber'></span>
            </div>
            {/* <InputBase
              className={classes.input}
              name="mobileNumber"
              placeholder="Contact"
              value={userInput.mobileNumber}
              onChange={handleInputChange}
              inputProps={{ 'aria-label': 'custom input' }}
            /> */}

          </Grid>
        </Grid>
        <Grid container className="edit-detail-item">

          <Grid item xs={6} className='edit-item dark-bg'>
            <Typography variant='body1' component='p'>
              Role
            </Typography>
          </Grid>
          <Grid item xs={6} className='edit-item'>
            <div style={{ width: "100%" }}>
              <SelectFieldComponent id="user-roleId" name="roleId" value={userInput.roleId} options={allRoles} labelKey="roleDisplayName" optionKey="roleId" onChange={handleInputChange} />
              <span className="modalError" id='errorDiv-user-roleId'></span>
            </div>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} >
        <Grid container columnSpacing={2} display="flex" justifyContent="center" alignItems="center" mt="20px">
          <Grid item xs={2}>
            {/* <Button variant="contained" className="rounded-btn-fill" onClick={handleAddUser}>Save</Button> */}
            <ButtonComponent loader={loader} disabled={loader} fullWidth={false} onClick={()=>handleAddUser(userInput)} label="Save" />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AddUser;


const isValidRequest = (): Promise<boolean> => {
  let responseArray: IValidationResponse[] = [];
  responseArray.push(validateEmail());
  responseArray.push(validateName());
  responseArray.push(validateMobile());
  responseArray.push(validateRole());
  // responseArray.push(ValidationUtiltiy.validateElement("signup-confirmPassword", ValidationType.Text, true, "errorDiv-cpwd", "Confirm Password"));
  let haveErrors = responseArray.filter((x) => {
    return !x.isValid;
  }).length <= 0;
  return new Promise((resolve, reject) => {
    if (haveErrors) {
      resolve(true);
    } else {
      reject(false);
    }
  });
}

const validateEmail = () => {
  return ValidationUtiltiy.validateElement("user-emailId", ValidationType.Email, true, "errorDiv-user-emailId", "Email");
}

const validateMobile = () => {
  return ValidationUtiltiy.validateElement("user-mobileNumber", ValidationType.Mobile, true, "errorDiv-user-mobileNumber", "Contact Info")
}

const validateName = () => {
  return ValidationUtiltiy.validateElement("user-fullName", ValidationType.Text, true, "errorDiv-user-fullName", "Name");
}
const validateRole = () => {
  return ValidationUtiltiy.validateElement("user-roleId", ValidationType.MUISelect, true, "errorDiv-user-roleId", "Role");
}
// const validateQuery = () => {
//   return ValidationUtiltiy.validateElement("query-queryDescription", ValidationType.Text, true, "errorDiv-query-queryDescription", "Query Description");
// }
