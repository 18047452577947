// SearchBar.js
import React from 'react';
import { TextField, InputAdornment } from "@mui/material";
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';

const SearchBar = ({ value,onChange,placeholder }:any) => {
  return (
    <TextField
      id="outlined-basic"
      className='search-input'
      size='small'
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      fullWidth
      variant="standard"
      InputProps={{
        endAdornment: (
          <InputAdornment position="start" sx={{ padding: "0 10px" }}>
            <SearchRoundedIcon sx={{ color: "#808080" }} />
          </InputAdornment>
        ),
        disableUnderline: true,
        sx: { mx: "10px" }
      }}
    />
  );
};

export default SearchBar;
