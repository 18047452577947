import React, { useState, useEffect } from "react";
import { Grid, Button, Typography } from "@mui/material";
import { serviceProvider } from "../../provider/serviceProvider";
import BlogTabs from "./BlogTabs";
import { useLocation, useNavigate } from "react-router-dom";
import SearchBar from "../../components/searchBar/SearchBar";

const BlogsManagement = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [value, setValue] = React.useState(0);
  const [pageState, setPageState] = React.useState({
    loader: false,
    data: [],
    totalPages: 0,
    page: 0,
    pageSize: 5,
  });

  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const index = searchParams.get("index");

  // Convert the 'index' value to a number if needed
  const indexNumber = parseInt(index + "", 10);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    navigate(`/blogs-management?index=${newValue}`)
  };



  const getBlogsList = (param: any) => {
    // setLoader(true);
    setPageState((prev) => ({ ...prev, loader: true }));

    serviceProvider.blogService
      .get(param)
      .then((res) => {
        // setLoader(false)
        if (res.httpStatus === 200) {
          setPageState((prev) => ({
            ...prev,
            loader: false,
            data: res?.result?.data,
            totalPages: res?.result?.totalRecordCount,
          }));
        }
        setPageState((prev) => ({ ...prev, loader: false }));
      })
      .catch((err) => {
        // setLoader(false)
        setPageState((prev) => ({ ...prev, loader: false }));
        console.log({ err });
      });
  };

  useEffect(() => {
    if (indexNumber) {
      setValue(indexNumber);
    } else {
      setValue(0);
    }
  }, [indexNumber]);

  
  useEffect(() => {
    const isActive = (value || indexNumber ) === 0 ? true : false;
   const query = `search?uId=NA&categoryId=NA&name=${searchQuery || "NA"}&tags=NA&content=NA&categoryName=NA&blogpostId=NA&custUrl=NA&offset=${pageState.page}&limit=${pageState.pageSize}&order=recordUpdatedTs.desc&profile=BASIC&title=NA&isActive=${isActive}&isFeatured=false`;
  getBlogsList(query);
  }, [value,indexNumber, pageState.page, pageState.pageSize,searchQuery]);

  return (
    
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={5} md={5}>
              <Typography variant="h6" component="h6">
                Blogs
              </Typography>
            </Grid>
            <Grid item xs={7}>
              <SearchBar
              value={searchQuery}
                onChange={(e:any)=>setSearchQuery(e.target.value)}
                placeholder="Search by username"
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Button
            variant="contained"
            className="rounded-btn-fill"
            onClick={()=>navigate("/blogs-management/post")}
          >
            Add Blog
          </Button>
        </Grid>
        <Grid item xs={12}>
   
          <BlogTabs
            pageState={pageState}
            setPageState={setPageState}
            value={value}
            handleChange={handleChange}
            getBlogsList={getBlogsList}
          />
         
        </Grid>
      </Grid>
    
  );
};

export default BlogsManagement;
