import React, { useEffect, useState } from "react";
import { serviceProvider } from "../../provider/serviceProvider";
import { toast } from "react-toastify";
import SpinLoader from "../loader/SpinLoader";
import { Box, Checkbox, FormControl, FormControlLabel, Grid, MenuItem, Select } from "@mui/material";
import CommonPagination from "../common/CommonPagination";
import Loader from "../loader/Loader";
import SearchBar from "../searchBar/SearchBar";
import NoResult from "../noResult/NoResult";
import AssingedAircraftCard from "./AssignedAircraftCard";
import AutoCompleteComponent from "../common/AutoCompleteComponent";

// getAllAssingedAircrafts
const AssignedAircrafts = () => {
  const [loader, setLoader] = useState<boolean>(false);
  const [assignedAircraftsLists, setAssignedAircraftsLists] = useState<any>([]);
  const [operatorList, setOperatorList] = useState<any>([]);
  const [operatorId, setOperatorId] = useState<any>("NA");
  const [searchQuery, setSearchQuery] = useState<string>("");


  const getAssignedAircraftsList = (operatorId:any,searchQuery:string) => {
    setLoader(true);
    serviceProvider.aircraftService
      .getAllAssingedAircrafts(operatorId,true,searchQuery || "NA")
      .then((res: any) => {
        // console.log(res);
        if (res.responseCode === 200) {
          setAssignedAircraftsLists(res.data);
        } else {
          toast.error(res?.message || "Something Went Wrong!!");
        }
        setLoader(false);
      })
      .catch((err: any) => {
        setLoader(false);
      });
  };


  

  const getOperatorsList = () => {
    // const searchQuery = e.target.value
    // setSearchQuery(e.target.value)
    // const active = value === 0 ? true : false;


    const operatorRoleId = "eaf1aa60-fee7-4e32-9afc-e04fc8f61c48";

    const query = `search?roleId=${operatorRoleId}&uId=NA&email=all&fullName=all&isActive=NA&offset=0&limit=100000&order=recordUpdatedts.desc&profile=BASIC`;

    
    setLoader(true)
    serviceProvider.userService.get(query).then((res) => {
      // console.log({ res })
      if(res.responseCode === 200){
        setOperatorList(res.data.details)   
      }else{
        toast.error(res?.message || "Something Went Wrong !!")
      }
  // if (res.responseCode === 200 && active)   setPageState(prev=>({...prev,loader:false,data:res.data.details,totalPages:res.data.totalRecordCount}))
      // else if (res.responseCode === 200 && !active)   setPageState(prev=>({...prev,loader:false,data:res.data.details,totalPages:res.data.totalRecordCount}))
      setLoader(false)
    }).catch((err) => {
      console.log({ err });
      setLoader(false)
    })
  }

  useEffect(() => {
   

    
    getAssignedAircraftsList(operatorId,searchQuery);
    
  }, [operatorId,searchQuery]);

  useEffect(()=>{
    getOperatorsList()
  },[])

  //   if(loader){
  //     return <SpinLoader/>
  //   }
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Grid container>
          <Grid item xs={5}>
          <SearchBar
              value={searchQuery}
                onChange={(e:any)=>setSearchQuery(e.target.value)}
                placeholder="Search by call sign"
              />
            </Grid>
            <Grid item xs={7} display="flex" alignItems="center" justifyContent="flex-end">
            <Box
                            sx={{
                                border: "1px solid gray",
                                borderRadius: "20px",
                                // width: "200px",
                            }}
                        >
                            <FormControl fullWidth>
                                <Select
                                    sx={{ padding: "0 10px" }}
                                    value={operatorId}
                                    // name={name}
                                    slotProps={{
                                        input: {
                                            id: "",
                                        },
                                    }}
                                    // id={id}
                                    variant="standard"
                                    disableUnderline
                                    onChange={(e:any)=>setOperatorId(e.target.value)}
                                >
                                  <MenuItem value="NA">Select Operator</MenuItem>
                                  {
                                    operatorList?.length>=1 && operatorList?.map((operator:any)=>{
                                      return <MenuItem value={`${operator?.uid}`}>{operator?.fullName}</MenuItem>
                                    })
                                  }
                                    
                                  
                                </Select>
                            </FormControl>
                        </Box>
            </Grid>
          {/* <Grid item xs={7}>
        <Box display="flex" alignItems="center" justifyContent="flex-end" mb="20px">
          <FormControlLabel
            // value={checked}
            control={<Checkbox size="small"  
            // onChange={handleSearch} 
            />}
            label="Available Aircraft"
            labelPlacement="end"
          />

        </Box>
      </Grid> */}
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid rowSpacing={3} container>
          {loader ? (
            new Array(3).fill(0).map((_, id) => {
              return (
                <Grid item xs={12} key={id}>
                  <Loader height="250px" width="100%" />
                </Grid>
              );
            })
          ) : assignedAircraftsLists.length >= 1 && loader === false ? (
            assignedAircraftsLists.map((aircraft: any) => {
              return (
                <Grid item xs={12}>
                  <AssingedAircraftCard aircraft={aircraft} />
                </Grid>
              );
            })
          ) : (
            <Grid item xs={12}>
              <NoResult title={"Assigned Aircrafts"} />
            </Grid>
          )}
        </Grid>
        <Box className="pagination-box">
          {/* <CommonPagination page={page} setPage={setPage} totalPages={totalPages} /> */}
        </Box>
      </Grid>
    </Grid>
  );
};

export default AssignedAircrafts;
