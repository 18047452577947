import {
  IconButton,
  Typography,
  Divider,
  Grid,
  Tooltip
} from "@mui/material";
import logo from "../../assets/logo/bmjLogo.svg";
import { Link, useNavigate } from "react-router-dom";
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import { sessionUtility } from "../../utility/sessionUtility";


const Header: React.FC = () => {
  const userDetails:any = sessionUtility.getAuthentication().session;
const navigate= useNavigate();

  const logout = () => {
    window.localStorage.clear();
    navigate("/login")
  };
  // console.log(userDetails?.fullName);
  return (
    <>
      <Grid container className="header-container">

        <Grid item xs={2} md={2} className="logo">
          <Link to="/">
          <img src={logo} alt="Bmj Logo" height="auto" width="120px" />
          </Link>
          
          {/* <img alt="logo" src={logo} style={{ width: 50, height: 50 }} /> */}
        </Grid>
        <Grid item xs={10} md={10} className="header-content">
          {/* <Box sx={{ display:"flex",alignItems:"center",gap:"10px"  }}> */}
          <Typography variant='h6' component='h6' sx={{ display: { xs: 'flex', md: 'flex' }}}>
            {userDetails?.fullName || "--"}</Typography>
          {/* <Divider color="#fff" sx={{ width: '0.8px' }} orientation="vertical" flexItem />
          <Tooltip title="Settings">
            <IconButton
              size="large"
              aria-label="show 17 new notifications"
              color="inherit"
            >

              <SettingsOutlinedIcon />

            </IconButton>
          </Tooltip> */}
          <Divider color="#fff" sx={{ width: '0.8px' }} orientation="vertical" flexItem />
          <Tooltip title="Logout">
            <IconButton
              size="large"
              edge="end"
              aria-haspopup="true"
              // onClick={handleProfileMenuOpen}
              color="inherit"
              onClick={logout}>
              <LogoutOutlinedIcon />
            </IconButton>
          </Tooltip>
          {/* </Box> */}
        </Grid>

      </Grid>
    </>
  );
};


export default Header;