// import React, { useEffect, useState } from 'react'
// import { Typography, Grid, TextField, InputAdornment, Box, Tabs, Tab } from "@mui/material";
// import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
// import { useNavigate } from 'react-router-dom';
// import QuotationCard from './QuotationCard';
// import { serviceProvider } from '../../provider/serviceProvider';
// import { IQuotation } from '../../interface/IQuotation';
// import Loader from '../loader/Loader';
// import { CustomTabPanel, a11yProps } from '../Tab/CustomTabPanel';
// import SearchBar from '../searchBar/SearchBar';
// // import CancelledQuotations from './CancelledQuotations';
// import NoResult from '../noResult/NoResult';
// import CommonPagination from '../common/CommonPagination';
// import { toast } from 'react-toastify';

// const QuotationManagement: React.FC = () => {
//   const [value, setValue] = React.useState(0);
//   const [loader, setLoader] = useState(false);
//   const [page, setPage] = useState(0);
//   const [totalPages, setTotalPages] = useState(0);
//   const [quotations, setQuotations] = useState([])
//   const navigate = useNavigate()
//   const handleChange = (event: React.SyntheticEvent, newValue: number) => {
//     setValue(newValue);
//   };





 


//   const handleSearch = (e: any) => {
//     const searchQuery = e.target.value
//     // setSearchQuery(e.target.value)
//     let status;
//     if (value === 0) status = "pending"
//     else if (value === 1) status = "sent"
//     else if (value === 2) status = "completed"
//     else status = "cancelled"


//     const query = `search?query=${searchQuery}&status=${status}&offset=${page}&limit=2&order=updatedTs.desc&profile=BASIC`
//     setLoader(true)
//     serviceProvider.quotationService.get(query).then((res: any) => {

//       if (res.responseCode === 200) {
//         setQuotations(res.data.data)
//         setTotalPages(res.data.totalRecordCount);


//       } else {
//         toast.error(res?.message ||"Something Went Wrong!")
//       }

//       // if (res.responseCode === 200 && value === 0) setActiveUsers(res.data.details)
//       // else if (res.responseCode === 200 && value === 1) setInActiveUsers(res.data.details)
//       setLoader(false)
//     }).catch((err) => {
//       console.log({ err })
//       setLoader(false)
//     })
//   }


//   const getQuotations = () => {
//     let query;

//     if (value === 0) {
//       query = `search?query=all&status=pending&offset=${page}&limit=2&order=updatedTs.desc&profile=BASIC`
//     }
//     else if (value === 1) {
//       // setQuotations([])
//       query = `search?query=all&status=sent&offset=${page}&limit=2&order=updatedTs.desc&profile=BASIC`
//     } else if(value === 2){
//       // setQuotations([])
//       query = `search?query=all&status=completed&offset=${page}&limit=2&order=updatedTs.desc&profile=BASIC`
//     } else {
//       // setQuotations([])
//       query = `search?query=all&status=cancelled&offset=${page}&limit=2&order=updatedTs.desc&profile=BASIC`
//     }
//     setLoader(true);

//     serviceProvider.quotationService.get(query).then((res: any) => {
//       if (res.responseCode === 200) {
//         setQuotations(res.data.data)
//         setTotalPages(res.data.totalRecordCount);


//       } else {
//         toast.error(res?.message ||"Something Went Wrong!")
//       }
//       setLoader(false)
//       // setQuotations(res.data)
//       // setTotalPages(res.totalRecordCount)
//     }).catch((err) => {
//       setLoader(false)
//       console.log({ err })
//     })
//   }


//   useEffect(() => {
//     getQuotations()
//   }, [value, page])

//   return (
//     <Grid container spacing={2} className="quotation-container">
//       <Grid item xs={12}>
//         <Grid container>
//           <Grid item xs={5} md={5} className="quotation-heading">
//             <Typography variant='h6' component='h6'>Quotations</Typography>
//           </Grid>
//           <Grid item xs={7} md={7}>
//             <SearchBar onChange={handleSearch} placeholder="Search by Phone number" />
//           </Grid>
//         </Grid>
//       </Grid>

//       <Grid item xs={12} >
//         <Box className="tabs-container">
//           <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
//             <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
//               <Tab label="Pending" {...a11yProps(0)} />
//               <Tab label="Sent" {...a11yProps(1)} />
//               <Tab label="Completed" {...a11yProps(2)} />
//               <Tab label="Cancelled" {...a11yProps(3)} />
//             </Tabs>
//           </Box>
//           <CustomTabPanel value={value} index={0}>
//             {/* <PendingQuotations/> */}
//             <Grid container spacing={4} >
//               {
//                 loader ?
//                   new Array(3).fill(0).map((_) => {
//                     return <Grid item xs={12} md={6} >
//                       <Loader height='300px' width='100%' />
//                     </Grid>
//                   }) : quotations.length >= 1 && loader === false ? quotations?.map((quotation: IQuotation) => {
//                     return <Grid  item xs={12} md={6} display='flex'>
//                       <QuotationCard index={0}  quotation={quotation} />
//                     </Grid>
//                   }) :
//                     <Grid item xs={12}>
//                       <NoResult title={"Pending Quotation"} />
//                     </Grid>
//               }
//             </Grid>
//           </CustomTabPanel>
//           <CustomTabPanel value={value} index={1}>
//             <Grid container spacing={4}>
//               {
//                 loader ?
//                   new Array(3).fill(0).map((_) => {
//                     return <Grid item xs={12} md={6}>
//                       <Loader height='300px' width='100%' />
//                     </Grid>
//                   }) : quotations.length >= 1 && loader === false ? quotations?.map((quotation: IQuotation) => {
//                     return <Grid item xs={12} md={6} display='flex'>
//                       <QuotationCard index={1}  quotation={quotation} />
//                     </Grid>
//                   }) :
//                     <Grid item xs={12}>
//                       <NoResult title={"Sent Quotation"} />
//                     </Grid>
//               }
//             </Grid>
//           </CustomTabPanel>
//           <CustomTabPanel value={value} index={2}>
//             <Grid container spacing={4}>
//               {
//                 loader ?
//                   new Array(3).fill(0).map((_) => {
//                     return <Grid item xs={12} md={6}>
//                       <Loader height='300px' width='100%' />
//                     </Grid>
//                   }) : quotations.length >= 1 && loader === false ? quotations?.map((quotation: IQuotation) => {
//                     return <Grid item xs={12} md={6} display='flex'>
//                       <QuotationCard index={1}  quotation={quotation} />
//                     </Grid>
//                   }) :
//                     <Grid item xs={12}>
//                       <NoResult title={"Completed Quotation"} />
//                     </Grid>
//               }
//             </Grid>
//           </CustomTabPanel>
//           <CustomTabPanel value={value} index={3}>
//             <Grid container spacing={4}>
//               {
//                 loader ?
//                   new Array(3).fill(0).map((_) => {
//                     return <Grid item xs={12} md={6}>
//                       <Loader height='300px' width='100%' />
//                     </Grid>
//                   }) : quotations.length >= 1 && loader === false ? quotations?.map((quotation: IQuotation) => {
//                     return <Grid item xs={12} md={6} display='flex'>
//                       <QuotationCard index={2} quotation={quotation} />
//                     </Grid>
//                   }) :
//                     <Grid item xs={12}>
//                       <NoResult title={"Cancelled Quotation"} />
//                     </Grid>
//               }
//             </Grid>


//           </CustomTabPanel>

//         </Box>

//         <Box className="pagination-box">
//           <CommonPagination page={page} setPage={setPage} totalPages={totalPages} />
//         </Box>

//       </Grid>
//     </Grid>
//   )
// }

// export default QuotationManagement;




import React, { useEffect, useState } from 'react'
import { Typography, Grid, TextField, InputAdornment, Box, Tabs, Tab } from "@mui/material";
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import { useLocation, useNavigate } from 'react-router-dom';
import QuotationCard from './QuotationCard';
import { serviceProvider } from '../../provider/serviceProvider';
import { IQuotation } from '../../interface/IQuotation';
import Loader from '../loader/Loader';
import { CustomTabPanel, a11yProps } from '../Tab/CustomTabPanel';
import SearchBar from '../searchBar/SearchBar';
// import CancelledQuotations from './CancelledQuotations';
import NoResult from '../noResult/NoResult';
import CommonPagination from '../common/CommonPagination';
import { toast } from 'react-toastify';
import PendingQuotation from './PendingQuotation';
import SentQuotation from './SentQuotation';
import CompletedQuotation from './CompletedQuotation';
import CancelledQuotation from './CancelledQuotation';

const QuotationManagement: React.FC = () => {
  const [value, setValue] = React.useState(0);
  const [loader, setLoader] = useState(false);
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [quotations, setQuotations] = useState([]);
  const [searchQuery,setSearchQuery]=useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const index = searchParams.get('index');

  // Convert the 'index' value to a number if needed
  const indexNumber = parseInt(index + "", 10);


  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    navigate(`/quotation-management?index=${newValue}`)
  };





 


  // const handleSearch = (e: any) => {
  //   const searchQuery = e.target.value
  //   // setSearchQuery(e.target.value)
  //   let status;
  //   if (value === 0) status = "pending"
  //   else if (value === 1) status = "sent"
  //   else if (value === 2) status = "completed"
  //   else if (value === 3) status = "cancelled"


  //   const query = `search?query=${searchQuery}&status=${status}&offset=${page}&limit=2&order=updatedTs.desc&profile=BASIC`
  //   setLoader(true)
  //   serviceProvider.quotationService.get(query).then((res: any) => {

  //     if (res.responseCode === 200) {
  //       setQuotations(res.data.data)
  //       setTotalPages(res.data.totalRecordCount);


  //     } else {
  //       toast.error(res?.message ||"Something Went Wrong!")
  //     }

  //     // if (res.responseCode === 200 && value === 0) setActiveUsers(res.data.details)
  //     // else if (res.responseCode === 200 && value === 1) setInActiveUsers(res.data.details)
  //     setLoader(false)
  //   }).catch((err) => {
  //     console.log({ err })
  //     setLoader(false)
  //   })
  // }


  const getQuotations = (query:string) => {
   
    setLoader(true);

    serviceProvider.quotationService.get(query).then((res: any) => {
      if (res.responseCode === 200) {
        setQuotations(res.data.data)
        setTotalPages(res.data.totalRecordCount);


      } else {
        toast.error(res?.message ||"Something Went Wrong!")
      }
      setLoader(false)
      // setQuotations(res.data)
      // setTotalPages(res.totalRecordCount)
    }).catch((err) => {
      setLoader(false)
      console.log({ err })
    })
  }


  useEffect(() => {
    let query="";

    let status="";
    if (value === 0) status = "pending"
    else if (value === 1) status = "sent"
    else if (value === 2) status = "completed"
    else if (value === 3) status = "cancelled"

    if (value === 0) {
      query = `search?query=${searchQuery || "all"}&status=${status}&offset=${page}&limit=2&order=updatedTs.desc&profile=BASIC`
    }
    else if (value === 1) {
      // setQuotations([])
      query = `search?query=${searchQuery || "all"}&status=${status}&offset=${page}&limit=2&order=updatedTs.desc&profile=BASIC`
    } else if(value === 2){
      // setQuotations([])
      query = `search?query=${searchQuery || "all"}&status=${status}&offset=${page}&limit=2&order=updatedTs.desc&profile=BASIC`
    } else if(value === 3) {
      // setQuotations([])
      query = `search?query=${searchQuery || "all"}&status=${status}&offset=${page}&limit=2&order=updatedTs.desc&profile=BASIC`
    }
    getQuotations(query)
  }, [value, page,searchQuery])

  useEffect(()=>{
    if(indexNumber){
      setValue(indexNumber)
    }else{
      setValue(0)
    }
  },[indexNumber])

  return (
    <Grid container spacing={2} className="quotation-container">
      <Grid item xs={12}>
        <Grid container>
          <Grid item xs={5} md={5} className="quotation-heading">
            <Typography variant='h6' component='h6'>Quotations</Typography>
          </Grid>
          <Grid item xs={7} md={7}>
            <SearchBar
            value={searchQuery} 
            onChange={(e:any)=>setSearchQuery(e.target.value)}
            // onChange={handleSearch}
            placeholder="Search by Phone number" />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12} >
        <Box className="tabs-container">
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
              <Tab label="Pending" {...a11yProps(0)} />
              <Tab label="Sent" {...a11yProps(1)} />
              <Tab label="Completed" {...a11yProps(2)} />
              <Tab label="Cancelled" {...a11yProps(3)} />
            </Tabs>
          </Box>
          <CustomTabPanel value={value} index={0}>
            <PendingQuotation loader={loader} quotations={quotations}/>
           
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
          <SentQuotation loader={loader} quotations={quotations}/>
          </CustomTabPanel>
          <CustomTabPanel value={value} index={2}>
          <CompletedQuotation loader={loader} quotations={quotations}/>
            
          </CustomTabPanel>
          <CustomTabPanel value={value} index={3}>
          <CancelledQuotation loader={loader} quotations={quotations}/>
          </CustomTabPanel>

        </Box>

       {
        quotations?.length >=1 &&  <Box className="pagination-box">
        <CommonPagination page={page} setPage={setPage} totalPages={totalPages} />
      </Box>
       }

      </Grid>
    </Grid>
  )
}

export default QuotationManagement;