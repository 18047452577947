import { Box, Typography } from '@mui/material'
import React from 'react'
import ButtonComponent from './ButtonComponent'
import notFoundImg from "../../assets/images/page-not-found.svg";

const PageNotFound = () => {
  return (
  <div style={{height:"100vh"}}>
      <Box className="not-found-container">
        <Typography variant="h1" component='h1'>404</Typography>
        <Typography variant="h5" component='h5'>Page Not Found</Typography>
        <Typography variant="body1" component='p'>The Page you’re looking for doesn’t seem to exist</Typography>
        <Box className="go-hme-btn">
            <Box className="img">
            <img src={notFoundImg} alt="page not found" />
            </Box>
            <ButtonComponent fullWidth={false} label="Go to Home" onClick={()=>window.location.href="/"}/>
        </Box>
    </Box>
  </div>
  )
}

export default PageNotFound