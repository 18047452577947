import React, { useState } from "react";
import { Tabs, Tab, Box, MenuItem, Select, FormControl, Grid } from "@mui/material";
import { CustomTabPanel, a11yProps } from "../Tab/CustomTabPanel";
// import QueryTable from "./QueryTable";
import SelectFieldComponent from "../common/SelectFieldComponent";
import GeneralQuery from "./GeneralQuery";
import QuotationQuery from "./QuotationQuery";
import BMJExclusiveQuery from "./BMJExclusiveQuery";

const QueryTabs = ({ pageState, setPageState, value, handleChange }: any) => {

 


  // console.log(status);
  
  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab label="General Query" {...a11yProps(0)} />
          <Tab label="Quotation Query" {...a11yProps(1)} />
          <Tab label="BMJ Exclusive Query" {...a11yProps(2)} />
        </Tabs>
      </Box>

      {/* ... */}

      <CustomTabPanel value={value} index={0}>
     <GeneralQuery/>
      
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
      <QuotationQuery/>
        {/* <QueryTable
          pageState={pageState}
          setPageState={setPageState}
          users={pageState.data}
        /> */}
      </CustomTabPanel>

      <CustomTabPanel value={value} index={2}>
        <BMJExclusiveQuery/>
        {/* <QueryTable
          pageState={pageState}
          setPageState={setPageState}
          users={pageState.data}
        /> */}
      </CustomTabPanel>
    </Box>
  );
};

export default QueryTabs;
