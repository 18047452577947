import { IMockStorage } from "../interface/IMockStorage";

class StatsStorage implements IMockStorage {
    data: any[] = [];
    cumulativeDataObj:any={};
    constructor() {
        this.data = dataArray;
        this.cumulativeDataObj=cumulativeData;
    }
    insert(item: any) {
        let list: any[] = [];
        this.data?.forEach(x => {
            if (x.jobTitle === item.jobTitle) {
                list.push(x);
            }
        });
        return list;
    }
    update(item: any): void {
        throw new Error("Method not implemented.");
    }
    delete(srno: number): void {

        this.data?.filter(x => x.srno !== srno);
    }
    get() {
        return this.cumulativeDataObj; 
    }

    public getByScenario(scenarioId: string) {
        return this.data?.filter((x,i) =>parseInt(scenarioId)>i) 
    }
}
const cumulativeData:any={
  "numberOfActiveJobs": "23",
  "numberOfUsers": "24",
  "numberofJobViews": "32",
  "numberOfJobs": "32"
}
const dataArray: any = [ { "statsId": 4, "numberOfUsersOnPlatform": 10000, "numberOfJobPosted": 11, "numberOfPartners": 5, "joiningsDone": 30, "userUpdatedby": null, "recordInsertedts": "2021-12-27T16:48:58.943+00:00", "numberOfSquadPrograms": 8, "numberOfHackathons": 11, "numberOfProfessionalsUsers": 0, "numberOfFreshersUsers": 0, "numberOfFresherHired": 0, "numberOfInternsHired": 0, "numberOfmeetups": 0, "numberOfColleges": 0, "numberOfFresherDrives": 0, "numberOfStudentsInSquad": 0 }, { "statsId": 3, "numberOfUsersOnPlatform": 0, "numberOfJobPosted": 0, "numberOfPartners": 5, "joiningsDone": 110, "userUpdatedby": null, "recordInsertedts": "2021-12-27T09:17:19.096+00:00", "numberOfSquadPrograms": 8, "numberOfHackathons": 11, "numberOfProfessionalsUsers": 0, "numberOfFreshersUsers": 0, "numberOfFresherHired": 0, "numberOfInternsHired": 0, "numberOfmeetups": 0, "numberOfColleges": 0, "numberOfFresherDrives": 0, "numberOfStudentsInSquad": 0 }, { "statsId": 2, "numberOfUsersOnPlatform": 15, "numberOfJobPosted": 4, "numberOfPartners": 19, "joiningsDone": 110, "userUpdatedby": null, "recordInsertedts": "2021-12-27T06:04:10.203+00:00", "numberOfSquadPrograms": 8, "numberOfHackathons": 11, "numberOfProfessionalsUsers": 0, "numberOfFreshersUsers": 0, "numberOfFresherHired": 0, "numberOfInternsHired": 0, "numberOfmeetups": 0, "numberOfColleges": 0, "numberOfFresherDrives": 0, "numberOfStudentsInSquad": 0 }, { "statsId": 1, "numberOfUsersOnPlatform": 15, "numberOfJobPosted": 4, "numberOfPartners": 19, "joiningsDone": 0, "userUpdatedby": "string", "recordInsertedts": "2021-12-27T06:03:06.258+00:00", "numberOfSquadPrograms": 8, "numberOfHackathons": 11, "numberOfProfessionalsUsers": 0, "numberOfFreshersUsers": 0, "numberOfFresherHired": 0, "numberOfInternsHired": 0, "numberOfmeetups": 0, "numberOfColleges": 0, "numberOfFresherDrives": 0, "numberOfStudentsInSquad": 0 } ];
export default StatsStorage;
