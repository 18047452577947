// import * as React from 'react';
// import Table from '@mui/material/Table';
// import TableBody from '@mui/material/TableBody';
// import TableCell from '@mui/material/TableCell';
// import TableContainer from '@mui/material/TableContainer';
// import TableHead from '@mui/material/TableHead';
// import TableRow from '@mui/material/TableRow';
// import Paper from '@mui/material/Paper';
// import { Checkbox } from '@mui/material';
// import { IAirport } from '../../interface/IAirport';

// const tableCellStyle = {
//   borderRight: '1px solid rgba(224, 224, 224, 1)',
// };

// interface AirportTableProps {
//   allAirports: IAirport[];
// }

// const AirportTable: React.FC<AirportTableProps>=({allAirports})=> {

//   return (
//     <TableContainer component={Paper} className="airports-table-container">
//       <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
//         <TableHead>
//           <TableRow>
//             <TableCell style={tableCellStyle}></TableCell>
//             <TableCell style={tableCellStyle}>Name</TableCell>
//             <TableCell align="center" style={tableCellStyle}>
//             Code as per ICAO
//             </TableCell>
//             <TableCell align="center" style={tableCellStyle}>
//             Runway length
//             </TableCell>
//             <TableCell align="center" style={tableCellStyle}>
//             City
//             </TableCell>
//             <TableCell align="center" style={tableCellStyle}>
//             State
//             </TableCell>
//             <TableCell align="center" style={tableCellStyle}>
//             Governing body
//             </TableCell>
//             <TableCell align="center" style={tableCellStyle}>
//             Longitude
//             </TableCell>
//             <TableCell align="center" style={tableCellStyle}>
//             Latitude
//             </TableCell>
//             <TableCell align="center" style={tableCellStyle}>
//             Start time
//             </TableCell>
//             <TableCell align="center" style={tableCellStyle}>
//             End time
//             </TableCell>
//             <TableCell align="center" style={tableCellStyle}>
//             Prohibited aircraft
//             </TableCell>
//           </TableRow>
//         </TableHead>
//         <TableBody>
//           {allAirports && allAirports?.map((airport:IAirport) => (
//             <TableRow
//               key={airport.airportId}
//               sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
//             >

//               <TableCell component="th" scope="row" style={tableCellStyle}>
//               <Checkbox size='small' />
//               </TableCell>
//               <TableCell component="th" scope="row" style={tableCellStyle}>
//                 {airport?.name}
//               </TableCell>
//               <TableCell align="center" style={tableCellStyle}>
//                 {airport?.icaoCode}
//               </TableCell>
//               <TableCell align="center" style={tableCellStyle}>
//                 {airport?.runwayLength}
//               </TableCell>
//               <TableCell align="center" style={tableCellStyle}>
//                 {airport?.city}
//               </TableCell>
//               <TableCell align="center" style={tableCellStyle}>
//                 {airport?.state}
//               </TableCell>
//               <TableCell align="center" style={tableCellStyle}>
//                 {airport?.governingBody}
//               </TableCell>
//               <TableCell align="center" style={tableCellStyle}>
//                 {airport?.longitude}
//               </TableCell>
//               <TableCell align="center" style={tableCellStyle}>
//                 {airport?.latitude}
//               </TableCell>
//               <TableCell align="center" style={tableCellStyle}>
//                 {airport?.operatingStartTime}
//               </TableCell>
//               <TableCell align="center" style={tableCellStyle}>
//                 {airport?.operatingEndTime}
//               </TableCell>
//               <TableCell align="center" style={tableCellStyle}>
//                 {airport?.prohibitedAircraft}
//               </TableCell>

//             </TableRow>
//           ))}
//         </TableBody>
//       </Table>
//     </TableContainer>
//   );
// }
// export default AirportTable;

import Box from "@mui/material/Box";
import {
  DataGrid,
  GridColDef,
  GridToolbar,
  GridValueGetterParams,
} from "@mui/x-data-grid";
import { IAirport } from "../../interface/IAirport";
import DataGridTable from "../DataGridTable/DataGridTable";
import { getFormattedTime } from "../../utility/formateDateAndTime";
import { Button, Tooltip } from "@mui/material";
import editIcon from "../../assets/icons/edit-icon.svg";
import { useState } from "react";
import CommonModal from "../modal/CommonModal";
import EditAirport from "./EditAirport";
import { formatNumber } from "../../utility/formateNumber";


const AirportTable = ({ pageState, setPageState,getAllAirports }: any) => {



  const columns = [
    //   { field: 'id',
    //    headerName: '',
    //   //  width: 90
    //  },
    {
      field: "name",
      headerName: "Name",
      width: 300,
      editable: false,
      renderCell: (params: any) => {
        return (
          <Tooltip title={params.row?.name}>
            <span>{params.row?.name}</span>
          </Tooltip>
        );
      },
    },
    {
      field: "icaoCode",
      headerName: "Code as per ICAO",
      width: 200,
      editable: false,
    },
    {
      field: "airportHandlingCharges",
      headerName: "Handling Charges (INR)",
      width: 200,
      editable: false,
    },
    {
      field: "runwayLength",
      headerName: "Runway length (meters)",
      // type: 'number',
      width: 250,
      // width: 300,
      editable: false,
    },
    {
      field: "city",
      headerName: "City",
      // width: 300,
      width: 200,
      editable: false,
    },
    {
      field: "state",
      headerName: "State",
      // width: 300,
      width: 200,
      editable: false,
      renderCell: (params: any) => {
        return (
          <Tooltip title={params.row?.state}>
            <span>{params.row?.state}</span>
          </Tooltip>
        );
      },
    },
    {
      field: "governingBody",
      headerName: "Governing body",
      // width: 300,
      width: 150,
      editable: false,
    },
    {
      field: "longitude",
      headerName: "Longitude",
      // width: 300,
      width: 150,
      editable: false,
    },
    {
      field: "latitude",
      headerName: "Latitude",
      // width: 300,
      width: 150,
      editable: false,
    },
    {
      field: "startTime",
      headerName: "Start Time",
      // width: 300,
      width: 150,
      editable: false,
    },
    {
      field: "endTime",
      headerName: "End Time",
      // width: 300,
      width: 150,
      editable: false,
    },
    {
      field: "edit",
      type: "actions",
      headerName: "Edit",
      width: 200,
      getActions: (params: any) => [
         <EditAirport airportDetails={params.row} getAllAirports={getAllAirports} />
      ],
    },
  ];
  const rows = pageState?.data?.map((airport: IAirport, index: number) => ({
    ...airport,
    id: airport.airportId, // You can use airport.airportId here if it's unique
    startTime: getFormattedTime(airport.operatingStartTime),
    endTime: getFormattedTime(airport.operatingEndTime),
    airportHandlingCharges: formatNumber(airport?.airportHandlingCharges),
    runwayLength:formatNumber(airport?.runwayLength)
  }));


 
  return (
   <>

    <DataGridTable
      pageState={pageState}
      setPageState={setPageState}
      rows={rows}
      columns={columns}
    />
   </>
  );
};

export default AirportTable;
