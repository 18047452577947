import { BrowserRouter, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { initAuthentication, UserContexProvider } from "./context/UserContext";
import { IAuthentication } from "./interface/IAuthentication";
import { LocalStorageUtility } from "./utility/localStorageUtility";
import { sessionStorageKey } from "./configuration/config.json";
import { color } from "./constant/cssVariables";
import { sessionUtility } from "./utility/sessionUtility";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import AllRoutes from "./routes/AllRoutes";
import NetworkError from "./components/common/NetworkError";
import jwt, { JwtPayload } from "jsonwebtoken";

const App = () => {
  const userDetails:any = sessionUtility.getAuthentication().session;
  // const navigate = useNavigate();
  // console.log(userDetails?.uId);
  // alert(userDetails?.fullName)

  // const isOperator = userDetails?.role[0]?.authority === "ROLE_OPERATOR:EDIT" ? true :false;

  const sessionKey = LocalStorageUtility.getStorageObj(sessionStorageKey.sessionKey);
  // console.log(sessionKey);
  let authenticationValue: IAuthentication;
  authenticationValue =
    sessionKey !== ""
      ? (authenticationValue = sessionKey as IAuthentication)
      : initAuthentication;
  const [authentication, setAuthentication] = useState(authenticationValue);

  const [darkMode, setDarkMode] = useState(false);

  console.log({ authentication })
  const theme = createTheme({
    palette: {
      primary: {
        main: "#4A4A4A",
      },
      secondary: {
        main: color.white,
        contrastText: color.colorTwo,
      },
      info: {
        main: color.black,
      },
      success: {
        main: color.green,
      },
      background: {
        default: '#f6f8fa'
      },
      contrastThreshold: 3,
      tonalOffset: 0.2,
      mode: darkMode ? "dark" : "light",
    },
    transitions: {
      duration: {
        shortest: 150,
        shorter: 200,
        short: 250,
        // most basic recommended timing
        standard: 300,
        // this is to be used in complex animations
        complex: 375,
        // recommended when something is entering screen
        enteringScreen: 225,
        // recommended when something is leaving screen
        leavingScreen: 195,
      },
    },
    typography: {
      fontSize: 16, // Change the base font size (in pixels)
      fontFamily: ["Microsoft Sans Serif", "sans-serif"].join(","),
      fontWeightBold: 400,

      h6: {
        fontSize: '18px',
        // fontSize: '16px',
        fontFamily: 'Montserrat',
        fontStyle: "normal",
        fontWeight: 600,
        lineHeight: "normal",
        letterSpacing: "0.24px",
        color: "#4A4A4A"
      },
      body1: {
        fontSize: '16px',
        //  fontSize: '16px',
        fontFamily: 'Microsoft Sans Serif',
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "normal",
        // letterSpacing: "0.24px",
        color: "#4A4A4A",
        // color:"red"
      },
      body2: {
        fontSize: '14px',
        fontFamily: 'Microsoft Sans Serif',
        fontStyle: "normal",
        fontWeight: 400,
        // letterSpacing: "0.24px",
        color: "#4A4A4A",
        letterSpacing: "1px"
      },


      // color: "#4A4A4A",
      button: {
        textTransform: "none"
      }
    },
    components: {
      
      MuiChip: { 
        styleOverrides: {
          colorPrimary: {
            backgroundColor: 'rgba(202, 54, 87, 0.50)',
          },
          
        },
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button':
              {
                display: 'none',
              },
            '& input[type=number]': {
              MozAppearance: 'textfield',
            },
          },
        },
      },
      MuiTableCell: {
        styleOverrides: {

          head: {
            // fontSize: '6rem',
            // color:"red" // Customize the font size for the table head here
            color: "#4A4A4A",
            fontFamily: "Montserrat",
            fontSize: "18px",
            fontStyle: "normal",
            fontWeight: 600,
            letterSpacing: "0.24px"
          },
          body: {
            // fontSize: '6rem',
            // color:"red" // Customize the font size for the table head here
            color: "#4A4A4A",
            fontFamily: "Microsoft Sans Serif",
            fontSize: "16px",
            fontStyle: "normal",
            fontWeight: 400,
            letterSpacing: "0.2px"

          },
        },
      },

      MuiTable: {
        styleOverrides: {
          root: {
            borderRadius: "20px",
            background: "#fff",
            boxShadow: "0px 2px 2px 0px rgba(0, 0, 0, 0.25)"
          },
        },
      },
      MuiListItemButton: {
        styleOverrides: {
          root: {
            "&.Mui-selected": {
              borderLeft: `5px solid #1e324f`,
              backgroundColor: "transparent"
            }
          }
        }
      },

      MuiTab: {
        styleOverrides: {
          root: {
            textAlign: "left",
            padding: 0,
            margin: 0,
            marginRight: "1.5rem !important",
            fontSize: "18px",
            color: "#4A4A4A",
            fontFamily: "Montserrat",
            fontWeight: 600,
            lineHeight: "normal",
            letterSpacing: "0.24px",
            "&.Mui-selected": {
              backgroundColor: "transparent",
              color: "#4A4A4A",
            },

          }
        }
      },
    }

  }
  );
  const bearerToken = sessionUtility.getAuthentication()?.token;
  // const navigate = useNavigate();
  let uid = sessionUtility.getAuthentication().session?.uId;

  const updateAuthentication = (value: any) => {
    setAuthentication(value);
  };
  useEffect(() => {
    if (bearerToken) {
      const tokenWithoutBearer = bearerToken.split(' ')[1];
      const decodedToken = jwt.decode(tokenWithoutBearer) as JwtPayload | null;
  
      if (decodedToken && decodedToken.exp) {
        const currentTime = Date.now() / 1000;
  
        if (decodedToken.exp < currentTime) {
          // Token has expired, log user out
          window.localStorage.clear();
          // navigate("/login");
          window.location.href="/login"
        }
      }
    }
  }, [bearerToken]);
  // console.log("isOperator",isOperator)
  
  return (
    <NetworkError>
    <UserContexProvider
      authentication={authentication}
      setAuthentication={updateAuthentication}
    >
      <ThemeProvider theme={theme}>
        <BrowserRouter>
         <AllRoutes/>
        </BrowserRouter>
      </ThemeProvider>
      <ToastContainer
        position="bottom-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        // theme="colored"
      />
    </UserContexProvider>
    </NetworkError>
  );
}

export default App;



