// import {
//   Box,
//   Button,
//   Card,
//   Grid,
//   IconButton,
//   InputAdornment,
//   TextField,
//   Tooltip,
//   Typography,
// } from "@mui/material";
// import React, { useState, useEffect, useRef } from "react";
// import UserQuotationDetails from "./UserQuotationDetails";
// import ItineraryQuotationDetails from "./ItineraryQuotationDetails";
// import editIcon from "../../assets/icons/edit-icon.svg";
// import { IQuotation } from "../../interface/IQuotation";
// import { useLocation, useNavigate, useParams } from "react-router-dom";
// import { serviceProvider } from "../../provider/serviceProvider";
// import CommonModal from "../modal/CommonModal";
// import ButtonComponent from "../common/ButtonComponent";
// import InputFieldComponent from "../common/InputFieldComponent";
// import config from "../../configuration/config.json";
// import { toast } from "react-toastify";
// import copy from "copy-to-clipboard";
// import ContentPasteRoundedIcon from "@mui/icons-material/ContentPasteRounded";
// import DateTimePickerComponent from "../common/DateTimePickerComponent";
// import AutoCompleteComponent from "../common/AutoCompleteComponent";
// import { IAirport } from "../../interface/IAirport";
// import dayjs from "dayjs";
// import { ValidationType } from "../../enum/validationType";
// import { ValidationUtiltiy } from "../../utility/ValidationUtility";
// import { IValidationResponse } from "../../interface/IValidation";
// import backArrow from "../../assets/icons/back-arrow.svg";
// import SpinLoader from "../loader/SpinLoader";
// import { formateCurrency } from "../../utility/formateCurreny";

// const FinalizeQuotation: React.FC = () => {
//   const [open, setOpen] = useState(false);
//   const [openQuoteModal, setOpenQuoteModal] = useState(false);
//   const [quotationDetails, setQuotationDetails] = useState<any>({});
//   const [userInput, setUserInput] = useState<any>({});
//   const location = useLocation();
//   const searchParams = new URLSearchParams(location.search);
//   const [airports, setAirports] = useState([]);
//   const [startDateTime, setStartDateTime] = useState(null);
//   const [loader, setLoader] = useState(false);
//   const [reedemPoints, setReedemPoints] = useState<any>(null);
//   const [finalAmount, setFinalAmount] = useState<any>(null);
//   const [errorMessage, setErrorMessage] = useState("");
//   // const { quotationId } = useParams();
//   const quotationId = searchParams.get("quotationId");
//   console.log({ quotationId });
//   // const aircraftId = searchParams.get('aircraftId');

//   let parsedOrderOfCities;

//   parsedOrderOfCities =
//     quotationDetails &&
//     quotationDetails?.itineraryDetails &&
//     quotationDetails?.itineraryDetails?.orderOfCities &&
//     JSON.parse(quotationDetails?.itineraryDetails?.orderOfCities);

//   console.log({ parsedOrderOfCities });

//   const orderOfCitiesArr: any =
//     parsedOrderOfCities !== undefined &&
//     parsedOrderOfCities !== null &&
//     Object.values(parsedOrderOfCities);

//   console.log({ orderOfCitiesArr });

//   const handleOpenModal = () => setOpen(true);
//   const handleCloseModal = () => setOpen(false);

//   const handleOpenQuoteModal = () => setOpenQuoteModal(true);
//   const handleCloseQuoteModal = () => setOpenQuoteModal(false);

//   const handleDateChange = (newDate: any) => {
//     // console.log({newDate});

//     setStartDateTime(newDate);
//   };

//   console.log({ quotationDetails });

//   const handleInputChange = (property: any, newValue: any) => {
//     // if (property === "startAirportId") setSearchInput({ ...searchInput, [property]: newValue.airportId })
//     // else if (property === "endAirportId") setSearchInput({ ...searchInput, [property]: newValue.airportId })
//     // else setSearchInput({ ...searchInput, [property]: newValue })

//     setUserInput({ ...userInput, [property]: newValue });
//     validateFieldsOnInput(property);
//   };

//   const validateFieldsOnInput = (key: string) => {
//     switch (key) {
//       case "flightDuration":
//         validateFlightDuration();
//         break;
//       case "numberOfPassengers":
//         validatePassengerCapacity();
//         break;
//       default:
//         break;
//     }
//   };

//   const baseUrl = config.frontendBaseUrl;
//   // https://localhost:3000/booking?quotationId=kdjfakd&aircraftId=akdjfk&uId=akdnfadl&itineraryId=akdjhfaeju
//   const bookingUrl = `${baseUrl}/booking?quotationId=${quotationDetails?.quotationId}&aircraftId=${quotationDetails?.aircraftId}&uId=${quotationDetails?.uId}&itineraryId=${quotationDetails?.itineraryId}`;

//   const getQuotationDetails = (quotationId: any) => {
//     setLoader(true);
//     serviceProvider.quotationService
//       .getSingleQuotation(quotationId)
//       .then((res: any) => {
//         if (res.responseCode === 200) {
//           setQuotationDetails(res.data[0]);
//         }
//         setLoader(false);
//       })
//       .catch((err) => {
//         setLoader(false);
//         console.log({ err });
//       });
//   };

//   console.log({ userInput });

//   const handleEditItinerary = () => {
//     // alert("hello")
//     const formattedDate = startDateTime
//       ? dayjs(new Date(startDateTime).toISOString()).format(
//           "YYYY-MM-DDTHH:mm:ss.SSSZ"
//         )
//       : "";
//     isValidRequest()
//       .then(() => {
//         setLoader(true);
//         const payload = { ...userInput, startDt: formattedDate };
//         serviceProvider.itineraryService
//           .put("", payload)
//           .then((res) => {
//             // const itineraryId = res.data.itineraryId;
//             if (res.responseCode === 200) {
//               toast.success(res.message);
//               handleCloseModal();
//               setTimeout(() => {
//                 window.location.reload();
//               }, 100);
//             } else {
//               toast.error(res?.message || "Something Went Wrong.");
//             }
//             setLoader(false);
//           })
//           .catch((err) => {
//             console.log({ err });
//             setLoader(false);
//             toast.error(err?.data?.message || "Something Went Wrong.");
//           });
//       })
//       .catch(() => {
//         toast.error("Please fill all the Required Fields!");
//       });
//   };

//   const textRef = useRef<any>(null);
//   const navigate = useNavigate();

//   const copyToClipboard = () => {
//     // Text from the html element
//     let copyText = textRef.current.value;
//     // Adding text value to clipboard using copy function
//     let isCopy = copy(copyText);

//     //Dispalying notification
//     if (isCopy) {
//       toast.success("Copied to Clipboard");
//     }
//   };

//   useEffect(() => {
//     getQuotationDetails(quotationId);
//   }, [quotationId]);

//   // console.log({userInput})

//   const getAllAirportsList = () => {
//     // setLoader(true);
//     serviceProvider.airportService
//       .getAllAirports()
//       .then((res) => {
//         // console.log({res})
//         if (res.responseCode === 200) {
//           setAirports(res.data);
//           // setLoader(false)
//         }
//       })
//       .catch((err) => {
//         // setLoader(false)
//         console.log({ err });
//       });
//   };

//   const actualAmount = finalAmount - reedemPoints;

//   const handlePointsChange = (event: any) => {
//     const enteredPoints = parseInt(event.target.value, 10);
//     const requestedPoints = quotationDetails?.requestedBmjRewardPoints || 0;

//     if (enteredPoints > requestedPoints) {
//       // If entered points exceed available points, show an error message
//       setErrorMessage("Entered points exceed requested points");
//     } else {
//       // If points are within the limit, update the points to redeem and clear the error message
//       setReedemPoints(enteredPoints);
//       setErrorMessage("");
//     }
//   };

//   console.log({ finalAmount });

//   const handleSendQuote = (
//     quotationId: any,
//     finalAmount: any,
//     reedemPoints: any,
//     requestedBmjRewardPoints:any,
//   ) => {
//     const payload = {
//       quotationId: quotationId,
//       approvedBmjRewardPoints: reedemPoints,
//       finalPrice: finalAmount,
//       requestedBmjRewardPoints:requestedBmjRewardPoints
//     };

//     setLoader(true);
//     serviceProvider.quotationService
//       .put("final", payload)
//       .then((res) => {
//         // alert("true");
//         if (res.responseCode === 200) {
//           handleCloseQuoteModal();
//           toast.success(res.message);
//           navigate("/quotation-management")

//         } else {
//           toast.error(res?.message || "Something Went Wrong!!");
//         }

//         setLoader(false);
//       })
//       .catch((err) => {
//         console.log({ err });
//         toast.error(err?.data?.message || "Something Went Wrong!!");
//         setLoader(false);
//       });
//     // handleOpenQuoteModal();
//   };

//   useEffect(() => {
//     setUserInput(quotationDetails?.itineraryDetails);
//    if(quotationDetails?.finalPrice !== (undefined || 0)){
//     setFinalAmount(quotationDetails?.finalPrice);
//    }
//    if(quotationDetails?.approvedBmjRewardPoints !==(undefined || 0) ){
//     setReedemPoints(quotationDetails?.approvedBmjRewardPoints)
//    }

//   }, [quotationDetails]);

//   console.log({reedemPoints})

//   useEffect(() => {
//     getAllAirportsList();
//   }, []);

//   useEffect(()=>{

//   },[])

//   // console.log("fddfdf",quotationDetails?.itineraryDetails)

//   if (loader) {
//     return <SpinLoader />;
//   }

//   const editItinearyModalBody = (
//     <Grid container spacing={1}>
//       <Grid item xs={12} className="modal-table-container">
//         <Grid container className="heading">
//           <Grid item xs={12}>
//             <Typography variant="h6" component="h6">
//               Edit Itinerary
//             </Typography>
//           </Grid>
//         </Grid>
//         <Grid container className="edit-detail-item">
//           <Grid item xs={6} className="edit-item dark-bg">
//             <Typography variant="body1" component="p">
//               Start Date and Time
//             </Typography>
//           </Grid>
//           <Grid item xs={6} className="edit-item">
//             <DateTimePickerComponent
//               placeholder="Date and Time"
//               defaultValue={quotationDetails?.itineraryDetails?.startDt}
//               value={startDateTime}
//               onChange={handleDateChange}
//             />
//           </Grid>
//         </Grid>
//         <Grid container className="edit-detail-item">
//           <Grid item xs={6} className="edit-item dark-bg">
//             <Typography variant="body1" component="p">
//               Start Airport
//             </Typography>
//           </Grid>
//           <Grid item xs={6} className="edit-item">
//             <AutoCompleteComponent
//               options={airports}
//               // className='search-input'
//               defaultValue={userInput?.startAirport}
//               // readOnly={index > 0 ? true : false}
//               getOptionLabel={(option: IAirport) =>
//                 `${option.name} (${option.icaoCode})`
//               }
//               renderOption={(props: any, option: any) => (
//                 <Box
//                   sx={{
//                     width: "100%",
//                     borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
//                   }}
//                   {...props}
//                 >
//                   <Typography textAlign="left">{option.city}</Typography>
//                   <Typography textAlign="left">
//                     {option.name} ({option.icaoCode})
//                   </Typography>
//                 </Box>
//               )}
//               onChange={(e: any, newValue: any) =>
//                 handleInputChange("startAirportId", newValue?.airportId)
//               }
//               // onChange={(e: any, newValue: any) => handleInputChange(index, "startAirportId", newValue?.airportId,newValue)}
//               placeholder="From"
//             />
//           </Grid>
//         </Grid>
//         <Grid container className="edit-detail-item">
//           <Grid item xs={6} className="edit-item dark-bg">
//             <Typography variant="body1" component="p">
//               End Airport
//             </Typography>
//           </Grid>
//           <Grid item xs={6} className="edit-item">
//             <AutoCompleteComponent
//               options={airports}
//               // className='search-input'
//               defaultValue={userInput?.endAirport}
//               // readOnly={index > 0 ? true : false}
//               getOptionLabel={(option: IAirport) =>
//                 `${option.name} (${option.icaoCode})`
//               }
//               renderOption={(props: any, option: any) => (
//                 <Box
//                   sx={{
//                     width: "100%",
//                     borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
//                   }}
//                   {...props}
//                 >
//                   <Typography textAlign="left">{option.city}</Typography>
//                   <Typography textAlign="left">
//                     {option.name} ({option.icaoCode})
//                   </Typography>
//                 </Box>
//               )}
//               onChange={(e: any, newValue: any) =>
//                 handleInputChange("endAirportId", newValue?.airportId)
//               }
//               // onChange={(e, newValue) => handleSearchInput(index, "startAirportId", newValue.airportId)}
//               // onChange={(e: any, newValue: any) => handleInputChange(index, "startAirportId", newValue?.airportId,newValue)}
//               placeholder="To"
//             />
//           </Grid>
//         </Grid>
//         <Grid container className="edit-detail-item">
//           <Grid item xs={6} className="edit-item dark-bg">
//             <Typography variant="body1" component="p">
//               Flight Duration (in hours)
//             </Typography>
//           </Grid>
//           <Grid item xs={6} className="edit-item">
//             <div>
//               <InputFieldComponent
//                 id="edit-flight-duration"
//                 name="flightDuration"
//                 type="number"
//                 value={userInput?.flightDuration}
//                 // onChange={handleInputChange}
//                 onChange={(e: any) =>
//                   handleInputChange("flightDuration", e.target.value)
//                 }
//               />

//               <span
//                 className="modalError"
//                 id="errorDiv-edit-flight-duration"
//               ></span>
//             </div>
//           </Grid>
//         </Grid>
//         <Grid container className="edit-detail-item">
//           <Grid item xs={6} className="edit-item dark-bg">
//             <Typography variant="body1" component="p">
//               Passenger Capacity
//             </Typography>
//           </Grid>
//           <Grid item xs={6} className="edit-item">
//             <div>
//               <InputFieldComponent
//                 id="edit-passenger-capacity"
//                 type="number"
//                 name="numberOfPassengers"
//                 value={userInput?.numberOfPassengers}
//                 onChange={(e: any) =>
//                   handleInputChange("numberOfPassengers", e.target.value)
//                 }
//                 // onChange={handleInputChange}
//               />
//               <span
//                 className="modalError"
//                 id="errorDiv-edit-passenger-capacity"
//               ></span>
//             </div>
//           </Grid>
//         </Grid>
//       </Grid>
//       <Grid item xs={12}>
//         <Grid
//           container
//           columnSpacing={2}
//           display="flex"
//           justifyContent="center"
//           alignItems="center"
//           mt="20px"
//         >
//           <Grid item xs={2}>
//             <ButtonComponent
//               variant="contained"
//               className="rounded-btn-fill"
//               label="Save"
//               onClick={handleEditItinerary}
//             />
//           </Grid>
//         </Grid>
//       </Grid>
//     </Grid>
//   );

//   const confirmQuoteModalBody = (
//     <Grid container>
//       <Grid item xs={12}>
//         <Typography variant="h6" component="h6" textAlign="center">
//           Are You Sure?
//         </Typography>
//       </Grid>
//       <Grid item xs={12}>
//         <Grid container columnSpacing={2} className="btn-container">
//           <Grid item xs={6} display="flex" justifyContent="flex-end">
//             <Button
//               variant="outlined"
//               className="rounded-btn-outlined"
//               onClick={handleCloseQuoteModal}
//             >
//               Cancel
//             </Button>
//           </Grid>
//           <Grid item xs={6}>
//             {/* <Button variant="contained" className="rounded-btn-fill" onClick={() => handleSendQuote(quotationDetails?.quotationId, finalAmount)}>Send</Button> */}
//             <Button
//               variant="contained"
//               className="rounded-btn-fill"
//               onClick={() =>
//                 handleSendQuote(
//                   quotationDetails?.quotationId,
//                   finalAmount,
//                   reedemPoints,
//                   quotationDetails?.requestedBmjRewardPoints
//                 )
//               }
//             >
//               Send
//             </Button>
//           </Grid>
//         </Grid>
//       </Grid>
//     </Grid>
//   );

//   console.log(quotationDetails);
//   return (
//     <>
//       {/* <CommonModal open={open} handleCloseModal={handleCloseModal} modalBody={editItinearyModalBody} /> */}
//       <CommonModal
//         open={openQuoteModal}
//         handleCloseModal={handleCloseQuoteModal}
//         modalBody={confirmQuoteModalBody}
//       />
//       <Grid container columnSpacing={4}>
//         <Grid item xs={12}>
//           <Box mb="10px" className="back-btn-container">
//             <Tooltip title="back">
//               <IconButton onClick={() => navigate(-1)}>
//                 <img src={backArrow} alt="back" />
//               </IconButton>
//             </Tooltip>
//           </Box>
//         </Grid>
//         <Grid item xs={12} md={8}>
//           <Grid container>
//             <Grid item xs={12}>
//               <UserQuotationDetails quotationDetails={quotationDetails} />
//             </Grid>
//             <Grid item xs={12}>
//               <Grid container rowSpacing={3} className="itinerary-details-container">
//                 <Grid item xs={3}>
//                   <Typography variant="h6" component="h6">
//                     Itinerary details
//                   </Typography>
//                 </Grid>
//                 {/* <Grid item xs={3}>
//                   <Box className="edit-container">
//                     <Typography variant="h6" component="h6" textAlign="right">
//                       Edit Itinerary
//                     </Typography>
//                     <Button
//                       onClick={handleOpenModal}
//                     >
//                       <img src={editIcon} alt="edit icon" height="20px" width="20px" />
//                     </Button>
//                   </Box>
//                 </Grid> */}

// {quotationDetails?.itineraryDetails?.itineraryType !==
//               "MULTICITY" && (
//               <Grid item xs={12}>
//                 <ItineraryQuotationDetails
//                   itineraryDetails={quotationDetails?.itineraryDetails}
//                 />
//               </Grid>
//             )}
//             {quotationDetails?.itineraryDetails?.itineraryType ===
//               "MULTICITY" &&
//               orderOfCitiesArr?.length &&
//               orderOfCitiesArr?.map((city: any) => {

//                 const startAirport = city?.startAirportDetails && JSON.parse(city?.startAirportDetails)
//                 const endAirport = city?.endAirportDetails && JSON.parse(city?.endAirportDetails)
//                 const updatedCity = {...city,startAirport,endAirport};

//                 return (
//                   <Grid item xs={12}>
//                     <ItineraryQuotationDetails itineraryDetails={updatedCity} />
//                   </Grid>
//                 );
//               })}
//               </Grid>
//             </Grid>

//           </Grid>
//         </Grid>
//         <Grid item xs={12} md={4}>
//           <Grid container rowSpacing={4}>
//             <Grid item xs={12}>
//               <Card className="estimated-quotation-card">
//                 <Grid
//                   container
//                   rowSpacing={2}
//                   className="estimated-quotation-container"
//                 >
//                   <Grid item xs={12} width="100%">
//                  <Box className="estimated-amount" sx={{display:"flex",justifyContent:"space-between",gap:"20px"}}>
//                  <Typography
//                           variant="body1"
//                           textAlign="left"
//                           component="p"
//                         >
//                           {/* Estimated Quotation Amount */}
//                           Base Amount (excluding GST)
//                         </Typography>
//                         <Typography
//                           variant="body1"
//                           textAlign="right"
//                           component="p"
//                         >
//                           {formateCurrency(quotationDetails?.basePrice)}
//                         </Typography>
//                  </Box>
//                  {quotationDetails?.finalPrice ? (
//                  <Box className="estimated-amount" sx={{display:"flex",justifyContent:"space-between",gap:"20px"}}>
//                  <Typography
//                             variant="body1"
//                             textAlign="left"
//                             component="p"
//                           >
//                             Final Price
//                           </Typography>
//                           <Typography
//                             variant="body1"
//                             textAlign="right"
//                             component="p"
//                           >
//                             {formateCurrency(quotationDetails?.finalPrice)}
//                           </Typography>
//                  </Box>
//                  ) : null }

// <Box className="estimated-amount" sx={{display:"flex",justifyContent:"space-between",gap:"20px"}}>
// <Typography
//                           variant="body1"
//                           textAlign="left"
//                           component="p"
//                         >
//                           Requested BMJ Reward Points
//                         </Typography>
//                         <Typography
//                           variant="body1"
//                           textAlign="right"
//                           component="p"
//                         >
//                           {quotationDetails?.requestedBmjRewardPoints || "--"}
//                         </Typography>

//                  </Box>
//                     {/* <Grid
//                       container
//                       columnSpacing={3}
//                       className="estimated-amount"
//                     >
//                       <Grid item xs={8}>
//                         <Typography
//                           variant="body1"
//                           textAlign="left"
//                           component="p"
//                         >
//                           Base Amount (excluding GST)
//                         </Typography>
//                       </Grid>
//                       <Grid item xs={4}>
//                         <Typography
//                           variant="body1"
//                           textAlign="right"
//                           component="p"
//                         >
//                           {formateCurrency(quotationDetails?.basePrice)}
//                         </Typography>
//                       </Grid>
//                     </Grid> */}
//                     {/* {quotationDetails?.finalPrice ? (
//                       <Grid container className="estimated-amount">
//                         <Grid item xs={8}>
//                           <Typography
//                             variant="body1"
//                             textAlign="left"
//                             component="p"
//                           >
//                             Final Price
//                           </Typography>
//                         </Grid>
//                         <Grid item xs={4}>
//                           <Typography
//                             variant="body1"
//                             textAlign="right"
//                             component="p"
//                           >
//                             {formateCurrency(quotationDetails?.finalPrice)}
//                           </Typography>
//                         </Grid>
//                       </Grid>
//                     ):null} */}
//                     {/* <Grid container className="estimated-amount">
//                       <Grid item xs={8}>
//                         <Typography
//                           variant="body1"
//                           textAlign="left"
//                           component="p"
//                         >
//                           Requested BMJ Reward Points
//                         </Typography>
//                       </Grid>
//                       <Grid item xs={4}>
//                         <Typography
//                           variant="body1"
//                           textAlign="right"
//                           component="p"
//                         >
//                           {quotationDetails?.requestedBmjRewardPoints || "--"}
//                         </Typography>
//                       </Grid>
//                     </Grid> */}
//                   </Grid>

//                   <Grid item xs={12}>
//                     <TextField
//                       type="number"
//                       label="Enter Final Quotation Amount"
//                       placeholder="Enter Final Quotation Amount"
//                       value={finalAmount}
//                       fullWidth
//                       variant="standard"
//                       InputProps={{ disableUnderline: true }}
//                       onChange={(e: any) => setFinalAmount(e.target.value)}
//                     />
//                   </Grid>
//                   <Grid item xs={12}>
//                     <TextField
//                       type="number"
//                       label="Enter Reedemable Points"
//                       placeholder="Enter Reedemable Points"
//                       value={reedemPoints}
//                       fullWidth
//                       variant="standard"
//                       InputProps={{ disableUnderline: true }}
//                       onChange={(e: any) => handlePointsChange(e)}
//                     />
//                     <span className="modalError">{errorMessage}</span>
//                   </Grid>

//                   <Grid item xs={12} width="100%">
//                   <Box className="estimated-amount" sx={{display:"flex",justifyContent:"space-between",gap:"20px"}}>
//                   <Typography
//                           variant="body1"
//                           textAlign="left"
//                           component="p"
//                         >
//                           Final Amount (excluding GST)
//                         </Typography>
//                         <Typography
//                           variant="body1"
//                           textAlign="right"
//                           component="p"
//                         >
//                           {formateCurrency(Number(actualAmount || "0"))}
//                         </Typography>
//                         {/* <Typography
//                           variant="body1"
//                           mt="10px"
//                           component="p"
//                           fontSize="8px"
//                         >
//                           * Final Amount Excluding GST
//                         </Typography> */}

//                  </Box>
//                   {/* <Box className="estimated-amount">
//                         <Typography
//                           variant="body1"
//                           mt="10px"
//                           component="p"
//                           fontSize="8px"
//                         >
//                           * Final Amount Excluding GST
//                         </Typography>
//                  </Box> */}
//                     {/* <Grid container className="estimated-amount">
//                       <Grid item xs={8}>
//                         <Typography
//                           variant="body1"
//                           textAlign="left"
//                           component="p"
//                         >
//                           Final Amount
//                         </Typography>
//                       </Grid>
//                       <Grid item xs={4}>
//                         <Typography
//                           variant="body1"
//                           textAlign="right"
//                           component="p"
//                         >
//                           {formateCurrency(Number(actualAmount || "0"))}
//                         </Typography>
//                       </Grid>
//                       <Grid item xs={12}>
//                       <Typography
//                           variant="body1"
//                           mt="10px"
//                           component="p"
//                           fontSize="8px"
//                         >
//                           * Final Amount Excluding GST
//                         </Typography>

//                       </Grid>
//                     </Grid> */}
//                   </Grid>

//                   <Grid item xs={12}>
//                     {/* <ButtonComponent/> */}
//                     <Button
//                       variant="contained"
//                       className="rounded-btn-fill"
//                       onClick={handleOpenQuoteModal}
//                     >
//                       Send Quote
//                     </Button>
//                   </Grid>
//                 </Grid>
//               </Card>
//             </Grid>

//             {quotationDetails?.finalPrice ?(
//               <Grid item xs={12}>
//                 <Card className="copy-link-card">
//                   <Grid container>
//                     <Grid item xs={12}>
//                       <Typography variant="body1" component="p" mt="10px">
//                         Booking Url
//                       </Typography>
//                     </Grid>
//                     <Grid item xs={12}>
//                       <Box
//                         sx={{
//                           display: "flex",
//                           justifyContent: "center",
//                           alignItems: "center",
//                         }}
//                       >
//                         <input
//                           value={bookingUrl}
//                           disabled
//                           type="text"
//                           ref={textRef}
//                         />
//                         <Tooltip title="copy link">
//                           <IconButton
//                             aria-label="copy"
//                             onClick={copyToClipboard}
//                           >
//                             <ContentPasteRoundedIcon fontSize="small" />
//                           </IconButton>
//                         </Tooltip>
//                       </Box>
//                     </Grid>
//                   </Grid>
//                 </Card>
//               </Grid>
//             ):null}
//           </Grid>
//         </Grid>
//       </Grid>
//     </>
//   );
// };

// export default FinalizeQuotation;

// const isValidRequest = (): Promise<boolean> => {
//   let responseArray: IValidationResponse[] = [];

//   responseArray.push(validateFlightDuration());
//   responseArray.push(validatePassengerCapacity());

//   // responseArray.push(ValidationUtiltiy.validateElement("signup-confirmPassword", ValidationType.Text, true, "errorDiv-cpwd", "Confirm Password"));
//   let haveErrors =
//     responseArray.filter((x) => {
//       return !x.isValid;
//     }).length <= 0;
//   return new Promise((resolve, reject) => {
//     if (haveErrors) {
//       resolve(true);
//     } else {
//       reject(false);
//     }
//   });
// };

// const validateFlightDuration = () => {
//   return ValidationUtiltiy.validateElement(
//     "edit-flight-duration",
//     ValidationType.Text,
//     true,
//     "errorDiv-edit-flight-duration",
//     "Flight Duration"
//   );
// };
// const validatePassengerCapacity = () => {
//   return ValidationUtiltiy.validateElement(
//     "edit-passenger-capacity",
//     ValidationType.Text,
//     true,
//     "errorDiv-edit-passenger-capacity",
//     "Passenger Capacity"
//   );
// };

import {
  Box,
  Button,
  Card,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useState, useEffect, useRef } from "react";
import UserQuotationDetails from "./UserQuotationDetails";
import ItineraryQuotationDetails from "./ItineraryQuotationDetails";
import editIcon from "../../assets/icons/edit-icon.svg";
import { IQuotation } from "../../interface/IQuotation";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { serviceProvider } from "../../provider/serviceProvider";
import CommonModal from "../modal/CommonModal";
import ButtonComponent from "../common/ButtonComponent";
import InputFieldComponent from "../common/InputFieldComponent";
import config from "../../configuration/config.json";
import { toast } from "react-toastify";
import copy from "copy-to-clipboard";
import ContentPasteRoundedIcon from "@mui/icons-material/ContentPasteRounded";
import DateTimePickerComponent from "../common/DateTimePickerComponent";
import AutoCompleteComponent from "../common/AutoCompleteComponent";
import { IAirport } from "../../interface/IAirport";
import dayjs from "dayjs";
import { ValidationType } from "../../enum/validationType";
import { ValidationUtiltiy } from "../../utility/ValidationUtility";
import { IValidationResponse } from "../../interface/IValidation";
import backArrow from "../../assets/icons/back-arrow.svg";
import SpinLoader from "../loader/SpinLoader";
import { formateCurrency } from "../../utility/formateCurreny";

const FinalizeQuotation: React.FC = () => {
  const [open, setOpen] = useState(false);
  const [openQuoteModal, setOpenQuoteModal] = useState(false);
  const [quotationDetails, setQuotationDetails] = useState<any>({});
  const [userInput, setUserInput] = useState<any>({});
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const [airports, setAirports] = useState([]);
  const [startDateTime, setStartDateTime] = useState(null);
  const [loader, setLoader] = useState(false);
  const [reedemPoints, setReedemPoints] = useState<any>(0);
  const [finalAmount, setFinalAmount] = useState<any>(0);
  const [errorMessage, setErrorMessage] = useState("");
  // const { quotationId } = useParams();
  const quotationId = searchParams.get("quotationId");
  console.log({ quotationId });
  // const aircraftId = searchParams.get('aircraftId');


  // const location = useLocation();
  // const searchParams = new URLSearchParams(location.search);
  const index = searchParams.get('index');

  // Convert the 'index' value to a number if needed
  const indexNumber = parseInt(index + "", 10);



  let parsedOrderOfCities;

  parsedOrderOfCities =
    quotationDetails &&
    quotationDetails?.itineraryDetails &&
    quotationDetails?.itineraryDetails?.orderOfCities &&
    JSON.parse(quotationDetails?.itineraryDetails?.orderOfCities);

  console.log({ parsedOrderOfCities });

  const orderOfCitiesArr: any =
    parsedOrderOfCities !== undefined &&
    parsedOrderOfCities !== null &&
    Object.values(parsedOrderOfCities);

  console.log({ orderOfCitiesArr });

  const handleOpenModal = () => setOpen(true);
  const handleCloseModal = () => setOpen(false);

  const handleOpenQuoteModal = () => setOpenQuoteModal(true);
  const handleCloseQuoteModal = () => setOpenQuoteModal(false);

  const handleDateChange = (newDate: any) => {
    // console.log({newDate});

    setStartDateTime(newDate);
  };

  console.log({ quotationDetails });

  const handleInputChange = (property: any, newValue: any) => {
    // if (property === "startAirportId") setSearchInput({ ...searchInput, [property]: newValue.airportId })
    // else if (property === "endAirportId") setSearchInput({ ...searchInput, [property]: newValue.airportId })
    // else setSearchInput({ ...searchInput, [property]: newValue })

    setUserInput({ ...userInput, [property]: newValue });
    validateFieldsOnInput(property);
  };

  const validateFieldsOnInput = (key: string) => {
    switch (key) {
      case "flightDuration":
        validateFlightDuration();
        break;
      case "numberOfPassengers":
        validatePassengerCapacity();
        break;
      default:
        break;
    }
  };

  const baseUrl = config.frontendBaseUrl;
  // https://localhost:3000/booking?quotationId=kdjfakd&aircraftId=akdjfk&uId=akdnfadl&itineraryId=akdjhfaeju
  const bookingUrl = `${baseUrl}/booking?quotationId=${quotationDetails?.quotationId}&aircraftId=${quotationDetails?.aircraftId}&uId=${quotationDetails?.uId}&itineraryId=${quotationDetails?.itineraryId}`;

  const getQuotationDetails = (quotationId: any) => {
    setLoader(true);
    serviceProvider.quotationService
      .getSingleQuotation(quotationId)
      .then((res: any) => {
        if (res.responseCode === 200) {
          setQuotationDetails(res.data[0]);
        }
        setLoader(false);
      })
      .catch((err) => {
        setLoader(false);
        console.log({ err });
      });
  };

  console.log({ userInput });

  const handleEditItinerary = () => {
    // alert("hello")
    const formattedDate = startDateTime
      ? dayjs(new Date(startDateTime).toISOString()).format(
          "YYYY-MM-DDTHH:mm:ss.SSSZ"
        )
      : "";
    isValidRequest()
      .then(() => {
        setLoader(true);
        const payload = { ...userInput, startDt: formattedDate };
        serviceProvider.itineraryService
          .put("", payload)
          .then((res) => {
            // const itineraryId = res.data.itineraryId;
            if (res.responseCode === 200) {
              toast.success(res.message);
              handleCloseModal();
              setTimeout(() => {
                window.location.reload();
              }, 100);
            } else {
              toast.error(res?.message || "Something Went Wrong.");
            }
            setLoader(false);
          })
          .catch((err) => {
            console.log({ err });
            setLoader(false);
            toast.error(err?.data?.message || "Something Went Wrong.");
          });
      })
      .catch(() => {
        toast.error("Please fill all the Required Fields!");
      });
  };

  const textRef = useRef<any>(null);
  const navigate = useNavigate();

  const copyToClipboard = () => {
    // Text from the html element
    let copyText = textRef.current.value;
    // Adding text value to clipboard using copy function
    let isCopy = copy(copyText);

    //Dispalying notification
    if (isCopy) {
      toast.success("Copied to Clipboard");
    }
  };

  useEffect(() => {
    getQuotationDetails(quotationId);
  }, [quotationId]);

  // console.log({userInput})

  const getAllAirportsList = () => {
    // setLoader(true);
    serviceProvider.airportService
      .getAllAirports()
      .then((res) => {
        // console.log({res})
        if (res.responseCode === 200) {
          setAirports(res.data);
          // setLoader(false)
        }
      })
      .catch((err) => {
        // setLoader(false)
        console.log({ err });
      });
  };

  const actualAmount = finalAmount - reedemPoints;

  const handlePointsChange = (event: any) => {
    const enteredPoints: any = event.target.value
      ? Number(event.target.value)
      : null;
    const requestedPoints = quotationDetails?.requestedBmjRewardPoints || 0;

    if (enteredPoints > requestedPoints) {
      // If entered points exceed available points, show an error message
      setErrorMessage("Entered points exceed requested points");
    } else {
      // If points are within the limit, update the points to redeem and clear the error message
      setReedemPoints(enteredPoints);
      setErrorMessage("");
    }
  };

  console.log({ finalAmount });

  const handleSendQuote = (
    quotationId: any,
    finalAmount: any,
    reedemPoints: any,
    requestedBmjRewardPoints: any
  ) => {
    const payload = {
      quotationId: quotationId,
      approvedBmjRewardPoints: reedemPoints ? reedemPoints : 0,
      finalPrice: finalAmount,
      requestedBmjRewardPoints: requestedBmjRewardPoints,
    };

    setLoader(true);
    serviceProvider.quotationService
      .put("final", payload)
      .then((res) => {
        // alert("true");
        if (res.responseCode === 200) {
          handleCloseQuoteModal();
          toast.success(res.message);
       navigate(`/quotation-management?index=${indexNumber}`)
          // window.location.href=`/quotation-management`
        } else {
          toast.error(res?.message || "Something Went Wrong!!");
        }

        setLoader(false);
      })
      .catch((err) => {
        console.log({ err });
        toast.error(err?.data?.message || "Something Went Wrong!!");
        setLoader(false);
      });
    // handleOpenQuoteModal();
  };

  useEffect(() => {
    setUserInput(quotationDetails?.itineraryDetails);
    if (quotationDetails?.finalPrice !== (undefined || 0)) {
      setFinalAmount(quotationDetails?.finalPrice);
    }
    if (quotationDetails?.approvedBmjRewardPoints !== (undefined || 0)) {
      setReedemPoints(quotationDetails?.approvedBmjRewardPoints);
    }
  }, [quotationDetails]);

  console.log({ reedemPoints });

  useEffect(() => {
    getAllAirportsList();
  }, []);

  useEffect(() => {}, []);

  // console.log("fddfdf",quotationDetails?.itineraryDetails)

  if (loader) {
    return <SpinLoader />;
  }

  console.log({ finalAmount }, finalAmount - reedemPoints);

  const editItinearyModalBody = (
    <Grid container spacing={1}>
      <Grid item xs={12} className="modal-table-container">
        <Grid container className="heading">
          <Grid item xs={12}>
            <Typography variant="h6" component="h6">
              Edit Itinerary
            </Typography>
          </Grid>
        </Grid>
        <Grid container className="edit-detail-item">
          <Grid item xs={6} className="edit-item dark-bg">
            <Typography variant="body1" component="p">
              Start Date and Time
            </Typography>
          </Grid>
          <Grid item xs={6} className="edit-item">
            <DateTimePickerComponent
              placeholder="Date and Time"
              defaultValue={quotationDetails?.itineraryDetails?.startDt}
              value={startDateTime}
              onChange={handleDateChange}
            />
          </Grid>
        </Grid>
        <Grid container className="edit-detail-item">
          <Grid item xs={6} className="edit-item dark-bg">
            <Typography variant="body1" component="p">
              Start Airport
            </Typography>
          </Grid>
          <Grid item xs={6} className="edit-item">
            <AutoCompleteComponent
              options={airports}
              // className='search-input'
              defaultValue={userInput?.startAirport}
              // readOnly={index > 0 ? true : false}
              getOptionLabel={(option: IAirport) =>
                `${option.name} (${option.icaoCode})`
              }
              renderOption={(props: any, option: any) => (
                <Box
                  sx={{
                    width: "100%",
                    borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
                  }}
                  {...props}
                >
                  <Typography textAlign="left">{option.city}</Typography>
                  <Typography textAlign="left">
                    {option.name} ({option.icaoCode})
                  </Typography>
                </Box>
              )}
              onChange={(e: any, newValue: any) =>
                handleInputChange("startAirportId", newValue?.airportId)
              }
              // onChange={(e: any, newValue: any) => handleInputChange(index, "startAirportId", newValue?.airportId,newValue)}
              placeholder="From"
            />
          </Grid>
        </Grid>
        <Grid container className="edit-detail-item">
          <Grid item xs={6} className="edit-item dark-bg">
            <Typography variant="body1" component="p">
              End Airport
            </Typography>
          </Grid>
          <Grid item xs={6} className="edit-item">
            <AutoCompleteComponent
              options={airports}
              // className='search-input'
              defaultValue={userInput?.endAirport}
              // readOnly={index > 0 ? true : false}
              getOptionLabel={(option: IAirport) =>
                `${option.name} (${option.icaoCode})`
              }
              renderOption={(props: any, option: any) => (
                <Box
                  sx={{
                    width: "100%",
                    borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
                  }}
                  {...props}
                >
                  <Typography textAlign="left">{option.city}</Typography>
                  <Typography textAlign="left">
                    {option.name} ({option.icaoCode})
                  </Typography>
                </Box>
              )}
              onChange={(e: any, newValue: any) =>
                handleInputChange("endAirportId", newValue?.airportId)
              }
              // onChange={(e, newValue) => handleSearchInput(index, "startAirportId", newValue.airportId)}
              // onChange={(e: any, newValue: any) => handleInputChange(index, "startAirportId", newValue?.airportId,newValue)}
              placeholder="To"
            />
          </Grid>
        </Grid>
        <Grid container className="edit-detail-item">
          <Grid item xs={6} className="edit-item dark-bg">
            <Typography variant="body1" component="p">
              Flight Duration (in hours)
            </Typography>
          </Grid>
          <Grid item xs={6} className="edit-item">
            <div>
              <InputFieldComponent
                id="edit-flight-duration"
                name="flightDuration"
                type="number"
                value={userInput?.flightDuration}
                // onChange={handleInputChange}
                onChange={(e: any) =>
                  handleInputChange("flightDuration", e.target.value)
                }
              />

              <span
                className="modalError"
                id="errorDiv-edit-flight-duration"
              ></span>
            </div>
          </Grid>
        </Grid>
        <Grid container className="edit-detail-item">
          <Grid item xs={6} className="edit-item dark-bg">
            <Typography variant="body1" component="p">
              Guest Capacity
            </Typography>
          </Grid>
          <Grid item xs={6} className="edit-item">
            <div>
              <InputFieldComponent
                id="edit-passenger-capacity"
                type="number"
                name="numberOfPassengers"
                value={userInput?.numberOfPassengers}
                onChange={(e: any) =>
                  handleInputChange("numberOfPassengers", e.target.value)
                }
                // onChange={handleInputChange}
              />
              <span
                className="modalError"
                id="errorDiv-edit-passenger-capacity"
              ></span>
            </div>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid
          container
          columnSpacing={2}
          display="flex"
          justifyContent="center"
          alignItems="center"
          mt="20px"
        >
          <Grid item xs={2}>
            <ButtonComponent
              variant="contained"
              className="rounded-btn-fill"
              label="Save"
              onClick={handleEditItinerary}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );

  const confirmQuoteModalBody = (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant="h6" component="h6" textAlign="center">
          Are You Sure?
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Grid container columnSpacing={2} className="btn-container">
          <Grid item xs={6} display="flex" justifyContent="flex-end">
            <Button
              variant="outlined"
              className="rounded-btn-outlined"
              onClick={handleCloseQuoteModal}
            >
              Cancel
            </Button>
          </Grid>
          <Grid item xs={6}>
            {/* <Button variant="contained" className="rounded-btn-fill" onClick={() => handleSendQuote(quotationDetails?.quotationId, finalAmount)}>Send</Button> */}
            {/* <Button
              variant="contained"
              className="rounded-btn-fill"
              onClick={() =>
                handleSendQuote(
                  quotationDetails?.quotationId,
                  finalAmount,
                  reedemPoints,
                  quotationDetails?.requestedBmjRewardPoints
                )
              }
            >
              Send
            </Button> */}

            <ButtonComponent
          loader={loader}
          disabled={loader}
          fullWidth={false}
          label={"Send"}
          onClick={() =>
            handleSendQuote(
              quotationDetails?.quotationId,
              finalAmount,
              reedemPoints,
              quotationDetails?.requestedBmjRewardPoints
            )
          }
        />
            
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );

  console.log(quotationDetails);
  return (
    <>
      {/* <CommonModal open={open} handleCloseModal={handleCloseModal} modalBody={editItinearyModalBody} /> */}
      <CommonModal
        open={openQuoteModal}
        handleCloseModal={handleCloseQuoteModal}
        modalBody={confirmQuoteModalBody}
      />
      
      <Grid container columnSpacing={4}>
        <Grid item xs={12}>
          <Box mb="10px" className="back-btn-container">
            <Tooltip title="back">
              <IconButton onClick={() => navigate(-1)}>
                <img src={backArrow} alt="back" />
              </IconButton>
            </Tooltip>
          </Box>
        </Grid>
        <Grid item xs={12} md={8}>
          <Grid container>
            <Grid item xs={12}>
              <UserQuotationDetails quotationDetails={quotationDetails} />
            </Grid>
            <Grid item xs={12}>
              <Grid
                container
                rowSpacing={3}
                className="itinerary-details-container"
              >
                <Grid item xs={12}>
                  <Typography variant="h6" component="h6">
                    Itinerary details
                  </Typography>
                </Grid>
                {/* <Grid item xs={3}>
                  <Box className="edit-container">
                    <Typography variant="h6" component="h6" textAlign="right">
                      Edit Itinerary
                    </Typography>
                    <Button
                      onClick={handleOpenModal}
                    >
                      <img src={editIcon} alt="edit icon" height="20px" width="20px" />
                    </Button>
                  </Box>
                </Grid> */}

                {/* {quotationDetails?.itineraryDetails?.itineraryType !==
                  "MULTICITY" && (
                  <Grid item xs={12}>
                    <ItineraryQuotationDetails
                      itineraryDetails={quotationDetails?.itineraryDetails}
                    />
                  </Grid>
                )} */}
                {/* {quotationDetails?.itineraryDetails?.itineraryType ===
                  "MULTICITY" &&
                  orderOfCitiesArr?.length &&
                  orderOfCitiesArr?.map((city: any) => {
                    const startAirport =
                      city?.startAirportDetails &&
                      JSON.parse(city?.startAirportDetails);
                    const endAirport =
                      city?.endAirportDetails &&
                      JSON.parse(city?.endAirportDetails);
                    const updatedCity = { ...city, startAirport, endAirport };

                    return (
                      <Grid item xs={12}>
                        <ItineraryQuotationDetails
                          itineraryDetails={updatedCity}
                        />
                      </Grid>
                    );
                  })} */}
                {quotationDetails?.pathDetails?.itineraryPathDetails?.length >=
                  1 &&
                  quotationDetails?.pathDetails?.itineraryPathDetails
                    .filter(
                      (itineraryPath: any) =>
                        itineraryPath?.flightType === "SEARCHED"
                    )
                    .map((filteredItineraryPath: any) => (
                      <Grid
                        item
                        xs={12}
                        key={filteredItineraryPath?.itineraryId}
                      >
                        <ItineraryQuotationDetails
                          itineraryDetails={filteredItineraryPath}
                        />
                      </Grid>
                    ))}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={4}>
          <Grid container rowSpacing={4}>
            <Grid item xs={12}>
              <Card className="estimated-quotation-card">
                <Grid
                  container
                  rowSpacing={2}
                  className="estimated-quotation-container"
                >
                  <Grid item xs={12} width="100%">
                    <Box
                      className="estimated-amount"
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        gap: "20px",
                      }}
                    >
                      <Typography
                        variant="body1"
                        textAlign="left"
                        component="p"
                      >
                        {/* Estimated Quotation Amount */}
                        Flying Cost
                      </Typography>
                      <Typography
                        variant="body1"
                        textAlign="right"
                        component="p"
                      >
                        {formateCurrency(quotationDetails?.pathDetails?.flyingCost || 0)}
                      </Typography>
                    </Box>
                    <Box
                      className="estimated-amount"
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        gap: "20px",
                      }}
                    >
                      <Typography
                        variant="body1"
                        textAlign="left"
                        component="p"
                      >
                        {/* Estimated Quotation Amount */}
                        Accomodation Charges
                      </Typography>
                      <Typography
                        variant="body1"
                        textAlign="right"
                        component="p"
                      >
                        {formateCurrency(quotationDetails?.pathDetails?.accomodationCharges || 0)}
                      </Typography>
                    </Box>
                    <Box
                      className="estimated-amount"
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        gap: "20px",
                      }}
                    >
                      <Typography
                        variant="body1"
                        textAlign="left"
                        component="p"
                      >
                        {/* Estimated Quotation Amount */}
                        Minimal FlyTime Charges
                      </Typography>
                      <Typography
                        variant="body1"
                        textAlign="right"
                        component="p"
                      >
                        {formateCurrency(quotationDetails?.pathDetails?.minimalFlyTimeCharges || 0)}
                      </Typography>
                    </Box>
                    <Box
                      className="estimated-amount"
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        gap: "20px",
                      }}
                    >
                      <Typography
                        variant="body1"
                        textAlign="left"
                        component="p"
                      >
                        {/* Estimated Quotation Amount */}
                        Handling Charges
                      </Typography>
                      <Typography
                        variant="body1"
                        textAlign="right"
                        component="p"
                      >
                        {formateCurrency(quotationDetails?.pathDetails?.handlingCharges || 0)}
                      </Typography>
                    </Box>
                    <Divider/>
                    <Box
                      className="estimated-amount"
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        gap: "20px",
                        mt:"10px"
                      }}
                    >
                      <Typography
                        variant="body1"
                        textAlign="left"
                        component="p"
                      >
                       
                        Base Amount (excluding GST)
                      </Typography>
                      <Typography
                        variant="body1"
                        textAlign="right"
                        component="p"
                      >
                        {formateCurrency(quotationDetails?.pathDetails?.baseCharges || 0)}
                      </Typography>
                    </Box>
                    <Box
                      className="estimated-amount"
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        gap: "20px",
                      }}
                    >
                      <Typography
                        variant="body1"
                        textAlign="left"
                        component="p"
                      >
                       Gst Charges
                      </Typography>
                      <Typography
                        variant="body1"
                        textAlign="right"
                        component="p"
                      >
                        {formateCurrency(quotationDetails?.pathDetails?.gstCharges || 0)}
                      </Typography>
                    </Box>
                    <Divider/>
                    <Box

                      className="estimated-amount"
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        gap: "20px",
                        mt:"10px"
                      }}
                    >
                      <Typography
                        variant="body1"
                        textAlign="left"
                        component="p"
                      >
                       
                       Estimated Price
                      </Typography>
                      <Typography
                        variant="body1"
                        textAlign="right"
                        component="p"
                      >
                        {formateCurrency(quotationDetails?.pathDetails?.estimatedPrice || 0)}
                      </Typography>
                    </Box>
                    {quotationDetails?.finalPrice ? (
                      <Box
                        className="estimated-amount"
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          gap: "20px",
                        }}
                      >
                        <Typography
                          variant="body1"
                          textAlign="left"
                          component="p"
                        >
                          Final Price
                        </Typography>
                        <Typography
                          variant="body1"
                          textAlign="right"
                          component="p"
                        >
                          {formateCurrency(quotationDetails?.finalPrice)}
                        </Typography>
                      </Box>
                    ) : null}

                    <Box
                      className="estimated-amount"
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        gap: "20px",
                      }}
                    >
                      <Typography
                        variant="body1"
                        textAlign="left"
                        component="p"
                      >
                        Requested BMJ Reward Points
                      </Typography>
                      <Typography
                        variant="body1"
                        textAlign="right"
                        component="p"
                      >
                        {quotationDetails?.requestedBmjRewardPoints || "--"}
                      </Typography>
                    </Box>
                    {/* <Grid
                      container
                      columnSpacing={3}
                      className="estimated-amount"
                    >
                      <Grid item xs={8}>
                        <Typography
                          variant="body1"
                          textAlign="left"
                          component="p"
                        >
                          Base Amount (excluding GST)
                        </Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography
                          variant="body1"
                          textAlign="right"
                          component="p"
                        >
                          {formateCurrency(quotationDetails?.basePrice)}
                        </Typography>
                      </Grid>
                    </Grid> */}
                    {/* {quotationDetails?.finalPrice ? (
                      <Grid container className="estimated-amount">
                        <Grid item xs={8}>
                          <Typography
                            variant="body1"
                            textAlign="left"
                            component="p"
                          >
                            Final Price
                          </Typography>
                        </Grid>
                        <Grid item xs={4}>
                          <Typography
                            variant="body1"
                            textAlign="right"
                            component="p"
                          >
                            {formateCurrency(quotationDetails?.finalPrice)}
                          </Typography>
                        </Grid>
                      </Grid>
                    ):null} */}
                    {/* <Grid container className="estimated-amount">
                      <Grid item xs={8}>
                        <Typography
                          variant="body1"
                          textAlign="left"
                          component="p"
                        >
                          Requested BMJ Reward Points
                        </Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography
                          variant="body1"
                          textAlign="right"
                          component="p"
                        >
                          {quotationDetails?.requestedBmjRewardPoints || "--"}
                        </Typography>
                      </Grid>
                    </Grid> */}
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      type="number"
                      label="Enter Final Quotation Amount"
                      // placeholder="Enter Final Quotation Amount"
                      value={finalAmount}
                      fullWidth
                      variant="outlined"
                      sx={{border: 'none',"& fieldset": { border: 'none' },}}
                      InputProps={{ disableUnderline: true }}
                      onChange={(e: any) => {
                        setFinalAmount(e.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      type="number"
                      label="Enter Reedemable Points"
                      // placeholder="Enter Reedemable Points"
                      value={reedemPoints}
                      fullWidth
                      variant="outlined"
                      sx={{border: 'none',"& fieldset": { border: 'none' },}}
                      InputProps={{ disableUnderline: true }}

                      onChange={(e: any) => handlePointsChange(e)}
                    />
                    
                  </Grid>
                  <span className="modalError">{errorMessage}</span>

                  <Grid item xs={12} width="100%">
                    <Box
                      className="estimated-amount"
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        gap: "20px",
                      }}
                    >
                      <Typography
                        variant="body1"
                        textAlign="left"
                        component="p"
                      >
                        Final Amount (excluding GST)
                      </Typography>
                      <Typography
                        variant="body1"
                        textAlign="right"
                        component="p"
                      >
                        {formateCurrency(
                          reedemPoints
                            ? Number(actualAmount || "0")
                            : Number(finalAmount || "0")
                        )}
                      </Typography>
                      {/* <Typography
                          variant="body1"
                          mt="10px"
                          component="p"
                          fontSize="8px"
                        >
                          * Final Amount Excluding GST
                        </Typography> */}
                    </Box>
                    {/* <Box className="estimated-amount">
                        <Typography
                          variant="body1"
                          mt="10px"
                          component="p"
                          fontSize="8px"
                        >
                          * Final Amount Excluding GST
                        </Typography>
                 </Box> */}
                    {/* <Grid container className="estimated-amount">
                      <Grid item xs={8}>
                        <Typography
                          variant="body1"
                          textAlign="left"
                          component="p"
                        >
                          Final Amount
                        </Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography
                          variant="body1"
                          textAlign="right"
                          component="p"
                        >
                          {formateCurrency(Number(actualAmount || "0"))}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                      <Typography
                          variant="body1"
                          mt="10px"
                          component="p"
                          fontSize="8px"
                        >
                          * Final Amount Excluding GST
                        </Typography>
             
                      </Grid>
                    </Grid> */}
                  </Grid>

                  <Grid item xs={12}>
                    {/* <ButtonComponent/> */}
                    <ButtonComponent
          loader={loader}
          disabled={loader || errorMessage ? true : false || !finalAmount}
          label={"Send Quote"}
          onClick={handleOpenQuoteModal}
        />
                    {/* <Button
                      variant="contained"
                      className="rounded-btn-fill"
                      disabled={errorMessage ? true : false}
                      onClick={handleOpenQuoteModal}
                    >
                      Send Quote
                    </Button> */}
                  </Grid>
                </Grid>
              </Card>
            </Grid>

            {quotationDetails?.finalPrice ? (
              <Grid item xs={12}>
                <Card className="copy-link-card">
                  <Grid container>
                    <Grid item xs={12}>
                      <Typography variant="body1" component="p" mt="10px">
                        Booking Url
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <input
                          value={bookingUrl}
                          disabled
                          type="text"
                          ref={textRef}
                        />
                        <Tooltip title="copy link">
                          <IconButton
                            aria-label="copy"
                            onClick={copyToClipboard}
                          >
                            <ContentPasteRoundedIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    </Grid>
                  </Grid>
                </Card>
              </Grid>
            ) : null}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default FinalizeQuotation;

const isValidRequest = (): Promise<boolean> => {
  let responseArray: IValidationResponse[] = [];

  responseArray.push(validateFlightDuration());
  responseArray.push(validatePassengerCapacity());

  // responseArray.push(ValidationUtiltiy.validateElement("signup-confirmPassword", ValidationType.Text, true, "errorDiv-cpwd", "Confirm Password"));
  let haveErrors =
    responseArray.filter((x) => {
      return !x.isValid;
    }).length <= 0;
  return new Promise((resolve, reject) => {
    if (haveErrors) {
      resolve(true);
    } else {
      reject(false);
    }
  });
};

const validateFlightDuration = () => {
  return ValidationUtiltiy.validateElement(
    "edit-flight-duration",
    ValidationType.Text,
    true,
    "errorDiv-edit-flight-duration",
    "Flight Duration"
  );
};
const validatePassengerCapacity = () => {
  return ValidationUtiltiy.validateElement(
    "edit-passenger-capacity",
    ValidationType.Text,
    true,
    "errorDiv-edit-passenger-capacity",
    "Guest Capacity"
  );
};
