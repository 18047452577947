import Box from "@mui/material/Box";
import {
  DataGrid,
  GridColDef,
  GridToolbar,
  GridValueGetterParams,
} from "@mui/x-data-grid";
import { IAirport } from "../../interface/IAirport";
import DataGridTable from "../DataGridTable/DataGridTable";
import { getFormattedTime } from "../../utility/formateDateAndTime";
import {
  Autocomplete,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import checkedIcon from "../../assets/icons/checked-icon.svg";
import crossIcon from "../../assets/icons/cross-icon.svg";
import reassignOperatorIcon from "../../assets/icons/reassign_operator.svg";
import { useEffect, useRef, useState } from "react";
import CommonModal from "../modal/CommonModal";
import AutoCompleteComponent from "../common/AutoCompleteComponent";
import InputFieldComponent from "../common/InputFieldComponent";
import addImageIcon from "../../assets/icons/add-image.svg";
import ButtonComponent from "../common/ButtonComponent";
import img from "../../assets/images/cessena.svg";
import SearchBar from "../searchBar/SearchBar";
import { toast } from "react-toastify";
import { serviceProvider } from "../../provider/serviceProvider";
import { IFleet } from "../../interface/IFleet";
import { IAircraft } from "../../interface/IAircraft";
import { ValidationUtiltiy } from "../../utility/ValidationUtility";
import S3 from "react-aws-s3-typescript";
import { awsConfig } from "../../configuration/config.json";
import { ValidationType } from "../../enum/validationType";
import { IValidationResponse } from "../../interface/IValidation";
import InfoIcon from '@mui/icons-material/Info';
import Loader from "../loader/Loader";
import SpinLoader from "../loader/SpinLoader";
import verifyIcon from "../../assets/icons/verifiedIcon.svg";
const initialPayload = {
  aircraftId: "",
  callSign: "",
  imageUrl: "",
  operatorId: "",
  baseLocation: "",
};

const OperatorTable = () => {
  const [open, setOpen] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openReactiveModal, setOpenReactiveModal] = useState(false);
  const [loader, setLoader] = useState<boolean>(false);
  const [imgLoader, setImgLoader] = useState<boolean>(false);
  const [allFleets, setAllFleets] = useState<IFleet[]>([]);
  const [fleetAircrafts, setFleetAircrafts] = useState<IAircraft[]>([]);
  const [allAssignedAircrafts, setAllAssignedAircrafts] = useState<any[]>([]);
  const [allUnassignedAircrafts, setAllUnassignedAircrafts] = useState<any[]>(
    []
  );
  const [selectedOperator, setSelectedOperator] = useState<any>({});
  const [userInput, setUserInput] = useState<any>(initialPayload);
  const [imageURLS, setImageURLs] = useState<any>([]);

  // const [file,setFile]= useState<any>(null)
  const [files, setFiles] = useState<any>([]); // Change from "const [file, setFile] = useState<any>(null);"

  const chooseFileRef: React.MutableRefObject<HTMLInputElement | null> =
    useRef(null);
  const [pageState, setPageState] = useState({
    loader: false,
    data: [],
    totalPages: 0,
    page: 0,
    pageSize: 5,
  });
  const [searchQuery,setSearchQuery]=useState<string>("");
  const [isCallSignVerified,setIsCallSignVerified]=useState<boolean>(false);
  const [selectedAircrafts, setSelectedAircrafts] = useState<any>([]);
  const [airports, setAirports] = useState<any>([]);

  const handleMultitpleAircraftChange = (newValue: any) => {
    const selectedAircraftIds = newValue?.map(
      (aircraft: any) => aircraft.aircraftId
    );
    setSelectedAircrafts(selectedAircraftIds);
  };

  const handleOpenModal = (operator: any) => {
    setOpen(true);
    setSelectedOperator(operator);

    setUserInput({ ...userInput, operatorId: operator?.uId });
  };
  const handleCloseModal = () => setOpen(false);

  const handleOpenDeleteModal = (operatorId: any) => {
    getAllAssignedAircrafts(operatorId, true);
    setOpenDeleteModal(true);
  };

  const handleOpenReactiveModal = (operatorId: any) => {
    getAllAssignedAircrafts(operatorId, false);
    setOpenReactiveModal(true);
  };

  const handleCloseDeleteModal = () => setOpenDeleteModal(false);
  const handleCloseReactiveModal = () => setOpenReactiveModal(false);

  const columns = [
    {
      field: "fullName",
      headerName: "Name",
      width: 250,
      editable: false,
      renderCell: (params: any) => {
        return (
          <Tooltip title={params.row?.fullName}>
            <span>{params.row?.fullName}</span>
          </Tooltip>
        );
      },
    },
    {
      field: "email",
      headerName: "Email",
      width: 250,
      editable: false,
      renderCell: (params: any) => {
        return (
          <Tooltip title={params.row?.email}>
            <span>{params.row?.email}</span>
          </Tooltip>
        );
      },
    },
    {
      field: "mobileNumber",
      headerName: "Mobile No.",
      // type: 'number',
      width: 200,
      // width: 300,
      editable: false,
    },
    {
      field: "assignAircraft",
      type: "actions",
      headerName: "Assign Aircraft",
      width: 250,
      getActions: (params: any) => [
        // <ManageUser userId={params.row.uId} roleId={params.row.roleId} isActive={params.row.isActive} />
        <Tooltip title="Assign">
          <Button onClick={() => handleOpenModal(params.row)}>
            <img
              src={checkedIcon}
              alt="assign icon"
              height="20px"
              width="20px"
            />
          </Button>
        </Tooltip>,
      ],
    },
    {
      field: "removeAircraft",
      type: "actions",
      headerName: "Remove Aircraft",
      getActions: (params: any) => [
        <Tooltip title="Unassign">
          <Button onClick={() => handleOpenDeleteModal(params.row.uId)}>
            <img src={crossIcon} alt="delete icon" height="20px" width="20px" />
          </Button>
        </Tooltip>,
      ],
    },
    // {
    //   field: "reassignAircraft",
    //   type: "actions",
    //   headerName: "Re-assign Aircraft",
    //   width: 200,
    //   getActions: (params: any) => [
    //     <Tooltip title="Re-assign">
    //       <Button onClick={() => handleOpenReactiveModal(params.row.uId)}>
    //         <img
    //           src={reassignOperatorIcon}
    //           alt="re-assign icon"
    //           height="20px"
    //           width="20px"
    //         />
    //       </Button>
    //     </Tooltip>,
    //   ],
    // },
  ];

  const handleDeleteAircraft = (selectedAircrafts: any) => {
    const param = `assign/false?aircraftList=${selectedAircrafts.join(",")}`;

    setLoader(true);
    serviceProvider.aircraftService
      .put(param, "")
      .then((res: any) => {
        if (res.responseCode === 200) {
          toast.success(res.message);
          handleCloseDeleteModal()
          setTimeout(() => {
            // window.location.reload()
            window.location.href=`/operator-management?index=${0}`
          }, 100)
        } else {
          toast.error(res?.message || "Something Went Wrong!!");
        }
        setLoader(false);
      })
      .catch((err) => {
        console.log({ err });
        setLoader(false);
        toast.error(err?.data?.message || "Something Went Wrong!!");
      });
  };

  const handleReactiveAircraft = (selectedAircrafts: any) => {
    const param = `assign/true?aircraftList=${selectedAircrafts.join(",")}`;

    setLoader(true);
    serviceProvider.aircraftService
      .put(param, "")
      .then((res: any) => {
        if (res.responseCode === 200) {
          toast.success(res.message);
          handleCloseReactiveModal()
          setTimeout(() => {
            // window.location.reload()
            window.location.href=`/operator-management?index=${0}`
          }, 100)
        } else {
          toast.error(res?.message || "Something Went Wrong!!");
        }
        setLoader(false);
      })
      .catch((err) => {
        console.log({ err });
        setLoader(false);
        toast.error(err?.data?.message || "Something Went Wrong!!");
      });
  };

  const rows = pageState?.data?.map((user: any, index: number) => ({
    ...user,
    id: user.uId,
  }));

  const getAllFleets = () => {
    setLoader(true);
    serviceProvider.fleetService
      .getAllFleets()
      .then((res) => {
        setLoader(false);
        if (res.responseCode === 200) {
          setAllFleets(res.data);
        }
        console.log({ res });
      })
      .catch((err) => {
        setLoader(false);
        console.log({ err });
      });
  };
  const getAllAssignedAircrafts = (operatorId: any, status: boolean) => {
    setLoader(true);
    serviceProvider.aircraftService
      .getAllAssingedAircrafts(operatorId, status,"NA")
      .then((res: any) => {
        setLoader(false);
        if (res.responseCode === 200) {
          if (status == true) {
            setAllAssignedAircrafts(res.data);
          } else if (status == false) {
            setAllUnassignedAircrafts(res.data);
          }
        }
        console.log({ res });
      })
      .catch((err) => {
        setLoader(false);
        console.log({ err });
      });
  };

  const getAllOperators = (query:string) => {
    // setLoader(true);
    setPageState((prev) => ({ ...prev, loader: true }));
   
    serviceProvider.userService
      .get(query)
      .then((res) => {
        // setLoader(false)
        // setPageState(prev=>({...prev,loader:false,data:res.data,totalPages:res.totalRecordCount}))
        if (res.responseCode === 200) {
          // setAllAirports(res.data)
          setPageState((prev) => ({
            ...prev,
            loader: false,
            data: res.data.details,
            totalPages: res.data.totalRecordCount,
          }));
        } else {
          toast.error(res?.message || "Something Went Wrong !!");
        }
        setPageState((prev) => ({ ...prev, loader: false }));
        // console.log({ res })
      })
      .catch((err) => {
        // setLoader(false)
        setPageState((prev) => ({ ...prev, loader: false }));
        toast.error(err?.data?.message || "Something Went Wrong !!");
        // console.log({ err })
      });
  };



  // const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   const searchQuery = event.target.value;
  //   // setValue(et as number[]);
  //   // const query = `airportName=${searchQuery}&city=all&state=all&runwayLength=all&operatingStartTime=all&operatingEndTime=all&aircraftName=all&offset=${pageState.page}&limit=${pageState.pageSize}&order=updatedTs.desc&profile=BASIC`

  //   const query = `search?roleId=eaf1aa60-fee7-4e32-9afc-e04fc8f61c48&uId=NA&email=all&fullName=${searchQuery}&isActive=NA&offset=${pageState.page}&limit=${pageState.pageSize}&order=recordUpdatedts.desc&profile=BASIC`;
  //   // setLoader(true)
  //   setPageState((prev) => ({ ...prev, loader: true }));
  //   serviceProvider.userService
  //     .get(query)
  //     .then((res) => {
  //       if (res.responseCode === 200) {
  //         // setAllAirports(res.data)
  //         setPageState((prev) => ({
  //           ...prev,
  //           loader: false,
  //           data: res.data.details,
  //           totalPages: res.data.totalRecordCount,
  //         }));
  //       } else {
  //         toast.error(res?.message || "Something Went Wrong !!");
  //       }
  //       // setLoader(false)
  //       // setPageState(prev=>({...prev,loader:false,data:res.data,totalPages:res.totalRecordCount}))
  //       // setAllAirports(res.data)
  //     })
  //     .catch((err) => {
  //       console.log({ err });
  //       setPageState((prev) => ({ ...prev, loader: false }));
  //       // setLoader(false)
  //     });
  // };

  const getAllFleetAircrafts = (fleetId: string) => {
    setLoader(true);
    serviceProvider.aircraftService
      .getAllAircrafts(fleetId)
      .then((res) => {
        setLoader(false);
        if (res.responseCode === 200) {
          const bmjFleetAircrafts = res.data.filter((aircraft:any) => aircraft?.owner?.toLowerCase() === "bmj");
          setFleetAircrafts(bmjFleetAircrafts);
          // setFleetAircrafts(res.data);
        }
        // console.log({ res });
      })
      .catch((err) => {
        setLoader(false);
        console.log({ err });
      });
  };

  const getAllAirportsList = () => {
    // setLoader(true);
    serviceProvider.airportService
      .getAllAirports()
      .then((res) => {
        // console.log({res})
        if (res.responseCode === 200) {
          setAirports(res.data);
          // setLoader(false)
        }
      })
      .catch((err) => {
        // setLoader(false)
        console.log({ err });
      });
  };

  const validateFieldsOnInput = (key: string) => {
    switch (key) {
      case "fleetId":
        validateFleet();
        break;
      case "aircraftId":
        validateAircraft();
        break;
      case "callSign":
        validateCallSign();
        break;
      case "baseLocation":
        validateBaseLocation();
        break;

      default:
        break;
    }
  };

  const handleInputChange = (property: any, newValue: any) => {
    // if (property === "startAirportId") setSearchInput({ ...searchInput, [property]: newValue.airportId })
    // else if (property === "endAirportId") setSearchInput({ ...searchInput, [property]: newValue.airportId })
    // else setSearchInput({ ...searchInput, [property]: newValue })

    if (property === "fleetId") {
      console.log({ newValue });

      getAllFleetAircrafts(newValue?.fleetId);
    } else if (property === "aircraftId") {
      setUserInput({ ...userInput, aircraftId: newValue?.aircraftId });
      // getAllFleetAircrafts(newValue?.fleetId)
    } else if (property === "baseLocation") {
      setUserInput({ ...userInput, baseLocation: newValue?.airportId });
      // getAllFleetAircrafts(newValue?.fleetId)
    } else if (property === "callSign") {
      setUserInput({ ...userInput, callSign: newValue });
    }
    validateFieldsOnInput(property);
  };

  const handleChooseFile = () => {
    // 👇️ open file input box on click of another element
    chooseFileRef.current?.click();
  };

  // new added
  const handleFileChange = (event: any) => {
    const fileObjs: [] = event.target.files;

    if (!fileObjs) {
      return;
    }

    const validFiles = Array.from(fileObjs).filter((fileObj) =>
      ValidationUtiltiy.isValidImg(fileObj["name"])
    );

    if (validFiles.length === 0) {
      toast.error("No valid files selected.");
      return;
    }

    const uploadPromises = validFiles.map((file: any, index) => {
      const newFileName = "123" + "_" + file.name.replace(/\..+$/, "");
      return uploadImage(file, newFileName);
    });
    setImgLoader(true)
    Promise.all(uploadPromises)
      .then((uploadedURLs) => {
        setImgLoader(false)
        setImageURLs((prevImageURLs: any) => [
          ...prevImageURLs,
          ...uploadedURLs,
        ]);
        updateUserInput(JSON.stringify(uploadedURLs));
        toast.success("Aircrafts Pictures Uploaded");
      })
      .catch((error) => {
        setImgLoader(false)
        console.error(error);
        toast.error("Error Occurred");
      });
  };

  // new added

  const uploadImage = (file: any, newFile: any) => {
    return new Promise((resolve, reject) => {
      const ReactS3Client = new S3(awsConfig);
      ReactS3Client.uploadFile(
        file,
        awsConfig.aircraftsSubFolder + "/" + newFile
      )
        .then((data) => {
          if (data.status === 204) {
            resolve(data.location);
          } else {
            reject("File Not Uploaded");
          }
        })
        .catch((err) => {
          reject("Something Went Wrong.");
        });
    });
  };
  console.log({ files });

  const updateUserInput = (imageUrl: string) => {
    // alert("dfdd")
    console.log({ imageUrl });

    if (imageUrl !== "") setUserInput({ ...userInput, imageUrl: imageUrl });
  };

  const handleVerifyCallSign = async () => {
    setLoader(true);
    try {
      const param = `callsign/verify?callSign=${userInput.callSign}`;
      const res: any = await serviceProvider.aircraftService.verifyCallSign(param);
      if (res?.responseCode === 200) {
        // setUrlVerified(true);
        setIsCallSignVerified(true)
        toast.success(res?.message || 'Call sign verified.');
      } else {
        toast.error(res?.message || "Something went wrong!");
      }
    } catch (err: any) {
      toast.error(err?.data?.message || "Something went wrong!");
    } finally {
      setLoader(false);
    }
  };

  const handleAssignAircraft = () => {
    // console.log({ userInput })
    const param = "assign";
    
    if(userInput.callSign && !isCallSignVerified) {
      toast.error("Please verify call sign.");
      return
    } 
    isValidRequest()
      .then(() => {
        setLoader(true);
        serviceProvider.aircraftService
          .assignAircraft(param, userInput)
          .then((res) => {
            setLoader(false);
            if (res.responseCode === 200) {
              toast.success(res.message);
              handleCloseModal();
              setTimeout(() => {
                // window.location.reload()
                window.location.href=`/operator-management?index=${0}`
              }, 100)
            } else {
              toast.error(res?.message || "Something Went Wrong !!");
            }
          })
          .catch((err) => {
            toast.error(err?.data?.message || "Something Went Wrong !!");
            setLoader(false);
            //   emptyInputFields()
            console.log({ err });
          });
      })
      .catch(() => {
        toast.error("Please fill all the Required Fields!");
      });
  };

  useEffect(() => {
    const query = `search?roleId=eaf1aa60-fee7-4e32-9afc-e04fc8f61c48&uId=NA&email=all&fullName=${searchQuery ||"all"}&isActive=NA&offset=${pageState.page}&limit=${pageState.pageSize}&order=recordUpdatedts.desc&profile=BASIC`;
    
    getAllOperators(query);
    
  }, [pageState.page, pageState.pageSize,searchQuery]);

  useEffect(() => {
    if (open) {
      getAllFleets();
      getAllAirportsList();
    }
  }, [open]);

  const modalBody = (
    <Grid container rowSpacing={4} className="assign-aircraft-modal">
      <Grid item xs={12}>
        <Typography variant="h5" component="h5">
          Assigning to <b>{selectedOperator?.fullName || "--"}</b>
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Grid container rowSpacing={3}>
          <Grid item xs={12}>
            <AutoCompleteComponent
              id="select-fleetId"
              options={allFleets}
              className="select-box"
              position="end"
              // defaultValue={searchResults?.startAirport}
              getOptionLabel={(option: any) => `${option.name}`}
              // getOptionLabel={(option: IAirport) => `${option.name} (${option.icaoCode})`}
              // renderOption={(props: any, option: any) => (
              //     <Box sx={{ width: "100%", borderBottom: "1px solid rgba(0, 0, 0, 0.12)" }} {...props}>
              //         <Typography textAlign="left">{option.city}</Typography>
              //         <Typography textAlign="left">{option.name} ({option.icaoCode})</Typography>
              //     </Box>
              // )}
              onChange={(e: any, newValue: any) =>
                handleInputChange("fleetId", newValue)
              }
              placeholder="Select Fleet"
            >
              {/* <img src={departureIcon} alt="departure-icon" height="25px" width="25px" /> */}
            </AutoCompleteComponent>
            <span className="modalError" id="errorDiv-select-fleetId"></span>
          </Grid>
          <Grid item xs={12}>
            <AutoCompleteComponent
              id="select-aircraftId"
              options={fleetAircrafts}
              className="select-box"
              position={"end"}
              // defaultValue={searchResults?.startAirport}
              getOptionLabel={(option: any) => `${option.name}`}
              // getOptionLabel={(option: IAirport) => `${option.name} (${option.icaoCode})`}
              // renderOption={(props: any, option: any) => (
              //     <Box sx={{ width: "100%", borderBottom: "1px solid rgba(0, 0, 0, 0.12)" }} {...props}>
              //         <Typography textAlign="left">{option.city}</Typography>
              //         <Typography textAlign="left">{option.name} ({option.icaoCode})</Typography>
              //     </Box>
              // )}
              onChange={(e: any, newValue: any) =>
                handleInputChange("aircraftId", newValue)
              }
              placeholder="Select Aircraft"
            >
              {/* <img src={departureIcon} alt="departure-icon" height="25px" width="25px" /> */}
            </AutoCompleteComponent>
            <span className="modalError" id="errorDiv-select-aircraftId"></span>
          </Grid>
          <Grid item xs={12}>
            <AutoCompleteComponent
              id="select-baseLocation"
              options={airports}
              position="end"
              
              className="select-box"
              // getOptionLabel={(option: any) => `${option.name} (${option.icaoCode})`}
              getOptionLabel={(option: IAirport) =>
                `${option.name} (${option.icaoCode}) - ${option.city}`
              }
              renderOption={(props: any, option: any) => (
                <Box
                  sx={{
                    width: "100%",
                    borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
                  }}
                  {...props}
                >
                  {/* <Typography textAlign="left">{option.city}</Typography> */}
                  <Typography textAlign="left">
                    {option.city}, {option.name} ({option.icaoCode})
                  </Typography>
                </Box>
              )}
              onChange={(e: any, newValue: any) =>
                handleInputChange("baseLocation", newValue)
              }
              placeholder="Select Base Location"
              // isIconVisible={true}
            >
              {/* <img src={destinationIcon} alt="destination-icon" height="25px" width="25px" /> */}
            </AutoCompleteComponent>

            <span
              className="modalError"
              id="errorDiv-select-baseLocation"
            ></span>
          </Grid>
          <Grid item xs={12}>
            <TextField
            variant="standard"
              id="aircraft-call-sign"
              className="call-sign-input"
              fullWidth={true}
              name="callSign"
              value={userInput.callSign}
              
              onChange={(e: any) =>
                handleInputChange("callSign", e.target.value)
              }
              placeholder={"Enter call sign"}
              InputProps={{
                disableUnderline:true,
                endAdornment: (
                  <Button
                    // className="common-button-underlined"
                    onClick={handleVerifyCallSign} // Replace handleVerify with your verify logic function
                    // sx={{ mr: 2 }}
                    disabled={!userInput.callSign}
                    sx={{padding:0, minWidth:0,margin:0,borderBottom:"1px solid #4A4A4A",borderRadius:"0",fontSize:"12px"}}
                  >
                    {isCallSignVerified ? "Verified" : "Verify"}
                    {isCallSignVerified ? (
                      <img src={verifyIcon} alt="verify-icon" height="22px" width="22px" style={{marginLeft:2}} />
                    ) : (
                      ""
                    )}
                  </Button>
                ),
              }}
            />
            <span
              className="modalError"
              id="errorDiv-aircraft-call-sign"
            ></span>
          </Grid>
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={12}>
                <Typography variant="body1" component="p">
                  Upload images
                </Typography>
              </Grid>
              <Grid item xs={12}>
                {
                  imgLoader ? <Loader height="150px" width="100%"/> : <Grid
                  container
                  rowSpacing={2}
                  className="upload-image-container"
                >
                  <Grid item xs={12} className="center">
                    <img src={addImageIcon} alt="upload-icon" />
                  </Grid>
                  <Grid item xs={12} className="center">
                    <input
                      type="file"
                      multiple
                      accept="image/*"
                      ref={chooseFileRef}
                      style={{ display: "none" }}
                      onChange={handleFileChange}
                    />
                    <ButtonComponent
                      className="btn"
                      fullWidth={false}
                      label="Choose file"
                      onClick={handleChooseFile}
                    />
                  </Grid>
                  <Grid item xs={12} className="images-container">
                   <Box  display="flex" justifyContent="center" gap="20px" alignItems="center">
                   {imageURLS
                      ?.slice(0, 3)
                      ?.map((imageSrc: any, index: any) => {
                        return (
                          // <Grid item xs={3} key={index}>
                            <Box className="card-img" >
                              <img src={imageSrc} alt="img" />
                            </Box>
                          // </Grid>
                        );
                      })}

                    {imageURLS?.length >= 3 && (
                      // <Grid item xs={3}>
                        <Box className="card-img more-imgs">
                          <img src={imageURLS[imageURLS?.length-1]} alt="img" />
                          <span>see more</span>
                        </Box>
                      // </Grid>
                    )}
                   </Box>
                  </Grid>
                </Grid>
                }
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} className="btn-container">
            <ButtonComponent
              fullWidth={false}
              className="rounded-btn-outlined"
              label="Cancel"
              onClick={handleCloseModal}
            />
            <ButtonComponent
              fullWidth={false}
              label="Assign"
              loader={loader}
              disabled={loader || imgLoader}
              
              onClick={handleAssignAircraft}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
  const deleteModalBody = (
    <Grid container rowSpacing={4} className="assign-aircraft-modal">
      <Grid item xs={12}>
        <Typography variant="h6" component="h6">
          Unassign Assigned Aircraft
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Grid container rowSpacing={3}>
          <Grid item xs={12}>
          <Box display="flex" justifyContent="flex-start" alignItems="flex-start" columnGap="10px" mb="10px">
              <InfoIcon sx={{color:"#ca3657",fontSize:"20px"}} />
              <Typography variant="caption" color="#4A4A4A" component="span">Unassigning aircraft will remove the aircraft availablity as well</Typography>
            </Box>
            <Autocomplete
              multiple
              className="select-box"
              id="select-fleetId"
              options={allAssignedAircrafts}
              getOptionLabel={(option) => option.name}
              // defaultValue={[top100Films[13]]}
              onChange={(e: any, newValue: any) =>
                handleMultitpleAircraftChange(newValue)
              }
            
              // placeholder="Select Aircraft"
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  multiline
                  minRows={4}
                  InputProps={{
                    ...params.InputProps,
                    disableUnderline: true,
                    placeholder:"Select Aircraft"
                  }}
                />
              )}
            />
          
            
            {/* <AutoCompleteComponent
              multiple={true}
              multiline={true}
              minRows={2}
              id="select-fleetId"
              options={allAssignedAircrafts}
              className='select-box'
              // defaultValue={selectedAircrafts}
              getOptionLabel={(option: any) => `${option.name}`}
              // getOptionLabel={(option: IAirport) => `${option.name} (${option.icaoCode})`}
              // renderOption={(props: any, option: any) => (
              //     <Box sx={{ width: "100%", borderBottom: "1px solid rgba(0, 0, 0, 0.12)" }} {...props}>
              //         <Typography textAlign="left">{option.city}</Typography>
              //         <Typography textAlign="left">{option.name} ({option.icaoCode})</Typography>
              //     </Box>
              // )}
              onChange={(e: any, newValue: any) => handleMultitpleAircraftChange(newValue)}
              placeholder="Select Aircraft"
            /> */}
            <span className="modalError" id="errorDiv-select-fleetId"></span>
            {/* <img src={departureIcon} alt="departure-icon" height="25px" width="25px" /> */}
            {/* </AutoCompleteComponent> */}
          </Grid>
          

          <Grid item xs={12} className="btn-container">
            <ButtonComponent
              fullWidth={false}
              className="rounded-btn-outlined"
              label="Cancel"
              onClick={handleCloseDeleteModal}
            />
            <ButtonComponent
              fullWidth={false}
              label="Unassign"
              loader={loader}
              disabled={loader}
              onClick={() => handleDeleteAircraft(selectedAircrafts)}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );

  const reactiveModalBody = (
    <Grid container rowSpacing={4} className="assign-aircraft-modal">
      <Grid item xs={12}>
        <Typography variant="h6" component="h6">
          Re-assign Aircraft
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Grid container rowSpacing={3}>
          <Grid item xs={12}>
          <Autocomplete
              multiple
              className="select-box"
              id="select-fleetId"
              options={allUnassignedAircrafts}
              getOptionLabel={(option) => option.name}
              // defaultValue={[top100Films[13]]}
              onChange={(e: any, newValue: any) =>
                handleMultitpleAircraftChange(newValue)
              }
              
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  multiline
                  minRows={4}
                  InputProps={{
                    ...params.InputProps,
                    disableUnderline: true,
                    placeholder:"Select Aircraft"
                    
                  }}
                />
              )}
            />
            {/* <AutoCompleteComponent
              multiple={true}
              multiline={true}
              minRows={2}
              id="select-fleetId"
              options={allUnassignedAircrafts}
              className="select-box"
              // defaultValue={searchResults?.startAirport}
              getOptionLabel={(option: any) => `${option.name}`}
              // getOptionLabel={(option: IAirport) => `${option.name} (${option.icaoCode})`}
              // renderOption={(props: any, option: any) => (
              //     <Box sx={{ width: "100%", borderBottom: "1px solid rgba(0, 0, 0, 0.12)" }} {...props}>
              //         <Typography textAlign="left">{option.city}</Typography>
              //         <Typography textAlign="left">{option.name} ({option.icaoCode})</Typography>
              //     </Box>
              // )}
              onChange={(e: any, newValue: any) =>
                handleMultitpleAircraftChange(newValue)
              }
              placeholder="Select Aircraft"
            /> */}
            <span className="modalError" id="errorDiv-select-fleetId"></span>
            {/* <img src={departureIcon} alt="departure-icon" height="25px" width="25px" /> */}
            {/* </AutoCompleteComponent> */}
          </Grid>

          <Grid item xs={12} className="btn-container">
            <ButtonComponent
              fullWidth={false}
              className="rounded-btn-outlined"
              label="Cancel"
              onClick={handleCloseReactiveModal}
            />
            <ButtonComponent
              fullWidth={false}
              label="Re-assign"
              loader={loader}
              disabled={loader}
              onClick={() => handleReactiveAircraft(selectedAircrafts)}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );

  // if(loader){
  //   return <SpinLoader />
  // }

  return (
    <>
      <CommonModal
        open={open}
        handleCloseModal={handleCloseModal}
        modalBody={modalBody}
      />
      <CommonModal
        open={openDeleteModal}
        handleCloseModal={handleCloseDeleteModal}
        modalBody={deleteModalBody}
      />
      <CommonModal
        open={openReactiveModal}
        handleCloseModal={handleCloseReactiveModal}
        modalBody={reactiveModalBody}
      />
      <Grid spacing={3} container>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={5}>
              <SearchBar
              value={searchQuery}
                onChange={(e:any)=>setSearchQuery(e.target.value)}
                placeholder={`Search by operator name`}
              />
            </Grid>
            <Grid item xs={7}></Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <DataGridTable
            pageState={pageState}
            setPageState={setPageState}
            rows={rows}
            columns={columns}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default OperatorTable;

const isValidRequest = (): Promise<boolean> => {
  let responseArray: IValidationResponse[] = [];

  responseArray.push(validateCallSign());
  responseArray.push(validateFleet());
  responseArray.push(validateAircraft());
  responseArray.push(validateBaseLocation());
  // responseArray.push(ValidationUtiltiy.validateElement("signup-confirmPassword", ValidationType.Text, true, "errorDiv-cpwd", "Confirm Password"));
  let haveErrors =
    responseArray.filter((x) => {
      return !x.isValid;
    }).length <= 0;
  return new Promise((resolve, reject) => {
    if (haveErrors) {
      resolve(true);
    } else {
      reject(false);
    }
  });
};

const validateCallSign = () => {
  return ValidationUtiltiy.validateElement(
    "aircraft-call-sign",
    ValidationType.Text,
    true,
    "errorDiv-aircraft-call-sign",
    "Call Sign"
  );
};
const validateFleet = () => {
  return ValidationUtiltiy.validateElement(
    "select-fleetId",
    ValidationType.AutoComplete,
    true,
    "errorDiv-select-fleetId",
    "Fleet"
  );
};
const validateAircraft = () => {
  return ValidationUtiltiy.validateElement(
    "select-aircraftId",
    ValidationType.AutoComplete,
    true,
    "errorDiv-select-aircraftId",
    "Aircraft"
  );
};
const validateBaseLocation = () => {
  return ValidationUtiltiy.validateElement(
    "select-baseLocation",
    ValidationType.AutoComplete,
    true,
    "errorDiv-select-baseLocation",
    "Base Location"
  );
};
