import React from "react";
import { Button, CircularProgress } from "@mui/material";

const ButtonComponent = ({
  loader = false,
  variant = "contained",
  fullWidth = true,
  className = "rounded-btn-fill",
  disabled = false,
  onClick,
  label,
}: any) => {
  return (
    <Button
      variant={variant}
      className={className}
      sx={{ height: "100%" }}
      fullWidth={fullWidth}
      disabled={disabled}
      startIcon={loader ? <CircularProgress size={20} color="inherit" /> : null}
      onClick={onClick}
    >
      {loader ? "loading.." : label}
    </Button>
  );
};

export default ButtonComponent;
