// import React from 'react';
import { FormControl, Select, MenuItem } from '@mui/material';

const SelectFieldComponent = ({ name="", value="",id="", options=[], labelKey="", optionKey="",onChange }:any) => {
  return (
    <FormControl fullWidth>
      <Select
        value={value}
        name={name}
        slotProps={{
          input:{
            id:id
          }
        }}
        // id={id}
        variant='standard' 

      //   slotProps={{
      //     textField: {
      //         placeholder:placeholder,
      //         variant: 'standard', inputProps: {
                  
      //         },
      //         InputProps:{
      //             disableUnderline:true
      //         }
      //     },
          
      // }}
        // placeholder='hello'
        disableUnderline
        onChange={onChange}
      >
        {options.map((option:any) => (
          <MenuItem key={option[optionKey]} value={option[optionKey]}>
            {option[labelKey]}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default SelectFieldComponent;
