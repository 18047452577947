import React from 'react';
import DataGridTable from '../DataGridTable/DataGridTable';
import { ManageUser } from './ManageUser';
import { Tooltip } from '@mui/material';




const UsersTable = ({pageState,setPageState,users,getUsersList}:any)=> {
  const rows = users.map((user:any, index:any) => ({
    ...user,
    id: user.uId,
    roleName: JSON.parse(user.role).roleName,
    roleId : JSON.parse(user.role).roleId,
  }));


  const columns = [
    //   { field: 'id',
    //    headerName: '',
    //   //  width: 90
    //  },
      {
        field: 'fullName',
        headerName: 'Name',
        width: 200,
        editable: false,
        renderCell: (params: any) => {
          return (
            <Tooltip title={params.row?.fullName}>
              <span>{params.row?.fullName}</span>
            </Tooltip>
          );
        },
      },
      {
        field: 'email',
        headerName: 'Email',
        width: 300,
        editable: false,
        renderCell: (params: any) => {
          return (
            <Tooltip title={params.row?.email}>
              <span>{params.row?.email}</span>
            </Tooltip>
          );
        },
      },
      {
        field: 'mobileNumber',
        headerName: 'Contact',
        // type: 'number',
        width: 150,
        // width: 300,
        editable: false,
      },
      {
        field: 'roleName',
        headerName: 'Role',
        width: 200,
        // width: 150,
        editable: false,
      },
      // {
      //   field: 'noOfBookings',
      //   headerName: 'No. of Bookings',
      //   width: 200,
      //   // width: 150,
      //   editable: false,
      // },
      // {
      //   field: 'bmjRewardPoints',
      //   headerName: 'BMJ reward points',
      //   width: 200,
      //   // width: 150,
      //   editable: false,
      // },
      {
        field: 'manage',
        type: 'actions',
        headerName: 'Manage',
        width: 200,
        getActions: (params: any) =>  [
          <ManageUser userId={params.row.uId} roleId={params.row.roleId} isActive={params.row.isActive} pageState={pageState} getUsersList={getUsersList} />
        ],
      },
     
    ];


 

  return <DataGridTable pageState={pageState} setPageState={setPageState} rows={rows} columns={columns}  />
}


export default UsersTable;