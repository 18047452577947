
import React,{useState} from "react"

import { Button, FormControl, Grid, MenuItem, Select, TextField, Tooltip, Typography } from '@mui/material';

import { serviceProvider } from "../../provider/serviceProvider";
import {toast} from "react-toastify";

const DeactivateUserModalBody = ({handleCloseDeactivateUserModal,userId,pageState,getUsersList}:any) => {

  
    const handleDeactivateUser=(userId :string)=>{
        const param =`${userId}/activate/false`
      serviceProvider.userService.put(param,{}).then((res)=>{
        console.log(res);
        if(res.responseCode ===200){
          toast.success(res.message)
          handleCloseDeactivateUserModal()
          const query = `search?uId=NA&email=all&fullName=all&isActive=${true}&offset=${pageState.page}&limit=${pageState.pageSize}&order=recordUpdatedts.desc&profile=BASIC`
  getUsersList(query)
          // setTimeout(()=>{
          //   // window.location.reload();
          //   window.location.href =`/users-management?index=${indexNumber}`
          // },100)
        }else{
          toast.error(res?.message || "Something Went Wrong!!")
        }
      }).catch((err)=>{
        console.log(err)
        toast.error(err?.data?.message || "Something Went Wrong!!")
        handleCloseDeactivateUserModal()
      })
      }
    
    return (
        <Grid container>
        <Grid item xs={12}>
          <Typography variant="h6" component="h6" textAlign="center">Confirm to deactivate user?</Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid container columnSpacing={2} className="btn-container">
            <Grid item xs={6} display="flex" justifyContent="flex-end">
              <Button variant="outlined" className="rounded-btn-outlined" onClick={handleCloseDeactivateUserModal}>Cancel</Button>
            </Grid>
            <Grid item xs={6}>
              <Button variant="contained" className="rounded-btn-fill" onClick={()=>handleDeactivateUser(userId)}>Confirm</Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };


  export default DeactivateUserModalBody;