import { Autocomplete } from '@mui/material';
export enum ValidationType {
    Mail,
    Email,
    Mobile,
    Integer,
    Float,
    Pan,
    Adhar,
    Password,
    Date,
    Select,
    Text,
    URL,
    MUISelect,
    AutoComplete,
    ImgFile,
    Editor
}