import React, { useEffect, useState } from 'react'
import { Typography, Grid, Button } from "@mui/material";
import { serviceProvider } from '../../provider/serviceProvider';
import CommonModal from '../modal/CommonModal';
import SearchBar from '../searchBar/SearchBar';
import { toast } from 'react-toastify';
import FeedTable from './FeedTable';
import AddFeedForm from './AddFeedForm';
import { useLocation, useNavigate } from 'react-router-dom';
import FeedTabs from './FeedTabs';

const FeedManagement: React.FC = () => {
  const [open, setOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [value, setValue] = React.useState(0);
  const [pageState, setPageState] = React.useState({
    loader: false,
    data: [],
    totalPages: 0,
    page: 0,
    pageSize: 5,
  });

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const index = searchParams.get("index");

  // Convert the 'index' value to a number if needed
  const indexNumber = parseInt(index + "", 10);
  const navigate = useNavigate();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    navigate(`/feeds-management?index=${newValue}`);
  };


  const handleOpenModal = () => setOpen(true)
  const handleCloseModal = () => setOpen(false);


  const getAllFeeds = (param:any) => {
    setPageState(prev=>({...prev,loader:true}))
    
    serviceProvider.feedService.get(param).then((res) => {
     
      if (res.responseCode === 200) {
        
        setPageState(prev=>({...prev,loader:false,data:res.data.data,totalPages:res.data.totalRecordCount}))
      } else {
       
        toast.error(res?.message||"Something Went Wrong !!")
      }
      setPageState(prev=>({...prev,loader:false}))
      
    }).catch((err) => {
      setPageState(prev=>({...prev,loader:false}))
      toast.error(err?.data?.message || "Something Went Wrong !!")
    })
  }

  // useEffect(() => {
  //   // const query = `airportName=${searchQuery || "all"}&city=all&state=all&runwayLength=0&operatingStartTime=all&operatingEndTime=all&aircraftName=all&offset=${pageState.page}&limit=${pageState.pageSize}&order=updatedTs.desc&profile=BASIC`
  //   const query = `search?feedTitle=${searchQuery || "all"}&feedId=all&isActive=${true}&offset=${pageState.page}&limit=${pageState.pageSize}&order=updatedTs.desc&profile=BASIC`
  //   getAllFeeds(query)
  // }, [pageState.page,pageState.pageSize,searchQuery])


  useEffect(() => {
    if (indexNumber) {
      setValue(indexNumber);
    } else {
      setValue(0);
    }
  }, [indexNumber]);

  
  useEffect(() => {
    const active = value === 0 ? true : false;
    const query = `search?feedTitle=${searchQuery || "all"}&feedId=all&isActive=${active}&offset=${pageState.page}&limit=${pageState.pageSize}&order=updatedTs.desc&profile=BASIC`
    getAllFeeds(query)
  }, [value, pageState.page, pageState.pageSize,searchQuery]);



  const modalBody = <AddFeedForm handleCloseModal={handleCloseModal} getAllFeeds={getAllFeeds} />;


  return (
    <>
      <CommonModal open={open} handleCloseModal={handleCloseModal} modalBody={modalBody}  />
      <Grid container spacing={2} className="airports-container">
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={5} md={5} className="fleet-heading">
              <Typography variant='h6' component='h6'>List of Feeds</Typography>
            </Grid>
            <Grid item xs={7} md={7}>
              <SearchBar 
              value={searchQuery}
              // onChange={handleSearch}
              onChange={(e:any)=>setSearchQuery(e.target.value)}
               placeholder="Search Feeds" />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Button variant="contained" className="rounded-btn-fill" onClick={handleOpenModal}>Add Feed</Button>
        </Grid>
        <Grid item xs={12}>
        <FeedTabs
            pageState={pageState}
            setPageState={setPageState}
            value={value}
            handleChange={handleChange}
            getAllFeeds={getAllFeeds}
          />
          {/* <FeedTable pageState={pageState} setPageState={setPageState} getAllFeeds={getAllFeeds}  /> */}
        </Grid>

      </Grid>
    </>
  )
}

export default FeedManagement