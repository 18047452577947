import React, { useState } from "react";

import {
  Button,
  FormControl,
  Grid,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";

import { serviceProvider } from "../../provider/serviceProvider";
import { toast } from "react-toastify";
import ButtonComponent from "../common/ButtonComponent";

const ActivateEmptyLegFlight = ({
  handleCloseActivateFlightModal,
  flightId,
  pageState,
  getEmptyLegFlights,
}: any) => {
  const [loader,setLoader]=useState(false);

  const handleActivateFlight = (flightId: any) => {
    // const payload = {status:false}
    const param = `${flightId}/${true}`;
    setLoader(true);
    serviceProvider.flightService
      .put(param, {})
      .then((res) => {
        // console.log({ res })
        setLoader(false);
        if (res.responseCode === 200) {
          toast.success(res.message);
          //   window.location.href="empty-leg-management"
          handleCloseActivateFlightModal();
          const query = `flightId=NA&flightType=EMPTYLEG&isActive=false`;
          getEmptyLegFlights(query);
          // setTimeout(()=>{
          //   window.location.href="empty-leg-management"
          // },100)
          // emptyInputFields()
          // setCabinSpecs()
        } else {
          toast.error(res?.message || "Something Went Wrong!!");
        }
      })
      .catch((err) => {
        toast.error(err?.data?.message || "Something Went Wrong !!");
        setLoader(false);
        //   emptyInputFields()
        console.log({ err });
      });
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant="h6" component="h6" textAlign="center">
          Confirm to Activate Flight?
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Grid container columnSpacing={2} className="btn-container">
          <Grid item xs={6} display="flex" justifyContent="flex-end">
            <Button
              variant="outlined"
              className="rounded-btn-outlined"
              onClick={handleCloseActivateFlightModal}
            >
              Cancel
            </Button>
          </Grid>
          <Grid item xs={6}>
            {/* <Button variant="contained" className="rounded-btn-fill" onClick={()=>handleActivateFlight(flightId)}>Confirm</Button> */}
            <ButtonComponent
              loader={loader}
              disabled={loader}
              fullWidth={false}
              onClick={() => handleActivateFlight(flightId)}
              label="Confirm"
              
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ActivateEmptyLegFlight;
