import CryptoUtility from "./cryptoUtility";

export class LocalStorageUtility {

    public static getStorageString(key: string) {
        return CryptoUtility.decrypt(localStorage.getItem(key));
    }
    public static setStorageString(key: string, input: string) {
        localStorage.setItem(key, CryptoUtility.encrypt(input));
    }
    public static getStorageObj(key: string) {
        return CryptoUtility.decryptObj(localStorage.getItem(key));
    }
    public static setStorageObj(key: string, input: any) {
        localStorage.setItem(key, CryptoUtility.encryptObj(input))
    }
    public static removeStorage(key: string) {
        localStorage.removeItem(key);
    }
    public static clearStorage() {
        localStorage.clear();
    }
}