import { IMockStorage } from "../interface/IMockStorage";
import { IUserMock } from "../interface/IUser";


export class UserStorage implements IMockStorage {
    private data: IUserMock[] = [];

    constructor() {
        this.data = dataArray;
    }
    insert(item: any): void {
        throw new Error("Method not implemented.");
    }
    update(item: any): void {
        throw new Error("Method not implemented.");
    }
    delete(srno: number): void {

        this.data.filter(x => x.srno !== srno);
    }
    get() {
        return this.data;
    }
    getByScenario(scenarioId: string) {
        return this.data.filter(x => x.scenarioId === scenarioId)
    }
    getByEmailPassword(mobileNumber: string, otp: string) {
        const data = this.data.filter(x => x.mobileNumber === mobileNumber);
        return data;
    }
}

const dataArray: IUserMock[] = [
]