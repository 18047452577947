import { Grid, Typography } from '@mui/material'
import React from 'react'
import { getFormattedDate, getFormattedTime } from '../../utility/formateDateAndTime'
import dividerPlane from "../../assets/icons/divider-plane.svg"


const ItineraryDetail = ({itineraryDetails}:any) => {
  return (
    <Grid container>
    <Grid item xs={12} className="grid-item">
      <Typography variant='body2' component="p">{getFormattedDate(itineraryDetails?.startDt)}</Typography>
      <Typography variant='body2' component="p" textAlign="right">{getFormattedDate(itineraryDetails?.endDt)}</Typography>
      {/* <Typography variant='body2' component="p">06:55</Typography> */}
      {/* <Typography variant='body2' component="p">VIDP</Typography> */}
    </Grid>
    <Grid item xs={12} className="grid-item">
      <Typography variant='body2' component="p">{getFormattedTime(itineraryDetails?.startDt)}</Typography>
      <Typography variant='body2' component="p" textAlign="right">{getFormattedTime(itineraryDetails?.endDt)}</Typography>
      {/* <Typography variant='body2' component="p">VIDP</Typography> */}
    </Grid>
    <Grid item xs={12} >
      <Grid container className="grid-item">
        <Grid item xs={2}>
          <Typography variant='body2'  textAlign="left" component="p">{itineraryDetails?.startAirportDetails?.icaoCode || "--"}</Typography>
        </Grid>
        <Grid item xs={8}>
          <img src={dividerPlane} alt="plane" width="100%" />
        </Grid>
        <Grid item xs={2}>
          <Typography variant='body2' textAlign="right" component="p">{itineraryDetails?.endAirportDetails?.icaoCode || "--"}</Typography>
        </Grid>
      </Grid>



    </Grid>
    {/* <Typography variant='body2' textAlign="left" component="p">{quotation?.itineraryDetails?.startAirport?.icaoCode || "--"} */}
    <Grid item xs={12} className="grid-item" >
      <Typography variant='body2' component="p" sx={{wordWrap: "break-word"}}>{itineraryDetails?.startAirportDetails?.name || "--"}</Typography>
      <Typography variant='body2' component="p" textAlign="right" sx={{wordWrap: "break-word",textAlign:"right"}}>{itineraryDetails?.endAirportDetails?.name || "--"}</Typography>
    </Grid>
    <Grid item xs={12} className="grid-item">
      <Typography variant='body2' component="p">{itineraryDetails?.startAirportDetails?.city || "--"}</Typography>
      <Typography variant='body2' component="p" textAlign="right">{itineraryDetails?.endAirportDetails?.city || "--"}</Typography>
    </Grid>
  </Grid>
  )
}

export default ItineraryDetail