// import React, { useEffect, useState } from 'react';
// import { Typography, Grid, Box, Tabs, Tab, Button } from "@mui/material";
// import OfferedFlightTable from './OfferedFlightTable';
// import { serviceProvider } from '../../provider/serviceProvider';
// import { CustomTabPanel, a11yProps } from '../Tab/CustomTabPanel';
// import { useNavigate } from 'react-router-dom';
// import OfferedFlightTabs from './OfferedFlightTabs';
// import ButtonComponent from '../common/ButtonComponent';

// const OfferedFlightManagement:React.FC = () => {
//   const [value, setValue] = React.useState(0);
//   // const [loader,setLoader]=useState(true);
//   // const [offeredFlights,setOfferedFlights] =useState([]);
//   const [pageState, setPageState] = React.useState({
//     loader:false,
//     data:[],
//     totalPages:0,
//     page:0,
//     pageSize:5,

//   });
//   const navigate= useNavigate()

//   const handleChange = (event: React.SyntheticEvent, newValue: number) => {
//     console.log(event)
//     setValue(newValue);
//   };

//   const getOfferedFlights=(params:any)=>{

//     // setLoader(true)
//     setPageState(prev=>({...prev,loader:true}))
//     serviceProvider.flightService.get(params).then((res:any)=>{
//       if(res.responseCode ===200){
//           // alert(res.data.totalRecordCount)
//           setPageState(prev=>({...prev,loader:false,data:res.data,totalPages:10}))
//     }

//     setPageState(prev=>({...prev,loader:false}))

//   }).catch((err)=>{
//       console.log({err})
//       setPageState(prev=>({...prev,loader:false}))
//       // setLoader(false)
//     })
//   }

//   // const getAllAirportsList = () => {
//   //   setLoader(true);
//   //   serviceProvider.airportService.getAllAirports().then((res) => {
//   //     // console.log({res})
//   //     if (res.responseCode === 200) {
//   //       setAirports(res.data)
//   //     }

//   //   }).catch((err) => {
//   //     console.log({ err })
//   //   })
//   // }

//   // const activeFlights= offeredFlights?.filter((flight:any)=>flight.active === true);
//   // const inActiveFlights= offeredFlights?.filter((flight:any)=>flight.active === false);

//   useEffect(()=>{
//      let params;
//      if(value===0 ) params =  `flightId=NA&flightType=EXCLUSIVE&isActive=true`
//      else params=  `flightId=NA&flightType=EXCLUSIVE&isActive=false`

//     getOfferedFlights(params)
//     // getAllAirportsList()
//   },[value,pageState.page,pageState.pageSize])

//   return (
//     <Grid container spacing={2}>
//       <Grid item xs={12}>
//         <Grid container spacing={2}>
//           <Grid item xs={12} md={12}>
//             <Typography variant='h6' component='h6'>Offered Flight</Typography>
//           </Grid>
//           <Grid item xs={12}>
//           <ButtonComponent
//           fullWidth={false}
//           label="Add Offered Flight"
//           onClick={()=>navigate("/offered-flight-management/add")}
//           />
//         </Grid>
//         </Grid>
//       </Grid>

//       <Grid item xs={12} >
//        <OfferedFlightTabs pageState={pageState} setPageState={setPageState} value={value} handleChange={handleChange}/>
//       </Grid>
//     </Grid>
//   )
// }

// export default OfferedFlightManagement;

import React, { useEffect, useState } from "react";
import { Typography, Grid, Box, Tabs, Tab, Button } from "@mui/material";
import OfferedFlightTable from "./OfferedFlightTable";
import { serviceProvider } from "../../provider/serviceProvider";
import { CustomTabPanel, a11yProps } from "../Tab/CustomTabPanel";
import { useLocation, useNavigate } from "react-router-dom";
import OfferedFlightTabs from "./OfferedFlightTabs";
import ButtonComponent from "../common/ButtonComponent";

const OfferedFlightManagement: React.FC = () => {
  const [value, setValue] = React.useState(0);
  // const [loader,setLoader]=useState(true);
  // const [offeredFlights,setOfferedFlights] =useState([]);
  const [pageState, setPageState] = React.useState({
    loader: false,
    data: [],
    totalPages: 0,
    page: 0,
    pageSize: 5,
  });

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const index = searchParams.get("index");
console.log({index})
  // Convert the 'index' value to a number if needed
  const indexNumber = parseInt(index + "", 10);
  const navigate = useNavigate();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    console.log(event);
    setValue(newValue);
    navigate(`/offered-flight-management?index=${newValue}`);
  };

  const getOfferedFlights = (query: any) => {
    // setLoader(true)
    setPageState((prev) => ({ ...prev, loader: true }));
    serviceProvider.flightService
      .get(query)
      .then((res: any) => {
        if (res.responseCode === 200) {
          // alert(res.data.totalRecordCount)

          // const newData = res?.data?.slice(pageState.page * pageState.pageSize, (pageState.page + 1) * pageState.pageSize);
          const totalPages = res?.data?.length;

          setPageState((prev) => ({
            ...prev,
            loader: false,
            data: res?.data,
            totalPages: totalPages,
          }));
          // setPageState(prev=>({...prev,loader:false,data:res.data,totalPages:10}))
        }
        setPageState((prev) => ({ ...prev, loader: false }));
      })
      .catch((err) => {
        console.log({ err });
        setPageState((prev) => ({ ...prev, loader: false }));
        // setLoader(false)
      });
  };

  // const getAllAirportsList = () => {
  //   setLoader(true);
  //   serviceProvider.airportService.getAllAirports().then((res) => {
  //     // console.log({res})
  //     if (res.responseCode === 200) {
  //       setAirports(res.data)
  //     }

  //   }).catch((err) => {
  //     console.log({ err })
  //   })
  // }

  // const activeFlights= offeredFlights?.filter((flight:any)=>flight.active === true);
  // const inActiveFlights= offeredFlights?.filter((flight:any)=>flight.active === false);
  // useEffect(() => {
  //   if (indexNumber) {
  //     setValue(indexNumber);
  //   } else {
  //     setValue(0);
  //   }
  // }, [indexNumber]); 


  // useEffect(() => {
  //   if (value === 0) {
  //     // alert(value)
  //     // const query = `flightId=NA&flightType=EXCLUSIVE&isActive=true`;
  //     // getOfferedFlights(query);
  //   } else if(value === 1) {
  //     // alert(value)
  //     const query = `flightId=NA&flightType=EXCLUSIVE&isActive=false`;
  //     getOfferedFlights(query);
  //   }
  //   //  let params;
  //   //  if(value===0 )
  //   //  else params=

  //   // getAllAirportsList()
  //   // },[value,pageState.page,pageState.pageSize])
  // }, [value]);

  useEffect(() => {
    console.log({indexNumber})
    // alert(indexNumber)
    if (indexNumber) {
      setValue(indexNumber);
      const query = `flightId=NA&flightType=EXCLUSIVE&isActive=false`;
      getOfferedFlights(query);
    } else {
      setValue(0);
      const query = `flightId=NA&flightType=EXCLUSIVE&isActive=true`;
      getOfferedFlights(query);
    }
  }, [indexNumber,value]);
  
 

 

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <Typography variant="h6" component="h6">
              Offered Flight
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <ButtonComponent
              fullWidth={false}
              label="Add Offered Flight"
              onClick={() => navigate("/offered-flight-management/add")}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <OfferedFlightTabs
          pageState={pageState}
          setPageState={setPageState}
          value={value}
          handleChange={handleChange}
          getOfferedFlights={getOfferedFlights}
        />
      </Grid>
    </Grid>
  );
};

export default OfferedFlightManagement;
