import React, { useState } from 'react'
import { Typography,Card, CardMedia, CardContent, Box, Button} from "@mui/material";
import turboProp from "../../assets/images/turboprops.svg"
import { IFleet } from '../../interface/IFleet';
import { getFormattedDate } from '../../utility/formateDateAndTime';
import deleteIcon from "../../assets/icons/delete-icon.svg";
import { toast } from 'react-toastify';
import { serviceProvider } from '../../provider/serviceProvider';


interface FleetCardProps{
  fleet: IFleet
}
const FleetCard:React.FC<FleetCardProps> = ({fleet}) => {

  const [loader, setLoader] = useState(false)

  const handleDeleteFleet = (fleetId: any) => {
    setLoader(true);
    serviceProvider.fleetService.delete(fleetId).then((res: any) => {
      setLoader(false)
      if (res.responseCode === 204) {
        toast.success(res.message);
        window.location.reload()
      } else {
        toast.error(res?.message||"Something Went Wrong.")
      }
    }).catch((err) => {
      console.log(err)
      setLoader(false)
      toast.error(err?.data?.message||"Something Went Wrong.")
    })
  }
  return (
    <Card className="fleet-card">
    <CardMedia
      component="img"
      height="140"
      image={fleet?.imageUrl}
      // image={fleet?.imageUrl}
      alt="turboProp"
    />
    <CardContent className='card-content'>
      <Typography gutterBottom variant="h6" component="h6">
      {fleet?.name}
      </Typography>
      <Typography  variant="body1" component="p">
      No. of planes : {fleet?.numberOfAircrafts}
      </Typography>
      <Typography  variant="body1" component="p">
      Last updated on : {getFormattedDate(fleet?.recordUpdatedTs || "")}
      </Typography>
      <Box sx={{display:"flex",justifyContent:"flex-end"}}>
      <Button variant='text' onClick={() => handleDeleteFleet(fleet?.fleetId)}>
              <img src={deleteIcon} alt="delete Icon" width="15px" />
            </Button>
      </Box>
    </CardContent>


 
</Card>
  )
}

export default FleetCard